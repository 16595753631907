import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import {translate} from "../../../../../translations/i18n";

const styles = theme => ({
    root:{
        display: 'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        padding:20,
    },
    icon:{
        fontSize:68,
        color: theme.palette.success.main,
    },
    text:{
        fontSize:20,
        maxWidth:300,
        textAlign:'center',
        marginTop:20
    }
});

class ImportLinkCompleted extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Icon className={classes.icon}>check_circle</Icon>
                <Typography className={classes.text}>
                    {translate('import_link.step_3.title', {}, "Importazione dei componenti avvenuta con successo")}
                </Typography>
            </div>
        );
    }
}

ImportLinkCompleted.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(ImportLinkCompleted);
