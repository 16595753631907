import API from './API';

class CategoryApi {

    static url = 'category';


    // ========================================= //
    // CATEGORY
    // ========================================= //

    static getCategory(query){
        const api = new API(this.url);
        return api.get(query);
    }

    static getCategoryById(id) {
        const api = new API(this.url);
        return api.getById(id);
    }

    static createCategory(model) {
        const api = new API(this.url);
        return api.post(model);
    }

    static updateCategory(id, model) {
        const api = new API(`${this.url}/${id}`);
        return api.update(model);
    }

    static addCategoryLabels(model, id, lang) {
        const api = new API(`core/${this.url}/${id}/label/${lang}`);
        return api.post(model);
    }

    static editCategoryLabels(model, id, lang) {
        const api = new API(`core/${this.url}/${id}/label/${lang}`);
        return api.update(model);
    }

    static deleteCategoryLabels(model, id, lang) {
        const api = new API(`core/${this.url}/${id}/label/${lang}`);
        return api.delete();
    }

    static deleteCategory(id) {
        const api = new API(this.url);
        return api.deleteById(id);
    }
}

export default CategoryApi;
