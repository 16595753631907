import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateUser } from '../../../../redux/actions/user-action';
import { updateLicense } from '../../../../redux/actions/license-action';
// Utils import
import Utils from '../../../../utils/Utils';
// components import
import ChangePasswordDialog from '../../../../components/ui/dialogs/changePasswordDialog/ChangePasswordDialog';
import LicenseDialog from '../../../../components/ui/dialogs/licenseDialog/LicenseDialog';
import Button from "@material-ui/core/Button";
import {MenuList, ClickAwayListener, Grow, Paper, Popper} from "@material-ui/core";
import LanguageDialog from "../../../../components/ui/dialogs/changeLanguageDialog/LanguageDialog";
import {translate} from "../../../../translations/i18n";
import BreadCrumbs from "../../../../components/ui/breadcrumbs/BreadCrumbs";
import LogoProfileOpen from "../../../../assets/logos/Logo-DKC.png";

const styles  = theme => ({
    appBar: {
        height : 103,
        boxShadow : 'none',
        background: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        zIndex: theme.zIndex.drawer - 1,
        transition: theme.transitions.create(['width', 'margin','marginLeft','paddingLeft'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        display:'flex',
        alignItems : 'flex-end',
        // borderBottom:'1pxx solid rgba(0,0,0,0.3)'
            [theme.breakpoints.down('xs')]:{
            height : 63
        }
    },
    margin: {
        margin: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 3,
    },
    appBarShift: {
        // marginLeft: 290,
        // paddingLeft : 16,
        width: `calc(100% - 201px)`,
        transition: theme.transitions.create(['width', 'margin','marginLeft','paddingLeft'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('xs')]:{
            width: '100%',
        }
    },
    toolbar:{
        height : '100%',
        width : 'calc(100% - 169px)',
        margin : '0px 40px',
        borderBottom : '1px solid rgba(0,0,0,0.3)',
        [theme.breakpoints.down('xs')]:{
            width: '100%',
            padding: '0px 20px',
            margin: '0px',
            borderBottom : 'unset',
        }
    },
    menuButton: {
        marginLeft: 12,
        [theme.breakpoints.down('sm')]:{
            marginLeft : 'auto'
        }

        },
    badge:{
        width:18,
        height:18,
        background: theme.palette.danger.dark,
        fontWeight: 600,
    },
    hide: {
        display: 'none',
    },
    /* Avatar */
    avatar: {
        cursor:'pointer',
        // marginRight:20,
        background: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]:{
            // marginRight:12,
      }
    },
    avatarShift: {
        cursor:'pointer',
        background: theme.palette.primary.main,
        // marginRight: -4
    },
    username:{
        marginRight:20,
        fontSize:14,
        [theme.breakpoints.down('sm')]:{
            display: 'none'
        }
    },
    rightContainer: {
        transform: 'skew(-30deg)',
        width:256,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        right: -40,
        [theme.breakpoints.down('sm')]:{
            width:220,
        }
        },
    basicBackground: {
/*
        backgroundImage: 'linear-gradient(to top, #797272, #ded4d4)'
*/
    },
    techBackground: {
/*
        backgroundImage: `linear-gradient(to top, ${theme.palette.secondary.light}, ${theme.palette.secondary.light})`
*/
    },
    commercialBackground: {
/*
        backgroundImage: 'linear-gradient(to top, #0085bf, #0085bf)'
*/
    },
    licenseTitle: {
        marginRight: 20,
        color:'#000!important',
        minWidth: 112
    },
    licenseMenuItem: {

    },
    usernameMobile:{
        fontSize:12,
        display: 'none',
        padding: '8px 20px',
        [theme.breakpoints.down('sm')]:{
            display: 'block'
        }
    },
    root : {
        flex : 1,
        // paddingLeft: 56,
        height : '100%',
        display : 'flex',
        alignItems: 'center',
        transition: theme.transitions.create(['paddingLeft'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
         [theme.breakpoints.down('xs')] :{
             paddingLeft: 0,
         }
    },
    rootOpen : {
        flex : 1,
        paddingLeft: 0,
        transition: theme.transitions.create(['paddingLeft'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    bottomSeparator : {
        width: 'calc(100% - 72px)',
        height: '1px',
        background: 'rgba(0, 0, 0, 0.3)',
        marginLeft: 'auto',
    },
    bottomSeparatorOpen : {
        width: 'calc(100% - 290px)',
        height: '1px',
        background: 'rgba(0, 0, 0, 0.3)',
        marginLeft: 'auto',
    }
});

class HeaderAppBar extends Component {

    constructor(props){
        super(props);
        this.state = {
            anchorProfileEl: null,
            anchorLicenseEl: null,
            anchorNotificationEl: null,
            openLanguageDialog: false,
        };
    }

    updateLicense = (license_code) => {
        this.setState({anchorLicenseEl: null});
        const license = this.props.user.company.licenses.find((item) => {return item.license === license_code});
        if (license){
            this.props.onUpdateLicense(license);
        }
    };

    render() {
        const { user, license, classes, } = this.props;
        const { anchorProfileEl, anchorLicenseEl} = this.state;
        const anchorRef = React.createRef();

        const licenses = user.company && user.company.licenses.map((item) => (
            (item.license !== license.license)?
                <Link to={'/dashboard'}>
                <MenuItem className={classes.licenseMenuItem}
                          onClick={() => this.updateLicense(item.license)}>
                    {item.license.toUpperCase()}
                </MenuItem>
                </Link>
                :null
        ));

        const backgroundLicense = () => {
            switch (license.license) {
                case 'tech':
                        return classes.techBackground;
                case 'commercial':
                        return classes.commercialBackground;
                default: return classes.basicBackground;
            }
        };

        return (
            <AppBar
                position="fixed"
                className={classNames(classes.appBar, {
                    [classes.appBarShift]: this.props.open,
                })}>

                <Toolbar
                    className={classes.toolbar}
                    disableGutters>
                    <Hidden smUp>

                        <Link to={`/dashboard`} style={{
                            height: '100%',
                            marginLeft:'auto'
                        }}>

                        <img alt={'ubilod-logo'} src={LogoProfileOpen} style={{
                            height: '100%',
                            padding: '16px 0px',
                        }}
                        />
                        </Link>


                    <IconButton
                        color="primary"
                        onClick={() => this.props.handleDrawer()}
                        className={classNames(classes.menuButton, {
                            // [classes.hide]: this.props.open,
                        })}>
                        <Icon>menu</Icon>
                    </IconButton>

                    </Hidden>
                    <Hidden xsDown>

                    <div
                        className={classNames(classes.root, {
                            [classes.rootOpen]: this.props.open,
                        })}
                    >
                        {/*<Link to={'/'}>
                            <Hidden smDown>
                                <img alt={'ubilod-logo'} src={(this.props.user &&
                                    (this.props.user.username === 'dkc'
                                        ||
                                        (this.props.user.company && this.props.user.company.business_name === 'DKC Tech')))
                                    ?
                                    LogoDKC
                                    :
                                    Logo
                                }
                                     width={150} style={{marginTop:4}} />
                            </Hidden>
                            <Hidden mdUp>
                                <img alt={'ubilod-logo'} src={LogoDKC} width={100} style={{marginTop:4}} />
                            </Hidden>
                        </Link>*/}
                        {/* Breadcrumbs */}
                        {(this.props.router && this.props.router.route && this.props.router.route.path !== '/m' && this.props.router.route.path !== '/') ?
                            <Hidden xsDown>
                            <BreadCrumbs/>
                            </Hidden>
                            :
                            null
                        }
                    </div>

                    {/*
                    <div className={[classes.rightContainer, backgroundLicense()].join(' ')}/>
                    */}

                    {(license.license) ?
                            <Button onClick={(e) => user.company.licenses.length > 1 && this.setState({ anchorLicenseEl: e.currentTarget })}
                                    disabled={user.company.licenses.length < 2}
                                    ref={anchorRef}
                                    className={classes.licenseTitle}>
                                {license.license}
                                {(user.company.licenses.length > 1) ?
                                        <Icon style={{color:'#000', fontSize:16}}>keyboard_arrow_down</Icon>
                                    : null}
                            </Button>
                            : <Button disabled={true}
                                      ref={anchorRef}
                                      className={classes.licenseTitle}>
                                Basic
                            </Button>
                        }

                    {/*<Popper
                        open={Boolean(anchorLicenseEl)}
                        anchorEl={anchorLicenseEl}
                        placement="bottom-start"
                        transition
                    >
                        {({ TransitionProps,  }) => (
                                <ClickAwayListener onClickAway={() => this.setState({anchorLicenseEl: null})}>
                                <Paper>
                                        <MenuList
                                            autoFocusItem={Boolean(anchorLicenseEl)}
                                            id="composition-menu"
                                            aria-labelledby="composition-button"
                                            onClick={() => this.setState({anchorLicenseEl:null})}
                                        >
                                            {licenses}
                                        </MenuList>
                                </Paper>
                                </ClickAwayListener>
                        )}
                    </Popper>*/}




                    <Menu
                        id="license-menu"
                        anchorEl={anchorLicenseEl}
                        open={Boolean(anchorLicenseEl)}
                        onClose={() => this.setState({anchorLicenseEl: null})}>
                        <MenuList onClick={() => this.setState({anchorLicenseEl:undefined})}>
                            {licenses}
                        </MenuList>
                    </Menu>

                        {/* ================ Profile Menu ================ */}
                        <Avatar className={classNames(classes.avatar, {
                            [classes.avatarShift]: this.props.open,
                        })}

                                aria-owns={anchorProfileEl ? 'profile-menu' : undefined}
                                aria-haspopup="true"
                                onClick={(e) => this.setState({ anchorProfileEl: e.currentTarget })}>
                            {Utils.getUsernameShort(this.props.user.username)}
                    </Avatar>

                    <Menu
                        id="profile-menu"
                        anchorEl={anchorProfileEl}
                        open={Boolean(anchorProfileEl)}
                        onClose={() => this.setState({ anchorProfileEl: null })}>
                        <Typography color="inherit" style={{fontWeight:'bold', padding:'0 20px',marginTop:12}}>Account</Typography>
                        <Typography color="inherit" className={classes.username} style={{textAlign:'start', padding:'12px 20px', width:'100%'}}>
                            {this.props.user.username}
                        </Typography>
                        <Divider/>
                        {
                            (this.props.user.company && license.license) ? (
                                <MenuItem onClick={() => this.setState({openLicenseDialog:true})}
                                          onClose={() => this.setState({openLicenseDialog:false})}>
                                    <Icon color="primary" style={{fontSize:18, marginRight:4}}>how_to_reg</Icon>

                                    {translate(`info.license`,{},'Licenza')}
                                </MenuItem>
                            ) : null
                        }

                        <MenuItem onClick={() => {this.setState({openLanguageDialog:true})}}>
                            <Icon color="primary" style={{fontSize:18, marginRight:4}}>language</Icon>
                            {translate(`info.language`,{},'Cambia lingua')}
                        </MenuItem>

                        <MenuItem onClick={() => {this.setState({openChangePasswordDialog:true})}}>
                            <Icon color="primary" style={{fontSize:18, marginRight:4}}>lock</Icon>
                            {translate(`info.password`,{},'Cambia password')}
                        </MenuItem>
                        <MenuItem onClick={() => {
                            this.props.onUpdateUser({});
                            this.props.onUpdateLicense({});
                            window.location = '/logout';
                        }}>
                            <Icon color="primary" style={{fontSize:18, marginRight:4}}>power_settings_new</Icon>
                            {translate(`info.exit`,{},'Esci')}
                        </MenuItem>
                    </Menu>
                    {/* ================ License Menu ================ */}

                </Hidden>

                </Toolbar>

                <Hidden smUp>
                    <BreadCrumbs/>
                </Hidden>

                {/*<div
                    className={classNames(classes.bottomSeparator, {
                        [classes.bottomSeparatorOpen]: this.props.open,
                    })}/>*/}

                {/* CHANGE PASSWORD DIALOG */}
                {(this.state.openChangePasswordDialog)?
                    <ChangePasswordDialog onClose={() => this.setState({openChangePasswordDialog:false})}/>
                :null}

                {/* LICENSE DIALOG */}
                {(this.state.openLicenseDialog) ?
                    <LicenseDialog open={true}
                                   onClose={() => this.setState({openLicenseDialog:false})}/>
                :null}

                {this.state.openLanguageDialog &&
                    <LanguageDialog
                        title={
                            translate(`info.dialog.title`,{},'Cambia lingua interfaccia')
                        }
                        open={true}
                        confirmBtnText={
                            translate(`info.dialog.confirm`,{},'Conferma')
                            }
                        onClose={() => this.setState({openLanguageDialog:false})}/>
                }

            </AppBar>
        )
    }
}


HeaderAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    license: state.license,
    api_control: state.api_control,
    router: state.router,
});


const mapActionsToProps = {
    onUpdateLicense : updateLicense,
    onUpdateUser : updateUser
}

export default compose(
    withRouter,
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(HeaderAppBar);
