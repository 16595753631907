import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationIT from './locales/it/translation.json';
import translationEN from './locales/en/translation.json';

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: i18n.options.lng,
    debug: false, // (process.env.NODE_ENV === 'development')
    resources: {
      it: {
        translation: translationIT
      },
      en: {
         translation: translationEN
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    parseMissingKeyHandler: (key) => {
      return `TRANSLATION_NOT_FOUND`;
    }
  }, (err, t) => {
    if (err) return console.log('something went wrong loading', err);
  });

// The method we'll use instead of a regular string
export function translate(name, params = {}, defaultText = '') {

    if (i18n.t(name, params) !== 'TRANSLATION_NOT_FOUND')
    return i18n.t(name, params);

    return defaultText
};


export function changeLanguage(key){

    i18n.changeLanguage(key).then(() => {
        i18n.options.lng = key;
        window.location.reload();
    })
    }

export default i18n;
