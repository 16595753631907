import React, { Component } from 'react';
import PropTypes, {func} from 'prop-types';
import i18n from 'i18next';

// material ui import
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../redux/actions/api-control-action';
// i18n translation
import {translate} from "../../../../translations/i18n";
import BackofficeViewContainer from '../../layout/container/AdminContainer';
import LanguageApi from "../../../../components/language-select/LanguageApi";
import BulkAPI from "../../../../services/BulkAPI";
import LanguageSelect from "../../../../components/language-select/LanguageSelect";
import Typography from "@material-ui/core/Typography";
import {IconButton} from "@material-ui/core";
import {ArrowDown} from "react-feather";
import Dropzone from "react-dropzone";
import {Inbox} from 'react-feather'
import fields from "./config/fields";
import AutoTable from "../../../../components/autotable/AutoTable";
import AlertDialog from "../../../../components/ui/dialogs/alertDialog/AlertDialog";
import Icon from "@material-ui/core/Icon";
import ModelSelect from "./components/ModelSelect";
import ConfirmDialog from "./components/ConfirmDialog";
import AddDialog from "./components/AddDialog";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {Prompt} from 'react-router-dom'

const styles  = (theme) => ({
    root : {
      position : 'relative'
    },
    dropzone : {
        width: '100%',
        height: 400,
        cursor: 'pointer',
        backgroundColor: '#fff',
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        display: 'flex',
        borderImage: 'url("http://i.stack.imgur.com/wLdVc.png") 0.5 round',
        justifyContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        flexFlow: 'column',
    },
    container : {
        width : '100%',
        display : 'flex',
        flexDirection : 'column',
        alignItems : 'flex-start',
    },
    icon : {
        width : 30,
        height : 30,
    },
    title : {
        fontSize : 24,
        fontWeight : 'bold',
        margin : '12px 0px',
        letterSpacing: '1px'
    },
    text : {
        fontSize : 16,
        margin : '12px 0px',
    },
    search : {
        fontSize : 20,
        fontWeight : 'bold',
        margin : '12px 0px',
    },
    tableContainer:{
        width: '91vw',
        display : 'flex',
        flexDirection : 'column',
        alignItems:  'flex-end',
        justifyContent : 'space-between'
    },
    button : {
        fontSize: 14,
        padding: '4px 24px',
        borderRadius: 8,
        fontWeight: 'bold',
        margin: '16px 0px',
    },
    legendContainer : {
      display : 'flex',
      flexDirection : 'column',
      alignItems : 'flex-end',
      justifyContent : 'center',
      marginLeft : 'auto',
      [theme.breakpoints.down('xs')]:{
      marginLeft : 0,
      alignItems : 'flex-start',
      }
        },
    legend : {
        width : 20,
        height : 20,
        borderRadius : 10,
        margin : '0px 4px 0px 20px'
    },
    legendText : {
        fontSize : 16
    },
    buttonLink: {
        padding: '6px 30px',
        fontSize : 16,
        textTransform : 'Capitalize',
        fontWeight : 'bold',
        margin : '12px 0px',
        boxShadow : 'unset'
    },
    loaderBackup : {
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center',
        position : 'fixed',
        bottom  : 0,
        left : '43%',
        width : 300,
        height : 50,
        backgroundColor: '#2c3a49',
        borderTopLeftRadius : 8,
        borderTopRightRadius : 8
    },
    loader : {
        right: 0,
        minWidth: 400,
        bottom: 0,
        /* height: 50px; */
        display: 'flex',
        position: 'fixed',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#2c3a49',
        borderRadius: 8,
        margin: 40,
        /* border-top-right-radius: 8px; */
        padding: '16px 30px'
},
    bottomButton : {
        position : 'fixed',
        bottom : 8,
        left : '50%',
        backgroundColor : theme.palette.primary.main,
        border : '1px solid #000',
        '&:hover':{
            backgroundColor : theme.palette.primary.main,
        }
    },
    countSubtitle : {
        color: '#7d878c',
        fontSize: 15,
        maxWidth: '100%',
        fontWeight: '600',
        minWidth: 120,
        textTransform: 'uppercase'
    },
    subtitle : {
        fontSize:15,
        marginTop:8,
        color: theme.palette.gray.main,
        marginBottom:20,
        maxWidth : '60%',
        [theme.breakpoints.down('xs')]:{
            maxWidth : '100%',
        }
        },
    headerTitle : {
        fontSize:30,
        fontWeight:700,
    },
    mainContainer : {
        width : '100%',
        display : 'flex',
        justifyContent : 'flex-start',
        marginTop : '-24px',
        [theme.breakpoints.down('xs')]:{
            flexWrap : 'wrap'
        }
    }
});

let hide = false;

class BulkImport extends Component {

    constructor(props){
        super(props);
        this.state = {
            categoriesList:[],
            categoriesFound:[],
            languageValues : [],
            newJsonFields : [],
            viewData : [],
            languageValue : null,
            table : false,
            openConfirmDialog : false,
            loaderDecode : false,
            loaderImport : false,
            loader : false,
            openCancelDialog : false,
            currentFile : null,
            hide : false,
            steps : [],
            currentStep : 0,
            count : 0,
            defaultLang : null,

        };
    }

    alertUser = e => {
        if (this.state.loader) {
            e.preventDefault();
            e.returnValue = ''
        }
    };

    componentWillUnmount(){
        // call the control on exit [STAY_CONNECTED user control]
        window.removeEventListener("beforeunload", this.alertUser)
    }

    componentDidMount(){

        function getYPosition(){
            var top  = window.pageYOffset || document.documentElement.scrollTop

            return top;
        };
        //IMPLEMENTARE DOPO
        /*
        document.addEventListener('scroll', function(e) {
            if (document.body.scrollHeight - getYPosition() < 1500){
                hide = true
                console.log('true')
            }else if (document.body.scrollHeight - getYPosition() > 1500){
                hide = false
                console.log('false')
            }
        });
        */


        /* let tmpRes = {
             links_to_create: [
                 {
                     "model": "CONCHIGLIA123",
                     "id": 1,
                     "code": 61271128,
                     "description": "Serie LSG - Segnalatori G.Rail /N.Jersey",
                     "category": "Catalogo ",
                     "name": "Segnalatori per guard-rail",
                     "url": "https://dkcblob.blob.core.windows.net/prodotti/conchiglia/segnalatori-GR.pdf"
                 },
                 {
                     "model": "CONCHIGLIA123",
                     "id": 1,
                     "code": 61271128,
                     "description": "Serie LSG - Segnalatori G.Rail /N.Jersey",
                     "category": "Catalogo ",
                     "name": "Segnalatori per guard-rail",
                     "url": "https://dkcblob.blob.core.windows.net/prodotti/conchiglia/segnalatori-GR.pdf"
                 },
             ],
             links_to_update: [
                 {
                     "model": "CONCHIGLIA",
                     "id": 1,
                     "code": 61271482,
                     "description": "Serie LSG - Segnalatori G.Rail /N.Jersey",
                     "category": "Catalogo ",
                     "name": "Segnalatori",
                     "url": "https://dkcblob.blob.core.windows.net/prodotti/conchiglia/segnalatori.pdf"
                 },
                 {
                     "model": "CONCHIGLIA",
                     "id": 1,
                     "code": 61272126,
                     "description": "Serie LSG - Segnalatori G.Rail /N.Jersey",
                     "category": "Catalogo ",
                     "name": "Delineatori Antinebbia",
                     "url": "https://dkcblob.blob.core.windows.net/prodotti/conchiglia/delineatori-nebbia.pdf"
                 },

             ]
         };*/


/*
        let tmpFinal = {links_to_create : tmpRes.links_to_create.map(item => ({...item , status : 'added'})) , links_to_update : tmpRes.links_to_update.map(item => ({...item , status : 'edited'}))}
*/


/*
        let tmpViewData = tmpFinal.links_to_update.concat(tmpFinal.links_to_create);
*/

        LanguageApi.getFlags().then(
            res => {
                if (res.data && res.data.content)
                    this.setState({languageValues : [...res.data.content.items]});
                if (res.data && res.data.default_lang && res.data.content && res.data.content.items.length !== 0) {
                    this.setState({languageValue : res.data.default_lang.toString()});
                    this.setState({defaultLang : res.data.default_lang.toString()});
                }
            },
            err => {

            }
        );
    }

    handleChangeMultiple = (event, index) => {
        const { value } = event.target;
        let tmpValue =  this.state.languageValue;
        let tmpLabels = this.state.labels;
        let  availableLang = this.state.languageValues.filter(item => this.state.steps.findIndex(v => v.flag == item.code) === -1);


        if (value.code === tmpValue ){
            this.setState({languageValue : null})
        }else if (availableLang.length > 0 && availableLang.findIndex(item => item.id == value) !== -1){
            this.setState({languageValue: value})
        }else{
            this.props.onUpdateApiControl({status: 400}, '', 'Questa lingua è gia stata selezionata');
        }

        this.setState({labels : tmpLabels})
    };


    onDrops = (file) => {
        this.setState({loaderDecode : true});

        BulkAPI.getJson(file,this.state.languageValue).then(
            res => {
                // let tmpRes = mock;
                let tmpRes = {...res.data};
                let tmpStep = Object.keys(tmpRes).filter(item => item.includes('sheet'));
                tmpStep = tmpStep.map(item =>
                    ({name : item, flag : null})
                );


                /*
                let tmpFinal = {links_to_create : tmpRes.links_to_create.map(item => ({...item , status : 'added'})) , links_to_update : tmpRes.links_to_update.map(item => ({...item , status : 'edited'})), links_no_changes : tmpRes.links_no_changes.map(item => ({...item , status : 'none'}))}
                let tmpViewData = tmpFinal.links_to_update.concat(tmpFinal.links_to_create);
                tmpViewData = tmpViewData.concat(tmpFinal.links_no_changes);
                */

                if ((tmpRes.count != 0 || tmpStep.length > 0) || res.data.records && res.data.records.new_links.length === 0 && res.data.next) {
                    this.setState({currentStep: 0});
                    this.setState({currentFile: file});
                    this.setState({newJsonFields: res.data});
                    this.setState({steps: (res.data.records.new_links.length !== 0) ? tmpStep : [{name : 'item', flag : 'IT'}]});
                    this.setState({table: (res.data.records.new_links.length !== 0), loaderDecode: false, count: tmpRes.count});
                    if (res.data.records && res.data.records.new_links.length === 0 && res.data.next){
                        this.setState({openConfirmDialog : true})
                    }

                }else{
                    this.setState({loaderDecode : false});
                    this.props.onUpdateApiControl({status : 400}, '',  translate( `bulk_import.error.noNew`, {},  "Non sono stati trovati nuovi elementi da importare") );
                }
            },
            err => {
                this.setState({loaderDecode : false});
                this.props.onUpdateApiControl(err, '',  translate( `bulk_import.error.decode`, {},  "Errore nella conversione del file") );

            }
        )
    };

    onConfirm = () => {
        window.addEventListener("beforeunload", this.alertUser);

        const {languageValues, languageValue, currentFile, steps, newJsonFields, currentStep} = this.state;

        const tmpLang = languageValues[languageValues.findIndex(item => item.id == languageValue)].code;
        let tmpSteps = steps;

        tmpSteps[currentStep].flag = tmpLang;

        this.setState({loader : true, openConfirmDialog : false});
            BulkAPI.postJson(newJsonFields.records,tmpSteps.map(item => item.flag), currentFile).then(
                () => {

                        this.setState({loader: false, openConfirmDialog: false, table: false});
                        this.props.onUpdateApiControl({status: 200}, 'registration-form', `File importato correttamente`);
                    window.removeEventListener("beforeunload", this.alertUser)
                },
                err => {
                        this.setState({loader: false, openConfirmDialog: false});
                        window.removeEventListener("beforeunload", this.alertUser);

                    this.props.onUpdateApiControl(err, '',  translate( `bulk_import.error.confirm`, {},  `Errore nella conferma dell'import File`) );
                }
            )
    };

    onContinue = (cStep) => {
        this.setState({loaderDecode : true});

        const {languageValues, languageValue, steps } = this.state;
        window.scrollTo(0, 0);

        const tmpLang = languageValues[languageValues.findIndex(item => item.id == languageValue)].code;
        let tmpSteps = steps;

        tmpSteps[cStep].flag = tmpLang;

        this.setState({ openConfirmDialog:false});

        setTimeout(
            function() {
                this.setState({ languageValue: null});
                this.setState({ currentStep: cStep + 1});
                this.setState({steps :  tmpSteps});
                this.setState({ currentStep: cStep + 1});
                this.setState({loaderDecode :  false});
            }.bind(this),
            2000
        );
    };

    render() {
        const { classes } = this.props;
        const {languageValues, languageValue, table, openConfirmDialog, loader, viewData, loaderDecode, openCancelDialog,
            newJsonFields, count, steps, currentStep} = this.state;

        return (
            <div className={classes.root}>
                <Prompt
                when={loader}
                message="Are you sure you want to leave the page?"
                />
                <BackofficeViewContainer
                    hasMarginTop={false}
                    // subtitle={"Il riepilogo delle tue notifiche"}
                    renderToolbar={languageValues.length > 0 &&
                    <div className={classes.mainContainer}>
                    <>
                           {table ?
                           <div style={{display : 'flex', flexDirection : 'column', alignItems : 'flex-start'}}>
                               <Typography
                                   className={classes.headerTitle}
                                   style={{marginBottom : 20}}>
                                   {translate('bulk_import.page', {}, 'Pagina')}  {currentStep + 1}
                               </Typography>
                                   <LanguageSelect
                                       onChange={this.handleChangeMultiple}
                                       style={{padding: '16px 16px 16px 0px'}}
                                       value={languageValue}
                                       values={languageValues}
                                       multiple={false}
                                       label={translate('bulk_import.language', {}, 'Seleziona lingua')}
                                       languageOptions={null}/>
                           </div>
                               :
                           <div style={{display : 'flex', flexDirection : 'column', alignItems : 'flex-start'}}>
                            <Typography
                            className={classes.subtitle}
                            >
                                {translate( `bulk_import.subtitle1`, {},  "È possibile caricare un file excel di più fogli, ognuno per ogni lingua. Ciascuna lingua dovrà far corrispondere le diverse traduzioni ad ogni riga. Non è possibile caricare un file excel con più fogli di quante sono le lingue sulla piattaforma." )}
                            </Typography>
                                <Typography className={classes.subtitle}>
                                    {translate( `bulk_import.subtitle2`, {},  "To upload content in one language, upload a single sheet file.")}
                                </Typography>
                            </div>
                           }
                    </>
                    {table &&
                    <div className={classes.legendContainer}>
                        <Typography className={classes.headerTitle}>
                            {count &&
                            count
                                }
                        </Typography>
                        <Typography className={classes.countSubtitle}>
                            {translate( `bulk_import.legendAdd`, {},  "Righe aggiunte/modificate")}
                        </Typography>
                    </div>}

                    </div>}
                    title={translate( `bulk_import.title`, {},  "Importa link" )}>
                    <div className={classes.container}>
                        {table ?
                            <div className={classes.tableContainer}>
                            <AutoTable
                                noDataMessage={'Non ci sono nuovi link da caricare'}
                                rowStyle={(row) => ({
                                    backgroundColor : row.status === 'edited' ? 'rgba(240,182,67,0.35)' : row.status === 'added' ? 'rgba(27,95,18,0.35)' : 'transparent'
                                })}
                                loader={loaderDecode}
                                count={newJsonFields[steps[currentStep].name].length}
                                fields={fields(currentStep)}
                                data={newJsonFields[steps[currentStep].name]}
                                pagination={'local'}
                            />

                            {/*
                                {!hide && <IconButton
                                className={classes.bottomButton}
                                onClick={() =>
                                    window.scrollTo(0, document.body.scrollHeight)
                                }
                                >
                                    <ArrowDown
                                    color='#fff'
                                    />
                                </IconButton>}
                            */}

                            <div style={{
                                position: 'fixed',
                                bottom: 4,
                                backgroundColor: 'rgba(255,255,255, 0.8)',
                                padding: 8,
                                borderRadius: 16
                            }}>
                                <Button color="secondary"
                                        variant="contained"
                                        onClick={() => this.setState({openCancelDialog : true})}
                                        className={classes.button}
                                        style={{marginRight : 8}}>
                                    <span style={{margin : '4px 0px'}}>
                                        {translate('bulk_import.cancel', {}, 'Annulla')}
                                    </span>
                                </Button>

                                <Button color="primary"
                                        disabled={!languageValue}
                                        variant="contained"
                                        onClick={() => this.setState({openConfirmDialog : true})}
                                        className={classes.button}>
                                    <span style={{margin : '4px 0px'}}>
                                    {steps.length - (currentStep + 1) === 0 ?
                                        translate('bulk_import.button', {}, 'Conferma bulk import')
                                        :
                                        translate('bulk_import.continue', {}, 'Continua')
                                    }
                                    </span>
                                </Button>
                            </div>

                            {/*<AddDialog
                                    open={openConfirmDialog}
                                    title={translate(`bulk_import.modal.title`, {}, "Sei sicuro di voler caricare i link?")}
                                    message={translate(`bulk_import.modal.message`, {}, "Questi dati sovrascriveranno quelli attuali. Potrai comunque trovare i dati precedenti nella sezione di punti di ripristino.")}
                                    confirmText={translate(`bulk_import.modal.message`, {}, "Carica")}
                                    cancelText={'chiudi'}
                                    onClose={() => this.setState({openConfirmDialog:false})}
                                    onConfirm={() => this.setState({openConfirmDialog:false})}
                                    fields={[
                                        {key : 'test', label : 'Test123'},
                                        {key : 'test1', label : 'Test124'},
                                        {key : 'test2', label : 'Test125'},
                                        {key : 'test3', label : 'Test126'},
                                        {key : 'test4', label : 'Test127'},
                                        {key : 'test5', label : 'Test128'},
                                        {key : 'test6', label : 'Test129'},
                                    ]}
                            />*/}



                                <ConfirmDialog
                                    open={openCancelDialog}
                                    title={translate(`bulk_import.cancelDialog.title`, {}, "Sei sicuro di voler annulare l'operazione di import?")}
                                    message={translate(`bulk_import.cancelDialog.message`, {}, "I dati presenti nella tabella non verranno salvari")}
                                    confirmBtnText={translate(`bulk_import.cancelDialog.confirm`, {}, "Conferma")}
                                    onClose={() => this.setState({openCancelDialog:false})}
                                    onConfirm={() => this.setState({table : false, newJsonFields : null,viewData : null, openCancelDialog : false})}/>
                            </div>
                            :
                            loaderDecode ?
                                <div className={classes.dropzone}>
                                    <CircularProgress size={32}/>
                                    <Typography className={classes.text}>
                                        {translate('file.loader', {}, "Caricamento del file...")}
                                    </Typography>
                                </div>
                                :
                            <Dropzone className={classes.dropzone}
                                  multiple={false}
                                  accept={".xlsx"}
                                  onDrop={(files) => this.onDrops(files)}>
                                {({getRootProps, getInputProps}) => (
                                    <div {...getRootProps()} className={classes.dropzone}>
                                    <Inbox className={classes.icon}/>

                            <Typography className={classes.title}>{translate('bulk_import.dropzone.title', {}, 'Trascina qui il file da caricare')}</Typography>

                            <Typography className={classes.text}>{translate('bulk_import.dropzone.text', {}, 'Oppure')}</Typography>

                                <Button variant="contained"
                                        color="primary"
                                        className={classes.buttonLink}
                                        {...getInputProps}
                                >
                                    {translate('bulk_import.dropzone.browse', {}, 'Sfoglia')}
                                </Button>
                            {/*<Typography className={classes.search}>{translate('bulk_import.dropzone.browse', {}, 'Sfoglia i tuoi file')}</Typography>*/}

                            <Typography className={classes.text} >
                                {translate('bulk_import.dropzone.formats', {}, 'Formati consentiti')}: .xlsx
                            </Typography>
                                    </div>
                                    )}
                        </Dropzone>
                        }
                    </div>
                </BackofficeViewContainer>
                {loader &&
                    <div className={classes.loader}>
                    <Typography style={{fontSize : 16, marginRight : 12, color : '#fff'}}>
                        {translate('bulk_import.loader', {}, 'Caricamento link in corso')}
                    </Typography>
                    <CircularProgress size={16} color='secondary'/>
                </div>}

                <ConfirmDialog
                    open={openConfirmDialog}
                    title={
                        steps.length - (currentStep + 1) === 0 ?
                            translate(`bulk_import.dialog.title`, {}, "Sei sicuro di voler caricare i link?")
                            :
                            translate(`bulk_import.continueDialog.title`, {}, "Continuare import massivo?")
                    }

                    message={
                        steps.length - (currentStep + 1) === 0 ?
                            translate(`bulk_import.dialog.message`, {}, "Questi dati sovrascriveranno quelli attuali. Potrai comunque trovare i dati precedenti nella sezione di punti di ripristino.")
                            :
                            translate(`bulk_import.continueDialog.message`, {}, "Controlla di avere selezionato la lingua giusta")
                    }
                    confirmBtnText={
                        steps.length - (currentStep + 1) === 0 ?
                            translate(`bulk_import.dialog.confirm`, {}, "Carica")
                            :
                            translate(`bulk_import.continueDialog.confirm`, {}, "Continua")
                    }
                    onClose={() => this.setState({openConfirmDialog:false})}
                    alertMessage={steps.length - (currentStep + 1) === 0}
                    onConfirm={() =>
                        steps.length - (currentStep + 1) === 0 ?
                            this.onConfirm()
                            :
                            this.onContinue(currentStep)
                    }
                />



            </div>
        )
    }
}


BulkImport.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    utils: state.utils,
    api_control: state.api_control
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl
};

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(BulkImport);
