import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// material ui import
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Grow from '@material-ui/core/Grow';
// Redux
import { compose } from 'redux';
// i18n translation
import Tooltip from "@material-ui/core/Tooltip";
import {translate} from "../../../translations/i18n";
import {Edit2, Share2, Trash} from 'react-feather'
import {updateApiControl} from "../../../redux/actions/api-control-action";
import {updateMultilanguage} from "../../../redux/actions/multilanguage-action";
import {connect} from "react-redux";

const styles  = theme => ({
    paperAnimated: {
        position: 'relative',
        cursor:'pointer',
        marginTop: -40,
        marginLeft: 32,
        marginRight: 32,
        marginBottom:80,
        borderRadius : '10px !important',
        height:'auto',
        background: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]:{
            maxWidth: 170,
            marginLeft: 28,
            marginRight: 28,
        },
        [theme.breakpoints.down('xs')]:{
            maxWidth: 150,
            marginLeft: 10,
            marginRight: 10,
        },
        transition: 'transform .2s',
        '&:hover':{
            background: theme.palette.primary.hover,
            transform:'scale(1.1)',
        }
    },
    paper: {
        position: 'relative',
        marginTop: -40,
        marginLeft: 32,
        borderRadius : '10px !important',
        marginRight: 32,
        marginBottom:80,
        height:'auto',
        background: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]:{
            maxWidth: 200,
        },
        [theme.breakpoints.down('sm')]:{
            maxWidth: 170,
            marginLeft: 28,
            marginRight: 28,
        },
        [theme.breakpoints.down('xs')]:{
            maxWidth: 150,
            marginLeft: 10,
            marginRight: 10,
        },
    },
    /* Standard template for the card */
    card:{
        width: 200,
        height: 200,
        background:'transparent',
        backgroundSize:'cover',
        textAlign:'center',
        display:'flex',
        justifyContent:'center',
        color: theme.palette.secondary.main,
        alignItems: 'center',
        [theme.breakpoints.down('sm')]:{
            width: 170,
            height: 170,
        },
        [theme.breakpoints.down('xs')]:{
            width: 150,
            height: 150,
        },
    },
    /* Template with the image */
    cardImage:{
        width: 200,
        height: 60,
        background:'transparent',
        backgroundSize:'cover',
        textAlign:'center',
        display:'flex',
        justifyContent:'center',
        boxShadow:'unset',
        alignItems: 'center',
        color: theme.palette.secondary.main,
        [theme.breakpoints.down('sm')]:{
            width: 180,
            height: 180,
        },
        [theme.breakpoints.down('xs')]:{
            width: 130,
            height: 130,
        },
    },
    /* Template with the image */
    buttonContainer:{
        display:'flex',
        justifyContent:'flex-end',
        alignItems:'center'
    },
    badge:{
        position:'absolute',
        top: 24,
        left: 24,
    },
    title:{
        // textTransform:'capitalize',
        textOverflow: 'ellipsis',
        overflow : 'hidden',
        fontSize:18,
        color: theme.palette.secondary.main,
    },
    subtitle:{
        fontSize:12,
        padding:'0 4px',
        maxWidth: 200,
        overflow : 'hidden',
        textOverflow: 'ellipsis',
        marginTop:8,
        color: theme.palette.secondary.main,
    },
    add:{
        fontSize: 60,
    },
    icon: {
        color: '#fff',
        width: 16,
        height: 16
    },
    imageContainer:{
        background:'#fff',
        paddingTop: 140,
        marginBottom:4,
        backgroundSize:'cover',
        backgroundPosition: 'center',
        transition: 'transform .2s',
        '&:hover':{
            //paddingTop:140,
        }
    },
    button:{
        borderRadius:20,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        boxShadow:'unset',
        background: theme.palette.primary.main,
        margin:4,
        width:36,
        height:36,
        '&:hover':{
            background: '#fff',
            '& svg':{
                color: theme.palette.primary.main
            }
        },
        '& span':{
            fontSize:16,
        }
    }
});

class BoxCard extends Component {

    constructor(props){
        super(props);
        this.state = {
            multilanguage : this.props.multilanguage
        }
    }

    // overlapped buttons click
    stopBubbling(evt){
        evt.stopPropagation();
        evt.cancelBubble = true;
    }

    getLabels = () => {
        const {labels = [], multilanguage, title = '' , subtitle = '',} = this.props;


        function getMostRelevantLang(){

            let tmpLang = multilanguage.selectedLanguage


            if (tmpLang.length > 1){
                let tmpIndx = tmpLang.findIndex(item => item == multilanguage.defaultlang);

                if (tmpIndx !== -1) return tmpLang[tmpIndx]; else return tmpLang[0]
            }else if (tmpLang.length === 1)
                return  tmpLang[0];
                else
                return multilanguage.defaultlang

        }

        let tmpLabels = [...labels];

        if (labels && labels.length > 0 && multilanguage && multilanguage.selectedLanguage && multilanguage.selectedLanguage.length > 0){

            let mainLanguage = getMostRelevantLang();

            tmpLabels = tmpLabels.filter(item =>
            item.flag.id == mainLanguage
            );

            if (tmpLabels.length === 0 && multilanguage.defaultlang)
                tmpLabels = labels.filter(item =>
                    item.flag.id == multilanguage.defaultlang
                );

            if (tmpLabels.length === 0 && !multilanguage.defaultlang)
                tmpLabels = {title , subtitle}

        }else if (!multilanguage || !multilanguage.selectedLanguage || multilanguage.selectedLanguage.length === 0 && multilanguage.defaultlang){
            tmpLabels = tmpLabels.filter(item =>
                item.flag.id == multilanguage.defaultlang
            );
        }

        if (labels && labels.length === 1)
        {
            if(subtitle){
                tmpLabels = {title : labels[0].title, subtitle : labels[0].description }
            }
            else{

                tmpLabels = {title : labels[0].title }

            }
        }

        if (tmpLabels.length === 0 && (title || subtitle))
            tmpLabels = {title , subtitle};


        if (tmpLabels && tmpLabels.length >= 1){
            if(subtitle){

                tmpLabels = {title : tmpLabels[0].title, subtitle : tmpLabels[0].description }
            }
            else{
                tmpLabels = {title : tmpLabels[0].title }
            }
        }

        return tmpLabels;
    };

    render() {
        const { classes,theme} = this.props;
        // const {finalLabels = {title : '', subtitle : ''}} = this.state;
        const {disableHover=false, image=null, isShared=false, labels = [], defaultLang, disabled} = this.props;
        const finalLabels = this.getLabels();

        return (
            <Grow in={true} timeout={800}>
                <div className={classes.root}>
                    <Paper className={(!disableHover || !disabled)?classes.paperAnimated:classes.paper}
                            /* eslint-disable no-unused-expressions */
                           onClick={(e) => {this.stopBubbling(e);(this.props.onClick && !disabled)?this.props.onClick():null}}
                           elevation={1}
                           style={(this.props.variant==='add')?
                               {background: theme.palette.active.main}
                           :null}>
                            {(isShared) ?
                                <span style={{position: 'absolute', top: 8, right: 0, backgroundColor: 'white', opacity: '0.9',  padding: 5, borderTopLeftRadius: 4, borderBottomLeftRadius: 4, fontSize: 12, boxShadow: 'inset -2px 0px 5px #e2e2e2'}}>
                                    <strong>{translate('general.shared',{}, 'Condivisa')}</strong>
                                    <Share2 style={{ width : 14, height : 14, marginBottom: -2, marginLeft: 4, marginRight: 3 }}/>
                                </span>
                            : null}

                            <Link to={(this.props.link)?this.props.link:'#'}>
                                {/* Image container */}
                                {(image && this.props.variant!=='add')?
                                    <div style={{padding:0,paddingTop:8}}>
                                        <div className={classes.imageContainer} style={{backgroundImage: `url(${this.props.image})`}}/>
                                    </div>
                                :null}
                                <Card className={(image && this.props.variant!=='add')?classes.cardImage:classes.card} style={(disableHover)?{cursor:'default'}:null}>
                                    <CardContent style={{padding:'0px 10px', width: '100%', display : 'flex', flexDirection : 'column'}}>
                                        <div>
                                        <Typography variant="h5"
                                                    component="h2"
                                                    className={[classes.title,(this.props.variant==='add')?classes.add:null].join(' ')}>
                                            {finalLabels.title}
                                        </Typography>
                                        </div>
                                        <div>
                                        <Typography className={classes.subtitle} color="textSecondary" gutterBottom>
                                            {finalLabels.subtitle}
                                        </Typography>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Link>
                        {(this.props.onUpdate || this.props.onDelete)?
                            <div className={classes.buttonContainer} style={(!image && this.props.variant!=='add')?{marginTop:-44}:null}>
                                {/* Edit button */}
                                {(this.props.onUpdate)?
                                    <Tooltip placement={"bottom"} title={translate('general.edit', {}, 'Modifica')}>
                                        <div className={classes.button}
                                             onClick={(e) => {
                                                 this.stopBubbling(e);
                                                 this.props.onUpdate()
                                             }}>
                                            <Edit2 className={classes.icon}/>
                                        </div>
                                    </Tooltip>
                                    :null}
                                {/* Delete button */}
                                {(this.props.onDelete)?
                                    <Tooltip placement={"bottom"} title={translate('general.delete', {}, 'Elimina')}>
                                        <div className={classes.button}
                                             onClick={(e) => {
                                                 this.stopBubbling(e);
                                                 this.props.onDelete()
                                             }}>
                                            <Trash className={classes.icon}/>
                                        </div>
                                    </Tooltip>
                                  :null}
                            </div>
                       :null}
                    </Paper>
                </div>
            </Grow>
        )
    }
}


BoxCard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    link: PropTypes.string, // url on click
    onClick: PropTypes.func, // on click
    onDelete: PropTypes.func, // delete button
    onUpdate: PropTypes.func, // edit button
    disableHover: PropTypes.bool,
    isShared: PropTypes.bool
};



const mapStateToProps = state => ({
    multilanguage: state.multilanguage,
});


const mapActionsToProps = {
    onUpdateMultilanguage: updateMultilanguage,
};

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(BoxCard);
