import React, { Component } from 'react';
import PropTypes from 'prop-types';
// material ui import
import { withStyles } from '@material-ui/core/styles';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../redux/actions/api-control-action';
// i18n translation
// api service import
import CategoryApi from '../../../../services/CategoryApi';
// containers import
import BackofficeViewContainer from '../../layout/container/AdminContainer';
// components import
import BoxCard from '../../../../components/ui/cards/BoxCard';
import Search from '../../../../components/ui/search/Search';
import SnackbarLoader from '../../../../components/ui/loaders/snackbar/SnackbarLoader';
import CreateCategoryDialog from './components/CreateCategoryDialog';
import AlertDialog from '../../../../components/ui/dialogs/alertDialog/AlertDialog';
import {translate} from "../../../../translations/i18n";
import LanguageApi from "../../../../components/language-select/LanguageApi";
import {updateMultilanguage} from "../../../../redux/actions/multilanguage-action";
import CreateModelDialog from "../modelsManager/components/CreateModelDialog";
import ProductApi from "../../../../services/ProductApi";
import ModelApi from "../../../../services/ModelApi";
import { debounce } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";

const styles  = () => ({
    root : {
        marginTop : 52,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    }
});

class CategoriesManager extends Component {

    constructor(props){
        super(props);
        this.state = {
            categoriesList:[],
            categoriesFound:[],
            languageValues : [],
            availableLang : [],
            defaultlang : null,
            pagination : {
                offset : 0,
                limit : 30,
                hasMore : true
            },
            searchQuery : ''
        }

        this.searchCategory = debounce((string) => {
            this.setState({searchQuery : string, pagination : {
                    offset : 0,
                    limit : 30,
                    hasMore : true
                },
                loader : true,
            }, () => {
                CategoryApi.getCategory({'limit' : this.state.pagination.limit , offset : this.state.pagination.offset, search : string}).then(
                    res => {
                        this.setState({
                            categoriesList: res.data,
                            categoriesFound: res.data,
                            pagination : {
                                ...this.state.pagination,
                                offset: this.state.pagination.offset + this.state.pagination.limit + 1,
                                hasMore : res.data.length === this.state.pagination.limit
                            },
                            loader : false,
                        });
                    },
                    err => {
                        this.setState({ loader: false });
                        // ====== API CONTROL MESSAGE ====== //
                        this.props.onUpdateApiControl(err, 'getMachine', );
                    }
                );
            })
        }, 700);
    }

    // ==================================================
    // GET THE CATEGORY LIST
    // ==================================================
    apiCalls = () => {
        CategoryApi.getCategory({'limit' : this.state.pagination.limit , offset : this.state.pagination.offset, search : this.state.searchQuery}).then(
            res => {
                this.setState({
                    categoriesList: res.data,
                    categoriesFound: res.data,
                    pagination : {
                        ...this.state.pagination,
                        offset: this.state.pagination.offset + this.state.pagination.limit + 1,
                        hasMore : res.data.length === this.state.pagination.limit
                    },
                    loader:false,
                })
            },
            err => {
                this.setState({ loader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'loadCategories', );
            }
        )
    };

        componentDidMount(){
        this.setState({ loader: true });

        this.apiCalls()
    }

    getCategories(){
        if (this.state.loader === false) {
            this.setState({loader: true});

            if (this.state.pagination)
                CategoryApi.getCategory({
                    'limit': this.state.pagination.limit,
                    offset: this.state.pagination.offset,
                    search: this.state.searchQuery,
                }).then(
                    res => {
                        this.setState({
                            categoriesList: [ ...this.state.categoriesList , ...res.data],
                            categoriesFound: [...this.state.categoriesFound, ...res.data],
                            pagination: {
                                ...this.state.pagination,
                                offset: this.state.pagination.offset + this.state.pagination.limit,
                                hasMore : res.data.length === this.state.pagination.limit
                            },
                            loader: false
                        });
                    },
                    err => {
                        this.setState({loader: false});
                        // ====== API CONTROL MESSAGE ====== //
                        this.props.onUpdateApiControl(err, 'getMachine',);
                    }
                );
        }
    }

    // ==================================================
    // CREATE CATEGORY
    // ==================================================

    createCategory(newCategory, labels = []){
        this.setState({ loader: true });
        CategoryApi.createCategory(newCategory).then(
            res => {
                labels.map(item =>
                    CategoryApi.addCategoryLabels(item, res.data.id, item.flag.code).then(
                        r => {
                        },
                        err => {

                        }
                    )
                );
                this.setState(prevState => ({
                    categoriesList:  [...prevState.categoriesList, {...res.data, labels}],
                    categoriesFound: [...prevState.categoriesFound, {...res.data, labels}],
                    loader:false,
                    openCreateCategoryDialog: false,
                }));
            },
            err => {
                this.setState({ loader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'createCategory', );
            }
        )
    }

    // ==================================================
    // DELETE CATEGORY
    // ==================================================

    deleteCategory(id){
        this.setState({ loader: true });
        CategoryApi.deleteCategory(id).then(
            () => {
                let deleteIndex = this.state.categoriesList.map(function (item) {return item.id}).indexOf(id);
                this.setState(prevState => ({
                    categoriesList:  prevState.categoriesList.filter((_, i) => i !== deleteIndex),
                    categoriesFound: prevState.categoriesFound.filter((_, i) => i !== deleteIndex),
                    loader:false,
                    openDeleteCategoryDialog: false,
                }));
            },
            err => {
                this.setState({ loader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'deleteCategory', );
            }
        )
    }

    // ==================================================
    // UPDATE CATEGORY
    // ==================================================
    updateCategory(updateCategory, labels){
        this.setState({ loader: true });

        CategoryApi.updateCategory(updateCategory.id, updateCategory).then(
            res => {


                let toBDeleted = [];

                if (labels.length > 0)
                    toBDeleted = res.data.labels.filter(item => labels.findIndex(v => v.flag.id === item.flag.id) === -1)


                if (labels)
                    labels.map(item =>
                            res.data.labels.length > 0 && res.data.labels.findIndex(v => v.flag.id == item.flag.id) !== -1 ?
                    CategoryApi.editCategoryLabels(item, updateCategory.id, item.flag.code).then(
                        r => {
                        },
                        err => {

                        }
                    )
                :
                CategoryApi.addCategoryLabels(item, updateCategory.id, item.flag.code).then(
                    r => {
                    },
                    err => {

                    }
                )
                );

                if(toBDeleted.length > 0)
                    toBDeleted.map(item =>
                        CategoryApi.deleteCategoryLabels(item, res.data.id, item.flag.code).then(
                            r => {
                            },
                            err => {
                            }
                        )
                    );

                let categoriesList = this.state.categoriesList;
                let updateIndex = categoriesList.map(function (item) {return item.id}).indexOf(updateCategory.id)
                categoriesList[updateIndex] = {...res.data, labels};
                this.setState(() => ({
                    categoriesList:  categoriesList,
                    categoriesFound: categoriesList,
                    loader:false,
                    openUpdateCategoryDialog: false,
                }));
            },
            err => {
                this.setState({ loader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'updateCategory', );
            }
        )
    }

    render() {
        const { classes, multilanguage} = this.props;
        const { categoriesList, categoriesFound, pagination } = this.state;
        const { loader } = this.state;

        return (
            <div>
                <BackofficeViewContainer
                    title={
                        translate( `category.title`, {},  "Categorie documenti" )
                    }
                    subtitle={
                        translate( `category.subtitle`, {},  "Crea le categorie documenti, note come cubotti nel mondo UBILOD, cerca tra le esistenti, rinominale ed eliminale.")
                    }
                    renderSearch={
                        <>
                        {/*<Search data={categoriesList} // data list where the component search
                                keySearch={"label.labels:title"}
                                multilingual={multilanguage.selectedLanguage}
                            // key that have to search
                                placeholder={
                                    translate( `category.search`, {},  "Cerca per categoria documento" )
                                    } // placeholder
                                onResult={(searchResult, string) =>
                                    this.searchCategory(string)
                                    // this.setState({categoriesFound: searchResult})
                                } // action after search
                        />*/}
                        </>
                    }
                    languageSelect
                    onChangeLanguage={() => this.apiCalls()}
                >

                    <InfiniteScroll
                        dataLength={this.state.categoriesFound.length}
                        next={() => this.getCategories()}
                        hasMore={pagination.hasMore}
                    >
                        <div className={classes.root}>
                    {/* CREATE CATEGORY BOX CARD */}
                    <BoxCard variant={"add"}
                             title={"+"}
                             disabled={this.state.loader}
                             subtitle={
                                 translate( `category.add`, {},  "Aggiungi categoria documento" )
                             }
                             onClick={() => this.setState({openCreateCategoryDialog:true})}/>

                    {/* CATEGORY LIST BOX CARDS */}
                    {categoriesFound.map((category, index) =>
                        <BoxCard key={category + '-' + index}
                                 title={category.label}
                                 subtitle={category.description}
                                 disableHover={true}
                                 labels={category.labels}
                                 onDelete={() => {this.setState({openDeleteCategoryDialog:true, activeDeleteId:category.id})}}
                                 onUpdate={() => this.setState({openUpdateCategoryDialog:true, activeUpdateCategory:category})}/>
                    )}
                        </div>
                    </InfiniteScroll>

                    {/* CREATE CATEGORY DIALOG */}
                    {(this.state.openCreateCategoryDialog)?
                    <CreateCategoryDialog title={translate( `category.create_modal`, {},  "Crea categoria documento")}
                                          loader={loader}
                                          open={this.state.openCreateCategoryDialog}
                                          onClose={() => this.setState({openCreateCategoryDialog:false})}
                                          onCreate={(newCategory, labels) => this.createCategory(newCategory, labels)}/>
                    :null}

                    {/* UPDATE CATEGORY DIALOG */}
                    {(this.state.openUpdateCategoryDialog)?
                    <CreateCategoryDialog title={translate( `category.edit_modal`, {},  "Modifica categoria documento")}
                                          loader={loader}
                                          category={this.state.activeUpdateCategory}
                                          open={this.state.openUpdateCategoryDialog}
                                          onClose={() => this.setState({openUpdateCategoryDialog:false})}
                                          onCreate={(updateCategory,labels) => this.updateCategory(updateCategory, labels)}/>
                    :null}

                    {/* DELETE CONFIRM DIALOG */}
                    <AlertDialog open={this.state.openDeleteCategoryDialog}
                                 title={translate( `category.delete_modal`, {},  "Eliminazione categoria documento")}
                                 message={
                                     translate( `category.delete_modal_message`, {},  "Sei sicuro di voler eliminare la categoria documento?")}
                                 confirmBtnText={
                                     translate( `category.delete_modal_button`, {},  "Elimina")
                                 }
                                 onClose={() => this.setState({openDeleteCategoryDialog:false})}
                                 onConfirm={() => this.deleteCategory(this.state.activeDeleteId)}/>

                    {/* LOADER */}
                    <SnackbarLoader open={loader} message={
                        translate( `category.loader`, {},  "Elaborazione in corso")
                    }/>
                </BackofficeViewContainer>
            </div>
        )
    }
}


CategoriesManager.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    utils: state.utils,
    multilanguage: state.multilanguage,
    api_control: state.api_control
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl,
    onUpdateMultilanguage: updateMultilanguage,
}

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(CategoriesManager);
