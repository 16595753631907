import GeneralSettings from '../settings/GeneralSettings';

export const LocalStorageKey = GeneralSettings.localStorageKey + ':state';

// Define the initial state
const initialState = {
    language : 'it',
    router : {},
    user: {},
    utils: {
        cookieBar: true,
    },
    loaders:{
        loaderFullWidth: {
            open: false,
            message: ""
        },
    },
    api_control: {
        message: "",
        response: {status: ""},
        action: null,
        context: null,
    },
    multilanguage: []
}

// Load state of redux from LocalStorage
export const loadState = () => {
    try {
        const serializedState = localStorage.getItem(LocalStorageKey);
        let store = Object.assign(initialState,JSON.parse(serializedState));
        return store;
    }catch(err){
        return undefined;
    }
};

// Save state of redux to LocalStorage
export const saveState = (state) => {
    try {
        // Define the new Local State
        const localState = {
            user: state.user,
            multilanguage: state.multilanguage,
            license: state.license,
            utils: state.utils,
            language: state.language,
        };
        const serializedState = localStorage.getItem(LocalStorageKey);
        if(!compare(localState,JSON.parse(serializedState))){
            const serializedState = JSON.stringify(localState);
            localStorage.setItem(LocalStorageKey, serializedState);
        }
    }catch(err){
        // Ignore write errors
    }
};

// Compare the redux state and the local storage
function compare(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
}
