// Config.js file for configuration
const config = window;

class GeneralSettings {

    /* =============== * APP SETTING * =============== */
    static appName = 'Ubilod';
    static appBaseUrl = (config.REACT_APP_BASE_URL) ? config.REACT_APP_BASE_URL : process.env.REACT_APP_BASE_URL;

    /* =============== * API SETTINGS * =============== */
    static baseUrl = (config.REACT_APP_API_BASE_URL) ? config.REACT_APP_API_BASE_URL : process.env.REACT_APP_API_BASE_URL;
    static bearerKey = (config.REACT_APP_BEARER_KEY) ? config.REACT_APP_BEARER_KEY : process.env.REACT_APP_BEARER_KEY;

    static baseMedia = '#';

    /* ================= HTACCESS ===================== */
    static htAccessEnable = false;
    static htaccessUser = 'Ubilod';
    static htaccessPassword = '12345678';

    /* ================= TESTING ===================== */
    static developmentBar = false; // TODO: false in produzione - disabilita il redirect login

    /* ================= LOCAL STORAGE ================ */
    static localStorageKey = 'Ubilod';
    static stateKeyLocalStorage = 'Ubilod:state';
    static testingKeyLocalStorage = 'Ubilod:testing';

    /* ============= API ERROR MESSAGE ============= */
    static timeoutRetryGetewayTimeoutApiCall = 5;
}

export default GeneralSettings;
