import React from 'react';
//import material-ui
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from "classnames";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import DownloadQrCode from "./DownloadQrCode";
import {translate} from "../../../../../translations/i18n";

const styles = theme => ({
    deleteButton: {
        color: theme.palette.error.main,
    },
    toolbarBtn:{
        marginRight:16,
        padding: '6px 12px',
        minHeight:20,
        lineHeight: '2px',
        '& > span:first-child':{
            textTransform:'capitalize',
        },
        [theme.breakpoints.down('xs')]:{
            padding: '6px 18px',
        }
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    productLabel: {
        margin:12,
        padding: '38px 52px 52px 52px',
        position: 'relative',
        backgroundColor: 'white',

    },
    title: {
        fontSize: 22,
        fontWeight:700,
        color: '#3c4252',
        marginTop:4,
        marginBottom:16,
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'left',
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
        },
    },
    strong : {
      fontSize : 20,
      marginRight: 8
    },
    subtitle: {
        fontSize:16,
        color: '#3c4252',
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
        },
    },
    typography: {
        marginTop:5,
        alignItems: 'center',
        display: 'flex',
        color: '#3c4252',
    },
    qrCodeImage:{
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            maxWidth:200,
        },
    },
    downloadQrLink: {
        marginTop: 12,
        cursor: 'pointer',
        fontWeight: 600,
    },
    paperWidthSm:{
        maxWidth : 800
    }
});

class DownloadLabelDialog extends React.Component {
    render() {
        const { classes } = this.props;
        const { cancelBtnText = "Annulla", confirmBtnText, loader, product,license,  name ,  description} = this.props;

        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.onClose}
                    classes={{
                        paperWidthSm : classes.paperWidthSm
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">

                    <div style={{width : '100%', display : 'flex', alignItems : 'center', justifyContent : 'flex-end'}}>
                        <IconButton
                            onClick={this.props.onClose}
                        >
                            <Icon style={{fontSize : 24}}>
                                close
                            </Icon>
                        </IconButton>
                    </div>

                    <div className={classes.root}>
                        <div className={classes.productLabel}>
                            <Grid container spacing={24} >

                                <Grid item
                                      xs={12}
                                      sm={5}
                                      style={{margin : '0px 20px 0px 0px'}}
                                >
                                    <Hidden xsDown>
                                        <div style={{ backgroundColor: 'white', borderRadius: 5, padding: 10, minWidth : '254.609'}}>
                                            <img className={classes.qrCodeImage} src={product.qrcode} alt={'qr-code'}/>
                                        </div>
                                    </Hidden>
                                </Grid>

                                <Grid item
                                      xs={12}
                                      sm={6}
                                      style={{display: 'flex', justifyItems: 'center', alignItems: 'flex-start'}}>
                                    <div style={{
                                        padding: '0px 0px 0px 12px',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between'
                                    }}>
                                        <div>
                                        <Typography className={classes.title}>
                                            {name}
                                        </Typography>
                                            <Typography className={classes.subtitle}>
                                                {description}
                                            </Typography>
                                        </div>

                                        <div>
                                            <Typography className={[classes.subtitle, classes.typography].join(' ')}>
                                                <strong className={classes.strong}>{(license.license === 'commercial') ?
                                                    translate( 'general.product', {},  'Linea Prodotto')
                                                    :
                                                    translate( 'general.model', {},  'Linea Prodotto')}
                                                    :  </strong>&nbsp;{(product.model)?product.model.name:'-'}
                                            </Typography>
                                            {(license && license.license !== 'commercial') && <Typography className={[classes.subtitle, classes.typography].join(' ')}>
                                                <strong className={classes.strong}>S/N: </strong>&nbsp;{(product.serial_number)?product.serial_number:'-'}
                                            </Typography>}
                                            <Typography className={[classes.subtitle, classes.typography].join(' ')}>
                                                <strong className={classes.strong}>Code:</strong> {product.code}
                                            </Typography>
                                        </div>

                                        <div style={{display : 'flex', padding : '12px 0px', alignItems : 'flex-end', justifyContent : 'space-between'}}>
                                            <DownloadQrCode button={true} product={product}/>
                                        </div>
                                    </div>
                                </Grid>

                                <div id="divQrToPrint" style={{display: 'none'}}>
                                    <img style={{width: '800px'}} src={product.qrcode} alt={'qr-code'}/>
                                </div>
                            </Grid>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}

DownloadLabelDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    loader: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    cancelBtnText: PropTypes.string,
    confirmBtnText: PropTypes.string.isRequired,
};

export default withStyles(styles, {withTheme: true})(DownloadLabelDialog);
