import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
// redux
import { compose } from 'redux';
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import gloImage from "../../../../assets/immagini/global-icon.jpg";
import LanguageApi from "../../../language-select/LanguageApi";
import {translate} from "../../../../translations/i18n";

const styles = theme => ({
    textField: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        width: '100%',
    },
    title:{
        color : '#2C3A49',
        fontSize: 16,
        marginRight: 18,
        marginTop : 6,
        opacity : 0.8,
    },
    valueContainer:{
        backgroundColor :'#E5E5E5',
        borderRadius : '8px',
        display : 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        padding : '8px 12px 8px 4px',
        width : 'fit-content',
        margin: '8px 0px'
    },
    optionLabel:{
        textTransform : 'uppercase',
        marginTop : 6,
        margin : '0px 0px 0px 8px',
        color :'#000',
        fontSize: 16,
        fontWeight: 600,
    },
});

class ImportLinkMatch extends React.Component {

    constructor(props){
        super(props);

        this.state={
            values : [],
        }

    }

    componentDidMount(){
        LanguageApi.getFlags().then(
            res => {
                this.setState({values : res.data.content.items})
            },
            err => {

            }
        )
    }

    render() {
        const { classes, contentText, handleChange,handleChangeLabel,link,loading, languageValue, label='Lingua selezionata', values, labels} = this.props;

        return (
            <DialogContent>

                <DialogContentText id="alert-dialog-description">
                    <Typography>
                        {contentText}
                    </Typography>
                </DialogContentText>

                {/*{
                    languageValue && languageValue.length > 0 &&
                        <div style={{display : 'flex', alignItems : 'center', padding : '16px 0px'}}>
                            <Typography className={classes.title}>
                                {label}
                            </Typography>
                            <div  style={{backgroundColor :'#E5E5E5',
                                borderRadius : '8px',
                            display : 'flex', alignItems : 'center',     flexWrap: 'wrap'}}>
                            {
                                languageValue.map(item =>
                                    <div className={classes.valueContainer}>
                                        <img src={values[values.findIndex(value => value.id == item)].src}
                                             style={{height : 22, width : 22, borderRadius : 11,margin: '8px 0px 8px 8px'}} width={28}/>
                                        <Typography className={classes.optionLabel}
                                                    style={{textTransform: 'uppercase'}}>
                                            {values[values.findIndex(value => value.id == item)].code}
                                        </Typography>
                                    </div>
                                )
                            }
                            </div>
                        </div>
                }*/}
                {languageValue.map((item, index) =>
                    <div style={{marginTop : 20}}>
                        <div style={{display : 'flex', alignItems : 'center', padding : '16px 0px'}}>
                        <Typography className={classes.title}>
                            {label}
                        </Typography>
                            <div className={classes.valueContainer}>
                                <img src={values[values.findIndex(value => value.id == item)].src}
                                     style={{height : 22, width : 22, borderRadius : 11,margin: '8px 0px 8px 8px'}} width={28}
                                />
                                <Typography className={classes.optionLabel}
                                            style={{textTransform: 'uppercase'}}>
                                    {values[values.findIndex(value => value.id == item)].code}
                                </Typography>
                            </div>
                        </div>
                    <TextField
                        required
                        InputProps={{inputProps:{maxLength: 50}}}
                        InputLabelProps={{shrink:true}}
                        label={translate('machine_dialog.name',{},'Nome')}
                        name="name"
                        className={classes.textField}
                        value={labels[index].title}
                        onChange={(e) => {
                            (index === 0) && handleChange(e);
                            handleChangeLabel({ ...e, target : {...e.target, name : 'title'} }, index);
                        }}
                        disabled={loading}
                    />
                    {(!index > 0) && <TextField
                    required
                    InputLabelProps={{shrink:true}}
                    label="Link"
                    name="link"
                    className={classes.textField}
                    value={link.link}
                    onChange={(e) => handleChange(e)}
                    disabled={loading}
                />}
                    </div>
                )
                }
            </DialogContent>
        );
    }
}

ImportLinkMatch.propTypes = {
    classes: PropTypes.object,
};

export default compose(withStyles(styles, {withTheme: true}))(ImportLinkMatch);
