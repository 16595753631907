import React from 'react';
//import material-ui
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import {translate} from "../../../../../translations/i18n";
const useStyles = makeStyles(theme => ({
    deleteButton: {
        color: theme.palette.error.main,
    },
    title:{
        '& h2':{
            fontSize:24
        }
    },
    text:{
        fontSize:16
    },
    content : {
        padding: '8px 24px',
        display : 'flex',
        flexWrap : 'wrap',
        justifyContent : 'space-between'
    },
    child : {
        display : 'flex',
        flexBasis : '33%',
    },
    alertContent:{
        flex: 1,
        borderRadius: 10,
        margin: '8px 24px',
        padding: '12px 0px',
        textAlign: 'center',
        backgroundColor: '#FCDA7F'
    }
}));

function ConfirmDialog (props){

    const classes = useStyles(props);
    const { cancelBtnText = translate('bulk_import.cancel', {}, 'Annulla'), confirmBtnText, message, title,loader, open ,onConfirm = () => null , onClose = () => null, alertMessage = false } = props;

        return (
            <div>
                <Dialog
                    open={open}
                    onClose={onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle className={classes.title}>{title}</DialogTitle>
                    <div className={classes.content}>
                        <Typography className={classes.text}>
                            {message}
                        </Typography>
                    </div>
                    {alertMessage &&
                    <div className={classes.alertContent}>
                        <Typography className={classes.text}>
                            {translate('bulk_import.alert', {}, "Attenzione l'operazione potrebbe durare alcuni minuti")}
                        </Typography>
                    </div>}
                    <DialogActions>
                        <Button onClick={onClose} disabled={loader}>
                            {cancelBtnText}
                        </Button>
                        <Button onClick={onConfirm}
                                className={classes.deleteButton}
                                autoFocus>
                            {(!loader)?
                                confirmBtnText
                                :
                                <CircularProgress size={16} style={{marginLeft:0}}/>
                            }
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
}

export default ConfirmDialog;
