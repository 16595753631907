import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
// translation import

// redux
import { compose } from 'redux';
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import gloImage from "../../../../assets/immagini/global-icon.jpg";
import LanguageApi from "../../../language-select/LanguageApi";
import Dropzone from "react-dropzone";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const styles = theme => ({
    textField: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        width: '100%',
    },
    title:{
        color : '#2C3A49',
        fontSize: 16,
        marginRight: 18,
        marginTop : 6,
        opacity : 0.8,
    },
    valueContainer:{
        display : 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        marginRight : 8,
        padding : '10px'
    },
    optionLabel:{
        textTransform : 'uppercase',
        marginTop : 6,
        margin : '0px 0px 0px 8px',
        color :'#000',
        fontSize: 16,
        fontWeight: 600,
    },
    dropzoneRoot: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    dropzoneText: {
        color: '#777',
        fontSize: 15,
        fontWeight: 200,
    },
    loaderFileStatusText: {
        marginTop:12,
        fontSize: 12,
    },
    dropzone: {
        width: '85%',
        height: 200,
        margin: 20,
        cursor: 'pointer',
        backgroundColor: '#e8e6e6',
        borderWidth: 1,
        borderColor: theme.palette.primary.main,
        borderStyle: 'dashed',
        borderRadius: 2,
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        fontSize: 68,
        marginTop: 20,
        color: theme.palette.primary.main,
    },
    dropText: {
        padding: 20,
        paddingTop:0,
        textAlign: 'center'
    },
    templateUrl: {
        color: theme.palette.active.main,
    }
});

class ImportLinkMatch extends React.Component {

    constructor(props){
        super(props);

    }


    render() {
        const { classes, contentText, handleChange,link,loading, languageValue, label='Lingua selezionata', values, multiple} = this.props;


        return (
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography>
                        {contentText}
                    </Typography>
                </DialogContentText>

                {languageValue && languageValue.length > 0 &&
                        <div style={{display : 'flex', alignItems : 'center', padding : '16px 0px'}}>
                            <Typography className={classes.title}>
                                {label}
                            </Typography>
                            <div  style={{backgroundColor :'#E5E5E5',
                                borderRadius : '8px',
                            display : 'flex', alignItems : 'center', flexWrap : 'wrap'}}>
                            {
                                languageValue.map(item =>
                                    <div className={classes.valueContainer}>
                                        <img src={values[values.findIndex(value => value.id == item)].src}
                                             style={{height : 22, width : 22, borderRadius : 11,margin: '8px 0px 8px 8px'}} width={28}/>
                                        <Typography className={classes.optionLabel}
                                                    style={{textTransform: 'uppercase'}}>
                                            {values[values.findIndex(value => value.id == item)].code}
                                        </Typography>
                                    </div>
                                )
                            }
                            </div>
                        </div>
                }

                <div className={classes.dropzoneRoot}>
                    <Dropzone className={classes.dropzone}
                              multiple={multiple}
                              onDrop={(files) => this.props.onDrop(files)}>
                        {({getRootProps, getInputProps}) => (
                            <div
                                {...getRootProps()}
                                className={classes.dropzone}>
                        <Icon className={classes.icon} style={{display: (loading) ? 'none' : 'block'}}>cloud_upload</Icon>
                        <Typography
                            {...getInputProps()}
                            className={[classes.typography, classes.dropText]} style={{display: (loading) ? 'none' : 'block'}}>
                            Clicca o trascina direttamente qui i <strong>file</strong> per caricarli.
                        </Typography>
                        <div style={{textAlign: 'center'}} hidden={!loading}>
                            <CircularProgress size={40}/>
                            <br/>
                            <Typography className={classes.loaderFileStatusText}>{this.props.filesStep} / {this.props.filesToBeSent}</Typography>
                        </div>
                            </div>
                        )}

                    </Dropzone>
                </div>

            </DialogContent>
        );
    }
}

ImportLinkMatch.propTypes = {
    classes: PropTypes.object,
};

export default compose( withStyles(styles, {withTheme: true}))(ImportLinkMatch);
