import React, {useEffect, useState, useRef} from 'react';
import Select from "@material-ui/core/Select/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({

    textField: {
        minWidth : 132,

    },
    label : {
        marginRight: '8px',
        fontSize : 18,
        color : '#2C3A49',
        fontStyle: 'italic'
    },
    option:{
        display :'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '8px',
        fontSize: 16,
        fontWeight: 600,
    },
    optionLabel:{
        textTransform : 'uppercase',
        marginTop : 6,
        margin : '0px 0px 0px 8px',
        fontSize: 16,
        fontWeight: 600,
    },
    title:{
        color : '#000',
        fontWeight : 'bold',
        fontSize: 18,
        marginRight: 18,
        marginTop : 6,
        opacity : 0.8,
    },
    filled : {
        padding: '8px 30px 10px 10px !important',
    },
    underline : {
        '&::before':{
            border : '0px !important'
        },
        '&::after':{
            border : '0px !important'
        }
    },
    selectRoot : {
        background: '#E5E5E5',
        borderRadius: 16,
        color : '#000',
        '&:focused':{
            background: '#E5E5E5',
        }
    },
    icon : {
        top: 'calc(50% - 12px)',
        right: 7,
        fontSize : 22,
        position: 'absolute',
        pointerEvents: 'none',
    },
    paper : {
        maxWidth : '132px !important',
        minWidth : '132px !important',
        background: '#E9EAEB',
    },
    list : {
        paddingTop : 0
    },
    selectMenu:{
        height : 24,
        display : 'flex',
        alignItems: 'center'
    },
    valueContainer:{
        display : 'flex',
        justifyContent: 'start',
        marginRight : 8,
        padding : '8px 0px',
        alignItems : 'center'
    },
    input:{
        padding :'10px 8px',
    }
}));


function useOutsideAlerter(ref, setOpen) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpen(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}


function ModelSelect(props) {
    const { value , onChange, label = 'Seleziona lingua',  values = []} = props;

    const classes = useStyles(props);
    const {multiple = true, style = {}} = props;
    const wrapperRef = useRef(null);
    const [open , setOpen] = useState(false);
    const [localValues , setValues] = useState([]);
    useOutsideAlerter(wrapperRef, setOpen);


    return (
        <div style={{display : 'flex', alignItems : 'center', justifyContent : 'flex-end', padding : 16, minWidth : 250, marginRight : 24, ...style}}>
            <Typography className={classes.title}>
                {label}
            </Typography>
            <FormControl className={classes.textField}
                         variant="filled"
            >
                <Select
                    value={value}
                    ref={wrapperRef}
                    open={open}
                    onClick={() => setOpen(true)}
                    variant="standard"
                    /*onChange={(e) => {
                        onChange(e);
                    }}*/
                    classes={{
                        root : classes.selectRoot,
                        filled : classes.filled,
                        selectMenu : classes.selectMenu
                    }}
                    displayEmpty
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        classes:{
                            paper : classes.paper,
                            list : classes.list,
                        }
                    }}

                    IconComponent={() => (
                        <Icon className={classes.icon} style={{color: '#000'}}>expand_more</Icon>)}
                    input={<Input classes={{underline : classes.underline, input : classes.input}}/>}
                >

                </Select>
            </FormControl>
        </div>
    )
}


export default ModelSelect
