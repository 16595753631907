import API from './API';

class AuthApi {

    static url = 'user';

    static getAuth(username, password) {
        // false: no check refresh/verify token
        const api = new API(this.url + '/auth', false);
        return api.post({
            username: username,
            password: password
        })
    }

    static customerRegister(username, password, first_name, last_name, birth_date) {
        const api = new API('customer/register');
        return api.post({
            username: username,
            password: password,
            first_name: first_name,
            last_name: last_name,
            birth_date: birth_date
        })
    }

    static refreshToken(token) {
        const api = new API(this.url + '/refresh-token', false);
        return api.post({token: token})
    }

    static verifyToken(token) {
        const api = new API(this.url + '/verify-token', false);
        return api.post({token: token})
    }

    static changePassword(old_password, new_password) {
        const api = new API(this.url + '/change-password');
        return api.post({
            old_password: old_password,
            new_password: new_password
        })
    }
}

export default AuthApi
