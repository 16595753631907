import API from '../../services/API';

class LanguageApi {
    static url = 'flag';


    static getFlags(){
        const api = new API(this.url);
        return api.get();
    }

    static getFlagsProduct(productId){

        const api = new API(this.url + `/product/${productId}`);
        return api.get();
    }

    static getFlagsModel(modelId){
        const api = new API(this.url + `/model/${modelId}`);
        return api.get();
    }

}

export default LanguageApi
