import React from 'react';
// material ui import
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
// components import
import TextFieldControlCharacters from '../../../../../components/ui/inputs/TextFieldControlCharacters'
// redux import
import { compose } from 'redux';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles  = theme => ({
    textField: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        width: '100%',
    },
    dialogAction: {
        margin: '0px 20px',
        marginBottom:20,
    }
});


class PermissionRequestDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            permissionRequest: {
                name: null,
                email: null,
                message: null,
            },
        }
    }

    componentDidMount(){
        const props = this.props;
        this.setState({
            permissionRequest:{
                name: (props.permissionRequest)?props.permissionRequest.name:null,
                email: (props.permissionRequest)?props.permissionRequest.email:null,
                message: (props.permissionRequest)?props.permissionRequest.message:null,
            },
        })
    }

    handleChange = (e) => {
        e.persist();
        this.setState(prevState => ({
            permissionRequest:{
                ...prevState.permissionRequest,
                [e.target.name] : e.target.value
            }
        }));
    };

    render() {
        const { permissionRequest } = this.state;
        const { classes, theme, open, loader } = this.props;

        return (
            <div>
                <Dialog
                    style={{width: '100%', backgroundColor: theme.palette.primary.main}}
                    open={open}
                    onClose={this.props.onClose}>
                    {/* Print edit machine on edit - Print the label on readOnly*/}
                        <DialogTitle>{this.props.title}</DialogTitle>
                    <DialogContent style={{paddingTop:0}}>
                        <form ref={this.formMachine} onSubmit={(e)=> {e.preventDefault(); this.props.onSubmitRequest(permissionRequest)}}
                              onKeyPress={(e) => (e.which === 13)?e.preventDefault():null}>
                                <DialogContentText>
                                    Compila i campi sottostanti per richiedere i permessi della macchina
                                </DialogContentText>
                                <div>
                                    <TextField
                                        label="Nominativo"
                                        name="name"
                                        InputLabelProps={{ shrink: true}}
                                        required={true}
                                        className={classes.textField}
                                        value={this.state.permissionRequest.name}
                                        onChange={(e) => this.handleChange(e)}
                                        //disabled={loader}
                                        disabled={true}
                                        InputProps={{
                                            readOnly: this.props.readOnly,
                                            disableUnderline: this.props.readOnly,
                                        }}
                                    />
                                    <TextField
                                        label="Ubilod account"
                                        name="email"
                                        InputLabelProps={{ shrink: true}}
                                        required={true}
                                        className={classes.textField}
                                        value={this.state.permissionRequest.email}
                                        onChange={(e) => this.handleChange(e)}
                                        //disabled={loader}
                                        disabled={true}
                                        InputProps={{
                                            readOnly: this.props.readOnly,
                                            disableUnderline: this.props.readOnly,
                                        }}
                                    />
                                    <TextField
                                        label="Il tuo messaggio"
                                        name="message"
                                        multiline
                                        rowsMax="4"
                                        InputLabelProps={{ shrink: true}}
                                        required={true}
                                        className={classes.textField}
                                        value={this.state.permissionRequest.message}
                                        onChange={(e) => this.handleChange(e)}
                                        //disabled={loader}
                                        disabled={true}
                                        inputProps={{
                                            maxLength: 350
                                        }}
                                    />
                                    <TextFieldControlCharacters element={this.state.permissionRequest.message} length={350}/>
                                </div>
                            <Button type="submit" id="submitButton" style={{display:'none'}}/>
                        </form>
                    </DialogContent>
                    <DialogActions className={classes.dialogAction}>
                        <Button onClick={() => this.props.onClose()} color="primary">Torna alle mie macchine</Button>
                            <Button disabled={true} onClick={() => document.getElementById('submitButton').click() } variant="outlined" color="primary">
                                {(!loader)?
                                    "Invia richiesta"
                                    :
                                    <CircularProgress size={16} style={{marginLeft:0}}/>
                                }
                            </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default compose(withStyles(styles, {withTheme: true}))(PermissionRequestDialog);