import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
//material-ui imports
import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../redux/actions/api-control-action';
// translation import

const styles = theme => ({
    dropzoneRoot: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    dropzoneText: {
        color: '#777',
        fontSize: 15,
        fontWeight: 200,
    },
    loaderFileStatusText: {
        marginTop:12,
        fontSize: 12,
    },
    dropzone: {
        width: '90%',
        height: 200,
        margin: 20,
        cursor: 'pointer',
        backgroundColor: '#ffffff',
        borderWidth: 1,
        borderColor: theme.palette.primary.main,
        borderStyle: 'dashed',
        borderRadius: 2,
        display: 'flex',
        justifyContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        flexFlow: 'row wrap',
        boxShadow: '0px 2px 2px rgba(131, 138, 154, 0.50)',
    },
    icon: {
        fontSize: 68,
        marginTop: 20,
        color: theme.palette.primary.main,
    },
    dropText: {
        padding: 20,
        paddingTop:0,
        textAlign: 'center'
    },
    templateUrl: {
        color: theme.palette.active.main,
    }
});


class SimpleDropzone extends Component {

    constructor(props){
        super(props);
            this.state={
        }
    }
    onDrop(acceptedFiles) {
        this.props.onDrop(acceptedFiles)
    }

    render() {
        const { loading } = this.state;
        const { classes, multiple } = this.props;

        return (
            <div className={classes.dropzoneRoot}>
                <Dropzone className={classes.dropzone}
                          multiple={multiple}
                          onDrop={(files) => this.onDrop(files)}>
                    {({getRootProps, getInputProps}) => (
                        <div
                            className={classes.dropzone}
                            {...getRootProps()}>
                    <Icon className={classes.icon} style={{display: (loading) ? 'none' : 'block'}}>cloud_upload</Icon>
                    <Typography
                        {...getInputProps()}
                        className={[classes.typography, classes.dropText]} style={{display: (loading) ? 'none' : 'block'}}>
                        Clicca o trascina direttamente qui <br/><strong>l'immagine della macchina</strong> per caricarla.
                    </Typography>
                    <div style={{textAlign: 'center'}} hidden={!loading}>
                        <CircularProgress size={40}/>
                        <br/>
                        <Typography className={classes.loaderFileStatusText}>{this.state.filesStep} / {this.state.filesToBeSent}</Typography>
                    </div>
                        </div>
                        )}
                </Dropzone>
            </div>
        )
    }
}

SimpleDropzone.propTypes = {
    classes: PropTypes.object.isRequired,
    element: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    multiple: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    api_control: state.api_control,
    router: state.router,
});

const mapActionsToProps = {
    onUpdateApiControl : updateApiControl
}

export default compose(

    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(SimpleDropzone);
