import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// components import

const styles  = () => ({
    root:{
        textAlign:'justify',
        padding:'40px 60px'
    }
});

class PrivacyAndCookiePolicy extends Component {

    componentDidMount(){

    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Typography variant="title" style={{marginBottom:40}}>PRIVACY & COOKIE POLICY</Typography>
                <Typography variant="body2">
                    NORME SULLA PRIVACY DI UBILOD<br/><br/>
                        Quando utilizzi i nostri servizi, ci affidi le tue informazioni. Siamo consapevoli che si tratti di una grande responsabilità e ci adoperiamo per proteggere le tue informazioni lasciando a te il controllo.

                    <strong>Pubblicato:</strong> 15 Febbraio 2019<br/>
                    <strong>Data di validità:</strong> 15 Febbraio 2019<br/><br/>
                        Ti ringraziamo per il tuo interesse in Ubilod. La nostra missione è di semplificare il modo di lavorare fornendo una piattaforma intuitiva e unificata per collaborare con gli altri. Qui viene descritto in che modo raccogliamo, utilizziamo e gestiamo i tuoi dati personali quando utilizzi i nostri siti web, il nostro software e i nostri servizi ("Servizi").

                        <br/><br/><strong>Che cosa e perché</strong><br/>
                        Ci riserviamo di raccogliere e conservare i seguenti dati per fornire, migliorare e proteggere i nostri Servizi:

                        <br/><br/><strong>Condivisione dei contenuti</strong><br/>
                        I nostri Servizi ti consentono di condividere i tuoi file con altre persone, per cui fai molta attenzione a cosa condividi.<br/>
                        <i>Informazioni account</i>. Raccogliamo e associamo al tuo account i dati che ci fornisci quando esegui operazioni quali la registrazione all'account, l'upgrade a un piano a pagamento; questi dati sono, ad esempio, il tuo nome, il tuo indirizzo email, il tuo numero di telefono e l'indirizzo fisico. Alcuni dei nostri Servizi ti consentono di accedere ai tuoi account e ai tuoi dati tramite altri fornitori di servizi.<br/>
                    <br/>
                        <i>I tuoi file</i>. I nostri Servizi sono progettati per consentirti di archiviare in modo semplice i tuoi file, documenti, foto, commenti, messaggi e così via ("<b>I tuoi file</b>"), collaborare con altre persone e lavorare su più dispositivi. Per rendere ciò possibile, archiviamo, elaboriamo e trasmettiamo I tuoi file nonché le informazioni ad essi correlate. Tali informazioni correlate possono essere, ad esempio, le informazioni del profilo, che rendono più semplice collaborare e condividere I tuoi file con altre persone, nonché le dimensioni del file, l'ora in cui è stato caricato, i collaboratori e l'attività di utilizzo. I nostri Servizi forniscono diverse opzioni per condividere I tuoi file.
                    <br/><br/>
                        <i>Contatti</i>. Puoi scegliere di concederci l'accesso ai tuoi contatti, per poter eseguire facilmente operazioni quali condividere e collaborare ai tuoi file, inviare messaggi e invitare altre persone a utilizzare i Servizi. Se ci fornisci l'accesso ai tuoi contatti, li archivieremo sui nostri server in modo che tu possa utilizzarli.
                    <br/><br/>
                        <i>Informazioni sull'utilizzo</i>. Raccogliamo informazioni relative al modo in cui utilizzi i Servizi, comprese le azioni intraprese nell'account (come condivisione, modifica, visualizzazione e spostamento di file o cartelle). Queste informazioni ci aiutano a migliorare i nostri Servizi, sviluppare nuovi servizi e funzionalità e proteggere gli utenti di Ubilod.
                    <br/><br/>
                        <i>Informazioni sui dispositivi</i>. Ci riserviamo inoltre di raccogliere dati provenienti da e riguardanti i dispositivi che utilizzi per accedere ai Servizi. Sono inclusi dati quali l'indirizzo IP, il tipo di browser e il dispositivo utilizzato e gli identificatori associati al tuo dispositivo. I tuoi dispositivi (a seconda delle loro impostazioni) potrebbero trasmettere ai Servizi anche dati sulla posizione.
                    <br/><br/>
                        <i>Cookie e altre tecnologie</i>. Utilizziamo tecnologie quali cookie per fornire, migliorare, proteggere e promuovere i nostri Servizi. Ad esempio, i cookie ci aiutano per operazioni quali ricordare il tuo nome utente per la visita successiva, capire il modo in cui interagisci con i nostri Servizi e migliorarli sulla base di tali dati. Puoi impostare il tuo browser in modo che non accetti i cookie, ma ciò potrebbe limitare la possibilità di utilizzare i nostri Servizi.
                    <br/><br/>
                        <i>Marketing</i>. Offriamo agli utenti la possibilità di utilizzare gratuitamente alcuni dei nostri Servizi. Questi Servizi gratuiti sono resi possibili dal fatto che alcuni utenti effettuano l'upgrade a uno dei nostri Servizi a pagamento. Se ti registri per i nostri Servizi gratuiti, ti invieremo periodicamente informazioni sugli aggiornamenti, quando consentito. Gli utenti che ricevono questi materiali di marketing possono disattivarne la ricezione in qualsiasi momento. Se non vuoi ricevere materiali di marketing da noi, inviaci una mail a <a href="support@ubilod.com">support@ubilod.com</a>
                    <br/><br/>
                        A volte contattiamo persone che non hanno un account Ubilod. Per i destinatari all'interno dell'UE, otterremo o una terza parte otterrà il consenso prima di contattarti. Se ricevi un'email e non desideri più essere contattato da Ubilod, tramite il messaggio stesso puoi annullare l'iscrizione e cancellarti dal nostro elenco contatti.
                    <br/><br/>
                        <i>Basi per il trattamento dei tuoi dati</i>. Raccogliamo e utilizziamo i dati personali sopra descritti al fine di fornirti i Servizi in modo affidabile e sicuro. Inoltre raccogliamo e utilizziamo dati personali per esigenze commerciali legittime. Quando trattiamo i tuoi dati personali per altri scopi, chiediamo il tuo consenso in anticipo o richiediamo ai nostri partner di ottenere tale consenso.
                    <br/><br/>

                    <strong>Con chi</strong><br/>
                    Ci riserviamo il diritto di condividere i dati come descritto sopra, ma questi non saranno venduti a inserzionisti o ad altre terze parti.
                    <br/><br/>
                    <i>Altre persone che lavorano per Ubilod</i>.
                    Ubilod si avvale di determinate terze parti di fiducia (ad esempio fornitori di assistenza clienti e servizi IT) per fornire, migliorare, proteggere e promuovere i propri Servizi. Tali terze parti avranno accesso ai tuoi dati esclusivamente per svolgere attività per conto nostro e in conformità alle presenti Norme sulla privacy. Noi continueremo a essere responsabili del modo in cui gestiscono i tuoi dati nel rispetto delle istruzioni che forniamo loro.

                    <br/><br/>
                    <i>Altri utenti</i>.
                    I nostri Servizi mostrano ad altri utenti i tuoi dati, quali il nome, l'immagine del profilo, il dispositivo e l'indirizzo email, in posizioni quali il tuo profilo utente e le notifiche di condivisione. Puoi anche scegliere di condividere I tuoi file con altri utenti.

                    <br/><br/>
                    Alcune funzioni ti permettono di rendere altri dati disponibili ad altri utenti.

                    <br/><br/>
                    <i>Ordine pubblico e interesse pubblico</i>.
                    Ci riserviamo il diritto di divulgare i tuoi dati a terze parti nel caso in cui stabilissimo che tale divulgazione è ragionevolmente necessaria per: (a) rispettare qualunque legge, regolamento, procedura legale o appropriata richiesta ufficiale; (b) salvaguardare qualsiasi persona da decesso o gravi lesioni fisiche; (c) prevenire frodi o abusi a danno di Ubilod o dei suoi utenti; (d) proteggere i diritti, la proprietà, la sicurezza o l'interesse di Ubilod; o (e) eseguire un compito di interesse pubblico.
                    <br/><br/>
                    La gestione dei tuoi dati ha per noi un'importanza fondamentale ed è una responsabilità di cui ci facciamo carico. Riteniamo che i tuoi dati debbano ricevere le stesse tutele legali a prescindere dal fatto che siano archiviati nei nostri Servizi o nel disco rigido del tuo computer in ufficio. Ci atterremo ai seguenti principi relativi alle richieste ufficiali quando riceveremo, esamineremo e risponderemo alle richieste ufficiali della pubblica amministrazione (comprese le richieste relative alla sicurezza nazionale) relative ai tuoi dati:

                    <ul>
                        <li>Essere trasparenti</li>
                        <li>Rifiutare richieste di portata globale</li>
                        <li>Proteggere tutti gli utenti</li>
                        <li>Fornire servizi di cui fidarsi</li>
                    </ul>

                    <br/>
                    <strong>Come</strong>
                    <br/>
                    <i>Sicurezza</i>.
                    Ubilod dispone di un team che si occupa di tenere i tuoi dati al sicuro e verificare eventuali vulnerabilità. Inoltre, continuiamo a implementare funzioni per la sicurezza dei tuoi dati, come l'autenticazione a due fattori, la crittografia dei file durante l'archiviazione e gli avvisi quando nuovi dispositivi e applicazioni sono collegate al tuo account. Adottiamo tecnologie automatizzate per rilevare comportamenti abusivi e contenuti che possono danneggiare i nostri Servizi, te o altri utenti.

                    <br/><br/>
                    <i>Controlli utente</i>.
                    Puoi accedere, modificare, scaricare ed eliminare i tuoi dati personali effettuando l'accesso al tuo account Ubilod e andando alla pagina delle impostazioni dell'account.

                    <br/><br/>
                    <i>Conservazione</i>.
                    Quando ti registri per ottenere un nostro account, conserveremo i dati che archivi sui nostri Servizi finché l'account esiste o finché ci è necessario per fornirti i Servizi. Se elimini il tuo account, procederemo all'eliminazione di questi dati dopo 30 giorni. Ma tieni presente che: (1) potrebbero verificarsi ritardi nell'eliminazione di tali dati dai nostri server e dallo spazio archiviazione di backup; e (2) possiamo conservare questi dati ove necessario per rispettare i nostri obblighi legali, risolvere dispute o applicare contratti.

                    <strong>Dove</strong>
                    <br/><br/>
                    <i>In tutto il mondo</i>.
                    Per fornirti i Servizi, ci riserviamo il diritto di archiviare, elaborare e trasmettere dati in sedi ubicate in Italia e in tutto il mondo, anche al di fuori del tuo Paese. I dati potrebbero anche essere archiviati in locale sui dispositivi che utilizzi per accedere ai Servizi.

                    <strong>Modifiche</strong>
                    <br/><br/>
                    In caso di riorganizzazione, fusione, acquisizione o vendita dei nostri beni, i tuoi dati potrebbero essere trasferiti nel quadro di tale accordo. Gli utenti verranno avvisati (ad esempio, tramite un messaggio all'indirizzo email associato con il tuo account) di eventuali accordi e verranno informati sulle scelte a loro disposizione.
                    <br/><br/>
                    Ci riserviamo il diritto di aggiornare periodicamente le presenti Norme sulla privacy. La versione più recente verrà pubblicata sul nostro sito web. Nel caso in cui una revisione possa ridurre in modo sostanziale i diritti dell'utente, invieremo un avviso pertinente.

                    <strong>Il tuo diritto al controllo e all'accesso delle tue informazioni</strong>
                    <br/><br/>
                    Hai il controllo sui tuoi dati personali e sulla loro modalità di raccolta, utilizzo e condivisione. Per esempio, hai il diritto di:
                    <ul>
                        <li>Cancellare o eliminare tutti I tuoi file o alcuni di essi nel tuo account Ubilod</li>
                        <li>Modificare o correggere i dati personali. Puoi gestire il tuo account e i suoi contenuti e modificare alcuni tuoi dati personali dalla pagina delle impostazioni dell'account</li>
                        <li>Accedere, scaricare e copiare i tuoi dati. Puoi scaricare una copia dei tuoi dati in un formato leggibile</li>
                    </ul>

                    <strong>Informazioni di contatto</strong>
                    <br/><br/>
                    I tuoi dati personali sono controllati da Ubilod, Inc. Per tutti gli altri utenti, i dati personali sono controllati da Co-Brains S.r.l. Hai domande o dubbi su Ubilod, i nostri Servizi e la privacy? Contatta il nostro responsabile della protezione dei dati all'indirizzo <a href="privacy@ubilod.com">privacy@ubilod.com</a>. Se non è in grado di rispondere alla tua domanda, hai il diritto di contattare l'autorità locale di supervisione della protezione dati.

                </Typography>
            </div>
        )
    }
}


PrivacyAndCookiePolicy.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(PrivacyAndCookiePolicy);