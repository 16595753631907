import React, { Component } from 'react';
import PropTypes from 'prop-types';
// material-ui imports
import { withStyles } from '@material-ui/core/styles';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux'
import { updateUser } from '../../../redux/actions/user-action';
import { updateApiControl } from '../../../redux/actions/api-control-action';
import { updateUtils } from '../../../redux/actions/utils-action';
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
// assets
import backgroundHeader from './img/ubilod-background.jpg';
import ubilodMap from './img/ubilod-map.png';
import ubilodProcess from './img/ubilod-process.png';
import ubilodAppDevices from './img/ubilod-app-devices.png';
import TextField from "@material-ui/core/TextField";
// logo import
import Logo from '../../../assets/logos/ubilod_logo-light_bg.svg';
import CobrainsLogo from '../../../assets/logos/logo-brand-cobrains.svg';
import MockupUbilod from './img/mockup-ubilod-landing.png';
import {Link} from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SupportApi from "../../../services/SupportApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import Icon from "@material-ui/core/Icon";
import Fab from "@material-ui/core/Fab";

const sectionHeight=400;
const styles  = theme => ({
    root: {
        marginTop:-32,
        '& strong':{
            color:'#333'
        }
    },
    menu:{
        top:64,
        position: 'fixed',
        background:theme.palette.primary.light,
        width:'100%',
        height:56,
        boxShadow:'0px 0px 5px #333',
        zIndex:99,
        [theme.breakpoints.down('sm')]:{
            top:56,
        }
    },
    menuContainer:{
        margin:'auto',
        width:'72%',
        height:'100%',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-around',
        '& p':{
            color:'#fff',
            fontSize:14,
        }
    },
    headerImgContainer:{
        borderBottom: '4px solid ' + theme.palette.primary.main,
        //marginTop:56,
        padding:'40px 80px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width:'100%',
        height:'calc(100vh - 64px)',
        backgroundBlendMode: 'multiply',
        backgroundColor:'rgba(0,0,0,0.25)',
        backgroundImage:`url(${backgroundHeader})`,
        backgroundSize:'cover',
        backgroundPosition:'center',
        [theme.breakpoints.down('sm')]:{
            padding:32,
            backgroundColor:'rgba(0,0,0,0.6)',
        },
        [theme.breakpoints.down('xs')]:{
            height:'calc(100vh - 56px)',
        }
    },
    headerMockup:{
        flex:1,
        //marginTop:160,
        marginRight: 0,
        '& img':{
            width:'160%'
        },
        [theme.breakpoints.down('sm')]:{
            display:'none'
        }
    },
    headerTitle:{
        fontSize:60,
        color:theme.palette.secondary.main,
        fontWeight:'bold'
    },
    headerSubtitle:{
        fontSize:32,
        color:theme.palette.secondary.main,
        width:'80%',
        lineHeight: '36px',
        [theme.breakpoints.down('xs')]:{
            fontSize:24,
        }
    },
    headerDivider:{
        background:theme.palette.secondary.main,
        height:3,
        width:200,
        marginTop:20,
        borderRadius:8,
},
    headerContent:{
        marginTop:20,
        fontSize:22,
        fontWeight:200,
        color:theme.palette.secondary.main,
        width:'80%',
        [theme.breakpoints.down('xs')]:{
            fontSize: 18
        }
    },
    buttonHeaderSignIn:{
        marginTop: 40,
        minWidth:220,
        fontSize:16,
        background: theme.palette.primary.main,
        border: '2px solid ' + theme.palette.active.main,
        color: theme.palette.secondary.main
    },
    container:{
        //padding:'20px 80px',
    },
    section:{
        margin:'80px 0',
        padding:'20px 80px',
        minWidth: '40vw',
        display:'flex',
        flexDirection:'row',
        minHeight:sectionHeight,
        alignItems:'center',
        [theme.breakpoints.down('sm')]:{
            flexDirection:'column',
            margin:0,
            padding:'0 32px',
        }
    },
    sectionMap:{
        [theme.breakpoints.down('sm')]:{
            flexDirection:'column-reverse!important',
        }
    },
    sectionFullWidth:{
        textAlign:'center',
        flexDirection:'column',
        color: '#fff',
        padding:0,
        minHeight:'unset',
        height:'auto',
        '& p':{
            fontSize:22
        }
    },
    title:{
        marginTop:38,
        fontSize:'48px!important',
        fontWeight:600,
        [theme.breakpoints.down('sm')]:{
            fontSize:'20px!important',
        }
    },
    titleOrange:{
        //marginTop:40,
        fontSize:'38px!important',
        fontWeight:600,
        color:theme.palette.active.main,
        [theme.breakpoints.down('sm')]:{
            fontSize:'20px!important',
        }
    },
    content:{
        marginTop:20,
        fontSize:22,
        color:theme.palette.gray.main
    },
    sectionImg:{
        flex: 1,
        justifyContent: 'center',
        display: 'flex',
    },
    imgUbilodMap:{
        height:400,
        maxHeight:400,
        [theme.breakpoints.down('xs')]:{
            height:'280px!important',
            maxHeight:'280px!important',
        }
    },
    imgFeatureProcess:{
        height:'700px!important',
        maxHeight:'700px!important',
        [theme.breakpoints.down('xs')]:{
            marginTop:40,
            height:'440px!important',
            maxHeight:'440px!important',
        }
    },
    listNumber:{
        fontSize:32,
        color:'#b4b5b4',
        fontWeight:'bold',
    },
    ubilodAppDevices:{
        width:'100%',
        height:360,
        backgroundSize:'60%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        [theme.breakpoints.down('sm')]:{
            backgroundSize:'100%',
        },
    },
    footerBackground:{
        padding:'20px 80px',
        background:theme.palette.secondary.main,
        width:'100%',
        display:'flex',
        [theme.breakpoints.down('sm')]:{
            marginTop:20,
            flexDirection:'column',
            textAlign: 'center'
        },
        '& p':{
            color: theme.palette.primary.main,
            fontSize:16
        }
    },
    logosFooterContainer:{
        display:'flex',
        [theme.breakpoints.down('sm')]:{
            justifyContent:'center',
            marginBottom:20
        },
    },
    registrationFormContainer:{
        width: '60%',
        [theme.breakpoints.down('sm')]:{
            width:'100%',
        },
    },
    textField:{
        width:'100%',
        background:theme.palette.secondary.main,
        [theme.breakpoints.down('sm')]:{
            width:'100%',
        },
    },
    checkbox:{
        width:'100%',
    },
    button:{
        marginTop:20,
        width:'60%',
    },
    /* Cookie Bar */
    cookieBar:{
        width: '40%',
        position: 'fixed',
        '& a':{
            color:theme.palette.primary.main,
        }
    },
    loader:{
        color: '#fff',
    },
    arrowContainer:{
        display:'flex',
        justifyContent:'center',
        alignItems:'flex-end',
        padding:20,
        position:'absolute',
        top:0,
        left:0,
        height:'100vh',
        width:'100%',
        [theme.breakpoints.down('sm')]:{
            display:'none'
        }
    }

});

class LandingPage extends Component {

    constructor(props){
        super(props);
        this.state = {
            registrationForm:{
                privacy_policy_checked:false,
                marketing_checked:false,
                first_name:'',
                last_name:'',
                company:'',
                sector:'',
                email:'',
                phone_number:'',
                company_role:''
            },
            loaderRegistrationForm:false,
        }
    }
    componentDidMount() {
        if (this.props.user.company_id) {
            let tmpUser = this.props.user;
            delete tmpUser.company_id;
            this.props.onUpdateUser(tmpUser)
        }
    }

    onRegisrationFormSubmit(e){
        e.preventDefault();
        // Api call for auth user login
        this.setState({loaderRegistrationForm:true})
        SupportApi.registrationForm(this.state.registrationForm)
            .then(
                res => {
                    this.setState({
                        loaderRegistrationForm: false,
                        registrationForm:{
                            privacy_policy_checked:false,
                            marketing_checked:false,
                            first_name:'',
                            last_name:'',
                            company:'',
                            sector:'',
                            email:'',
                            phone_number:'',
                            company_role:''
                        }
                    });
                    // ====== CONFIRM MESSAGE ====== //
                    this.props.onUpdateApiControl({status: 200}, 'registration-form', 'La tua richiesta é stata inviata correttamente.');
                },
                err => {
                    this.setState({
                        loaderRegistrationForm: false,
                    });
                    // ====== API CONTROL MESSAGE ====== //
                    this.props.onUpdateApiControl(err, 'registration-form',  `C'é stato un problema nell'inoltrare la tua richiesta. Controlla i campi e riprova.`);
                }
            );
    }

    // ==============================================
    // REGISTRATION FORM
    // ==============================================
    onChangeRegistrationForm(e){
        e.persist();
        const value = (e.target.type === "checkbox")?e.target.checked:e.target.value;
        this.setState(prevState => ({
            registrationForm:{
                ...prevState.registrationForm,
                [e.target.name] : value

            }
        }));
    }


    render() {
        const { theme, classes } = this.props;
        const { loaderRegistrationForm } = this.state;
        const { privacy_policy_checked, marketing_checked, first_name, last_name, sector, company, email, phone_number, company_role} = this.state.registrationForm;

        window.location = 'https://gestionedocumentale.ubilod.com/';

        return (
                <div className={classes.root} style={{
                    zIndex: 999999999999,
                    position: 'absolute',
                    marginTop: '-96px',
                    backgroundColor : '#fff',
                    height : 500,
                    width : '100%'
                }}>

                </div>
        )
    }
}


LandingPage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    router: state.router,
    api_control: state.api_control,
    utils: state.utils
});

const mapActionsToProps = {
    onUpdateUser : updateUser,
    onUpdateApiControl : updateApiControl,
    onUpdateUtils : updateUtils
}

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(LandingPage);
