import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// logo import
import Logo from '../../../../assets/logos/ubilod_logo-light_bg.svg';
//import material-ui
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    footerBar: {
        background: theme.palette.primary.main,
        color: '#fff',
        display: 'flex',
        height: 30,
        border: 0,
        borderTop: 1,
        borderStyle: 'solid',
        borderColor: '#e1e1e1',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            textAlign:'center',
            alignItems: 'center',
            height: 'auto',
        }
    },
    containerTextContent: {
        flex:1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginTop:20,
            marginBottom: 12,
        }
    },
    footerWidgetContainer: {
        minHeight:40,
        display: 'flex',
        border:0,
        borderTop: 1,
        borderStyle: 'solid',
        borderColor: '#e1e1e1',
        background: '#fff',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    languageContainer: {
        display: 'flex',
        flex: 2,
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    textContent:{
        marginLeft: 16,
        marginRight: 16,
        fontSize:11
    }
});

class BackofficeFooter extends Component {

    render(){
        const { classes } = this.props;

        return (
            <div className={classes.root} {...this.props}>
                <div className={classes.footerWidgetContainer}>
                    <div className={classes.languageContainer}>
                        <Link to="/">
                            <img src={Logo} width={90} alt="logo" style={{padding:12}}/>
                        </Link>
                    </div>
                </div>
                <footer className={classes.footerBar}>
                    <div className={classes.containerTextContent}>
                        <Typography color="inherit" variant="body2" className={classes.textContent} style={{flex:1}}>
                            Copyright © {new Date().getFullYear()} Co-Brains S.r.l.
                        </Typography>
                        <Link to={'/termini-e-condizioni'} style={{color:'#fff', textDecoration:'unset'}}>
                            <Typography color="inherit" variant="body2" className={classes.textContent} >Termini e condizioni</Typography>
                        </Link>
                        <Typography color="inherit" variant="body2" className={classes.textContent}>Privacy policy</Typography>
                    </div>
                </footer>
                <div style={{textAlign:'left',padding:'6px 16px', background:'#fff'}}>
                    <a href="https://co-brains.com" target="_blank" rel="noopener noreferrer">
                        <Typography style={{fontSize:10, color:'#111'}}>Developed by Co-Brains S.r.l.</Typography>
                    </a>
                </div>
            </div>
        );
    }
}

BackofficeFooter.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(BackofficeFooter);



