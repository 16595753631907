import React from 'react';
// material ui import
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

// components import
import ImportStepper from './ImportComponentsStepper';
import ImportComponentsUploadFile from './ImportComponentsUploadFile';
import ImportComponentsMatch from './ImportComponentsMatch';
import ImportComponentsCompleted from './ImportComponentsCompleted';
import AlertDialog from '../../../../../../components/ui/dialogs/alertDialog/AlertDialog';
// api imports
import ProductApi from '../../../../../../services/ProductApi';
// i18n translation

// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../../../redux/actions/api-control-action';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles  = theme => ({
    backButton: {
        marginRight: theme.spacing.unit,
    },
    footer:{
        display: 'flex',
        justifyContent: 'flex-end',
    }
});

class ImportComponentsDialog extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            labelMatch: [],
            fileUploaded: null // file uploaded
        }
    }

    // ==================================================
    // RENDER STEP CONTENT
    // ==================================================
    getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:return <ImportComponentsUploadFile handleUpdateImportMatch={(file, labelMatch) => this.updateImportMatch(file, labelMatch)}/>;
            case 1:return <ImportComponentsMatch labelList={this.props.labelList} 
                                                 labelMatch={this.state.labelMatch}/>;
            case 2:return <ImportComponentsCompleted/>;
            default:return 'Uknown stepIndex';
        }
    }

    // ==================================================
    // UPDATE LABEL AFTER DROPZONE IMPORT
    // ==================================================
    updateImportMatch(file, labelMatch){
        this.setState({
            labelMatch: labelMatch,
            fileUploaded: file,
        }, this.props.handleNext())
    }

    // ==================================================
    // MATCH SUBMIT
    // ==================================================
    onMatchSubmit(){
        this.setState({loader:true})
        let serialize = require('form-serialize');
        let form = document.querySelector('#import-match-form');
        let matchObject = serialize(form, { hash: true });

        // add the file on the object match
        matchObject["file"]=this.state.fileUploaded;

        // call import component api
        ProductApi.importComponent(
            this.props.idMachine, // machine id
            this.props.idCategory, // category id
            matchObject
        ).then(
            res => {
                this.setState({loader:false},
                    this.props.handleOnCompleteImport(res.data)
                )
            },
            err => {
                this.setState({ loader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'importComponent', );
            }
        )
    }

    render() {
        const { classes, open, activeStep } = this.props;
        const { loader } = this.state;
        const steps = ['Carica il tuo file', 'Abbina', 'Completato'];

        return (
            <div>
                <Dialog
                    open={open}
                    onClose={this.props.onClose}>
                    <DialogTitle>Importazioni componenti macchina</DialogTitle>
                        <DialogContent style={{paddingTop:0}}>
                            {/* Controller Stepper */}
                            <ImportStepper steps={steps}
                                           activeStep={this.props.activeStep}
                                           handleReset={() => this.props.handleReset()}
                                           handleBack={() => this.props.handleBack()}
                                           handleNext={() => this.props.handleNext()}/>
                            {/* Container */}
                            <div>
                                {/* Step content */}
                                <div style={{marginBottom:20}}>
                                    {this.getStepContent(activeStep)}
                                </div>
                                {/* footer container */}
                                <div className={classes.footer}>
                                    {(activeStep < steps.length - 1)?
                                        <Button
                                            onClick={() => (activeStep!==0)?
                                                this.setState({openBackConfirmDialog:true})
                                                :
                                                this.props.onClose()}
                                            className={classes.backButton}>
                                            {(activeStep === 0)?'Annulla':'Indietro'}
                                        </Button>
                                    :null}
                                    {(activeStep > 0)?
                                        <Button variant="contained"
                                                disabled={loader}
                                                color="primary"
                                                /* eslint-disable no-unused-expressions */
                                                onClick={() => {
                                                    (activeStep !== steps.length - 1)?
                                                        (activeStep === 1)?
                                                            this.onMatchSubmit()
                                                        :null
                                                    :
                                                    this.props.onClose();
                                                }}>
                                            {(!loader) ?
                                                (activeStep === steps.length - 1) ?
                                                    'Completato' : 'Avanti'
                                                :
                                                <CircularProgress color="secondary" size={16} style={{marginLeft:0}}/>
                                            }
                                        </Button>
                                    :null}
                                </div>
                            </div>
                        </DialogContent>
                </Dialog>

                {/* BACK CONFIRM DIALOG */}
                <AlertDialog open={this.state.openBackConfirmDialog}
                             title={"Sei sicuro di voler tornare indietro"}
                             message={"Tornando indietro verrà annullato il file caricato"}
                             onClose={() => this.setState({openBackConfirmDialog:false})}
                             confirmBtnText={"Continua"}
                             onConfirm={() => {this.setState({openBackConfirmDialog:false},this.props.handleBack())}}/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    api_control: state.api_control,
    router: state.router
})
const mapActionsToProps = {
    onUpdateApiControl : updateApiControl
}

export default compose(

    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(ImportComponentsDialog);
