import React, {useEffect, useState, useRef} from 'react';
import Select from "@material-ui/core/Select/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import Icon from "@material-ui/core/Icon";
import itImage from '../../assets/immagini/flag-it.png'
import gloImage from '../../assets/immagini/global-icon.jpg'
import {makeStyles, withStyles} from "@material-ui/core/styles";
import LanguageApi from "./LanguageApi";
import {updateApiControl} from "../../redux/actions/api-control-action";
import {updateMultilanguage} from "../../redux/actions/multilanguage-action";
import {compose} from "redux";
import {connect} from "react-redux";
import {updateUser} from "../../redux/actions/user-action";
import {translate} from "../../translations/i18n";

const useStyles = makeStyles(theme => ({
    textField: {
        minWidth : 112,
    },
    label : {
        marginRight: '8px',
        fontSize : 18,
        color : '#2C3A49',
        fontStyle: 'italic'
    },
    option:{
        display :'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '8px 0px 0px 0px',
        fontSize: 16,
        fontWeight: 600,
    },
    optionLabel:{
        textTransform : 'uppercase',
        marginTop : 6,
        margin : '0px 0px 0px 8px',
        fontSize: 16,
        fontWeight: 600,
    },
    title:{
        color : '#000',
        fontWeight : 'bold',
        fontSize: 18,
        marginRight: 18,
        marginTop : 6,
        opacity : 0.8,
},
    filled : {
    padding: '8px 30px 10px 10px !important',
    },
    underline : {
        '&::before':{
            border : '0px !important'
        },
        '&::after':{
            border : '0px !important'
        }
    },
    selectRoot : {
        background: '#E5E5E5',
        borderRadius: 16,
        color : '#000',
        overflow: 'hidden',
        '&:focused':{
            background: '#E5E5E5',
        }
    },
    icon : {
        top: 'calc(50% - 12px)',
        right: 7,
        fontSize : 22,
        position: 'absolute',
        pointerEvents: 'none',
    },
    paper : {
        maxWidth : '112px !important',
        minWidth : '112px !important',
        background: '#E9EAEB',
},
list : {
        paddingTop : 0
},
    selectMenu:{
        height : 24,
        display : 'flex',
        alignItems: 'center'
    },
    valueContainer:{
        display : 'flex',
        justifyContent: 'start',
        padding : '8px 0px',
        alignItems : 'center',
        '&:nth-child(n + 2)':{
            marginLeft : '-12px'
        }
    },
    input:{
        padding :'10px 8px',
    },
    root:{
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'flex-start',
        padding : 16,
        minWidth : 328,
        [theme.breakpoints.down('xs')]:{
            padding : '16px 0px !important',
            minWidth : 'unset',
        }
    }
}));

const defaultValues = [
    {
        code : 'all',
        src : gloImage
    },
];

function useOutsideAlerter(ref, setOpen) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {

            if (ref.current && !ref.current.contains(event.target)) {
                setOpen(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}


function LanguageSelect(props) {
    const classes = useStyles(props);
    const { multiple = true, style = {}, multilanguage} = props;
    const { value = [] , onChange = null, label = translate('bulk_import.language', {}, 'Seleziona lingua'), } = props;
    const wrapperRef = useRef(null);
    const [open , setOpen] = useState(false);
    const [localValues , setValues] = useState([] );
    const [localValue , setValue] = useState([] );
    useOutsideAlerter(wrapperRef, setOpen);

    useEffect(() => {
        setValues(multilanguage.languageValues);
        setValue(onChange ? value : multilanguage.selectedLanguage)
    }, []);



    function handleChangeMultiple(event){

        let { value } = event.target;


        value = value === 'all' ? value : Number(value);

        let tmpValue =  localValue;

        let tmpI = tmpValue.findIndex(item => item == value)


        if (value == 'all') {
            if (localValues.filter(lng => tmpValue.includes(lng.id) === true).length === localValues.length)
                tmpValue = [];
            else
                localValues.map(item => {
                    let tmpIdx = tmpValue.findIndex(tmpV => tmpV === item.id);

                    if (tmpIdx == -1)
                        tmpValue.push(item.id);
                });
        }

        if (tmpI == -1 && value !== 'all')
            tmpValue.push(value);
        else if (value != 'all')
            tmpValue.splice(tmpI , 1);

        setValue(tmpValue);

        props.onUpdateMultilanguage({...multilanguage,  selectedLanguage : tmpValue});


        if (!multiple){
            setOpen(false)
        }else{
            setOpen(true)
        }
    };

    useEffect(() => {
        if (value !== null)
        setValue(value)
    }, [value]);


        return (
            (localValues) ?
            <div style={{ ...style}} className={classes.root}>
                <Typography className={classes.title}>
                    {label}
                </Typography>
                <FormControl className={classes.textField}
                             variant="filled"
                >
                <Select
                    value={localValue}
                    open={open}
                    ref={wrapperRef}
                    SelectDisplayProps={{
                        onClick: () => setOpen(true)
                    }}
                    variant="standard"
                    disableScrollLock={ true }
                    onChange={(e) => {
                        (onChange) ?
                            onChange(e, () => setOpen(false))
                            :
                            handleChangeMultiple(e);
                    }}
                    classes={{
                        root : classes.selectRoot,
                        filled : classes.filled,
                        selectMenu : classes.selectMenu
                    }}
                    displayEmpty
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        classes:{
                            paper : classes.paper,
                            list : classes.list,
                    }
                    }}
                    inputProps={{
                        disableScrollLock : true
                    }}
                    renderValue={(selectValue) => {
                        return (
                        (localValues !== undefined && localValues.length > 0 ) &&
                        (multiple === false) ?
                            <div className={classes.valueContainer}>
                                {selectValue &&
                                <>
                                <img src={localValues[localValues.findIndex(value => value.id == selectValue)].src} style={{width : 22,height : 22,  borderRadius : 11, margin: '8px 0px 8px 8px'}} />
                                <Typography className={classes.optionLabel} style={{textTransform : 'uppercase'}}>
                                    {localValues[localValues.findIndex(value => value.id == selectValue)].code}
                                </Typography>
                                </>
                                    }
                            </div>
                            :
                            selectValue && selectValue.length > 0 && localValue && localValue.length > 0 && localValues.length > 0 && selectValue.map(item =>
                            (item !== 'all') &&
                            <div className={classes.valueContainer}>
                                <img src={localValues[localValues.findIndex(value => value.id == item)].src} style={{width : 22,height : 22,  borderRadius : 11, margin: '8px 0px 8px 8px'}} />
                                {/*<Typography className={classes.optionLabel} style={{textTransform : 'uppercase'}}>
                                    {localValues[localValues.findIndex(value => value.id == item)].code}
                                </Typography>*/}
                            </div>
                            )
                        )}}
                    IconComponent={() => (
                        <Icon className={classes.icon} style={{color: '#000'}}>expand_more</Icon>
                    )}
                    input={<Input classes={{underline : classes.underline, input : classes.input}}/>}
                >
                    {localValues && localValues.length > 0 && multiple &&
                        <MenuItem
                            key={`key-all`}
                            className={classes.option}
                            value={`all`}
                        >
                            <>
                                <Checkbox
                                    checked={localValues.filter(lng => localValue.includes(lng.id) === true).length === localValues.length}
                                    color="primary"
                                    style={{padding: '0px 4px'}}
                                />
                                <img src={gloImage} style={{margin: '8px 0px 8px 8px'}} width={22}/>
                                <Typography className={classes.optionLabel}>
                                    all
                                </Typography>
                            </>
                        </MenuItem>
                    }
                    {localValues && localValues.length > 0 && localValues.map(language =>
                            <MenuItem
                                key={`key-${language.code}`}
                                className={classes.option}
                                value={`${language.id}`}>
                                    <>
                                        {
                                            multiple &&
                                            <Checkbox
                                            checked={localValue.includes(language.id)}
                                            color="primary"
                                            style={{padding: '0px 4px'}}/>
                                        }
                                        <img src={language.src}
                                             style={{width : 22,height : 22,  borderRadius : 11,margin: '0px 0px 0px 8px'}}
                                        />
                                        <Typography className={classes.optionLabel}>
                                            {language.code}
                                        </Typography>
                                    </>
                            </MenuItem>
                    )}
                </Select>
            </FormControl>
            </div>:<div/>
        )
}



const mapStateToProps = state => ({
    user: state.user,
    multilanguage: state.multilanguage,
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl,
    onUpdateMultilanguage: updateMultilanguage,
    onUpadateteUser: updateUser(),
};

export default compose(

    connect(mapStateToProps, mapActionsToProps)
)(LanguageSelect);

