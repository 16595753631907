import React from "react";

export default class GdocViewer extends React.Component {
    constructor(props) {
        super();
        this.bindActions();
    }
    bindActions() {
        this.updateIframeSrc = this.updateIframeSrc.bind(this);
        this.iframeLoaded = this.iframeLoaded.bind(this);
    }
    iframeLoaded() {
        clearInterval(this.iframeTimeoutId);
        this.props.onLoad()
    }
    getIframeLink() {
        return  `https://docs.google.com/viewerng/viewer?url=${this.props.url}&embedded=true`; // no need for this if thats not dynamic
    }
    updateIframeSrc() {
        if(this.refs)
            if(this.refs.iframe){
                this.refs.iframe.src = this.getIframeLink();
            }
    }

    componentDidMount() {
        this.iframeTimeoutId = setInterval(
            this.updateIframeSrc, 1000 * 3
        );
    }
    render() {
        return (
            <iframe title={'idocs'}
                    onLoad={() => { this.iframeLoaded() }}
                    onError={this.updateIframeSrc}
                    src={this.getIframeLink()}
                    frameborder="0"
                    loading={'eager'}
                    height="100%"
                    width="100%"
                    isEnabled
                    allowtransparency={true}
                    allowFullScreen={true}
                    ref="iframe"
                    style={{
                        border:'none',
                        overflow:'hidden',
                        height:'100%',
                        width:'100%',
                        backgroundColor : 'transparent'
                    }}
            />
        );
    }
}
