import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// react helmet
import Helmet from 'react-helmet';

const styles  = () => ({
    root: {
        position:'relative',
        backdropFilter: 'blur(4px) ',
        webkitBackdropFilter: 'blur(4px)',
        border:'1px solid rgba(42, 58, 75, .1)',
        padding:12,
        overflow: 'hidden',
        color: '#fff',
        backgroundSize: 'cover!important',
        marginTop:-1,
    }
});

// Define the opacity of background
class BlurDiv extends Component {

    constructor(props){
        super(props);
        this.state = {}
    }

    prepareOpacity(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        result = `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}, ${(this.props.opacity)?this.props.opacity:0.88})`;
        return result;
    }


    componentDidMount(){}

    render() {
        const { classes } = this.props;
        const { style, className } = this.props;
        const { overflow } = this.props;

        return (
            <div className={[classes.root,className].join(' ')}
                 style={{
                    background: (this.props.background)?this.prepareOpacity(this.props.background):this.prepareOpacity('#000000'),
                    borderRadius:(this.props.borderRadius)?this.props.borderRadius:null,
                    backgroundImage:(this.props.backgroundImage)?`url("${this.props.backgroundImage}")`:null,
                    ...style
                }}>
                <Helmet>
                    <style type="text/css">{`
                html,body{
                    ${(!overflow)?'overflow-y:hidden!important':''}
                }
                `}</style>
                </Helmet>
                {this.props.children}
            </div>
        )
    }
}


BlurDiv.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    style: PropTypes.object,
    className:PropTypes.object,
    background: PropTypes.string,
    borderRadius: PropTypes.number,
    overflow: PropTypes.bool
};

export default withStyles(styles, {withTheme: true})(BlurDiv);