import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// material-ui imports
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog from "@material-ui/core/Dialog";
import Icon from "@material-ui/core/Icon";
// logo import
// auth api imports
import AuthApi from '../../../services/AuthApi';
// assets import
// utils import
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux'
import { updateUser } from '../../../redux/actions/user-action';
import { updateApiControl } from '../../../redux/actions/api-control-action';
import LanguageApi from "../../../components/language-select/LanguageApi";
import {updateLicense} from "../../../redux/actions/license-action";
import {updateMultilanguage} from "../../../redux/actions/multilanguage-action";

const styles  = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding:20,
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    textField: {
        width: '100%',
        marginTop: 24,
        '& input':{
            background:'transparent',
        },
    },
    buttonSign: {
        marginTop: 32,
        marginBottom:20,
        minWidth: 200,
        color: '#fff',
        textDecoration: 'none',
    },
    message:{
        textAlign: 'center',
    },
    loader: {
        color: '#fff',
        marginLeft:12,
    },
    icon:{
        fontSize: 50,
        color: theme.palette.primary.main,
        opacity: 0.6,
    }
});

class SignForm extends Component {

    constructor(props){
        super(props);
        this.state = {
            email : '',
            password : '',
            showPassword: false,
            stayConnected: true,
            loader: false,
        }
    }

    handleEmailChange = (event) => {
        this.setState({
            error: false,
            email: event.target.value
        });
    }
    handlePasswordChange = (event) => {
        this.setState({
            error: false,
            password: event.target.value
        });
    }

    handleClickShowPassword = () => {this.setState(state => ({ showPassword: !state.showPassword }))};

    handleClickStayConnected = (e) => {
        this.setState({
            stayConnected: e.target.checked
        });
    };


    handleSubmitSign(event){
        event.preventDefault();
        this.setState({ loader: true });

        // Api call for auth user login
        AuthApi.getAuth(this.state.email , this.state.password)
            .then(
                res => {
                    let user = res.data;
                    // set to true the logged state on store
                    user.is_logged = true;
                    user.stay_connected = this.state.stayConnected;
                    this.props.onUpdateUser(user)



                    LanguageApi.getFlags().then(
                        res => {
                            let multilanguage = {
                                defaultlang : res.data.default_lang,
                                languageValues : res.data.content.items,
                                selectedLanguage : [res.data.default_lang]
                            };

                            this.props.onUpdateMultilanguage(multilanguage)

                        },
                        err => {
                            this.setState({loader: false})
                        }
                    );

                    if (user.company && user.company.licenses.length > 0)
                        this.props.onUpdateLicense(user.company.licenses[0]);

                    this.setState({
                        loader: false,
                        onRedirect: true,
                    });
                },
                err => {
                    this.setState({
                        loader: false,
                        onRedirect: false,
                    });
                    // ====== API CONTROL MESSAGE ====== //
                    this.props.onUpdateApiControl(err, 'auth',  'Username e/o Password errati');
                }
            );
    }

    render() {
        const { classes } = this.props;
        const { loader } = this.state;

        if(this.props.user.is_logged && this.state.onRedirect){
            return <Redirect to={this.props.redirectOnAuth}/>
        }

        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.actionClose}>
                <div className={classes.root}>
                    <Icon className={classes.icon}>lock</Icon>
                    <Typography variant="title" style={{margin:'20px 0'}}>{this.props.title}</Typography>
                    <Typography variant="body" className={classes.message}>{this.props.message}</Typography>
                    <form onSubmit={this.handleSubmitSign.bind(this)}
                          ref="form"
                          className={classes.formContainer}
                          autoComplete="off">
                        <TextField
                            id="username"
                            InputLabelProps={{ shrink: true }}
                            label="Username"
                            className={classes.textField}
                            value={this.state.email.toLowerCase()}
                            onChange={(e) => {
                                this.handleEmailChange(e)
                            }}/>
                        <Input
                            id="password"
                            InputLabelProps={{ shrink: true }}
                            type={this.state.showPassword ? 'text' : 'password'}
                            label="Password"
                            placeholder="Password"
                            className={classes.textField}
                            value={this.state.password}
                            onChange={(e) => {
                                this.handlePasswordChange(e)
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Toggle password visibility"
                                        onClick={this.handleClickShowPassword}>
                                        {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <Button disabled={loader}
                                type="submit"
                                className={classes.buttonSign}
                                variant="contained"
                                color="primary"
                                size="small">
                            {(!loader) ? <span>Accedi</span> : null}
                            {(loader) ? <CircularProgress className={classes.loader} size={16} style={{marginLeft:0}}/> : null}
                        </Button>
                    </form>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={this.state.stayConnected}
                                onChange={this.handleClickStayConnected.bind(this)}/>
                        }
                        label="Rimani connesso"
                    />
                    <Typography color="primary">Password Dimenticata?</Typography>
                </div>
            </Dialog>
        )
    }
}


SignForm.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    api_control: state.api_control,
    license: state.license
});

const mapActionsToProps = {
    onUpdateUser : updateUser,
    onUpdateLicense: updateLicense,
    onUpdateApiControl : updateApiControl,
    onUpdateMultilanguage: updateMultilanguage
}

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(SignForm);
