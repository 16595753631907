import API from './API';

class ContactApi {

    static url = 'contact';


    // ========================================= //
    // CONTACT
    // ========================================= //

    static getContact(){
        const api = new API(this.url);
        return api.get();
    }

    static getContactById(id) {
        const api = new API(this.url);
        return api.getById(id);
    }

    static createContact(data) {
        const api = new API(this.url);
        return api.post(data);
    }

    static updateContact(id, data) {
        const api = new API(`${this.url}/${id}`);
        return api.update(data);
    }

    static deleteContact(id) {
        const api = new API(this.url);
        return api.deleteById(id);
    }
}

export default ContactApi
