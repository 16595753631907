import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
// material-ui import
import { withStyles } from '@material-ui/core/styles';
import Typography  from '@material-ui/core/Typography';
import {translate} from "../../../translations/i18n";

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width:'100%',
        minHeight:'90vh',
    },
    container404: {
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center'
    },
    text: {
        textAlign: 'center',
        color: '#111111',

    },
    title:{
        fontSize:120,
        fontWeight:700,
        letterSpacing:'4px'
    },
    subtitle:{
        fontSize:40,
    },
    redirect:{
        marginTop:12,
        fontSize:18,
        color:theme.palette.active.main,
        cursor:'pointer'
    }
});

class PageNotFound extends Component {

    render(){
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.container404}>
                    <Typography className={classes.title}>404</Typography>
                </div>
                <Typography className={classes.subtitle} gutterBottom>{translate( `404.tech`, {}, "Pagina non trovata")}</Typography>
                <Link to="/" className={classes.redirect} >{translate( `404.back`, {}, "Torna alla dashboard")}</Link>
            </div>
        );
    }
}

PageNotFound.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(PageNotFound);




