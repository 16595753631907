import React, {Component} from 'react';
import PropTypes from 'prop-types';
// utils import
//material-ui imports
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
// right click
// TODO: Replace with material menu -> then remove react-contexify on package.json
import { Menu, Item, MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
// file utils import
import FileUtils from '../../../../../utils/FileUtils';
import {updateMultilanguage} from "../../../../../redux/actions/multilanguage-action";
import {compose} from "redux";
import {connect} from "react-redux";
import {translate} from "../../../../../translations/i18n";

const styles = theme => ({
    file: {
        position: 'relative',
        textAlign: 'center',
        margin: 20,
        width: 168,
        height: 'auto',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            width: 100,
            height: 'auto',
        },
    },
    fileIcon: {

    },
    fileNameGrid: {
        paddingTop: 10,
        color: theme.palette.gray.dark,
        fontWeight: 400,
        wordWrap: 'break-word',
    },
    fileNameCell: {
        color: theme.palette.gray.dark,
        fontWeight: 500,
        fontSize: 15,
        [theme.breakpoints.down('xs')]:{
            fontSize: 12,
            textAlign: 'center'
        }
    },
    optionLabel:{
        textTransform : 'uppercase',
        marginTop : 6,
        margin : '0px 0px 0px 8px',
        color :'#000',
        fontSize: 16,
        [theme.breakpoints.down('xs')]:{
            fontSize: 12
        },
        fontWeight: 600,
    },
    tableCell: {
        paddingTop: 20,
        paddingBottom: 20,
    },
    checkboxGrid: {
        position: 'absolute',
        top: -5,
        left: 5,
    },
    fileTableRow:{
        cursor: 'pointer',
        transition: 'transform .2s',
        '&:hover':{
            background: '#f5f6f7',
        }
    },

    // =================================
    // RIGHT CLICK MENU
    // =================================
    contextMenu: {
        background: theme.palette.primary.main,
        color: '#fff',
        boxShadow:'0px 1px 5px rgba(0,0,0,0.3)',
        padding:'8px 12px',
        borderRadius:4,
        transition: 'transform .2s',
        zIndex:1100
    },
    contextMenuItem:{
        display: 'flex',
        alignItems:'center',
        padding:2,
        background: theme.palette.primary.main,
        borderRadius:4,
        '&:hover':{
            background:  theme.palette.primary.dark+ '!important',
        }
    },
    contextMenuText:{
        color: '#fff',
        fontSize:14,
        marginLeft:8,
    },
    contextMenuIcon:{
        color: '#fff',
        fontSize:18,
    },
    contextMenuDivider:{
        background:'rgba(255,255,255,0.4)',
        marginTop:2,
        marginBottom:2
    },
    fileUrlCell : {
        color: theme.palette.gray.dark,
        fontWeight: 500,
        fontSize: 15,
        [theme.breakpoints.down('sm')]:{
            width : '20vw',
            overflow : 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace : 'no-wrap'
        },
        [theme.breakpoints.down('xs')]:{
            fontSize: 12,
            textAlign: 'center'
        },
    }
});

class File extends Component {

    constructor(props){
        super(props);
        this.state = {
            hover: false,
            finalLabel : {title : ''}

        };
    }

    handlerMouseEnter = () => {
        this.setState({ hover: true })
    };

    handlerMouseLeave = () => {
        this.setState({ hover: false })
    };

    handleOpenLink(url){

    if (!url.match(/^https?:\/\//i)) {
    url = 'http://' + url;

    }
        window.open(url);
}

componentDidMount() {
    if (this.props.file)
        this.getLabels();
}

    componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.multilanguage !== this.props.multilanguage) {

        if (this.props.file)
            this.getLabels();
    }
}

    getLabels = () => {
        const {labels = [], name = '' , } = this.props.file;

        const {defaultlang, multilanguage} = this.props;




        let tmpLabels = [...labels];

        if (labels && labels.length > 0 && multilanguage && multilanguage.selectedLanguage && multilanguage.selectedLanguage.length > 0){
            let mainLanguage = multilanguage.selectedLanguage[0];

            tmpLabels = tmpLabels.filter(item =>
                item.flag.id == mainLanguage
            );


            if (tmpLabels.length === 0 && defaultlang)
                tmpLabels = labels.filter(item =>
                    item.flag.id == defaultlang
                );

            if (tmpLabels.length === 0 && !defaultlang)
                tmpLabels = {title : name , subtitle : ''}

        }else if (!multilanguage.selectedLanguage ||  multilanguage.selectedLanguage.length === 0 && defaultlang){
            tmpLabels = tmpLabels.filter(item =>
                item.flag.id == defaultlang
            );
        }

        if (labels && labels.length === 1)
            tmpLabels = {title : labels[0].title, subtitle : labels[0].description};

        if (tmpLabels.length === 0 && name)
            tmpLabels = {title : name , subtitle : ''};


        if (tmpLabels && tmpLabels.length >= 1){
            tmpLabels = {title : tmpLabels[0].title, subtitle : tmpLabels[0].description }
        }

        this.setState({finalLabel : tmpLabels})

        return tmpLabels;
    };

    render(){
        const { hover, finalLabel } = this.state;
        const { classes, file, mode, onClickCheckbox, fileChecked, displayAllCheck, onClickFile, readonly, languageValues,multilanguage } = this.props;
        const dimension = FileUtils.bytesToSize(this.props.file.size);

        const onDownloadFile = () => {
            return this.props.onDownloadFile();
        };

        const ContextMenu = () => (<Menu id={'menu_id_' + file.id} className={classes.contextMenu}>
                                    {file.type === 'link' ?
                                        <Item onClick={onDownloadFile} className={classes.contextMenuItem}>
                                            <Icon className={classes.contextMenuIcon} style={{transform: 'rotate(90deg)'}}>forward</Icon>
                                            <Typography className={classes.contextMenuText} color="secondary">Download</Typography>
                                        </Item>
                                        :
                                    <Item onClick={() => this.handleOpenLink(file.link)} className={classes.contextMenuItem}>
                                        <Icon className={classes.contextMenuIcon}>open_in_new</Icon>
                                        <Typography className={classes.contextMenuText} color="secondary">{translate('general.open', {}, 'Apri')}</Typography>
                                    </Item>}
                                    {/*<Separator className={classes.contextMenuDivider}/>
                                    <Item disabled>
                                        <div className={classes.contextMenuItem}>
                                            <Icon className={classes.contextMenuIcon}>delete</Icon>
                                            <Typography className={classes.contextMenuText} color="secondary">Elimina</Typography>
                                        </div>
                                    </Item>*/}
                                </Menu>);

        const GridItem = () => (<div className={classes.file} onMouseEnter={this.handlerMouseEnter} onMouseLeave={this.handlerMouseLeave}>
                                    <MenuProvider id={"menu_id_" + file.id}>
                                        <div onClick={file.type === 'link' ? () => this.handleOpenLink(file.link) : onClickFile}>
                                            {FileUtils.getIcon(file.name, '8em')}
                                            <Typography onClick={file.type === 'link' ? () => null : onClickFile} className={classes.fileNameGrid}>{ finalLabel.title }</Typography>
                                        </div>
                                    </MenuProvider>
                                    <ContextMenu/>
                                    {(!readonly) ?
                                        <Checkbox
                                            checked={fileChecked}
                                            onChange={onClickCheckbox}
                                            color='primary'
                                            className={classes.checkboxGrid}
                                            style={{display:'inline'}}
                                        /> : null
                                    }
                                </div>);

        const RowItem  = () => (<TableRow key={file.id}
                                  className={classes.fileTableRow}
                                  onMouseEnter={this.handlerMouseEnter}
                                  onMouseLeave={this.handlerMouseLeave}>

                                    <TableCell padding="none" style={{width:'5%'}} >
                                        {(!readonly)
                                            ? <Checkbox
                                                checked={fileChecked}
                                                onChange={onClickCheckbox}
                                                color='primary'

                                            /> : null}
                                    </TableCell>
                                    <TableCell padding="none" className={classes.tableCell}  style={{width:'5%'}} onClick={file.type === 'link' ? () => this.handleOpenLink(file.link) : onClickFile}>
                                        {FileUtils.getIcon(file.name, file.type === 'link' ? () => this.handleOpenLink(file.link)  : onClickFile) }
                                    </TableCell>
                                    <TableCell padding="none" style={{width:'30%', textAlign : 'center'}} onClick={file.type === 'link' ? () => this.handleOpenLink(file.link) :onClickFile}>
                                        <Typography className={classes.fileNameCell}>{ finalLabel.title } </Typography>
                                    </TableCell>
                                    <TableCell padding="none" style={{width:'30%'}} onClick={file.type === 'link' ? () => this.handleOpenLink(file.link) :onClickFile}>
                                        {file.flags &&  file.flags.length > 0  ?
                                            <div style={{display :'flex'}}>

                                            {file.flags.map( (item, index) =>
                                            <div style={{display: 'flex', justifyContent : 'flex-start', alignItems : 'center'}}>
                                                <img src={(file.type === 'link') ?
                                                (item.id) ?
                                                languageValues[languageValues.findIndex(lng => lng.id == item.id)].src
                                                    :
                                                    languageValues[languageValues.findIndex(lng => lng.id == item)].src
                                                :
                                                item.src} style={{height : 18,margin: '0px 0px 0px 8px'}}
                                                 width={(window.screen.width > 600) ? 28 : 16}
                                            />
                                                <Typography className={classes.optionLabel}>{(file.type === 'link') ?
                                                    (item.id) ?
                                                    languageValues[languageValues.findIndex(lng => lng.id == item.id)].code
                                                        :
                                                        languageValues[languageValues.findIndex(lng => lng.id == item)].code
                                                    : item.code}
                                                </Typography>
                                                {index + 1 !== file.flags.length &&
                                                    <Typography style={{fontSize : (window.screen.width > 600) ? 15 : 12, color : '#000', margin : '0px 4px'}}>
                                                    -
                                                    </Typography>
                                                }
                                            </div>
                                            )}
                                            </div>
                                                :
                                                <Typography className={classes.fileNameCell}/>
                                            }
                                    </TableCell>
                                  {(window.screen.width > 600 ) &&
                                    <TableCell padding="none" style={{width:'20%'}} onClick={file.type === 'link' ? () => this.handleOpenLink(file.link) :onClickFile}>
                                        {file.type === 'link' ?
                                            <Typography className={classes.fileUrlCell}>{ file.link }</Typography>
                                            :
                                            <Typography className={classes.fileNameCell}>{ dimension }</Typography>}
                                    </TableCell>
                                    }
                                    <TableCell padding="none" style={{width:'20%', textAlign:'right', display:'none'}}>
                                        <Tooltip title="Download">
                                            <IconButton style={{marginRight:12}} onClick={() => this.props.onDownloadFile()}>
                                                <Icon style={{transform: 'rotate(90deg)'}}>forward</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>);


        return (
            (mode === 0 ) ? <GridItem/>: <RowItem/>
        );
    }
}

File.propTypes = {
    classes: PropTypes.object.isRequired,
    file: PropTypes.object.isRequired,
    onClickCheckbox: PropTypes.func,
    onClickFile: PropTypes.func,
    onDownloadFile: PropTypes.func,
    onDownloadDelete: PropTypes.func,
    fileChecked: PropTypes.bool,
    mode: PropTypes.number,
    displayAllCheck: PropTypes.bool,
    readonly: PropTypes.bool,
};


const mapStateToProps = state => ({
    multilanguage: state.multilanguage,
});


const mapActionsToProps = {
    onUpdateMultilanguage: updateMultilanguage,
};

export default compose(
    withStyles(styles),
connect(mapStateToProps, mapActionsToProps)
)(File);
