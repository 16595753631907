import React, {useEffect, useState} from 'react';
// material ui import
import {makeStyles, withStyles} from '@material-ui/core/styles';
import FullScreenLoader from "../../components/ui/loaders/fullScreen/FullScreenLoader";

export default function Logout(props) {


    return (
        <div>
            <FullScreenLoader/>
        </div>
    );

}
