import API from './API';

class SupportApi {

    static url = 'support';

    static createSupportTicket(supportTicket){
        const api = new API(this.url + '/ticket/create');
        return api.post(supportTicket);
    }

    static registrationForm(registrationform){
        const api = new API(this.url + '/registration-form');
        return api.postUnsafe(registrationform);
    }
}

export default SupportApi
