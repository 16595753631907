import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Dialog, Button, DialogContent, DialogTitle,DialogActions, Typography} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        marginBottom: 20,
    },
    content: {
        padding: 12,
    },
    input: {
        marginTop: 20,
        width: '100%',
    },
    btn: {
        minWidth: 80,
    },
    header: {
        padding: 20,
    },
    draggableHeader: {
        cursor: 'move',
    },
    dialog: {
        '& .MuiDialog-paper': {
            minWidth: 375,
        },
    },
    title: {
        textTransform: 'uppercase',
    },
    subtitle: {
        textTransform: 'uppercase',
        fontSize: 11,
        marginTop: 8,
    },
    label: {
        color: '#7a7e80',
    },
}));

export default function DetailDialog(props) {
    const { onClose = () => null, onConfirm = () => null, open, subtitle, title, cancelText, confirmText, children, data, fields} = props;
    const classes = useStyles(props);

    return (
        <Dialog
            draggable={false}
            maxWidth="md"
            open={open}
            title={title}
            subtitle={subtitle}
            onClose={() => onClose()}
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby={title}
            className={classes.dialog}

        >
            <DialogTitle
                id="dialog-title"
            >
                <Typography className={classes.title}>{title}</Typography>
                <Typography className={classes.subtitle}>{subtitle}</Typography>
            </DialogTitle>

                <DialogContent className={classes.content} dividers>
                    {fields.map(item =>
                        item.key && (
                            <div style={{margin : '16px'}}>
                                <Typography variant="body2" className={classes.label} >
                                    {item.label}
                                </Typography>
                                <>{data[item.key] && <Typography>{data[item.key]}</Typography>}</>
                            </div>
                    ))
                    }
                </DialogContent>
                <DialogActions>

                         <Button className={classes.btn} onClick={() => onClose()}>
                            {cancelText}
                        </Button>


                        {onConfirm() && <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={() => onConfirm()}
                            className={classes.btn}
                        >
                            {confirmText}
                        </Button>}

                </DialogActions>
        </Dialog>
    )
}
