import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// components import

const styles  = () => ({
    root:{
        textAlign:'justify',
        padding:'40px 60px'
    }
});

class TermsAndConditions extends Component {

    componentDidMount(){

    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Typography variant="title" style={{marginBottom:40}}>NORME SULLA PROPRIETÀ INTELLETTUALE</Typography>
                <Typography variant="body2">
                    Ubilod ("<b>Ubilod</b>") rispetta i diritti di proprietà intellettuale delle altre persone e si attende che i suoi utenti si comportino nello stesso modo. In base all’articolo 13 del 2016/0280(COD), il cui testo può essere consultato sul sito web dell'Ufficio per il copyright statunitense, all'indirizzo <a href="https://eur-lex.europa.eu/legal-content/IT/TXT/?uri=CELEX%3A52016PC0593" rel="noopener noreferrer" target="_blank">https://eur-lex.europa.eu/legal-content/IT/TXT/?uri=CELEX%3A52016PC0593</a>
                    Ubilod risponderà rapidamente ai reclami relativi a violazioni del copyright commesse utilizzando il servizio Ubilod e/o il sito web di Ubilod (il "Sito") quando tali reclami siano comunicati al Responsabile designato di Ubilod per il copyright indicato nell'avviso esemplificativo riportato di seguito.

                    <br/><br/>
                    Se l'utente è titolare del copyright, autorizzato ad agire in nome del titolare o autorizzato ad agire in base a qualunque diritto esclusivo basato su copyright, può segnalare la presunta violazione del copyright presente sul sito o perpetrata attraverso il sito completando il seguente Avviso di presunta violazione della direttiva europea sul copyright e inviandolo al Responsabile del copyright designato da Ubilod. Una volta ricevuto l'Avviso strutturato come descritto in seguito, Ubilod adotterà le misure che ritiene appropriate, a sua unica discrezione, inclusa la rimozione del contenuto problematico dal sito.
                    <br/><br/>
                    Avviso di presunta violazione ("Avviso")

                    <ol>
                        <li>Identificare l'opera protetta dal copyright che si ritiene sia stato violato o, nel caso in cui l'Avviso si riferisca a varie opere protette da copyright, un elenco rappresentativo delle opere protette dal copyright che si ritiene sia stato violato.</li>
                        <li>Identificare il materiale o il link a cui si riferisce la segnalazione di violazione (o l'oggetto dell'attività di violazione) e al quale si richiede di disattivare l'accesso, includendo almeno, dove applicabile, l'URL del link mostrato sul sito o la posizione esatta in cui è possibile trovare tale materiale.</li>
                        <li>Indicare la propria impresa di appartenenza (se applicabile), l'indirizzo postale, il numero di telefono e, ove disponibile, l'indirizzo e-mail.</li>
                        <li>Includere entrambe le seguenti dichiarazioni nel corpo dell'Avviso:
                            <ul>
                                <li>"Con la presente dichiaro di ritenere in buona fede che l'uso contestato dei materiali protetti da copyright non è stato autorizzato dal titolare del copyright, dal suo rappresentante o dalla legge (ad es. un uso consentito)."</li>
                                <li>"Con la presente dichiaro che le informazioni contenute nel presente Avviso sono precise e, consapevole delle mie responsabilità legali, che sono il titolare o sono autorizzato ad agire per conto del titolare del copyright o di diritti esclusivi sul copyright presumibilmente violato."</li>
                            </ul>
                        </li>
                        <li>Indicare il proprio nome legale completo e fornire una firma elettronica o fisica.</li>
                    </ol>
                    <br/>
                    Inviare l'Avviso, dopo aver completato tutti gli elementi, al Responsabile del copyright designato da Ubilod.

                    <br/>
                    Responsabile del copyright<br/><br/>
                    <b>Co-Brains S.r.l.</b><br/>
                    Via Diaz 54<br/>
                    22100 Como - Italia<br/>
                    <br/><br/>
                    Sarà nostra premura riscontrare l’avviso nel più breve tempo possibile, adoperandoci, se del caso, per risolvere la problematica celermente ed evitando conflitti!

                </Typography>
            </div>
        )
    }
}


TermsAndConditions.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(TermsAndConditions);