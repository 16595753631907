class Utils {

    /**
     * @description Returned the first character of the name and surname (Mario Rossi = MR)
     * @description If is available only the name return the first character
     * @param {name} String name
     * @param {surname} String surname
     * @return {initals} String(2) name and surname initials
     */
    static getUsernameShort(name, surname) {
        let shortName, initials;
        if(surname !=='' && surname !== null && surname !== undefined){
            shortName = name + " " + surname;
            initials = shortName.match(/\b\w/g) || [];
            initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        }else{
            //
            shortName = name;
            initials = shortName.charAt(0).toUpperCase();
        }
        return initials;
    }

    /**
     * @description return the string of the browser active
     * @return {string} browser user agent data string
     */
    static getBrowserUserAgent(){
        return navigator.userAgent;
    }

    /**
     * @description return the string of the device
     * @return {string} device string
     */
    static getDeviceType(){
        if(navigator.userAgent.match(/Android/i)){return 'Device Android'}
        if(navigator.userAgent.match(/webOS/i)){return 'Web OS mobile'}
        if(navigator.userAgent.match(/iPhone/i)){return 'iPhone'}
        if(navigator.userAgent.match(/iPad/i)){return 'iPad'}
        if(navigator.userAgent.match(/iPod/i)){return 'iPod'}
        if(navigator.userAgent.match(/BlackBerry/i)){return 'Blackberry'}
        if(navigator.userAgent.match(/Windows Phone/i)){return 'Windows Phone'}
        return 'Desktop'
    }
}

export default Utils