import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../../redux/actions/api-control-action';
import { updateLoaders } from '../../../../../redux/actions/loaders-action';
import { updateRouter } from "../../../../../redux/actions/router-action";
// Material-ui
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// Api
import ProductApi from '../../../../../services/ProductApi';
import ModelApi from '../../../../../services/ModelApi';
// containers import
import BackofficeViewContainer from '../../../layout/container/AdminContainer';
import "video-react/dist/video-react.css"
// Components
import AlertDialog from '../../../../../components/ui/dialogs/alertDialog/AlertDialog';
import FunctionsToolbar from '../../../../../components/ui/toolbar/FunctionsToolbar';
import SnackbarLoader from '../../../../../components/ui/loaders/snackbar/SnackbarLoader';
import CircularProgress from '@material-ui/core/CircularProgress';
// fileutils import
import FileUtils from '../../../../../utils/FileUtils';
//import logger from 'logging-library';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
// image zoom
import ReactImageMagnify from 'react-image-magnify';
// doc viewer
import GdocViewer from '../../../../../components/ui/docViewer/GdocViewer';
// audio player
import ReactAudioPlayer from 'react-audio-player';
// video player
import { Player } from 'video-react';
import {translate} from "../../../../../translations/i18n";
import {DownloadCloud } from 'react-feather'
import Lightbox from 'react-image-lightbox';


const styles  = () => ({
    noSupportContainer:{
        textAlign:'center'
    }
});

class FileManager extends Component {

    constructor(props){

        super(props);
        this.state = {
            loader: false,
            product: null,
            category: null,
            permissions: null,
            file: null,
            fileUrl: '',
            fileExtension: '',
            dialogLoader: false,
            docFileLoader: false,
            openDeleteConfirmDialog: false,
            open: false,
        }
    }

    componentDidMount(){
        this.setState({ loader: true });
        const params = this.props.match.params;
        let fileId = this.props.match.params.file_id;
        if ('machine_id' in params) {
            let productId = this.props.match.params.machine_id;
            this.getMachineFile(productId, fileId)
        } else if ('model_id' in params) {
            let modelId = this.props.match.params.model_id;
            this.getModelFile(modelId, fileId)
        } else {
            this.setState({ loader: false });
            this.props.onUpdateApiControl({status: 400}, 'getFile', );
        }
    }

    getMachineFile(productId, fileId) {
        ProductApi.getFileUrlById(productId, fileId).then(
            res => {
                this.props.onUpdateRouter({
                    ...this.props.router,
                    activeMachineName: res.data.product.name,
                    activeMachineLabels: res.data.product.labels,
                    activeCategoryName: res.data.file.category.label,
                    activeCategoryLabels: res.data.file.category.labels,
                    activeFileName: res.data.file.name,
                    activeFileLabels: res.data.file.labels
                });
                this.setState({
                    loader:false,
                    product: res.data.product,
                    file: res.data.file,
                    // permissions: res.data.permissions,
                });
            },
            err => {
                this.setState({ loader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'getFile', );
            }
        )
    }

    getModelFile(modelId, fileId) {
        ModelApi.getFileUrlById(modelId, fileId).then(
            res => {
                this.props.onUpdateRouter({
                    ...this.props.router,
                    activeModelName: res.data.model.name,
                    activeModelLabels: res.data.model.labels,
                    activeCategoryName: res.data.file.category.label,
                    activeCategoryLabels: res.data.file.category.labels,
                    activeFileName: res.data.file.name,
                    activeFileLabels: res.data.file.labels
                });
                this.setState({
                    loader:false,
                    model: res.data.model,
                    file: res.data.file,
                    // permissions: res.data.permissions,
                });
            },
            err => {
                this.setState({ loader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'getFile', );
            }
        )
    }

    downloadFile() {
        const { file } = this.state;
        this.setState({ loader: true });

        fetch(file.url)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = file.name;
                    a.click();
                    this.setState({loader : false})
                });
                },
            err => {
                let link = document.createElement('a', );
                document.body.appendChild(link); // Firefox requires the link to be in the body
                link.download = file.name;
                link.setAttribute('download', file.name);
                link.setAttribute('target' , "_blank");
                link.href = file.url;
                link.click();
                document.body.removeChild(link)
                this.setState({loader : false})
            }

            )
    }

    addToolbarFunction = (permissions) => {
        let toolBarFunctions = [];
        if(!permissions) { return toolBarFunctions; }

        if(permissions.delete){
            toolBarFunctions.push({label: translate('file.download', {}, "Scarica file"),
                disabled: false,
                icon: (style) => <DownloadCloud style={style}/>,
                func: () => this.downloadFile()});
            // toolBarFunctions.push({label: 'Elimina', disabled: false, icon: 'delete', func: () => this.setState({openDeleteConfirmDialog: true})});
        }
        return toolBarFunctions;
    };

    deleteFile = () => {
        this.setState({ dialogLoader: true });
        this.state.selectedFiles.map((file, i)=>
            ProductApi.deleteFileById(this.state.product.id, file.id).then(
                () => {
                    let files = [...this.state.files];
                    const index = files.indexOf(file);
                    if (index >= 0)
                        files.splice(index, 1);
                    this.setState({
                        files: files,
                        filteredFiles: files,
                        selectedFiles: (this.state.selectedFiles.length === i+1) ? [] : this.state.selectedFiles,
                        openDeleteConfirmDialog: false,
                        dialogLoader: false,
                    });
                },
                err => {
                    this.setState({ loader: false });
                    // ====== API CONTROL MESSAGE ====== //
                    this.props.onUpdateApiControl(err, 'deleteFile', );
                }
            )
        );
    };


    render() {
        const { file, openDeleteConfirmDialog, loader, dialogLoader} = this.state;
        const { classes, theme } = this.props;

        const RenderComponentFileType = (file) => {
            let filetype = FileUtils.getFileType(file.name);
            switch(filetype){
                case 'video':
                    return<Player fluid={false} width={'100%'}>
                              <source src={file.url} />
                          </Player>
                case 'image':
                    let decodeImgUrl = file.url;

                    return (
                        <div style={{
                            width : '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            cursor : 'pointer'
                        }}>
                            <img alt={file.name}
                            src={decodeImgUrl}
                            style={{
                                height : 'auto',
                                maxWidth : '100%',
                            }}
                                 onClick={() => this.setState({open : true})}
                            />

                            {this.state.open && (
                                <Lightbox
                                    reactModalStyle={{
                                        overlay: {zIndex : 99999}
                                        }}
                                    mainSrc={decodeImgUrl}
                                    onCloseRequest={() => this.setState({ open: false })}
                                />
                            )}

                    {/*<ReactImageMagnify {...{
                        smallImage: {
                            alt: file.name,
                            isFluidWidth: true,
                            src: decodeImgUrl
                        },
                        largeImage: {
                            src: decodeImgUrl,
                            width: 2400,
                            height: 1600
                        },
                        enlargedImagePosition: 'over',
                        isEnlargedImagePortalEnabledForTouch: true
                    }} />*/}
                        </div>
                    );
                case 'doc':
                    let encodedUrl = encodeURIComponent(file.url);

                        return (
                            <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
                                <GdocViewer onLoad={() => this.setState({docFileLoader:true})} url={encodedUrl}/>
                                {(!this.state.docFileLoader)?<CircularProgress size={48} color="primary" style={{marginTop:40,position:'absolute'}}/>:null}
                            </div>
                        );
                case 'audio':
                    return(
                        <ReactAudioPlayer
                            src={file.url}
                            autoPlay={false}
                            controls
                        />
                    );
                default:
                    return (
                        <div className={classes.noSupportContainer}>
                            <FontAwesomeIcon icon={faFileDownload} size="4x" style={{color:theme.palette.gray.secondary}} />
                            <Typography style={{fontSize:18,margin:'20px 0'}}>
                                {translate('file.format', {}, "Il formato di questo file non è visualizzabile da Ubilod")}
                            </Typography>
                            {/*<Button type="button" variant="contained" color="primary" style={{minWidth:200}} onClick={() => this.downloadFile()}>
                                {translate('file.download', {}, "Scarica file")}
                            </Button>*/}
                        </div>
                    )
            }
        };

        return (
            <div className={classes.root}>
                <BackofficeViewContainer
                    title={(file)?file.name:translate('file.loader', {}, "Caricamento del file...")}
                    subtitle={(file)?`${translate('file.size', {}, "Dimensione")} ${FileUtils.bytesToSize(file.size)}`:null}
                    background={false}
                    hasMarginTop={false}
                    renderToolbar={
                        <FunctionsToolbar items={this.addToolbarFunction({write: true, read: true, delete:true})} />
                    }
                >

                    {/* Render the correct component using the file type */}
                    {(file)?
                        RenderComponentFileType(file)
                        :
                        <CircularProgress className={classes.progress} color="secondary"></CircularProgress>
                    }
                    {/* LOADER */}
                    <SnackbarLoader open={loader} message={translate('category.loader', {}, "Elaborazione in corso")}/>
                </BackofficeViewContainer>
                <div style={{width:'100%', height:40,marginBottom:20}}/>

                {/* DELETE CONFIRM DIALOG */}
                <AlertDialog title={translate('documents.delete.title', {}, "Eliminazione file")}
                             message={translate('documents.delete.subtitle', {}, "Sicuro di voler eliminare i file selezionati?")}
                             confirmBtnText={translate('documents.delete.confirm', {}, "Elimina")}
                             open={openDeleteConfirmDialog}
                             loader={dialogLoader}
                             onClose={() => this.setState({openDeleteConfirmDialog: false})}
                             onConfirm={this.deleteFile}
                />
            </div>
        )
    }

    onError(e) {
        console.log(e, 'error in file-viewer');
    }
}


FileManager.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    api_control: state.api_control,
    router: state.router,
    utils: state.utils
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl,
    onUpdateLoaders: updateLoaders,
    onUpdateRouter: updateRouter,
};

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(FileManager);
