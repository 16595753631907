import React, {Component} from 'react';
import PropTypes from 'prop-types';
import loaderImage from '../../../../assets/logos/Logo-DKC.png';
//import material-ui
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    root: {
        zIndex:99999,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        overflowY: 'hidden',
        overflowX: 'hidden',
        background: '#fff'
    },
    centerIcon: {
        display: 'flex'
    },
    linearProgress: {
        width:'100%',
        position: 'absolute',
        bottom: 0,
        left:0,
    }
});

class Loader extends Component {

    render(){
        const { classes } = this.props;

        return (
            <div>
                <div className={classes.root}>
                     <img alt="logo" src={loaderImage} width={240}/><br/>
                     <CircularProgress size={20} color="primary"/>
                </div>
            </div>
        );
    }
}

Loader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Loader);



