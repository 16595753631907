import API from './API';

class PagesApi {

    static url = 'pages';

    static getHome() {
        const api = new API(this.url + '/dashboard');
        return api.get();
    }

    static getMachineList() {
        const api = new API(this.url + '/machine-list');
        return api.get();
    }
}

export default PagesApi
