import React from 'react';
// material ui import
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// components import
import TextFieldControlCharacters from '../../../../../components/ui/inputs/TextFieldControlCharacters'
import {compose} from "redux";
import connect from "react-redux/es/connect/connect";
import {translate} from "../../../../../translations/i18n";
import {Globe, X} from "react-feather";
import LanguageSelect from "../../../../../components/language-select/LanguageSelect";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles  = theme => ({
    dialogPaper: {
        [theme.breakpoints.down('xs')]:{
            margin: 10,
        },
    },
    textField: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        width: '47%',
        padding: '0px 0px 16px'
},
    dialogAction: {
        margin: '0px 20px',
        marginBottom:20,
    }
});


class CreateMachineDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            product: {
                id: null,
                name: null,
                serial_number: null,
                vin: null,
                commission: null,
                customer: null,
                model: null,
                production_date: null,
                public: false,
            },
            labels : [
                {
                    description: '',
                    flag: null,
                    title: ''
                }
            ],
        }
    }

    componentDidMount(){
        const props = this.props;

        this.setState({
            labels : (props.product && props.product.labels && props.product.labels.length > 0) ? props.product.labels.map(item => ({...item, flag : item.flag.id})) : [
                {
                    description: '',
                    flag: props.multilanguage.defaultlang,
                    title: ''
                }
            ],
            product:{
                id: (props.product)?props.product.id:null,
                name: (props.product)?props.product.name:null,
                serial_number: (props.product)?props.product.serial_number:null,
                vin: (props.product)?props.product.vin:null,
                commission: (props.product)?props.product.commission:null,
                customer: (props.product)?props.product.customer:null,
                model: (props.product && props.product.model)?props.product.model.id: (props.model) ? props.model.id : null,
                model_view: (props.product)?props.product.model:(props.model) ? props.model : null,
                production_date: (props.product)?props.product.production_date:null,
                public: (props.product)?props.product.public:false,
                description: (props.product)?props.product.description:null
            },
        })
    }

    handleChangeMultiple = (event, index, callback) => {
        const { value } = event.target;
        let tmpValue =  this.state.labels[index].flag;
        let tmpLabels = this.state.labels;


        let  availableLang = this.props.multilanguage.languageValues.filter(item => this.state.labels.findIndex(v => v.flag == item.id) === -1);

        if (value.id === tmpValue ){
            tmpLabels[index] = {
                ...tmpLabels[index],
                flag : null
            };
        }else if (availableLang.length > 0 && availableLang.findIndex(item => item.id == value) !== -1){
            tmpLabels[index] = {
                ...tmpLabels[index],
                flag : value
            };
        }

        this.setState({labels : tmpLabels})
        callback()

    };

    addLabel = () => {
        let tmpLabels = this.state.labels;
        tmpLabels.push({
            description: '',
            flag: null,
            title: ''
        });

        this.setState({label : tmpLabels})
    };

    removeLabel = (index) => {
        let tmpLabels = this.state.labels;

        tmpLabels.splice(index , 1);

        this.setState({label : tmpLabels})
    };

    handleChange = (e) => {
        e = {...e};

        this.setState(prevState => ({
            product:{
                ...prevState.product,
                [e.target.name] : e.target.value
            }
        }));
    };

    handleChangeLabel = (e , index) => {
        let tmpLabel = this.state.labels;

        tmpLabel[index] = {
            ...tmpLabel[index],
            [e.target.name] : e.target.value
        };

        this.setState({labels : tmpLabel})
    };

    handleCheck = (e) => {
        e.persist();
        this.setState(prevState => ({
            product:{
                ...prevState.product,
                [e.target.name] : e.target.checked
            }
        }));
    };


    formatLabels = () => {
        return (this.state.labels.map((item, index) => {
            return ({
                description : item.description,
                title : item.title,
                flag : this.props.multilanguage.languageValues[this.props.multilanguage.languageValues.findIndex(v => v.id == item.flag)]
            })}))
    };

    render() {
        const { product, labels } = this.state;
        const { classes, user, open, loader, models, license, multilanguage} = this.props;

        return (
            <div>
                <Dialog
                    PaperProps={{ classes: {root: classes.dialogPaper } }}
                    open={open}
                    onClose={this.props.onClose}>
                    {/* Print edit product on edit - Print the label on readOnly*/}
                    {(this.props.readOnly)?
                        <DialogTitle>{product.name}</DialogTitle>
                    :
                        <DialogTitle>{this.props.title}</DialogTitle>
                    }
                    <DialogContent style={{paddingTop:0}}>
                        <form ref={this.formMachine} onSubmit={(e)=> {e.preventDefault(); this.props.onCreate(product, this.formatLabels())}}
                              onKeyPress={(e) => (e.which === 13)?e.preventDefault():null}>

                            {(!this.props.readOnly)?
                                <DialogContentText>
                                    {translate('machine_dialog.subtitle',{},'Compila i campi sottostanti')}
                                </DialogContentText>
                                :null}

                            <div style={{padding : '12px 0px', borderBottom : '1px solid rgba(0,0,0,0.5)'}}>
                            {multilanguage.languageValues && this.state.labels.map( (item, index) =>
                                <div style={{marginTop : 20}}>
                                    <div style={{display : 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                                        {index > 0 ?
                                            <div style={{display : 'flex', alignItems : 'center'}}>
                                            <Button
                                                style={{minWidth : 16, padding : '4px', borderRadius : '16px'}}
                                                onClick={() => this.removeLabel(index)}>
                                                <X size={24}/>
                                            </Button>
                                                <Typography style={{fontSize : 14}}>
                                                    {translate('general.remove_language', {}, "Rimuovi traduzione")}
                                                </Typography>
                                            </div>
                                            :
                                            <div style={{display : 'flex', alignItems : 'center', visibility : 'hidden'}}>
                                                <Button
                                                    style={{minWidth : 16, padding : '4px', borderRadius : '16px'}}
                                                    onClick={() => this.removeLabel(index)}>
                                                    <X size={24}/>
                                                </Button>
                                                <Typography style={{fontSize : 14}}>
                                                    {translate('general.remove_language', {}, "Rimuovi traduzione")}
                                                </Typography>
                                            </div>
                                        }
                                        <LanguageSelect
                                            onChange={(e, callback) => this.handleChangeMultiple(e, index, callback)}
                                            style={{padding : '0px 16px 16px 0px', justifyContent : 'flex-end'}}
                                            value={item.flag}
                                            values={multilanguage.languageValues}
                                            multiple={false}
                                            label={''}
                                            languageOptions={null}
                                        />
                                    </div>
                                    <div style={{display : 'flex', flexWrap : 'wrap', justifyContent : 'space-between'}}>
                                    <TextField
                                        required
                                        variant={'outlined'}
                                        InputProps={{inputProps: {maxLength: 50}}}
                                        InputLabelProps={{shrink: true}}
                                        label={translate('machine_dialog.name', {}, 'Nome')}
                                        name="title"
                                        className={classes.textField}
                                        rowsMax="4"
                                        value={item.title}
                                        onChange={(e) => {
                                            index === 0 &&
                                            this.handleChange({...e, target : {...e.target, name : 'name'}});
                                            this.handleChangeLabel(e, index);
                                        }}
                                        disabled={loader}
                                    />
                                    <TextField
                                        label={translate('associate_category.description', {}, "Descrizione")}
                                        name="description"
                                        multiline
                                        variant={'outlined'}
                                        rowsMax="4"
                                        InputLabelProps={{shrink: true}}
                                        className={classes.textField}
                                        value={item.description}
                                        onChange={(e) => {
                                            index === 0 &&
                                            this.handleChange(e);
                                            this.handleChangeLabel(e, index)
                                        }}
                                        disabled={loader}
                                        margin="normal"
                                    />
                                    </div>
                                </div>
                            )}

                            <Button
                                style={{marginTop : 12}}
                                disabled={this.state.labels.length >= multilanguage.languageValues.length}
                                onClick={() => this.addLabel()}>
                                <Globe size={16} style={{marginRight : 12}} />
                                {translate( `general.add_language`, {},  'Aggiungi traduzione')}
                            </Button>
                            </div>
                            <div style={{display : 'flex', flexWrap : 'wrap', justifyContent : 'space-between', paddingTop : 16 }}>
                            {(!this.props.readOnly || product.serial_number)?
                                <TextField
                                    label={translate('machine_dialog.serial_number',{},"Codice seriale")}
                                    name="serial_number"
                                    InputLabelProps={{ shrink: true}}
                                    required={false}
                                    variant={'outlined'}
                                    className={classes.textField}
                                    value={product.serial_number}
                                    onChange={(e) => this.handleChange(e)}
                                    disabled={loader}
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                        disableUnderline: this.props.readOnly,
                                    }}
                                />
                                :null}
                            {((!this.props.readOnly || product.vin) )?
                                <TextField
                                    label={translate('machine_dialog.vin',{},"Vin")}
                                    name="vin"
                                    InputLabelProps={{ shrink: true}}
                                    className={classes.textField}
                                    value={product.vin}
                                    onChange={(e) => this.handleChange(e)}
                                    variant={'outlined'}
                                    disabled={loader}
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                        disableUnderline: this.props.readOnly,
                                    }}
                                />
                                :null}
                            {((!this.props.readOnly || product.commission) )?
                                <TextField
                                    label={translate('machine_dialog.commission',{},"N° Commissione")}
                                    name="commission"
                                    InputLabelProps={{ shrink: true}}
                                    className={classes.textField}
                                    value={product.commission}
                                    onChange={(e) => this.handleChange(e)}
                                    disabled={loader}
                                    variant={'outlined'}
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                        disableUnderline: this.props.readOnly,
                                    }}
                                />
                                :null}
                            {((!this.props.readOnly || product.customer) )?
                                <TextField
                                    label={translate('machine_dialog.customer',{},"Cliente")}
                                    name="customer"
                                    InputLabelProps={{ shrink: true}}
                                    className={classes.textField}
                                    value={product.customer}
                                    onChange={(e) => this.handleChange(e)}
                                    disabled={loader}
                                    variant={'outlined'}
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                        disableUnderline: this.props.readOnly,
                                    }}
                                />
                                :null}
                            {(!this.props.readOnly && models && user.type === 'MF')?
                                <FormControl className={classes.textField}>
                                    <InputLabel shrink={true} htmlFor="model">
                                        {translate(`machine_dialog.${license.license}`,{},(license.license === 'commercial') ? 'Linea prodotto' : 'Modello')}
                                        </InputLabel>
                                    <Select
                                        native
                                        value={(product.model) ? product.model : (this.props.model) ? this.props.model.id : ''}
                                        onChange={(e) => this.handleChange(e)}
                                        inputProps={{
                                            name: 'model',
                                            id: 'model',
                                        }}
                                        disabled={loader || this.props.disableModel}>
                                        <option value="">
                                            {translate(`machine_dialog.placeholder_${license.license}`,{},(license.license === 'commercial') ? 'Nessuna linea prodotto' : 'Nessun modello')}
                                        </option>
                                        {models.map( model =>
                                            <option selected={(product.model_view && product.model_view.id === model.id) || (this.props.model && this.props.model.id === model.id)} value={model.id}>{model.name}</option>
                                        )}
                                    </Select>
                                </FormControl>
                                :
                                <TextField
                                    label={translate(`machine_dialog.${license.license}`,{},(license.license === 'commercial') ? 'Linea prodotto' : 'Modello')}
                                    name="model"
                                    variant={'outlined'}
                                    InputLabelProps={{shrink: true}}
                                    className={classes.textField}
                                    value={(product.model_view) ? product.model_view.name : '-'}
                                    disabled={loader}
                                    InputProps={{
                                        readOnly: true,
                                        disableUnderline: true,
                                    }}
                                />
                            }

                            {(!this.props.readOnly || product.production_date)?
                                <TextField
                                    label={translate('machine_dialog.production_date',{},"Data di produzione")}
                                    name="production_date"
                                    type="date"
                                    InputLabelProps={{ shrink: true}}
                                    className={classes.textField}
                                    value={product.production_date}
                                    onChange={(e) => this.handleChange(e)}
                                    variant={'outlined'}
                                    disabled={loader}
                                    InputProps={{
                                        readOnly: this.props.readOnly,
                                        disableUnderline: this.props.readOnly,
                                    }}
                                    margin="normal"
                                />
                                :null}
                            </div>

                            {console.log({product})}
                            {(this.props.readOnly && !product.serial_number && !product.vin && !product.commission &&
                                !product.customer && !product.model && !product.production_date)?
                                <Typography>Non sono presenti dati relativi alla macchina</Typography>
                                :null}
                                <div style={{display : 'flex', flexDirection : 'column', justifyContent : 'space-between', alignItems : 'flex-start'}}>
                            {(!this.props.readOnly)?
                                <FormControlLabel
                                    style={{marginLeft: "unset"}}
                                    control={
                                        <Switch
                                            color="primary"
                                            name={'public'}
                                            checked={product.public}
                                            onChange={(e) => this.handleCheck(e)}
                                        />
                                    }
                                    label={translate('machine_dialog.public',{},"Pubblico")}
                                    labelPlacement="start"
                                />
                                :null}
                                </div>

                            {/*<TextField*/}
                            {/*label="Pubblico"*/}
                            {/*name="public"*/}
                            {/*InputLabelProps={{shrink: true}}*/}
                            {/*className={classes.textField}*/}
                            {/*value={(product.public) ? 'Sì' : 'No'}*/}
                            {/*disabled={loader}*/}
                            {/*InputProps={{*/}
                            {/*readOnly: true,*/}
                            {/*disableUnderline: true,*/}
                            {/*}}*/}
                            {/*/>*/}
                            <Button type="submit" id="submitButton" style={{display:'none'}}/>
                        </form>
                    </DialogContent>
                    <DialogActions className={classes.dialogAction}>
                        <Button onClick={() => this.props.onClose()} color="primary">
                            {(!this.props.readOnly)?
                                translate('machine_dialog.cancel',{},"Annulla") : translate('machine_dialog.close',{},"Chiudi")
                                }
                        </Button>
                        {(!this.props.readOnly)?
                            <Button
                                disabled={this.state.labels.findIndex(item => item.flag === null) !== -1 || multilanguage.languageValues.length === 0}
                                onClick={() => document.getElementById('submitButton').click() } variant="outlined" color="primary">
                                {(!loader)?
                                    (!product.id)?
                                        translate('machine_dialog.add',{},"Crea") : translate('machine_dialog.edit',{},"Modifica")
                                    :
                                    <CircularProgress size={16} style={{marginLeft:0}}/>
                                }
                            </Button>
                            :null}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    license: state.license,
    multilanguage : state.multilanguage
});


export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps)
)(CreateMachineDialog);
