import {translate} from "../../translations/i18n";

export const UPDATE_APICONTROL = 'apiControl:updateApiControl';
export const RESET_APICONTROL = 'apiControl:resetApiControl';


export function updateApiControl(err, context, message=null) {

    if (!message) {
        message = (err && err.response && err.response.data && err.response.data.error) ?
            translate(`api_message_control.${err.response.data.error}`, {}, `api_message_control.${err.response.data.error}`)
            : 'Si è verificato un errore durante l\'elaborazione della richiesta, contattare il supporto';
    }

    const data = {
        context: context ,
        message: message,
        response: (err.response)?err.response:{status:err.status},
        action: null,
    };

    return {
        type: UPDATE_APICONTROL,
        payload: {
            api_control: data
        }
    }
}

export function resetApiControl() {
    return {
        type: RESET_APICONTROL,
        payload: {
            api_control: {
                message: "",
                response: {status: ""},
                action: null,
                context: null,
            }
        }
    }
}
