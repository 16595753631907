import {
    Component
} from 'react';
import AuthApi from './AuthApi';
import GeneralSettings from '../settings/GeneralSettings';
import LanguageApi from "../components/language-select/LanguageApi";

class AuthService extends Component {

    /**
     * @description: Check if the user is authenticated for the testing (htaccess)
     * @return: {boolean} true: the user is enabled for testing
     **/
    static isAuthenticatedForTesting = () => {
        const enable = JSON.parse(localStorage.getItem(`${GeneralSettings.testingKeyLocalStorage}`));
        return enable;
    };

    static setAuthenticationForTesting = () => {
        localStorage.setItem(`${GeneralSettings.testingKeyLocalStorage}`, 'true');
    };

    /**
     * @description: Check the token state using the verify and refresh token function
     * @isLogged: globale variable setted for isLogged() function
     * @return: {boolean} true: token is valid
     **/
    static isAuthenticated = () => {
        try {
            const token = JSON.parse(localStorage.getItem(GeneralSettings.stateKeyLocalStorage)).user.token;
            if (token) {
                // Verify Token Api Call
                AuthApi.verifyToken(token).then(
                    res => {
                        const tmpStorage = JSON.parse(localStorage.getItem(GeneralSettings.stateKeyLocalStorage));
                        tmpStorage.user.token = res.data.token;
                        tmpStorage.user.is_logged = true;
                        localStorage.setItem(GeneralSettings.stateKeyLocalStorage, JSON.stringify(tmpStorage));
                        return true;
                    },
                    err => {
                        // Refresh Token Api Call
                        AuthApi.refreshToken(token).then(
                            res => {
                                const tmpStorage = JSON.parse(localStorage.getItem(GeneralSettings.stateKeyLocalStorage));
                                tmpStorage.user.token = res.data.token;
                                tmpStorage.user.is_logged = true;
                                localStorage.setItem(GeneralSettings.stateKeyLocalStorage, JSON.stringify(tmpStorage));
                                return true;
                            },
                            err => {
                                const tmpStorage = JSON.parse(localStorage.getItem(GeneralSettings.stateKeyLocalStorage));
                                tmpStorage.user = {is_logged: false};
                                localStorage.setItem(GeneralSettings.stateKeyLocalStorage, JSON.stringify(tmpStorage));
                                // redirect to login
                                window.location.href = `${window.location.origin}/login`
                                return false;
                            }
                        )
                    }
                )
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    };

    /**
     * @description: Return if the user is logged (checking the isLogged function)
     * @isLogged: globale variable setted in store from isAuthenticated() function
     * @return: {boolean} true: user is logged (token is valid)
     **/
    static isLogged = () => {
        let localstorage = JSON.parse(localStorage.getItem(GeneralSettings.stateKeyLocalStorage));
        return (localstorage && localstorage.user.is_logged) ? localstorage.user.is_logged : false;
    };

    /**
     * @description: Return the local token get from store
     * @return: {string} token string
     **/
    static getLocalToken = () => {
        try {
            const user = JSON.parse(localStorage.getItem(GeneralSettings.stateKeyLocalStorage)).user;
            return (user) ? user.token : '';
        } catch (err) {
            return '';
        }
    };
}


export default AuthService;
