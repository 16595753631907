import React, {Component} from 'react';
import PropTypes from 'prop-types';
// material-ui import
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// table import
import PermissionTable from './components/PermissionTable';
// api import
import ProductApi from '../../../../../services/ProductApi';
// container import
import BackofficeViewContainer from '../../../layout/container/AdminContainer';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateLoaders } from '../../../../../redux/actions/loaders-action';
import { updateApiControl } from '../../../../../redux/actions/api-control-action';
import { updateRouter } from "../../../../../redux/actions/router-action";
//components
import Search from '../../../../../components/ui/search/Search';
import SnackbarLoader from '../../../../../components/ui/loaders/snackbar/SnackbarLoader';

const styles = () => ({
    root: {},
    inputSearch: {
        width:'100%',
        background: '#fff',
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,
        '& input':{
            padding: 14
        }
    }
});

class MachinePermissionManager extends Component {

    constructor(props){
        super(props);
        this.state = {
            loader: false,
            product: null,
            user: null,
            permissions: [],
            filteredPermissions: [],
        }
    }


    componentDidMount(){
        this.setState({
            loader: true,
        });
        let productId = this.props.match.params.machine_id;
        let userId = this.props.match.params.user_id;
        ProductApi.getPermission(productId, userId)
            .then(
                res => {
                    // ====== UPDATE BREADCRUMB ====== //
                    this.props.onUpdateRouter({
                        ...this.props.router,
                        activePermissionName: `${res.data.user.first_name} ${res.data.user.last_name}`
                    });
                    this.setState({
                        product: res.data.product,
                        user: res.data.user,
                        permissions: res.data.permissions,
                        filteredPermissions: res.data.permissions,
                        loader: false
                    });
                },
                err => {
                    this.setState({
                        loader: false,
                    });
                    // ====== API CONTROL MESSAGE ====== //
                    this.props.onUpdateApiControl(err, 'getPermissions', );
            })
    }

    handleOnChangeCheckbox = (permission, mod, checked) => {
        const { product, user, permissions } = this.state;
        if(checked) {
            ProductApi.addPermission(product.id, permission.category.id, user.id, mod)
                    .then(
                        res => {
                            let index = permissions.map(function (item) {return item.id;}).indexOf(permission.id);
                            let tmpPermissions = [...permissions];
                            tmpPermissions[index] = res.data;
                            this.setState(() => ({
                                permissions: tmpPermissions,
                                filteredPermissions: tmpPermissions
                            }));
                        },
                        err => {
                            // ====== API CONTROL MESSAGE ====== //
                            this.props.onUpdateApiControl(err, 'addPermission', );
                        })
        } else {
            ProductApi.removePermission(product.id, permission.category.id, user.id, mod)
                .then(
                    res => {
                        let index = permissions.map(function (item) {return item.id;}).indexOf(permission.id);
                        let tmpPermissions = [...permissions];
                        tmpPermissions[index] = res.data;
                        this.setState(() => ({
                            permissions: tmpPermissions,
                            filteredPermissions: tmpPermissions
                        }));
                    },
                    err => {
                        // ====== API CONTROL MESSAGE ====== //
                        this.props.onUpdateApiControl(err, 'removePermission', );
                    })
        }
    };

    render(){
        const { classes } = this.props;
        const { loader, product, user, permissions, filteredPermissions } = this.state;

        return (
            <div className={classes.root}>
                <BackofficeViewContainer
                    title={`Gestione Permessi di ${user && user.first_name} ${user && user.last_name} per la macchina ${product && product.name}`}
                    subtitle={`Dà e revoca i permessi per di modifica, lettura ed eliminazione dei file caricati nella macchina ${product && product.name}.`}
                    background={false}
                    hasMarginTop={false}
                    renderSearch={
                        <Search data={permissions} // data list where the component search
                                keySearch={"category.label"} // key that have to search
                                placeholder={"Cerca utenti in Ubilod"} // placeholder
                                onResult={(searchResult) => this.setState({filteredPermissions: searchResult})}/> // action after search
                    } >

                    {(product && permissions.length > 0) ?
                        <PermissionTable permissions={filteredPermissions}
                                     handleOnChangeCheckbox={this.handleOnChangeCheckbox.bind(this)}/>
                        :
                        <Typography style={{marginTop:20}}>Non sono presenti dati</Typography>
                    }

                    {/* LOADER */}
                    <SnackbarLoader open={loader} message={"Elaborazione in corso"}/>
                </BackofficeViewContainer>
            </div>
        );
    }
}

MachinePermissionManager.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    utils: state.utils,
    api_control: state.api_control,
    router: state.router
});

const mapActionsToProps = {
    onUpdateLoaders : updateLoaders,
    onUpdateApiControl : updateApiControl,
    onUpdateRouter: updateRouter,
};


export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(MachinePermissionManager);
