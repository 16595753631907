import React from 'react';
// material ui import
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import {translate} from "../../../../translations/i18n";

const styles  = (theme) => ({
    snackbar:{
        '& div':{
            background: 'rgba(0,0,0,1)',
            borderRadius:0,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
        }
    },
    container:{
        background:'transparent',
        display: 'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    loader : {
        right: 0,
        minWidth: 400,
        bottom: 0,
        /* height: 50px; */
        display: 'flex',
        position: 'fixed',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#2c3a49',
        borderRadius: 8,
        margin: 40,
        /* border-top-right-radius: 8px; */
        padding: '16px 30px',
        [theme.breakpoints.down('xs')]:{
            minWidth: 'unset',
            right: 'unset',
        }
    },
});

class SnackbarLoader extends React.Component {

    render() {
        const { classes, loader, open, message } = this.props;

        return (


        <div className={classes.loader} style={{display : open ? 'flex' : 'none'}}>
            <Typography style={{fontSize : 16, marginRight : 12, color : '#fff'}}>
                {message}
            </Typography>
            <CircularProgress size={16} color='secondary'/>
        </div>
        )



       /* return (
            <div>
                <Snackbar
                    className={classes.snackbar}
                    open={this.props.open}
                    onClose={this.handleClose}
                    TransitionComponent={Fade}
                    message={
                        <div className={classes.container}>
                            <span id="message-loader" style={{marginRight:12}}>{this.props.message}</span>
                            <CircularProgress size={16} color="secondary"/>
                        </div>
                    }
                />
            </div>
        );*/
    }
}

export default (withStyles(styles, {withTheme: true}))(SnackbarLoader);
