import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// material-ui import
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import Typography from '@material-ui/core/Typography';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux'
import { updateApiControl } from '../../../../../../redux/actions/api-control-action';
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import OffersPaginationTable from "./PermissionPaginationTable";

const styles = theme => ({
    root: {
        width: '100%',
        boxShadow: 'none',
    },
    table: {
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    body: {
        fontSize: 14,
    },
});

class MachinePermissionUserTable extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
        }
    }

    // ==================================================
    // ON SELECT CHECKBOX
    // ==================================================
    handleUserCheckbox = (user) => {
        const index = this.props.selectedUsers.indexOf(user);
        let selectedUsers = [...this.props.selectedUsers];
        (index >= 0) ? selectedUsers.splice(index, 1) : selectedUsers.push(user);
        // call onResult function to set the searchResult
        this.props.onSelect(selectedUsers);
    };

    // ==================================================
    // ON SELECT ALL CHECKBOX
    // ==================================================
    handleSelectAllCheckbox(){}

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    // Change number rows per page
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    render() {
        const {classes} = this.props;
        const { product, users } = this.props;
        const { page, rowsPerPage } = this.state;

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {/*<Checkbox color="primary" onChange={(e) => this.handleSelectAllCheckbox()}></Checkbox>*/}
                            </TableCell>
                            <TableCell>
                               Nome
                            </TableCell>
                            <TableCell>
                                Email
                            </TableCell>
                            <TableCell>
                                Admin
                            </TableCell>
                            <TableCell>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(users.length > 0)?
                            users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) =>
                                (user !== undefined)?
                                    <TableRow className={classes.row} key={user.id}>
                                        <TableCell component="th" scope="row">
                                            <Checkbox color="primary"
                                                      onChange={() => this.handleUserCheckbox(user)}/>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {`${user.first_name} ${user.last_name}`}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {user.email}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Switch
                                                color="primary"
                                                checked={user.is_admin}
                                                onChange={(e) => this.props.onSelectAdmin(user, e.target.checked)}
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {(!user.is_admin) ?
                                                <Link to={`/machine/${product.id}/permission/${user.id}`}>
                                                    <Button color="primary"
                                                            size={'small'}
                                                            variant="outlined"
                                                            style={{minWidth:80}}>
                                                        Gestisci permessi
                                                    </Button>
                                                </Link>
                                            :null}
                                        </TableCell>
                                    </TableRow>
                                    :
                                    null
                            )
                            :
                            <TableRow className={classes.row}>
                                <TableCell component="th" scope="row" colSpan={6}>
                                    <Typography style={{padding: '20px 0'}}>Nessun risultato trovato</Typography>
                                </TableCell>
                            </TableRow>}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                style={{position: 'absolute', flex:1}}
                                colSpan={3}
                                count={users.length}
                                rowsPerPage={rowsPerPage}
                                labelRowsPerPage={'Righe per pagina'}
                                rowsPerPageOptions={[5, 10, 20]}
                                page={page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                ActionsComponent={OffersPaginationTable}
                            />
                        </TableRow>
                        {/*<Button variant="fab" mini color="secondary" style={{marginLeft:8,marginRight:8}}>
                            <Icon>keyboard_arrow_left</Icon>
                        </Button>
                        <Button variant="fab" mini color="secondary" >
                            <Icon>keyboard_arrow_right</Icon>
                        </Button>*/}
                    </TableFooter>
                </Table>
            </Paper>
        );
    }
}

MachinePermissionUserTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    api_control: state.api_control
});

const mapActionsToProps = {
    onUpdateApiControl : updateApiControl
};

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(MachinePermissionUserTable);


