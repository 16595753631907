import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../../redux/actions/api-control-action';
//material-ui imports
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

//import domtoimage from 'dom-to-image';
// import moment date
import moment from 'moment';
import DownloadQrCode from "./DownloadQrCode";

const styles  = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: -80,
        marginBottom: 80,
        width:'50%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    productLabel: {
        margin:12,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 6,
        padding: 20,
        position: 'relative',
        backgroundColor: 'white',
        borderColor: '#adb4c7',
        boxShadow: '0px 2px 2px rgba(131, 138, 154, 0.50)',
        width: '80%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            textAlign:'center'
        },
    },
    title: {
        fontSize: 22,
        fontWeight:500,
        color: '#3c4252',
        marginTop:4,
        marginBottom:16,
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'left',
        [theme.breakpoints.down('xs')]: {
            fontSize: 15,
        },
    },
    subtitle: {
        fontSize:15,
        color: '#3c4252',
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
        },
    },
    typography: {
        marginTop:5,
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'left',
        color: '#3c4252',
    },
    qrCodeImage:{
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            maxWidth:200,
        },
    },
    downloadQrLink: {
        marginTop: 12,
        cursor: 'pointer',
        fontWeight: 600,
    },
});

class MachineLabel extends Component {

    constructor(props){
        super(props);
        this.state = {}
    }

    render() {

        const { classes, product, license } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.productLabel}>
                    <Grid container spacing={24} >
                        <Grid item
                              xs={12}
                              sm={8}
                              style={{display: 'flex', justifyItems: 'center', alignItems: 'flex-start'}}>
                            <div style={{padding :'30px 0px 0px 8px'}}>
                                <Typography className={classes.title}>
                                    {product.name}
                                </Typography>
                                <Typography className={[classes.subtitle, classes.typography].join(' ')}>
                                    <strong>{(license.license === 'commercial') ? 'Linea Prodotto': 'Modello'} :  </strong>&nbsp;{(product.model)?product.model.name:'-'}
                                </Typography>
                                {(license && license.license !== 'commercial') && <Typography className={[classes.subtitle, classes.typography].join(' ')}>
                                    <strong>S/N: </strong>&nbsp;{(product.serial_number)?product.serial_number:'-'}
                                </Typography>}
                            </div>
                        </Grid>

                        <Grid item
                              xs={12}
                              sm={4} style={{padding: 20}}>
                            <Hidden xsDown>
                                <div style={{ backgroundColor: 'white', borderRadius: 5, padding: 10}}>
                                    <img className={classes.qrCodeImage} src={product.qrcode} alt={'qr-code'}/>
                                </div>
                            </Hidden>
                            <Typography className={[classes.subtitle, ].join(' ')} style={{justifyContent: "center", display: 'flex'}}>
                                <strong>Code:</strong> {product.code}
                            </Typography>
                            <DownloadQrCode product={product}/>
                        </Grid>

                        <div id="divQrToPrint" style={{display: 'none'}}>
                            <img style={{width: '800px'}} src={product.qrcode} alt={'qr-code'}/>
                        </div>
                    </Grid>
                </div>
            </div>
        )
    }
}


MachineLabel.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    api_control: state.api_control,
    router: state.router,
    license: state.license,
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl
};

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(MachineLabel);
