import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
//material-ui imports
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../redux/actions/api-control-action';
// translation import
import LanguageSelect from "./components/LanguageSelect";
import LinkAdd from "./components/LinkAdd";
import LanguageApi from "../../language-select/LanguageApi";
import {translate} from "../../../translations/i18n";

const styles = theme => ({
    dialogPaper: {
        [theme.breakpoints.down('xs')]:{
            margin: 10,
        },
    },
    textField: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        width: '100%',
    },
});


class LinkDialog extends Component {

    constructor(props){
        super(props);
        this.state={
            model :{
                link : null,
                name : null,
            },
            activeStep : 0,
            languageValue: [],
            labels : []
        }
    }

    componentDidMount(){

                this.setState({
                        labels: [{
                            description: '',
                            flag: [this.props.multilanguage.defaultlang],
                            title: ''
                        }]
                    }
                );
                this.setState({languageValue: [this.props.multilanguage.defaultlang]});

    }

    onSubmit(acceptedFiles) {
        this.props.onSubmit(acceptedFiles)
    }

    handleChange = (e) => {
        e.persist();
        this.setState(prevState => ({
            model:{
                ...prevState.model,
                [e.target.name] : e.target.value
            }
        }));
    };

    handleChangeLabel = (e , index) => {
        let tmpLabel = this.state.labels;

        tmpLabel[index] = {
            ...tmpLabel[index],
            [e.target.name] : e.target.value
        };

        this.setState({labels : tmpLabel})
    };

    handleChangeMultiple = (event) => {

        let { value } = event.target;

        if (value !== 'all')
        value = Number(value);

        let tmpValue =  this.state.languageValue

        let tmpI = tmpValue.findIndex(item => item === value)

        if (value === 'all') {
            if (
                this.props.multilanguage.languageValues.filter(lng => tmpValue.includes(lng.id) === true).length === this.props.multilanguage.languageValues.length)
                tmpValue = [];
            else
                this.props.multilanguage.languageValues.map(item => {
                    let tmpIdx = tmpValue.findIndex(tmpV => tmpV === item.id);

                    if (tmpIdx === -1)
                        tmpValue.push(item.id);
                });
        }

        if (tmpI === -1 && value !== 'all')
            tmpValue.push(value);
        else if (value !== 'all')
            tmpValue.splice(tmpI , 1);

        this.setState({labels : tmpValue.map((item) => ({
                description: '',
                    flag: [item],
                    title: ''
        })), languageValue: tmpValue});

    };

    getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:return <LanguageSelect contentText={translate('bulk_import.language', {}, 'Seleziona lingua')}
                                          handleChangeLanguage={this.handleChangeMultiple}
                                          languageValue={this.state.languageValue}
                                          values={this.props.multilanguage.languageValues}
            />;
            case 1:return <LinkAdd contentText={this.props.contentText}
                                   handleChangeLabel={(e, index) => this.handleChangeLabel(e, index)}
                                   handleChange={this.handleChange}
                                   link={this.state.model}
                                   languageValue={this.state.languageValue}
                                   values={this.props.multilanguage.languageValues}
                                   labels={this.state.labels}
                                   />;
            default:return 'Uknown stepIndex';
        }
    }


    handleStepperNext() {
        this.setState(() => ({
            activeStep: this.state.activeStep + 1,
        }));
    };

    handleStepperBack() {
        this.setState(() => ({
            activeStep: this.state.activeStep - 1,
        }));
    };

    handleSubmit(){
        const {values, labels} = this.state

        let tmpFlag = this.state.languageValue.map(item => (Number(item)));

        this.state.model.flags = tmpFlag

        let tmpModel = [];

        tmpModel.push(this.state.model)

        this.props.onSubmit(tmpModel, labels)

    }

    render() {
        const { classes, multiple, loading, language = false,  multilanguage } = this.props;
        const { activeStep } = this.state;

        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.onClose}
                    PaperProps={{ classes: {root: classes.dialogPaper } }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" className={classes.title}>{this.props.title}</DialogTitle>
                    <form onSubmit={(e)=> {e.preventDefault(); (activeStep !== 0) && this.handleSubmit()}}
                          className={classes.container}
                          onKeyPress={(e) => (e.which === 13)?e.preventDefault():null}>
                        {this.getStepContent(activeStep)}
                    <DialogActions>
                        <Button disabled={loading} onClick={(activeStep === 0 || multilanguage === false)? this.props.onClose : () => this.handleStepperBack()}>
                            {(activeStep === 0 || multilanguage === false)?
                                translate( `general.cancel`, {},  "Annulla")
                                :
                                translate( `general.back`, {},  'Indietro')
                            }
                        </Button>
                        {(activeStep === 0) &&
                        <Button disabled={(loading || this.state.languageValue.length === 0)} onClick={() => this.handleStepperNext()} variant="outlined"
                                color="primary">
                            {(!loading) ?
                                translate( `general.next`, {},  'Avanti')
                                :
                                <CircularProgress className={classes.loader} size={16} style={{marginLeft: 0}}/>
                            }
                        </Button>
                        }

                        { (activeStep === 1) &&
                        <Button disabled={loading || this.state.labels.findIndex(item => item.flag === null) !== -1} type="submit" variant="outlined" color="primary">
                            {(!loading)?
                                translate( `general.create`, {},  'Crea')
                                :
                                <CircularProgress className={classes.loader} size={16} style={{marginLeft:0}}/>
                            }
                        </Button>
                        }
                    </DialogActions>
                    </form>
                </Dialog>
            </div>
        )
    }
}

LinkDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    element: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    api_control: state.api_control,
    router: state.router,
    multilanguage: state.multilanguage,
});

const mapActionsToProps = {
    onUpdateApiControl : updateApiControl
}

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(LinkDialog);
