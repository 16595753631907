import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
// material-ui imports
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Helmet from 'react-helmet';
// import Logo from '../../../assets/logos/ubilod_logo-light_bg.svg';
import Logo from '../../../assets/logos/DKClogo.png'
// auth api imports
import AuthApi from '../../../services/AuthApi';
// assets import
import Background from './img/sign-background.jpg';
// utils import
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux'
import { updateUser } from '../../../redux/actions/user-action';
import { updateLicense } from '../../../redux/actions/license-action';
import { updateApiControl } from '../../../redux/actions/api-control-action';
import FullScreenLoader from "../../../components/ui/loaders/fullScreen/FullScreenLoader";
import LanguageApi from "../../../components/language-select/LanguageApi";
import {updateMultilanguage} from "../../../redux/actions/multilanguage-action";
import moment from 'moment'

const styles  = theme => ({
    root: {
        display: 'flex',
        position:'absolute',
        width:'100%',
        top:0,
        left:0,
        zIndex:9999,
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundBlendMode: 'multiply',
        backgroundSize: 'cover',
    },
    /* Effects */
    blurEffect:{
        width:'108%',
        height:'108vh',
        backgroundImage: 'url(' + Background + ')',
        background:'rgba(255, 255, 255, .3)',
        backgroundBlendMode: 'multiply',
        backgroundSize:'cover',
        position:'absolute',
        top:'-4%',
        left:'-4%',
        [theme.breakpoints.down('xs')]:{
            height: '100vh',
            background:'rgba(255, 255, 255, 0.5)',
        }
    },
    gradientEffect:{
        overflow:'hidden',
        width:'100%',
        height:'108vh',
        position:'absolute',
        top:0,left:0,
        background: 'linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0));',
        [theme.breakpoints.down('xs')] : {
            display:'none',
        }
    },
    card: {
        minWidth: 400,
        backdropFilter: 'blur(10px) contrast(.1)',
        background: 'rgba(255,255,255,0.88)',
        //minHeight: '60vh',
        zIndex: 10,
        padding:20,
        borderRadius : '10px',
        display: 'flex',
        flexDirection: 'column',
        boxShadow : 'none',
        justifyContent: 'center',
        [theme.breakpoints.between('sm','md')] : {
            minHeight: 'unset',
        },
        [theme.breakpoints.only('xs')] : {
            background: 'rgba(255,255,255,1)',
            padding:'0 20px',
            minWidth: '100%',
            minHeight: '100vh',
            marginTop:0,
        }
    },
    cardContent: {
        textAlign: 'center',
        paddingBottom: 0,
        margin: 10,
        display : 'flex',
        flexDirection : 'column',
        [theme.breakpoints.down('xs')] : {
            padding:'0 48px'
        }
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'space-between',
        width : '100%'
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textField: {
        width: '100%',
        marginTop: 24,
        '& input':{
            background:'transparent',
        },
    },
    /*buttonSign: {
        marginTop: 32,
        marginBottom:20,
        minWidth: 200,
        color: '#fff',
        textDecoration: 'none',
    },*/
    buttonSign:{
        backgroundColor : theme.palette.active.main,
        fontSize: 14,
        margin:'30px 0px',
        padding: '11px 16px 9px 16px !important',
        borderRadius: 24,
        width : 'max-content',
        minHeight:20,
        lineHeight: 'unset',
        '& > span:first-child':{
            textTransform:'capitalize',
            padding: '0px 8px',
            color : '#000',

        },
        '&:hover':{
            backgroundColor : '#dc4d56',
        },
        [theme.breakpoints.down('xs')]:{
            padding: '6px 18px',
        }
    },
    loader: {
        color: '#fff',
        marginLeft:12,
    },
    label : {
        fontSize: 14,
        '& > span':{
            fontSize: 14
        }
        }
});

class SignIn extends Component {

    constructor(props){
        super(props);
        this.state = {
            email : '',
            password : '',
            showPassword: false,
            stayConnected: true,
            loader: false,
            toHomePage: false,
        }
    }

    componentDidMount(){
        if (this.props.user.company_id) {
            let tmpUser = this.props.user;
            delete tmpUser.company_id;
            this.props.onUpdateUser(tmpUser)
        }
    }

    handleEmailChange = (event) => {
        this.setState({
            email: event.target.value
        });
    };

    handlePasswordChange = (event) => {
        this.setState({
            password: event.target.value
        });
    };

    handleClickShowPassword = () => {
        this.setState(state => ({
            showPassword: !state.showPassword
        }))
    };

    handleClickStayConnected = (e) => {
        this.setState({
            stayConnected: e.target.checked
        });
    };

    handleSubmitSign(e){
        e.preventDefault();
        this.setState({ loader: true });
        // Api call for auth user login
        AuthApi.getAuth(this.state.email , this.state.password)
            .then(
                res => {
                    this.setState({ loaderFullScreen: true });

                    setTimeout(
                        function() {
                            this.setState({
                                loaderFullScreen: false
                            });
                            let user = res.data;
                            // set to true the logged state on store
                            user.is_logged = true;


// usa questo per testare la scadenza della licenza
                            user.company.licenses = [
                                {
                                    "license": "tech",
                                    "demo": true,
                                    "expire": moment().add(3, 'days').format('YYYY-MM-DD')
                                },
                                {
                                    "license": "commercial",
                                    "demo": true,
                                    "expire": moment().add(3, 'days').format('YYYY-MM-DD')
                                }
                            ]


                            if ((user && user.company && user.company.licenses.length > 0
                                &&
                                moment(user.company.licenses[0].expire).isAfter(moment().format('YYYY-MM-DD'))
                                &&
                                moment(user.company.licenses[0].expire).isBefore(moment().add(7,'d').format('YYYY-MM-DD'))
                            ))
                                user.warning = true

                            console.log({user})

                            if ((user.company_id && user.company_id === 103) || (user.company && (user.company.business_name === 'DKC Tech' || user.company.business_name === 'DKC'))){

                                const favicon = document.getElementById("favicon");


                                favicon.href = 'https://www.dkceurope.com/favicon-32x32.png'

                            }

                            user.stay_connected = this.state.stayConnected;

                            this.props.onUpdateUser(user);

                            LanguageApi.getFlags().then(
                                res => {
                                    let multilanguage = {
                                        defaultlang : res.data.default_lang,
                                        languageValues : res.data.content.items,
                                        selectedLanguage : [res.data.default_lang]
                                    };

                                    this.props.onUpdateMultilanguage(multilanguage)

                                },
                                err => {
                                    this.setState({loader: false})
                                }
                            );

                            if (user.company && user.company.licenses.length > 0)
                                this.props.onUpdateLicense(user.company.licenses[0]);
                        }.bind(this),
                        2000
                    );
                },
                err => {
                    this.setState({
                        loader: false,
                    });
                    // ====== API CONTROL MESSAGE ====== //
                    this.props.onUpdateApiControl(err, 'auth',  'Username e/o Password errati');
                }
            );
    }

    render() {
        const { classes } = this.props;
        const { loader, loaderFullScreen } = this.state;

        if (this.props.user.is_logged) {
            return <Redirect to='/'/>
        }

        return (
            <div className={classes.root}>
                {(loaderFullScreen)?<FullScreenLoader/>:null}

                {/* Background effects */}
                <Helmet><style type="text/css">{`html,body{overflow-y:hidden!important}`}</style></Helmet>
                <div className={classes.blurEffect}></div>
                <div className={classes.gradientEffect}></div>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                            <img src={Logo} width={220} alt="logo" style={{margin : '0px auto 16px'}}/>
                        <Typography style={{color:"#777", fontSize : 14, marginBottom : '20px'}}>Organizza, condividi e collabora</Typography>
                        <form onSubmit={this.handleSubmitSign.bind(this)}
                              ref="form"
                              className={classes.formContainer}
                              autoComplete="off">
                            <TextField
                                id="username"
                                InputLabelProps={{ shrink: true }}
                                label="NOME UTENTE"
                                variant="outlined"
                                className={classes.textField}
                                value={this.state.email.toLowerCase()}
                                onChange={(e) => {
                                    this.handleEmailChange(e)
                                }}/>
                            <TextField
                                id="password"
                                InputLabelProps={{
                                    shrink: true,
                            }}
                                InputProps={{
                                    endAdornment :
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickShowPassword}>
                                                {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>}}
                                type={this.state.showPassword ? 'text' : 'password'}
                                label="PASSWORD"
                                variant="outlined"
                                className={classes.textField}
                                value={this.state.password}
                                onChange={(e) => {
                                    this.handlePasswordChange(e)
                                }}
                            />
                            <CardActions className={classes.cardActions}>
                                <FormControlLabel
                                    style={{fontSize : 14}}
                                    className={classes.label}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={this.state.stayConnected}
                                            onChange={this.handleClickStayConnected.bind(this)}/>
                                    }
                                    label="Rimani connesso"
                                />
                                <Link to={"/forgot-password"} className="link">
                                    <Typography color="primary"
                                                className={classes.label}
                                                style={{color : '#B2B2B2'}}

                                    >
                                        Password dimenticata?
                                    </Typography>
                                </Link>
                            </CardActions>
                            <Button disabled={loader}
                                    type="submit"
                                    className={classes.buttonSign}
                                    variant="contained"
                                    color="primary"
                                    size="small">
                                {(!loader) ? <span>ACCEDI</span> : null}
                                {(loader) ? <CircularProgress className={classes.loader} size={16} style={{marginLeft:0}}/> : null}
                            </Button>
                            <Typography style={{fontSize : 14}}>
                                Non hai un account?
                                {' '}
                                <a href="https://gestionedocumentale.ubilod.com/contattaci/" target="_blank" rel="noopener noreferrer"
                                style={{color : '#E30613'}}
                                >
                                Richiedi la licenza.
                                </a>
                            </Typography>
                        </form>
                    </CardContent>

                </Card>
            </div>
        )
    }
}


SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    api_control: state.api_control,
    license: state.license
});

const mapActionsToProps = {
    onUpdateUser : updateUser,
    onUpdateApiControl : updateApiControl,
    onUpdateLicense: updateLicense,
    onUpdateMultilanguage: updateMultilanguage
}

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(SignIn);
