import React, { Component } from 'react';
import PropTypes from 'prop-types';
// material ui import
import { withStyles } from '@material-ui/core/styles';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../redux/actions/api-control-action';
// i18n translation

// api service import
import ProductApi from '../../../../services/ProductApi';
// containers import
import BackofficeViewContainer from '../../layout/container/AdminContainer';
// components import
import Search from '../../../../components/ui/search/Search';
import SnackbarLoader from '../../../../components/ui/loaders/snackbar/SnackbarLoader';
import LanguageSelect from '../../../../components/language-select/LanguageSelect';
import AlertDialog from '../../../../components/ui/dialogs/alertDialog/AlertDialog';
import CreateMachineDialog from './components/CreateMachineDialog';
import ProductList from './components/ProductList';
import {updateMultilanguage} from "../../../../redux/actions/multilanguage-action";
import LanguageApi from "../../../../components/language-select/LanguageApi";
import {translate} from "../../../../translations/i18n";
import CreateModelDialog from "../modelsManager/components/CreateModelDialog";
import FunctionsToolbar from "../../../../components/ui/toolbar/FunctionsToolbar";
import {Download} from "react-feather";
import BulkAPI from "../../../../services/BulkAPI";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import ModelApi from "../../../../services/ModelApi";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce } from "lodash";

const styles  = theme => ({
    noMachineMessage:{
        marginTop:40,
        fontSize:16,
        display:'flex',
        flexDirection: 'column',
        alignItems:'center'
    },
    noMachineIcon:{
        marginBottom:20,
        icon: theme.palette.gray.secondary,
        fontSize:48
    },
    subtitleLink:{
        color:theme.palette.active.main,
        fontWeight:400,
    }
});

class MachinesManager extends Component {

    constructor(props){
        super(props);
        this.state = {
            machinesList:[],
            machinesFound:[], // list
            models: [],
            dialogLoader: false,
            languageValue : [],
            languageValues : [],
            availableLang : [],
            defaultlang : null,
            pagination : {
                offset : 0,
                limit : 30,
                hasMore : true
            },
            searchQuery : ''
        };

        this.searchProduct = debounce((string) => {
            this.setState({searchQuery : string, pagination : {
                    offset : 0,
                    limit : 30,
                    hasMore : true
                },
                loader : true,
            }, () => {
                ProductApi.getProducts({'q': '', 'license': (this.props.license) ? this.props.license.license : '', 'limit' : this.state.pagination.limit , offset : this.state.pagination.offset, search : string}).then(
                    res => {
                        this.setState({
                            machinesList: res.data.products,
                            machinesFound: res.data.products,
                            pagination : {
                                ...this.state.pagination,
                                offset: this.state.pagination.offset + this.state.pagination.limit + 1,
                                hasMore : res.data.products.length === this.state.pagination.limit
                            },
                            loader : false,
                        });
                    },
                    err => {
                        this.setState({ loader: false });
                        // ====== API CONTROL MESSAGE ====== //
                        this.props.onUpdateApiControl(err, 'getMachine', );
                    }
                );
            })
        }, 700);
    }

        apiCalls(){

            // this.props.onUpdateApiControl({status: 400}, 'auth',  'La password non è abbastanza sicura: Utilizza un carattere maiuscolo e un numero');


            ProductApi.getProducts({'q': '', 'license': (this.props.license) ? this.props.license.license : '', 'limit' : this.state.pagination.limit , offset : this.state.pagination.offset}).then(
            res => {
                this.setState({
                    machinesList: res.data.products,
                    machinesFound: res.data.products,
                    pagination : {
                        ...this.state.pagination,
                        offset: this.state.pagination.offset + this.state.pagination.limit + 1,
                        hasMore : res.data.products.length === this.state.pagination.limit

                    }
                });

                ModelApi.getModel({'license': this.props.license.license}).then(
                    res => {
                        this.setState({
                            models: res.data,
                            loader:false,
                        })
                    },
                    err => {
                        this.setState({ loader: false });
                        // ====== API CONTROL MESSAGE ====== //
                        this.props.onUpdateApiControl(err, 'getModel');
                    }
                )
            },
            err => {
                this.setState({ loader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'getMachine', );
            }
        );
    }


    getMachines(){

        if (this.state.loader === false) {
            this.setState({loader: true});

            if (this.state.pagination)
                ProductApi.getProducts({
                    'q': '',
                    'license': (this.props.license) ? this.props.license.license : '',
                    'limit': this.state.pagination.limit,
                    offset: this.state.pagination.offset,
                    search: this.state.searchQuery,
                }).then(
                    res => {
                        this.setState({
                            machinesList: [ ...this.state.machinesList , ...res.data.products],
                            machinesFound: [...this.state.machinesFound, ...res.data.products],
                            pagination: {
                                ...this.state.pagination,
                                offset: this.state.pagination.offset + this.state.pagination.limit,
                                hasMore : res.data.products.length === this.state.pagination.limit
                            },
                            loader: false
                        });
                    },
                    err => {
                        this.setState({loader: false});
                        // ====== API CONTROL MESSAGE ====== //
                        this.props.onUpdateApiControl(err, 'getMachine',);
                    }
                );
        }
    }

    componentDidMount(){
        this.setState({ loader: true });

        this.apiCalls()
    }

    // ==================================================
    // CREATE MACHINE
    // ==================================================
    addMachine(data, labels) {
        this.setState(prevState => ({
            machinesList:  [...prevState.machinesList, {...data, labels}],
            machinesFound: [...prevState.machinesFound, {...data, labels}],
        }));
    };

    // ==================================================
    // DELETE MACHINE
    // ==================================================
    removeMachine(id) {
        let deleteIndex = this.state.machinesList.map(function (item) {return item.id}).indexOf(id)
        this.setState(prevState => ({
            machinesList:  prevState.machinesList.filter((_, i) => i !== deleteIndex),
            machinesFound: prevState.machinesFound.filter((_, i) => i !== deleteIndex),
        }));
    }

    addToolbarFunction = () => {
        let toolBarFunctions = [];

            toolBarFunctions.push({
                label: translate(`documents.download.${this.props.license.license}`, {}, "Scarica struttura macchine"),
                disabled: this.state.loader,
                icon: (style) => (this.state.downloadLoader) ? <CircularProgress color={'secondary'} size={20} style={style}/> : <Download style={style}/>,
                func: () => {
                    this.setState({downloadLoader : true});
                    BulkAPI.getMachineStructure().then(
                        response => {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `export-${Date.now()}.csv`); //or any other extension
                            document.body.appendChild(link);
                            link.click();
                            this.setState({downloadLoader : false});
                        },
                        err => {
                        }
                    )
                }
            });

        return toolBarFunctions;
    };

    // ==================================================
    // UPDATE MACHINE
    // ==================================================
    updateMachine(data, labels) {
        let machineList = this.state.machinesList;
        let updateIndex = machineList.map(function (item) {return item.id}).indexOf(data.id);
        machineList[updateIndex] = {...data};

        this.setState(() => ({
            machinesList:  machineList,
            machinesFound: machineList,
        }));
    }


    render() {
        const { classes ,license, intl, multilanguage} = this.props;
        const { machinesList, machinesFound, models, loader, dialogLoader,languageValues,defaultlang, pagination } = this.state;

        return (
            <div className={classes.root}>

                <BackofficeViewContainer
                    title={
                        translate(`machines.title.${license.license}`,{} , (license.license === 'commercial') ? "Creazione Prodotti" :"Creazione Macchine")
                        }
                    subtitle={
                        translate( `machines.subtitle.${license.license}`, {},  (license.license === 'commercial') ?  "Crea i tuoi prodotti, cerca tra le esistenti, rinominale ed eliminale." : "Crea le tue macchine, cerca tra le esistenti, rinominale ed eliminale.")
                    }
                    renderSearch={
                        <div>
                        <FunctionsToolbar items={this.addToolbarFunction()}/>

                        <Search data={machinesList} // data list where the component search
                                keySearch={"name.description.serial_number.labels:title"}
                                multilingual={multilanguage.selectedLanguage}// key that have to search
                            disabled={loader}
                                placeholder={
                                    translate( `machines.search.${license.license}`, {},  (license.license === 'commercial') ?  "Cerca per prodotto" : "Cerca per macchina")
                                } // placeholder
                                onResult={(searchResult, string) =>
                                    this.searchProduct(string)}
                                    // this.setState({machinesFound: searchResult})} // action after search
                        />
                        </div>
                    }
                    languageSelect
                >

                    {/* CREATE MACHINE BOX CARD */}
                    {/*{(user.is_admin || user.type === 'MF')?*/}
                        {/*<BoxCard variant={"add"}*/}
                                 {/*title={"+"}*/}
                                 {/*subtitle={"Aggiungi macchina"}*/}
                                 {/*onClick={() => this.setState({openCreateMachineDialog:true})}/>*/}
                        {/*:null}*/}

                    {/*/!* MACHINES LIST BOX CARDS *!/*/}
                    {/*{(machinesFound.length > 0)?*/}
                            {/*machinesFound.map((machine, index) =>*/}
                            {/*<BoxCard key={machine + '-' + index}*/}
                                     {/*title={machine.name}*/}
                                     {/*subtitle={machine.description}*/}
                                     {/*link={`machine/${machine.id}`}*/}
                                     {/*image={machine.image}*/}
                                     {/*//onClick={() => this.setState({openUpdateMachineDialog:true, activeUpdateMachine:machine, machineDialogReadOnly: true})}*/}
                                     {/*onDelete={() => {this.setState({openDeleteMachineDialog:true, activeDeleteId:machine.id});}}*/}
                                     {/*onUpdate={() => this.setState({openUpdateMachineDialog:true, activeUpdateMachine:machine, machineDialogReadOnly:false})}/>*/}
                            {/*)*/}
                        {/*:null*/}
                    {/*}*/}

                    <InfiniteScroll
                        dataLength={this.state.machinesFound.length}
                        next={() => this.getMachines()}
                        hasMore={pagination.hasMore}>
                    <ProductList products={machinesFound}
                                 models={models}
                                 baseLink={'machine'}
                                 updateProductsCreate={(data,labels) => this.addMachine(data, labels)}
                                 updateProductUpdate={(data, labels) => this.updateMachine(data, labels)}
                                 updateProductsDelete={(id) => this.removeMachine(id)}/>
                    </InfiniteScroll>

                    {/* CREATE MACHINE DIALOG */}
                    {(this.state.openCreateMachineDialog)?
                    <CreateMachineDialog title={
                        translate( `machines.create_modal.${license.license}`, {},  (license.license === 'commercial') ? "Crea prodotto" : "Crea macchina")}
                                         loader={dialogLoader}
                                         languageValues={languageValues}
                                         defaultlang={defaultlang}
                                         models={this.state.models}
                                         open={this.state.openCreateMachineDialog}
                                         onClose={() => this.setState({openCreateMachineDialog:false})}
                                         onCreate={(newMachine) => this.createMachine(newMachine)}/>
                    :null}

                    {/* UPDATE MACHINE DIALOG */}
                    {(this.state.openUpdateMachineDialog)?
                    <CreateMachineDialog title={
                        translate( `machines.edit_modal.${license.license}`, {},  (license.license === 'commercial') ? "Modifica prodotto" : "Modifica macchina")
                        }
                                         languageValues={languageValues}
                                         defaultlang={defaultlang}
                                         loader={dialogLoader}
                                         machine={this.state.activeUpdateMachine}
                                         models={this.state.models}
                                         open={this.state.openUpdateMachineDialog}
                                         readOnly={this.state.machineDialogReadOnly} // disable the edit
                                         onClose={() => this.setState({openUpdateMachineDialog:false})}
                                         onCreate={(updateMachine) => this.updateMachine(updateMachine)}/>
                    :null}

                    {/* DELETE CONFIRM DIALOG */}
                    <AlertDialog open={this.state.openDeleteMachineDialog}
                                 title={translate( `machines.delete_modal.${license.license}`, {},  (license.license === 'commercial') ?"Eliminazione prodotto" : "Eliminazione macchina")}
                                 message={
                                     translate( `machines.delete_modal_message.${license.license}`, {}, (license.license === 'commercial') ? "Sei sicuro di voler eliminare il prodotto?" : "Sei sicuro di voler eliminare la Macchina?")
                                     }
                                 confirmBtnText={
                                     translate( `machines.delete_modal_button`, {},  "Elimina")
                                     }
                                 loader={dialogLoader}
                                 onClose={() => this.setState({openDeleteMachineDialog:false})}
                                 onConfirm={() => this.deleteMachine(this.state.activeDeleteId)}/>

                    {/* LOADER */}
                    <SnackbarLoader open={loader} message={
                        translate( `machines.loader`, {},  "Elaborazione in corso")
                    }/>
                </BackofficeViewContainer>
            </div>
        )
    }
}


MachinesManager.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    license: state.license,
    utils: state.utils,
    api_control: state.api_control,
    multilanguage: state.multilanguage,
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl,
    onUpdateMultilanguage: updateMultilanguage,
};

export default compose(

    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(MachinesManager);
