import React, {Component, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../redux/actions/api-control-action';
// i18n translation

// report problem import
import ReportProblemDialog from '../../../../components/ui/dialogs/reportProblemDialog/ReportProblemDialog';
import Logo from "../../../../assets/logos/ubilod_logo-light_bg.svg";

/*import LogoProfile from "../../../../assets/logos/ubilod_marchio-dark_bg.svg";
import LogoProfileOpen from "../../../../assets/logos/ubilod logo - dark bg.svg";*/

import LogoProfile from "../../../../assets/logos/Logo-DKC-small.png";
import LogoProfileOpen from "../../../../assets/logos/Logo-DKC.png";

import Hidden from "@material-ui/core/Hidden";
import {translate} from "../../../../translations/i18n";
import {Home, Folder, Cpu, Users, Upload, Layers, RotateCcw, Info, LifeBuoy, ChevronRight} from 'react-feather'
import Button from "@material-ui/core/Button";
import {MenuList} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Utils from "../../../../utils/Utils";
import {updateLicense} from "../../../../redux/actions/license-action";
import {updateUser} from "../../../../redux/actions/user-action";
import ChangePasswordDialog from "../../../../components/ui/dialogs/changePasswordDialog/ChangePasswordDialog";
import LicenseDialog from "../../../../components/ui/dialogs/licenseDialog/LicenseDialog";
import LanguageDialog from "../../../../components/ui/dialogs/changeLanguageDialog/LanguageDialog";

const drawerWidth = 290; // if change => change also in the AppBar component

const styles  = theme => ({
    // Drawer
    '@keyframes appearAnimation': {
        from: { opacity: 0 },
        to: { opacity: 1 },
    },
    '@keyframes appearAnimation2': {
        from: { opacity: 0 },
        to: { opacity: .5 },
    },
    hidden : {opacity : '0 !important', position : 'absolute'},
    imageOpen:{
        height : '34px',
        width : 'auto',
        opacity : 1,
        transition: 'opacity .20s ease-in;'
    },
    image:{
        height : '34px',
        width : 'auto',
        opacity : 1,
        transition: 'opacity .20s ease-in;'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerPaper: {
        overflowY : 'unset',
        background: theme.palette.primary.dark,
    },
    drawerOpen: {
        width: drawerWidth,
/*
        overflowX : 'hidden',
*/
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.leavingScreen,
        }),
/*
        overflowX: 'hidden',
*/
        width: theme.spacing.unit * 11 + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 11 + 1,
        },
    },
    drawerList:{
        '& span':{
            color: theme.palette.secondary.main,
            fontWeight : 'bold',
        },
        opacity : 1,
        transition: 'opacity .20s ease-in;',
    },
    drawerListClose:{
        opacity : 0,
        transition: 'opacity .20s ease-in;',
        '& span':{
            color: theme.palette.secondary.main,
            fontWeight : 'bold',
            transition: 'none',
        }
    },
    listItemIcon: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 16,
            paddingRight: 8,
        },
    },
    drawerActivePage:{
        background: theme.palette.active.main,
        paddingTop : '12px',
        paddingBottom : '12px'
    },
    drawerItem:{
        paddingTop : '12px',
        paddingBottom : '12px',
        '&:hover':{
            background: theme.palette.primary.hover
        },
        [theme.breakpoints.down('xs')]:{
            paddingTop : '8px',
            paddingBottom : '8px',
        }
    },
    listRoot:{
        padding:0,
        position : 'initial'
    },
    toolbar: {
        background: theme.palette.primary.main,
        display: 'flex',
        height : '103px',
        alignItems: 'center',
        position : 'relative',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    icon: {
        color: '#fff',
    },
    supportContainer : {
        position: 'absolute',
        bottom: '5px',
        display : 'flex',
        flexDirection : 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    info:{
        position: 'absolute',
        top: '72vh',
    },
    support:{
        position: 'absolute',
        top: '78vh',
    },
    logo:{
        position: 'absolute',
        top: '84vh',
    },
    categoryTitle: {
        color: '#fff',
        opacity: 0.5,
        animation : '$appearAnimation2 .20s ease-in',
        transition: 'opacity .20s ease-in;',
        padding: '9px 24px',
        fontWeight: 'bold',
        [theme.breakpoints.down('xs')]: {
            padding: '9px 16px',
        },
    },
    categoryTitleClose: {
        opacity: 0,
        pointerEvents : 'none',
        transition: 'opacity .20s ease-in;',
    },
        openIcon : {
            transform : 'rotate(0deg)',
            transition: 'transform .20s ease-in;',
            color : '#000'
        },
        openIconOpen : {
            transform : 'rotate(180deg)',
            transition: 'transform .20s ease-in;',
            color : '#000'
        },
        openButton:{
            position :'absolute',
            right : -20,
            backgroundColor : '#FFF !important',
            padding : 4,
            border : '1px solid rgba(0,0,0,0.3)'
        },
        whiteSpace: {
            minHeight : 38,
            display : 'flex',
            justifyContent : 'center',
            alignItems : 'center',
            animation : '$appearAnimation .20s ease-in',
        },
    licenseTitle: {
        marginRight: 20,
        color:'#fff!important',
        minWidth: 112,
        padding : '0px 8px'
    },
    name:{
        color:'#fff! important',
        marginRight: 20,
        padding : '0px 8px',
        fontWeight : '700',
        fontSize : 14
    },
    avatar: {
        cursor:'pointer',
        // marginRight:20,
        background: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]:{
            // marginRight:12,
        }
    },
    mobileMenu:{
        display : 'flex',
        justifyContent : 'flex-start',
        alignItems : 'center',
        width : '100%',
        padding : '0px 8px',
        maxHeight : 40
    },
    label:{
        justifyContent : 'flex-start'
    }

});

class HeaderDrawerBackup extends Component {

    constructor(props){
        super(props);
        this.state = {
            anchorSupportEl: null,
            anchorInfoEl: null,
            anchorLicenseEl: null,
            anchorProfileEl: null,
        };
    }

    updateLicense = (license_code) => {
        this.setState({anchorLicenseEl: null});
        const license = this.props.user.company.licenses.find((item) => {return item.license === license_code});
        if (license){
            this.props.onUpdateLicense(license);
        }
    };


    /* ============================================== */
    /* SUPPORT MENU CLICK
    /* ============================================== */
    handleSupportClick = event => {
        this.setState({ anchorSupportEl: event.currentTarget })
    };

    handleSupportClose = () => {
        this.setState({ anchorSupportEl: null });
    };

    /* ============================================== */
    /* INFO MENU CLICK
    /* ============================================== */
    handleInfoClick = event => {
        this.setState({ anchorInfoEl: event.currentTarget })
    };

    handleInfoClose = () => {
        this.setState({ anchorInfoEl: null });
    };

    /* ============================================== */
    /* CHECK IS ACTIVE PAGE TO COLOR BACKGROUND
    /* ============================================== */
    isActivePage(page){
        if(this.props.router.location){
            if(this.props.router.location.pathname !== '/') {
                let activePage = this.props.router.location.pathname.split('/')[1];
                if (page.join(" ").indexOf(activePage) > -1) {
                    return this.props.classes.drawerActivePage;
                } else {
                    return this.props.classes.drawerItem;
                }
            }else{
                if (page.indexOf('/dashboard') > -1) {
                    return this.props.classes.drawerActivePage;
                }
            }
        }
    }

    render() {
        const { classes, theme, user, license, intl, open } = this.props;
        const { anchorSupportEl, anchorInfoEl, anchorLicenseEl,anchorProfileEl} = this.state;
        const anchorRef = React.createRef();
        const licenses = user.company && user.company.licenses.map((item) => (
            (item.license !== license.license)?
                <Link to={'/dashboard'}>
                    <MenuItem className={classes.licenseMenuItem}
                              onClick={() => this.updateLicense(item.license)}>
                        {item.license.toUpperCase()}
                    </MenuItem>
                </Link>
                :null
        ));

        return (
            <div className={classes.root}>
                <CssBaseline />
                <Drawer
                    //transitionDuration={1000}
                    containerStyle={{backgroundColor: 'black'}}
                    // onMouseOver={() => (window.screen.width>768)?this.props.handleDrawer(true):null}
                    // onMouseOut={() => (window.screen.width>768)?this.props.handleDrawer(false):null}
                    // onClose={() => this.props.handleDrawer(false)}
                    variant={(window.screen.width > 599)?"permanent":"temporary"}
                    className={classNames(classes.drawer, {
                        [classes.drawerOpen]: this.props.open,
                        [classes.drawerClose]: !this.props.open,
                    })}
                    classes={{
                        paper: classNames(classes.drawerPaper,{
                            [classes.drawerOpen]: this.props.open,
                            [classes.drawerClose]: !this.props.open,
                        }),
                    }}
                    open={this.props.open}>
                    <div className={classes.toolbar}>

                        <Hidden xsDown>


                        <img alt={'ubilod-logo'} src={LogoProfileOpen} className={
                                classNames(classes.imageOpen, {
                                    [classes.hidden] : !open
                                })
                            }/>

                            <img alt={'ubilod-logo'} src={LogoProfile} className={
                                classNames(classes.image, {
                                    [classes.hidden] : open
                                })
                            }
                            />

                        </Hidden>

                        <Hidden smUp>
                            <div className={classes.mobileMenu}>

                                {/* ================ Profile Menu ================ */}
                                <Avatar className={classNames(classes.avatar, {
                                    [classes.avatarShift]: this.props.open,
                                })}

                                        aria-owns={anchorProfileEl ? 'profile-menu' : undefined}
                                        aria-haspopup="true"
                                        onClick={(e) => this.setState({ anchorProfileEl: e.currentTarget })}>
                                    {Utils.getUsernameShort(this.props.user.username)}
                                </Avatar>

                                <div style={{display : 'flex', flexDirection : 'column', marginLeft : 20 }}>

                                    <Typography className={classes.name}>
                                        {user.first_name} {user.last_name}
                                    </Typography>

                                {(license.license) ?
                                    <Button onClick={(e) => user.company.licenses.length > 1 && this.setState({ anchorLicenseEl: e.currentTarget })}
                                            disabled={user.company.licenses.length < 2}
                                            ref={anchorRef}
                                            classes={{
                                                label : classes.label
                                            }}
                                            className={classes.licenseTitle}>
                                        {license.license}
                                        {(user.company.licenses.length > 1) ?
                                            <Icon style={{color:'#fff', fontSize:16}}>keyboard_arrow_down</Icon>
                                            : null}
                                    </Button>
                                    : <Button disabled={true}
                                              ref={anchorRef}
                                              className={classes.licenseTitle}>
                                        Basic
                                    </Button>
                                }
                                </div>

                                <Menu
                                    id="license-menu"
                                    anchorEl={anchorLicenseEl}
                                    open={Boolean(anchorLicenseEl)}
                                    onClose={() => this.setState({anchorLicenseEl: null})}>
                                    <MenuList onClick={() => this.setState({anchorLicenseEl:undefined})}>
                                        {licenses}
                                    </MenuList>
                                </Menu>


                                <Menu
                                    id="profile-menu"
                                    anchorEl={anchorProfileEl}
                                    open={Boolean(anchorProfileEl)}
                                    onClose={() => this.setState({ anchorProfileEl: null })}>
                                    <Typography color="inherit" style={{fontWeight:'bold', padding:'0 20px',marginTop:12}}>Account</Typography>
                                    <Typography color="inherit" className={classes.username} style={{textAlign:'start', padding:'12px 20px', width:'100%'}}>
                                        {this.props.user.username}
                                    </Typography>
                                    <Divider/>
                                    {
                                        (this.props.user.company && license.license) ? (
                                            <MenuItem onClick={() => this.setState({openLicenseDialog:true})}
                                                      onClose={() => this.setState({openLicenseDialog:false})}>
                                                <Icon color="primary" style={{fontSize:18, marginRight:4}}>how_to_reg</Icon>

                                                {translate(`info.license`,{},'Licenza')}
                                            </MenuItem>
                                        ) : null
                                    }

                                    <MenuItem onClick={() => {this.setState({openLanguageDialog:true})}}>
                                        <Icon color="primary" style={{fontSize:18, marginRight:4}}>language</Icon>
                                        {translate(`info.language`,{},'Cambia lingua')}
                                    </MenuItem>

                                    <MenuItem onClick={() => {this.setState({openChangePasswordDialog:true})}}>
                                        <Icon color="primary" style={{fontSize:18, marginRight:4}}>lock</Icon>
                                        {translate(`info.password`,{},'Cambia password')}
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        this.props.onUpdateUser({});
                                        this.props.onUpdateLicense({});
                                        window.location = '/';
                                    }}>
                                        <Icon color="primary" style={{fontSize:18, marginRight:4}}>power_settings_new</Icon>
                                        {translate(`info.exit`,{},'Esci')}
                                    </MenuItem>
                                </Menu>


                            </div>
                        </Hidden>



                        <IconButton
                            className={classes.openButton}
                            onClick={() => this.props.handleDrawer(!open)} color="secondary">
                            <ChevronRight
                                size={20}
                                className={classNames({
                                    [classes.openIconOpen] : this.props.open,
                                    [classes.openIcon] : !this.props.open
                                })}
                            />
                        </IconButton>
                    </div>
                    <Divider/>
                        <Typography className={classNames(classes.categoryTitle, {
                                [classes.categoryTitleClose] : user.type !== 'MF'|| !this.props.open
                            })}>
                            {translate( `drawer.manage`, {},  'Organizza')}</Typography>
                        <Divider/>

                    <List className={classes.listRoot}>
                        {(user.type === 'MF') ?
                            <Link to={`/dashboard`} style={{display : 'flex'}} onClick={() => this.props.handleDrawer(false)}>
                                <ListItem className={this.isActivePage(['/','/dashboard'])}>
                                    {(!this.props.open) ?
                                        <Tooltip style={{fontSize: 14}} arrow placement="right" title={<Typography>{translate(`drawer.dashboard`, {}, 'Dashboard')}</Typography>}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <Home className={classes.icon}/>
                                            </ListItemIcon>
                                        </Tooltip>
                                        :
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <Home className={classes.icon}/>
                                            </ListItemIcon>
                                    }
                                    <ListItemText className={
                                        classNames({
                                            [classes.drawerList]: this.props.open,
                                            [classes.drawerListClose]: !this.props.open,
                                        })
                                    } primary={translate( `drawer.dashboard`, {},  'Dashboard')} />
                                </ListItem>
                            </Link>
                            : null
                        }
                        <Divider/>
                        {(user.type === 'MF') ?
                            <Link to={`/model`}  style={{display : 'flex'}} onClick={() => this.props.handleDrawer(false)}>
                                <ListItem className={this.isActivePage(['/model','/models'])}>
                                    {(!this.props.open) ?
                                        <Tooltip style={{fontSize: 14}} arrow placement="right" title={<Typography>{translate( `drawer.model.${license.license}`, {},  (license.license === 'commercial') ?'Linee prodotto':'Modelli')}</Typography>}>
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <Layers className={classes.icon}/>
                                        </ListItemIcon>
                                        </Tooltip>
                                        :
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <Layers className={classes.icon}/>
                                        </ListItemIcon>
                                    }
                                    <ListItemText className={classNames({
                                        [classes.drawerList]: this.props.open,
                                        [classes.drawerListClose]: !this.props.open,
                                    })} primary={translate( `drawer.model.${license.license}`, {},  (license.license === 'commercial') ?'Linee prodotto':'Modelli')} />
                                </ListItem>
                            </Link>
                            : null
                        }
                        <Divider/>

                        {(user.type === 'MF') ?
                            <Link to={`/category`} style={{display : 'flex'}} onClick={() => this.props.handleDrawer(false)}>
                                <ListItem className={this.isActivePage(['/category','/categories'])}>
                                    {(!this.props.open) ?
                                        <Tooltip style={{fontSize: 14}} arrow placement="right" title={<Typography>{translate( `drawer.document`, {},  'Categorie documenti')}</Typography>}>
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <Folder className={classes.icon}/>
                                        </ListItemIcon>
                                        </Tooltip>
                                        :
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <Folder className={classes.icon}/>
                                        </ListItemIcon>
                                    }
                                    <ListItemText className={classNames({
                                        [classes.drawerList]: this.props.open,
                                        [classes.drawerListClose]: !this.props.open,
                                    })} primary={translate( `drawer.document`, {},  'Categorie documenti')} />
                                </ListItem>
                            </Link>
                            : null
                        }

                        <Divider/>
                        {(user.type === 'MF') ?
                            <Link to={`/machine`} style={{display : 'flex'}} onClick={() => this.props.handleDrawer(false)}>
                                <ListItem className={this.isActivePage(['/machine','/machines'])}>
                                    {(!this.props.open) ?
                                        <Tooltip style={{fontSize: 14}} arrow placement="right" title={<Typography>{translate( `drawer.machine.${license.license}`, {},  (license.license === 'commercial') ?'Prodotti':'Macchine')}</Typography>}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <Cpu className={classes.icon}/>
                                            </ListItemIcon>
                                        </Tooltip>
                                        :
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <Cpu className={classes.icon}/>
                                        </ListItemIcon>
                                    }
                                    <ListItemText className={classNames({
                                        [classes.drawerList]: this.props.open,
                                        [classes.drawerListClose]: !this.props.open,
                                    })} primary={translate( `drawer.machine.${license.license}`, {},  (license.license === 'commercial') ?'Prodotti':'Macchine')} />
                                </ListItem>
                            </Link>
                            :
                            <Link to={`/machine`} style={{display : 'flex'}} onClick={() => this.props.handleDrawer(false)}>
                                <ListItem className={this.isActivePage(['/machine','/machines'])}>
                                    {(!this.props.open) ?
                                        <Tooltip style={{fontSize: 14}} arrow placement="right" title={<Typography>{translate( `drawer.shared_machine`, {},  'Macchine Condivise')}</Typography>}>
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <Cpu className={classes.icon}/>
                                        </ListItemIcon>
                                        </Tooltip>
                                     :
                                         <ListItemIcon className={classes.listItemIcon}>
                                            <Cpu className={classes.icon}/>
                                        </ListItemIcon>
                                    }
                                    <ListItemText className={classNames({
                                        [classes.drawerList]: this.props.open,
                                        [classes.drawerListClose]: !this.props.open,
                                    })} primary={translate( `drawer.shared_machine`, {},  'Macchine Condivise')} />
                                </ListItem>
                            </Link>
                        }


                        <Divider/>
                        {(user.type === 'MF') ?
                            <Link to={`/contact`} style={{display : 'flex'}} onClick={() => this.props.handleDrawer(false)}>
                                <ListItem className={this.isActivePage(['contact','/contacts'])}>
                                    {(!this.props.open) ?
                                        <Tooltip style={{fontSize: 14}} arrow placement="right" title={<Typography>{translate( `drawer.contacts`, {},  'Contatti')}</Typography>}>
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <Users className={classes.icon}/>
                                        </ListItemIcon>
                                        </Tooltip>
                                     :
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <Users className={classes.icon}/>
                                        </ListItemIcon>
                                    }
                                    <ListItemText className={classNames({
                                        [classes.drawerList]: this.props.open,
                                        [classes.drawerListClose]: !this.props.open,
                                    })} primary={translate( `drawer.contacts`, {},  'Contatti')} />
                                </ListItem>
                            </Link>
                            : null
                        }

                        <Divider/>

                        {(user.type === 'MF' && this.props.open) ?
                            <>
                            <Typography className = {classes.categoryTitle} > {translate( `drawer.operate`, {},  'Gestisci')} </Typography>
                                <Divider/>
                            </>
                            :
                            <>
                                <div className={classes.whiteSpace}>
                                    <div style={{width : '60%', height : 1, backgroundColor : '#fff'}}/>
                                </div>
                            <Divider/>
                            </>
                        }
                        {/*{!this.props.open && <div style={{heigth : 32}}/>}*/}

                        {(user.type === 'MF') ?
                            <Link to={`/bulk_import`} onClick={() => this.props.handleDrawer(false)}>
                                <ListItem className={this.isActivePage(['/bulk_import'])}>
                                    {(!this.props.open) ?
                                        <Tooltip style={{fontSize: 14}} arrow placement="right" title={<Typography>{translate( `drawer.bulk_import`, {},  'Importa Link')}</Typography>}>
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <Upload className={classes.icon}/>
                                        </ListItemIcon>
                                        </Tooltip>
                                        :
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <Upload className={classes.icon}/>
                                        </ListItemIcon>
                                    }
                                    <ListItemText className={classNames({
                                        [classes.drawerList]: this.props.open,
                                        [classes.drawerListClose]: !this.props.open,
                                    })} primary={translate( `drawer.bulk_import`, {},  'Importa Link')} />
                                </ListItem>
                            </Link>
                            : null
                        }
                        <Divider/>

                        {(user.type === 'MF') ?
                            <Link to={`/restore`} onClick={() => this.props.handleDrawer(false)}>
                                <ListItem className={this.isActivePage(['/restore'])}>
                                    {(!this.props.open) ?
                                        <Tooltip style={{fontSize: 14}} arrow placement="right" title={<Typography>{translate( `drawer.restore`, {},  'Punti di ripristino')}</Typography>}>
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <RotateCcw className={classes.icon}/>
                                        </ListItemIcon>
                                        </Tooltip>
                                     :
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <RotateCcw className={classes.icon}/>
                                        </ListItemIcon>
                                    }
                                    <ListItemText className={classNames({
                                        [classes.drawerList]: this.props.open,
                                        [classes.drawerListClose]: !this.props.open,
                                    })} primary={translate( `drawer.restore`, {},  'Punti di ripristino')} />
                                </ListItem>
                            </Link>
                            : null
                        }

                        <Divider/>
                        {/* Info menu */}

                        <div className={classes.supportContainer}>

                        <ListItem button
                                  aria-haspopup="true"
                                  aria-owns={anchorInfoEl ? 'info-menu' : undefined}
                                  onClick={this.handleInfoClick}
                                  style={{paddingTop : 12, paddingBottom : 12}}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <Info className={classes.icon}/>
                            </ListItemIcon>
                            <ListItemText className={classNames({
                                [classes.drawerList]: this.props.open,
                                [classes.drawerListClose]: !this.props.open,
                            })} primary={translate( `drawer.info`, {},  'Info')} />
                        </ListItem>

                        {/* Support menu */}
                        <ListItem button
                                  aria-haspopup="true"
                                  aria-owns={anchorInfoEl ? 'support-menu' : undefined}
                                  onClick={this.handleSupportClick}
                                  style={{paddingTop : 12, paddingBottom : 12}}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <LifeBuoy className={classes.icon}/>
                            </ListItemIcon>
                            <ListItemText className={classNames({
                                [classes.drawerList]: this.props.open,
                                [classes.drawerListClose]: !this.props.open,
                            })} primary={translate( `drawer.support`, {},  'Supporto')} />
                        </ListItem>

                        {/*<ListItem button
                                  aria-haspopup="true"
                                  style={{paddingTop : 12, paddingBottom : 12}}
                        >
                            <Link to={'/'} style={{marginLeft : window.innerWidth <= 480 ?  0 :  8, display : 'flex', alignItems : 'end'}}>
                                <img alt={'ubilod-logo'} src={LogoProfile} width={26} style={{marginTop:4, marginRight : window.innerWidth <= 480 ? 32 : 28}} />
                                <ListItemText className={classNames({
                                    [classes.drawerList]: this.props.open,
                                    [classes.drawerListClose]: !this.props.open,
                                })} primary={'Ubilod'} />
                            </Link>
                        </ListItem>*/}
                        </div>

                        <Menu
                            id="support-menu"
                            anchorEl={anchorSupportEl}
                            open={Boolean(anchorSupportEl)}
                            onClose={this.handleSupportClose}>
                            <MenuItem onClick={() => this.setState({openReportProblemDialog:true, anchorSupportEl:undefined})}>
                                {translate( `drawer.problem`, {},  'Segnala un problema')}
                            </MenuItem>
                        </Menu>

                        <Menu
                            id="info-menu"
                            anchorEl={anchorInfoEl}
                            open={Boolean(anchorInfoEl)}
                            className={classes.infoMenu}
                            onClose={this.handleInfoClose}>
                            <Link to="/terms-and-conditions" target="_blank" style={{color:'#000'}}>
                                <MenuItem>
                                    {translate( `drawer.terms_and_conditions`, {},  'Termini e condizioni')}
                                </MenuItem>
                            </Link>
                            <Link to="/privacy-and-cookie-policy" target="_blank" style={{color:'#000'}}>
                                <MenuItem>
                                    {translate( `drawer.privacy_and_cookie_policy`, {},  'Privacy & Cookie Policy')}
                                </MenuItem>
                            </Link>
                            <Link to="/acceptable-usage" target="_blank" style={{color:'#000'}}>
                                <MenuItem>
                                    {translate( `drawer.acceptable_usage`, {},  'Uso accettabile')}
                                </MenuItem>
                            </Link>
                            <Link to="/regulation-on-intellectual-property" target="_blank" style={{color:'#000'}}>
                                <MenuItem>
                                    {translate( `drawer.regulation_on_intellectual_property`, {},  'Norme sulla proprietá intelletuale')}
                                </MenuItem>
                            </Link>
                        </Menu>
                    </List>
                </Drawer>

                {/* Report problem dialog*/}
                <ReportProblemDialog open={this.state.openReportProblemDialog}
                                     onClose={() => this.setState({openReportProblemDialog:false})}/>

                {(this.state.openChangePasswordDialog)?
                    <ChangePasswordDialog onClose={() => this.setState({openChangePasswordDialog:false})}/>
                    :null}

                {/* LICENSE DIALOG */}
                {(this.state.openLicenseDialog) ?
                    <LicenseDialog open={true}
                                   onClose={() => this.setState({openLicenseDialog:false})}/>
                    :null}

                {this.state.openLanguageDialog &&
                    <LanguageDialog
                        title={
                            translate(`info.dialog.title`,{},'Cambia lingua interfaccia')
                        }
                        open={true}
                        confirmBtnText={
                            translate(`info.dialog.confirm`,{},'Conferma')
                        }
                        onClose={() => this.setState({openLanguageDialog:false})}/>
                }
            </div>
        )
    }
}

HeaderDrawerBackup.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    api_control: state.api_control,
    router: state.router,
    license: state.license,
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl,
    onUpdateLicense : updateLicense,
    onUpdateUser : updateUser
}

export default compose(

    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(HeaderDrawerBackup);
