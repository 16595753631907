import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
//material-ui imports
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../../../redux/actions/api-control-action';
// translation import
import LanguageApi from "../../../../../../components/language-select/LanguageApi";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Remove from '@material-ui/icons/Remove';
import {translate} from "../../../../../../translations/i18n";

const styles = theme => ({
    dialogPaper: {
        [theme.breakpoints.down('xs')]:{
            margin: 10,
        },
    },
    textField: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        width: '100%',
    },
    languageContainer:{
        minWidth : 500,
        margin : '28px 0px',
        maxHeight : 400,
        overflowY : 'auto'
    },
    language:{
        display : 'flex',
        justifyContent : 'flex-start',
        alignItems : 'center',
        margin : '16px 0px',
        borderTop : '1px solid rgba(44,58,73,0.6)',
        '&:last-child':{
            borderBottom : '1px solid rgba(44,58,73,0.6)',
        }
    },
    valueContainer:{
        display : 'flex',
        justifyContent: 'start',
        marginRight : 8,
        padding : '10px'
    },
    optionLabel:{
        textTransform : 'uppercase',
        marginTop : 6,
        margin : '0px 0px 0px 8px',
        color :'#000',
        fontSize: 16,
        fontWeight: 600,
    },
});


class ChangeLanguageDialog extends Component {

    constructor(props){
        super(props);
        this.state={

            languageValue: [],
            languageValues: [],
            values : [],
            languageValuesRaw : [],
        }
    }


    componentDidMount(){



        LanguageApi.getFlags().then(
            res => {
                this.setState({values : res.data.content.items})

                let tmpLanguageValues = [];

                this.props.selected.map(item =>(
                    item.flags.map(flags =>
                        (typeof flags === 'number') ?
                            tmpLanguageValues.push(res.data.content.items[res.data.content.items.findIndex(item => item.id === flags)])
                            :
                            tmpLanguageValues.push(flags)
                    )
                ));

                let tmpLanguageValuesFiltered = tmpLanguageValues.filter((val, indx, array) => {
                    return array.findIndex(valLang => valLang.id === val.id) == indx ;
                });

                tmpLanguageValuesFiltered = tmpLanguageValuesFiltered.map(item => (
                    {
                        ...item,
                        value : tmpLanguageValues.filter(multiple_item => multiple_item.id === item.id).length === this.props.selected.length ||  this.props.selected.length === 1 ? 'multiple' : 'single'
                    }
                ));

                this.setState({languageValues : [...tmpLanguageValuesFiltered], languageValue : [...tmpLanguageValuesFiltered], languageValuesRaw : [...tmpLanguageValues]})

            },
            err => {

            }
        )

    }


    handleChangeMultiple = (value) => {

        const { id } = value;

        let tmpValue =  this.state.languageValue

        let tmpI = tmpValue.findIndex(item => item.id === id)

        if (tmpI !== -1)
            tmpValue[tmpI].value = (tmpValue[tmpI].value === 'multiple' || tmpValue[tmpI].value === 'single') ?  'removed' : 'multiple';
        else
            tmpValue.push({...value , value : 'multiple'})

        this.setState({languageValue: tmpValue});
    };

    handleSubmit(){
        const { languageValue } = this.state;

        let tmpFlag = languageValue.map(item => item.id);

        this.props.onSubmit(languageValue , tmpFlag)
    }


    render() {
        const { classes, multiple, loading, contentText, selected } = this.props;
        const { languageValues, languageValue, values } = this.state;

        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.onClose}
                    PaperProps={{ classes: {root: classes.dialogPaper } }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" style={{padding : '16px 24px 0px 16px'}} className={classes.title}>{this.props.title}</DialogTitle>
                    <div className={classes.container}>

                        <DialogContent>

                            <DialogContentText id="alert-dialog-description">
                                <Typography>
                                    {selected.length} selezionati
                                </Typography>
                            </DialogContentText>

                            <div className={classes.languageContainer}>
                                {
                                    values.map(item =>
                                            <div className={classes.language}>
                                                <Checkbox
                                                    checked={languageValue.findIndex(lngvalue => lngvalue.id === item.id) !== -1}
                                                    color="primary"
                                                    checkedIcon={
                                                        (languageValue.findIndex(lngvalue => lngvalue.id === item.id) !== -1 && languageValue[languageValue.findIndex(lngvalue => lngvalue.id === item.id)].value === 'multiple') ?
                                                            <CheckBoxIcon/>
                                                            :
                                                            (languageValue.findIndex(lngvalue => lngvalue.id === item.id) !== -1 && languageValue[languageValue.findIndex(lngvalue => lngvalue.id === item.id)].value === 'removed') ?
                                                                <CheckBoxOutlineBlankIcon/>
                                                                :
                                                                <div style={{
                                                                    display: 'flex',
                                                                    maxWidth : 17.76,
                                                                    maxHeight : 17.76,
                                                                    alignItems: 'center',
                                                                    border: '2px solid #2C3A49',
                                                                    borderRadius: 4,
                                                                    background: '#2C3A49',
                                                                    marginLeft : 2,
                                                                    marginRight : 1
                                                                }}
                                                                >
                                                                    <Remove style={{color : '#fff', fontSize : 13}} />
                                                                </div>

                                                }
                                                style={{padding: '0px 4px', marginRight : 18}}
                                                onChange={() => this.handleChangeMultiple(item)}
                                            />
                                            <div className={classes.valueContainer}>
                                                <img src={item.src}
                                                     style={{height : 18,margin: '4px 10px 4px 8px'}} width={28}
                                                />
                                                <Typography className={classes.optionLabel}
                                                            style={{textTransform: 'uppercase'}}>
                                                    {item.code}
                                                </Typography>
                                            </div>
                                        </div>

                                    )
                                }
                            </div>


                        </DialogContent>

                        <DialogActions>
                            <Button disabled={loading} onClick={this.props.onClose}>
                                {translate( `general.cancel`, {},  "Annulla")}
                            </Button>
                                <Button
                                    onClick={() => this.handleSubmit()}
                                    disabled={loading} variant="outlined" color="primary">
                                    {(!loading)?
                                        'Modifica'
                                        :
                                        <CircularProgress className={classes.loader} size={16} style={{marginLeft:0}}/>
                                    }
                                </Button>
                        </DialogActions>
                    </div>
                </Dialog>
            </div>

        )
    }
}

ChangeLanguageDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    element: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    api_control: state.api_control,
    router: state.router,
});

const mapActionsToProps = {
    onUpdateApiControl : updateApiControl
}

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(ChangeLanguageDialog);
