import React, {useEffect, useState} from "react";
import {IconButton, Typography} from '@material-ui/core'
import {Download, Info} from 'react-feather'
import moment from 'moment';
import BulkAPI from "../../../../../services/BulkAPI";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {translate} from "../../../../../translations/i18n";

function InfoHandler(props) {

    const {onClick = () => null} = props

    return(
        <IconButton onClick={() => onClick()} style={{color: '#000', padding: '0px', marginLeft: 24}} >
            <Info/>
        </IconButton>
    )
}

const fields = (props) => [
    {
        key: '',
        label : '',
        render: (values) => {

            function handleOpen(id){
                BulkAPI.getLogsDownload(id).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${values.filename.split('.')[0]}-ID${id}.xlsx`); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        props.setSelected(null)
                    },
                    err => {

                    }
                )
            }

            return (
                <>
                    <IconButton
                        onClick={() => {
                            handleOpen(values.id, values);
                            props.setSelected(values.id)
                        }}
                        style={{color: '#000', padding: '0px'}}>
                        {props.selected ===  values.id? <CircularProgress size={16}/> : <Download/>}
                    </IconButton>
                </>
            )
        }
    },
    {
        key: 'filename',
        label: translate('restore.column.file', {}, 'Nome file'),
    },
    {
        key: 'id',
        label : 'ID',
    },
    {
        key: 'created_at',
        label: translate('restore.column.date', {}, 'Data di aggiunta'),
        render: (data) => <Typography style={{fontSize : 14, color : '#000', whiteSpace : 'nowrap',maxWidth : 200, overflow : 'hidden', textOverflow: 'ellipsis'}}>{moment(data.created_at).locale('it').format('L LT')}</Typography>
    },
    {
        key: 'updated_at',
        label: translate('restore.column.updatedDate', {}, 'Data ultima modifica'),
        render: (data) => <Typography style={{fontSize : 14, color : '#000', whiteSpace : 'nowrap',maxWidth : 200, overflow : 'hidden', textOverflow: 'ellipsis'}}>{moment(data.updated_at).locale('it').format('L LT')}</Typography>
    },
    {
        key: 'status',
        label: translate('restore.column.status', {}, 'Status'),
    },
    {
        key: 'message',
        label: translate('restore.column.notes', {}, 'Note'),
        render: (data) => <Typography style={{fontSize : 14, color : '#000', whiteSpace : 'nowrap',maxWidth : 200, overflow : 'hidden', textOverflow: 'ellipsis'}}>{data.message}</Typography>
    },
];
export default fields;

