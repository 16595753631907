import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import HeaderAppBar from './HeaderAppBar';
import HeaderDrawer from './HeaderDrawer';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../redux/actions/api-control-action';
import Drawer from "@material-ui/core/Drawer/Drawer";
// i18n translation

const styles  = (theme) => ({
    root : {
        position : 'absolute',
        top : 0,
        zIndex : theme.zIndex.drawer,
        left : 0
    }
});

class HeaderRoot extends Component {

    constructor(props){
        super(props);
        this.state = {
            open: false,
        };
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount(){

        if (window.screen.width > 1199)
            this.setState({open : true});


        // Bind the event listener
        if (window.screen.width > 599)
        document.addEventListener("mousedown", this.handleClickOutside);
    }


    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }


    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            setTimeout(() => {this.setState({open : false});}, 100)
        }
    }


    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}
                 ref={this.wrapperRef}
                 style={{maxHeight : '100vh', overflowX : 'hidden'}}>
                <CssBaseline />
                {/* AppBar*/}
                {(window.location.pathname !== '/m') &&

                    <HeaderAppBar handleDrawer={() => this.setState({ open: !this.state.open })}
                              open={this.state.open}/>}
                {/* Drawer*/}
                {(window.location.pathname !== '/m') &&

                    <HeaderDrawer handleDrawer={(state) => this.setState({open: state})}
                                  open={this.state.open}/>
                }

            </div>
        )
    }
}

HeaderRoot.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    api_control: state.api_control,
    router: state.router,
});

const mapActionsToProps = {
    onUpdateApiControl : updateApiControl
};

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(HeaderRoot);
