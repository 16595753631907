import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles(theme => ({
    root : {
        width : '100%',
        borderRadius : 20,
        border: '1px solid #CAD2DA',
        flexDirection : 'column',
        '&:last-child':{
            margin: '24px 0px',
        },
        '&:first-child':{
            margin: '24px 0px',
        }
    },
    container : {
        padding : 30,
        display : 'flex',
        justifyContent : 'flex-start',
    },
    iconContainer:{
        borderRadius: 5,
        padding: 12,
        display: 'flex',
        justifyContent: 'center',
        alignItems : 'center',
        marginRight : 20
    },
    textContainer:{
      display : 'flex',
      flexDirection : 'column',
      justifyContent :'center'
    },
    value : {
        fontSize : 28,
        fontWeight : '500',
        lineHeight : 1.2
    },
    text : {
        fontSize: 16,
        color : '#000',
        opacity : 0.4
    }

}));

function DashCard (props){

    const classes = useStyles(props);
    const { color , icon, text = '123', value = '', children } = props;

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <div className={classes.iconContainer} style={{backgroundColor : color}}>
                    {icon}
                </div>
                <div className={classes.textContainer}>
                    {value && <Typography className={classes.value}>{value}</Typography>}
                    <Typography className={classes.text}>{text}</Typography>
                </div>
            </div>
            {children}
        </div>
    );
}

export default DashCard;
