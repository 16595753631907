import React from 'react'
import PropTypes from 'prop-types'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import { withStyles } from '@material-ui/core/styles'
// icons
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import {CheckCircle, XCircle, AlertTriangle, Lock, Info, LifeBuoy, HelpCircle} from 'react-feather'
import Typography from "@material-ui/core/Typography";
import ReportProblemDialog from "../../ui/dialogs/reportProblemDialog/ReportProblemDialog";
import TableCell from "@material-ui/core/TableCell/TableCell";

const variantIcon = {
    success: CheckCircle,
    warning: AlertTriangle,
    error: XCircle,
    info: Info,
    forbidden: Lock
};

const styles1 = theme => ({
    success: {
        borderLeft: '8px solid' + green[600]
    },
    error: {
        borderLeft: '8px solid' + theme.palette.error.dark
    },
    info: {
        borderLeft: '8px solid' + theme.palette.primary.dark
    },
    warning: {
        borderLeft: '8px solid' + amber[700]
    },
    forbidden: {
        background: '#313131'
    },
    icon: {
        opacity: 0.9,
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: 14
    },
    message: {
        display: 'flex',
        alignItems: 'center'
    },
    root : {
        right: 0,
        minWidth: 400,
        bottom: 0,
        /* height: 50px; */
        display: 'flex',
        position: 'fixed',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#2c3a49',
        borderRadius: 8,
        margin: 40,
        /* border-top-right-radius: 8px; */
        // padding: '16px 30px'
        [theme.breakpoints.down('xs')]:{
            minWidth: 'unset',
            right: 'unset',
        }
    }
});

function SnackbarMessageContent(props) {
    const { classes, className, message, onClose, variant, onOpen, ...other } = props
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={[classes[variant], classes.root, className].join(' ')}
            aria-describedby='client-snackbar'
            message={
                <span id='client-snackbar' className={classes.message}>
          {/*<Icon className={[classes.icon, classes.iconVariant].join(' ')} />*/}
                      <Typography style={{fontSize : 16, marginRight : 12, color : '#fff'}}>
                    {message}
                      </Typography>
        </span>
            }
            action={[
                (variant === 'error' || variant === 'warning') &&
                <Tooltip title="Segnala un problema">
                <IconButton
                    key='close'
                    aria-label='Close'
                    color='inherit'
                    className={classes.close}
                    onClick={onOpen}>
                    <HelpCircle className={classes.icon} />
                </IconButton>
                </Tooltip>
            ]}
            {...other}
        />
    )
}

SnackbarMessageContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info', 'forbidden']).isRequired
}

const SnackbarMessageContentWrapper = withStyles(styles1)(SnackbarMessageContent);

const styles2 = theme => ({
    margin: {
        margin: theme.spacing.unit
    }
});

class SnackBarApiMessage extends React.Component {


    constructor(props){
        super(props);
        this.state = {
            openReportProblemDialog : false
        }
    }

    render() {
        return (
            <div>
                <Snackbar
                    style={{
                        zIndex: 9999,
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    open={this.props.open}
                    autoHideDuration={this.props.autoHideDuration}
                    onClose={this.props.onClose}>
                    <SnackbarMessageContentWrapper
                        onClose={this.props.onClose}
                        variant={this.props.variant}
                        onOpen={this.props.onOpen}
                        message={this.props.message}/>
                </Snackbar>
            </div>
        )
    }
}

SnackBarApiMessage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles2)(SnackBarApiMessage)
