import React, {Component} from 'react';
import PropTypes from 'prop-types';
// material-ui import
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
// redux
import { compose } from 'redux'
import {translate} from "../../../translations/i18n";

const styles = () => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    formHelperText: {},
});

class TextFieldControlCharacters extends Component {

    render(){
        const { classes } = this.props;
        const { element, length } = this.props;

        return (
            <div className={classes.root}>
                <FormHelperText className={classes.formHelperText}>
                    {translate('general.char_left', {}, 'Caratteri disponibili')} {(element)?length-element.length:length}
                </FormHelperText>
            </div>
        );
    }
}

TextFieldControlCharacters.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    element: PropTypes.object.isRequired,
    length: PropTypes.number.isRequired,
};

export default compose(
    withStyles(styles, {withTheme: true}),
)(TextFieldControlCharacters);




