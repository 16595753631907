import React from 'react';
//import material-ui
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    deleteButton: {
        color: theme.palette.error.main,
    },
    title:{
        '& h2':{
            fontSize:28
        }
    },
    text:{
            fontSize:16
    }
});

class AlertDialog extends React.Component {
    render() {
        const { classes } = this.props;
        const { cancelBtnText = "Annulla", confirmBtnText, loader } = this.props;

        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" className={classes.title}>{this.props.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText className={classes.text} id="alert-dialog-description">
                            {this.props.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.onClose} disabled={loader}>
                            {cancelBtnText}
                        </Button>
                        <Button onClick={this.props.onConfirm}
                                className={classes.deleteButton}
                                autoFocus>
                            {(!loader)?
                                confirmBtnText
                                :
                                <CircularProgress size={16} style={{marginLeft:0}}/>
                            }
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

AlertDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    loader: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    cancelBtnText: PropTypes.string,
    confirmBtnText: PropTypes.string.isRequired,
};

export default withStyles(styles, {withTheme: true})(AlertDialog);
