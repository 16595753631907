import API from './API';

class BulkAPI {

    static getJson(file, lang) {
        const formData = new FormData();
        for (var key in file) {

            formData.append('file', file[key])
        }

        const api = new API(`link/massive/decode/`);

        return api.post(formData);
    }

    static postJson(links, lang, file){
        const formData = new FormData();
        for (var key in file) {

            formData.append('file', file[key])

        }

        formData.append('records', JSON.stringify(links));
        formData.append('langs', JSON.stringify(lang));

        const api = new API(`link/massive/import/`);
        return api.post(formData);
    }

    static getLogs(filter = ''){

        const api = new API(`core/massive/log?${filter}`);
        return api.get();
    }


    static getMachineStructure(){
        const api = new API(`qrexport/`);
        return api.get({});
    }

    static getLogsDownload(id){
        const api = new API(`core/massive/log/${id}`);
        return api.get({}, {responseType: "blob"});
    }

}

export default BulkAPI
