import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
//material-ui imports
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
// api import
import ProductApi from '../../../../../../services/ProductApi';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../../../redux/actions/api-control-action';
// translation import
// file template import
import templateImportComponent from './import_components.csv';
import {UploadCloud} from 'react-feather'
const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    dropzoneText: {
        color: '#777',
        fontSize: 15,
        fontWeight: 200,
    },
    loaderFileStatusText: {
        marginTop:12,
        fontSize: 12,
    },
    dropzone: {
        width: '90%',
        height: 200,
        margin: 20,
        cursor: 'pointer',
        backgroundColor: '#e8e6e6',
        borderWidth: 1,
        borderColor: theme.palette.primary.main,
        borderStyle: 'dashed',
        borderRadius: 2,
        display: 'flex',
        justifyContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        flexFlow: 'row wrap',
    },
    icon: {
        width : 60,
        height : 60,
        // fontSize: 68,
        marginTop: 20,
        color: theme.palette.primary.main,
    },
    dropText: {
        padding: 20,
        paddingTop:0,
        textAlign: 'center'
    },
    templateUrl: {
        color: theme.palette.active.main,
    }
});


class ImportComponentsUploadFile extends Component {

    constructor(props){
        super(props);
        this.state={
            filesToBeSent:0,
            loading: false,
            filesStep: 1,
        }
    }

    onDrop(acceptedFiles) {
        this.setState({filesToBeSent: acceptedFiles.length, loading: true});
        //ciclo sui file caricati per uploadarli sul server
        acceptedFiles.map((file) =>
            ProductApi.importComponentMatch({"file": file}).then(
                res => {
                    // file is the original file, res.data the result of the api call
                    this.props.handleUpdateImportMatch(file, res.data);
                },
                err => {
                    this.setState({ loading: false });
                    // ====== API CONTROL MESSAGE ====== //
                    this.props.onUpdateApiControl(err, 'importComponentMatch', );
                }
            )
        );
    }

    render() {
        const { loading } = this.state;
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Typography>
                    Scarica il <a href={templateImportComponent} className={classes.templateUrl}>template ubilod</a> per completare facilmente l'importazione
                </Typography>
                <Dropzone className={classes.dropzone}
                          multiple={false}
                          accept=".xlsx, .xls, .csv"
                          onDrop={(files) => this.onDrop(files)}>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()}
                             className={classes.dropzone}
                        >
                    <UploadCloud className={classes.icon} style={{display: (loading) ? 'none' : 'block'}}/>
                    <Typography {...getInputProps()}
                                className={[classes.typography, classes.dropText]} style={{display: (loading) ? 'none' : 'block'}}>
                        Clicca o trascina direttamente qui i file per caricarli.
                    </Typography>
                    <div style={{textAlign: 'center'}} hidden={!loading}>
                        <CircularProgress size={40}/>
                        <br/>
                        <Typography className={classes.loaderFileStatusText}>{this.state.filesStep} / {this.state.filesToBeSent}</Typography>
                    </div>
                        </div>
                        )}
                </Dropzone>
            </div>
        )
    }
}

ImportComponentsUploadFile.propTypes = {
    classes: PropTypes.object.isRequired,
    //uploadType ( 0 => machine, 1 => model)
    type: PropTypes.number,
    element: PropTypes.string,
    ids: PropTypes.array,
    updateFiles: PropTypes.func,
    handleClose: PropTypes.func,
};

const mapStateToProps = state => ({
    api_control: state.api_control,
    router: state.router,
});

const mapActionsToProps = {
    onUpdateApiControl : updateApiControl
}

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(ImportComponentsUploadFile);
