import React from "react";
import {translate} from "../../../../../translations/i18n";



const fields = (currentStep) =>  [
    {
        key: `model_label_${currentStep}`,
        label : translate('bulk_import.column.model', {}, 'Modello') ,
    },
    {
        key: `product_label_${currentStep}`,
        label: translate('bulk_import.column.product', {}, 'Prodotto'),
    },
    {
        key: `category_label_${currentStep}`,
        label: translate('bulk_import.column.category', {}, 'Categoria'),
    },
    {
        key: `link_label_${currentStep}`,
        label: translate('bulk_import.column.linkName', {}, 'Nome Link'),
    },
    {
        key: `link_code`,
        label: translate('bulk_import.column.linkCode', {}, 'Codice link'),

    },
    {
        key: 'link_url',
        label: translate('bulk_import.column.link', {}, 'Link'),
    },
];
export default fields;
