import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import OffersPaginationTable from './PermissionPaginationTable';
const CustomTableCell = withStyles(() => ({
    head: {
        background: '#fff',
        color: '#111',
        fontSize: 12,
    },
    body: {
        fontSize: 12,
    },
}))(TableCell);

const widthFirstColumn = 160;
const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 1,
        overflowX: 'auto',
        boxShadow: 'unset',
    },
    table: {
        minWidth: 700,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    input: {
        background: '#fff',
        padding: '2px 8px',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        maxWidth: 32,
        '& input':{
            textAlign: 'center'
        }
    },
    flexVertCenter: {
        display:'flex',
        alignItems: 'center',
    },
    fixedColumn:{
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        maxWidth: widthFirstColumn,
        width: widthFirstColumn,
        //borderTop: '1px solid #e0e0e0',
        height: 58,
        zIndex: 999,
        //boxShadow: '2px 2px 5px #e6e6e6'
    },
    column: {
        fontSize:12,
        width:'25%',
        textAlign: 'center',
    }
});

class PermissionTable extends Component {

    constructor(props){
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
        }
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    // Change number rows per page
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    render() {
        const { classes } = this.props;
        const { rowsPerPage, page } = this.state;

        return (
            <Paper className={classes.root}>
                <Table className={classes.table} style={{overflowX:'auto'}}>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell style={{width:'20%'}} />
                            <CustomTableCell className={classes.column}>Lettura</CustomTableCell>
                            <CustomTableCell className={classes.column}>Scrittura</CustomTableCell>
                            {/*<CustomTableCell className={classes.column}>Modifica</CustomTableCell>*/}
                            <CustomTableCell className={classes.column}>Elimina</CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(this.props.permissions.length > 0)?
                            this.props.permissions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((permission) =>
                                    <TableRow className={classes.row} key={permission.id}>
                                        <CustomTableCell component="th" scope="row">{permission.category.label}</CustomTableCell>
                                        <CustomTableCell component="th" scope="row" className={classes.column}>
                                            <Checkbox disabled={false}
                                                      color="primary"
                                                      name={permission.id + '_read'}
                                                      onClick={(e) => this.props.handleOnChangeCheckbox(permission, 'read', e.target.checked)}
                                                      checked={permission.read}/>
                                        </CustomTableCell>

                                        <CustomTableCell component="th" scope="row" className={classes.column}>
                                            <Checkbox disabled={false}
                                                      color="primary"
                                                      name={permission.id + '_write'}
                                                      onClick={(e) => this.props.handleOnChangeCheckbox(permission, 'write', e.target.checked)}
                                                      checked={permission.write}/>
                                        </CustomTableCell>

                                       {/* <CustomTableCell component="th" scope="row" className={classes.column}>
                                            <Checkbox disabled={false}
                                                      color="primary"
                                                      name={permission.id + '_modify'}
                                                      onClick={(e) => this.props.handleOnChangeCheckbox(permission, 'modify', e.target.checked)}
                                                      checked={permission.modify}/>
                                        </CustomTableCell>*/}

                                        <CustomTableCell component="th" scope="row" className={classes.column}>
                                            <Checkbox disabled={false}
                                                      color="primary"
                                                      name={permission.id + '_delete'}
                                                      onClick={(e) => this.props.handleOnChangeCheckbox(permission, 'delete', e.target.checked)}
                                                      checked={permission.delete}/>
                                        </CustomTableCell>
                                    </TableRow>
                            )
                            :
                            null
                            }
                            {/* No results
                                <TableRow className={classes.row}>
                                <TableCell component="th" scope="row" colSpan={this.props.customers.length}>
                                    <Typography style={{padding: '20px 0'}}>Nessun risultato trovato</Typography>
                                </TableCell>
                                </TableRow>
                            */}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                style={{position: 'absolute', flex:1}}
                                colSpan={3}
                                count={this.props.permissions.length}
                                rowsPerPage={rowsPerPage}
                                labelRowsPerPage={'Righe per pagina'}
                                rowsPerPageOptions={[5, 10, 20]}
                                page={page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                ActionsComponent={OffersPaginationTable}
                            />
                        </TableRow>
                        {/*<Button variant="fab" mini color="secondary" style={{marginLeft:8,marginRight:8}}>
                            <Icon>keyboard_arrow_left</Icon>
                        </Button>
                        <Button variant="fab" mini color="secondary" >
                            <Icon>keyboard_arrow_right</Icon>
                        </Button>*/}
                    </TableFooter>
                </Table>
            </Paper>
        );
    }
}

PermissionTable.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired,
    handleOnChangeCheckbox: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(PermissionTable);
