import React from "react";
import Dashboard from "./views/dashboard/Dashboard";

const NoLicenseRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        breadcrumbs:[{path: '/dashboard'}],
    }
];

export default NoLicenseRoutes;
