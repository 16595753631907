import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
// material-ui imports
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Helmet from 'react-helmet';
// logo import
import Logo from '../../../assets/logos/Logo-DKC.png';
// auth api imports
import ProductApi from '../../../services/ProductApi';
// assets import
import Background from './img/background.jpg';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux'
import { updateUser } from '../../../redux/actions/user-action';
import { updateApiControl } from '../../../redux/actions/api-control-action';
// dialog import
import SignDialog from '../../oauth/sign/SignDialog';
import {translate} from "../../../translations/i18n";
import {updateMultilanguage} from "../../../redux/actions/multilanguage-action";

const styles  = theme => ({
    root: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundBlendMode: 'multiply',
        backgroundSize: 'cover',
    },
    /* Effects */
    blurEffect:{
        width:'108%',
        height:'108vh',
        backgroundImage: 'url(' + Background + ')',
        background:'rgba(255, 255, 255, .3)',
        backgroundBlendMode: 'multiply',
        backgroundSize:'cover',
        filter: 'blur(10px)',
        position:'absolute',
        top:'-4%',
        left:'-4%',
        [theme.breakpoints.down('xs')]:{
            height: '100vh',
            background:'rgba(255, 255, 255, 0.5)',
        }
    },
    gradientEffect:{
        overflow:'hidden',
        width:'100%',
        height:'108vh',
        position:'absolute',
        top:0,left:0,
        background: 'linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0));',
        [theme.breakpoints.down('xs')] : {
            display:'none',
        }
    },
    card: {
        marginTop:-80,
        minWidth: 300,
        backdropFilter: 'blur(10px) contrast(.1)',
        background: 'rgba(255,255,255,0.88)',
        //minHeight: '60vh',
        zIndex: 10,
        padding:20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.between('sm','md')] : {
            minHeight: 'unset',
        },
        [theme.breakpoints.only('xs')] : {
            background: 'rgba(255,255,255,1)',
            padding:'0 20px',
            minWidth: '100%',
            minHeight: '100vh',
        }
    },
    cardContent: {
        textAlign: 'center',
        paddingBottom: 0,
        margin: 10,
        [theme.breakpoints.down('xs')] : {
            padding:'0 48px'
        }
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]:{
            flexDirection: 'column',
        }
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    textField: {
        width: '100%',
        marginTop: 24,
        '& input':{
            background:'transparent',
        },
    },
    buttonSign: {
        marginTop: 32,
        marginBottom:20,
        minWidth: 200,
        color: '#fff',
        textDecoration: 'none',
    },
    loader: {
        color: '#fff',
        marginLeft:12,
    },
    error: {
        marginTop:20,
        fontSize:12,
        color: theme.palette.error.main,
    },
});

class MachineCode extends Component {

    constructor(props){
        super(props);
        this.state = {
            codeMachine: (this.props.match.params.machine_code)?this.props.match.params.machine_code:'',
            showPassword: false,
            machine_id: null,
        }
    }

    componentDidMount(){
        if (this.props.user.company_id) {
             let tmpUser = this.props.user;
             delete tmpUser.company_id;
             this.props.onUpdateUser(tmpUser)
        }
        // if codeMachine is present called th api
        if(this.state.codeMachine){
            this.machineSign();
        }

    }

    handleCodeMachineChange = (event) => {this.setState({codeMachine: event.target.value})}

    handleSubmitSign(event) {
        event.preventDefault();
        this.machineSign();
    }

    // ========================================= //
    // MACHINE SIGN BY TOKEN
    // ========================================= //
    machineSign(){
        this.setState({loader: true});
        // Api call for auth user login
        ProductApi.getProductByToken(this.state.codeMachine)
            .then(
                res => {


                    // define if the user needs to sign in or can see the machine detail
                    let redirectOnDetail = (res.data.public)?true:(this.props.user.is_logged)?true:false;
                    this.setState({
                        loader: false,
                        machine_id: res.data.id,
                        machineName: res.data.name,
                        redirectOnDetail: redirectOnDetail,
                        openSignDialog: !redirectOnDetail,
                    });

                    let multilanguage = {
                        defaultlang : (res.data.flags.length > 0) ? res.data.flags[0].id :  1 ,
                        languageValues : res.data.flags,
                        selectedLanguage : [(res.data.flags.length > 0) ? res.data.flags[0].id :  1]
                    };

                    this.props.onUpdateMultilanguage(multilanguage)
                },
                err => {
                    this.setState({loader: false});
                    // ====== API CONTROL MESSAGE ====== //
                    this.props.onUpdateApiControl(err, 'getMachineByToken', translate( `machine_code.error`, {}, 'Macchina non trovata - Verifica il codice'));
                }
            );
    }

    render() {
        const { classes } = this.props;
        const { loader, redirectOnDetail, machine_id, machineName, openSignDialog } = this.state;

        // Define the redirect on machine detail
        if (redirectOnDetail && machine_id) {
            return <Redirect to={`/machine/${machine_id}`}/>
        }

        return (
                <div className={classes.root}>
                    {/* Background effects */}
                    <Helmet><style type="text/css">{`html,body{overflow-y:hidden!important}`}</style></Helmet>
                    <div className={classes.blurEffect}></div>
                    <div className={classes.gradientEffect}></div>
                    <Card className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            <Link to="/">
                                <img src={Logo} width={200} alt="logo"/>
                            </Link>
                            <form onSubmit={this.handleSubmitSign.bind(this)}
                                  ref="form"
                                  className={classes.formContainer}
                                  autoComplete="off">
                                <Input
                                    id="codeMachine"
                                    InputLabelProps={{ shrink: true }}
                                    type={'text'}
                                    label={translate( `machine_code.input.label`, {}, "Codice macchina")}
                                    placeholder={translate( `machine_code.input.label`, {}, "Codice macchina")}
                                    className={classes.textField}
                                    value={this.state.codeMachine}
                                    onChange={(e) => {
                                        this.handleCodeMachineChange(e)
                                    }}
                                />
                                <Button disabled={loader}
                                        type="submit"
                                        className={classes.buttonSign}
                                        variant="contained"
                                        color="primary"
                                        size="small">
                                    {(!loader) ? <span>{translate( `machine_code.input.button`, {}, "Accedi alla macchina")}</span> : null}
                                    {(loader) ? <CircularProgress className={classes.loader} size={16} style={{marginLeft:0}}/> : null}
                                </Button>
                            </form>
                        </CardContent>
                        <CardActions className={classes.cardActions}>
                            <Link to="/login">
                                <Typography color="primary">{translate( `machine_code.back`, {}, "Torna al login")}</Typography>
                            </Link>
                        </CardActions>
                    </Card>

                    {/* DIALOG LOGIN TO ACCESS ON MACHINE */}
                    {(!redirectOnDetail && openSignDialog)?
                    <SignDialog
                        title={translate( `login.dialog.title`, {},  "Effettua il login")}
                        message={`
                                   ${translate( `login.dialog.subtitle.one`, {},  "La macchina")} 
                                   ${machineName}
                                   ${translate( `login.dialog.subtitle.two`, {},  "risulta essere privata, effettua il login per visualizzare i dettagli")}
                                   `}
                                redirectOnAuth={`/machine/${machine_id}`}
                                open={this.state.openSignDialog}
                                onClose={() => this.setState({openSignDialog:false})}/>
                    :null}


                </div>
        )
    }
}


MachineCode.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    router: state.router,
    api_control: state.api_control
});

const mapActionsToProps = {
    onUpdateUser : updateUser,
    updateMultilanguage : updateMultilanguage,
    onUpdateApiControl : updateApiControl
};

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(MachineCode);
