import React, { Component } from 'react';
import PropTypes from 'prop-types';
// material ui import
import { withStyles } from '@material-ui/core/styles';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../redux/actions/api-control-action';
// api service import
import ModelApi from '../../../../services/ModelApi';
// containers import
import BackofficeViewContainer from '../../layout/container/AdminContainer';
// components import
import BoxCard from '../../../../components/ui/cards/BoxCard';
import Search from '../../../../components/ui/search/Search';
import AlertDialog from '../../../../components/ui/dialogs/alertDialog/AlertDialog';
import CreateModelDialog from './components/CreateModelDialog';
import SnackbarLoader from '../../../../components/ui/loaders/snackbar/SnackbarLoader';
import {translate} from "../../../../translations/i18n";
import {updateMultilanguage} from "../../../../redux/actions/multilanguage-action";
import LanguageApi from "../../../../components/language-select/LanguageApi";
import ProductApi from "../../../../services/ProductApi";

const styles  = () => ({

});

class ModelsManager extends Component {

    constructor(props){
        super(props);
        this.state = {
            modelsList:[],
            modelsFound: [],
            dialogLoader: false,
            openDeleteConfirmDialog: false,
            openDropzoneDialog: false,
            openLinkDialog : false,
            openImportComponentsDialog : false,
            uploadFiles:[],
            addFileData: {count: 1, total: 0},
            languageValues : [],
            availableLang : [],
            defaultlang : null,
        }
    }

    // ==================================================
    // GET THE MODEL LIST
    // ==================================================


    apiCalls = () => {
        ModelApi.getModel({'license': this.props.license.license}).then(
            res => {
                this.setState({
                    modelsList: res.data,
                    modelsFound: res.data,
                    loader:false,
                })
            },
            err => {
                this.setState({ loader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'getModel');
            }
        )
    };


    componentDidMount(){
        this.setState({ loader: true });

        this.apiCalls();
    }

    // ==================================================
    // CREATE MODEL
    // ==================================================
    createModel(newModel){
        this.setState({ dialogLoader: true });


        newModel.license = this.props.license.license;
        ModelApi.createModel(newModel).then(
            res => {
                newModel.labels.map(item =>
                    ModelApi.addModelLabels(item, res.data.id, item.flag.code).then(
                        r => {
                        },
                        err => {

                        }
                    )
                );


                this.setState(prevState => ({
                    modelsList:  [...prevState.modelsList, {...res.data, labels : newModel.labels }],
                    modelsFound: [...prevState.modelsFound, {...res.data, labels : newModel.labels }],
                    dialogLoader:false,
                    openCreateModelDialog: false,
                }));
            },
            err => {
                this.setState({ openCreateModelDialog: false, dialogLoader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'createModel', );
            }
        )
    }

    // ==================================================
    // DELETE MODEL
    // ==================================================
    deleteModel(id){
        this.setState({ dialogLoader: true });
        ModelApi.deleteModel(id).then(
            () => {
                let deleteIndex = this.state.modelsList.map(function (item) {return item.id}).indexOf(id);
                this.setState(prevState => ({
                    modelsList:  prevState.modelsList.filter((_, i) => i !== deleteIndex),
                    modelsFound: prevState.modelsFound.filter((_, i) => i !== deleteIndex),
                    dialogLoader:false,
                    openDeleteModelDialog: false,
                }));
            },
            err => {
                this.setState({ openDeleteModelDialog: false, dialogLoader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'deleteModel', );
            }
        )
    }

    // ==================================================
    // UPDATE MODEL
    // ==================================================
    updateModel(model){
        this.setState({ dialogLoader: true });

        if (model && model.description === '')
            model.description = null;

        ModelApi.updateModel(model.id, model).then(
            res => {

                let toBDeleted = [];

                if (model.labels.length > 0)
                    toBDeleted = res.data.labels.filter(item => model.labels.findIndex(v => v.flag.id === item.flag.id) === -1)

                model.labels.map(item =>
                    res.data.labels.length > 0 && res.data.labels.findIndex(v => v.flag.id == item.flag.id) !== -1 ?
                        ModelApi.editModelLabels(item, model.id, item.flag.code).then(
                        r => {
                        },
                        err => {

                        }
                    )
                        :
                        ModelApi.addModelLabels(item, res.data.id, item.flag.code).then(
                            r => {
                            },
                            err => {

                            }
                        )
                );

                if(toBDeleted.length > 0)
                    toBDeleted.map(item =>
                        ModelApi.deleteModelLabels(item, res.data.id, item.flag.code).then(
                            r => {
                            },
                            err => {

                            }
                        ));

                let modelList = this.state.modelsList;
                let updateIndex = modelList.map(function (item) {return item.id}).indexOf(model.id)
                modelList[updateIndex] = {...res.data, labels : model.labels};
                this.setState(() => ({
                    modelsList:  modelList,
                    modelsFound: modelList,
                    dialogLoader:false,
                    openUpdateModelDialog: false,
                }));
            },
            err => {
                this.setState({ openUpdateModelDialog: false, dialogLoader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'updateModel', );
            }
        )
    }

    render() {
        const { classes, license} = this.props;
        const { modelsList, modelsFound, dialogLoader } = this.state;
        const { loader } = this.state;

        return(
            <div className={classes.root}>
                <BackofficeViewContainer
                    title={translate( `model.creation.${license.license}`, {},  (license.license === 'commercial') ? 'Creazione linee prodotto' : "Creazione modelli" )}
                    subtitle={
                        translate( `model.description.${license.license}`, {},  (license.license === 'commercial') ?
                                "Crea le tue linee prodotto, rinominale o eliminale. In quest’area puoi creare la cartella dei tuo modello produttivo, rinominarla, eliminarla e cercare tra i modelli che hai creato. Per gestire i macchinari e le categorie abbinate clicca sul modello produttivo di tuo interesse.":
                                "Crea i tuoi modelli produttivi, rinominali o eliminali. In quest’area puoi creare la cartella dei tuo modello produttivo, rinominarla, eliminarla e cercare tra i modelli che hai creato. Per gestire i macchinari e le categorie abbinate clicca sul modello produttivo di tuo interesse."
                         )
                    }
                    renderSearch={
                        <Search data={modelsList} // data list where the component search
                                keySearch={"name.description"} // key that have to search
                                placeholder={
                                    translate( `model.search.${license.license}`, {},  (license.license === 'commercial') ? "Cerca per linee prodotto" : "Cerca per modello")
                                } // placeholder
                                onResult={(searchResult) => this.setState({modelsFound: searchResult})}/> // action after search
                    }
                    languageSelect
                >

                    {/* CREATE MODEL BOX CARD */}
                    <BoxCard variant={"add"}
                             title={"+"}
                             disabled={this.state.loader}
                             subtitle={
                                 translate( `model.add.${license.license}`, {},  (license.license === 'commercial') ? "Aggiungi linea prodotto" : "Crea modello")
                             }
                             onClick={() => this.setState({openCreateModelDialog:true})}/>

                    {/* MODELS LIST BOX CARDS */}
                    {modelsFound.map((model, index) =>
                        <BoxCard key={model + '-' + index}
                                 title={model.name}
                                 subtitle={model.description}
                                 labels={model.labels}
                                 link={`model/${model.id}`}
                                 onDelete={() => {this.setState({openDeleteModelDialog:true, activeDeleteId:model.id})}}
                                 onUpdate={() => this.setState({openUpdateModelDialog:true, activeUpdateModel:model})}/>
                    )}

                    {/* CREATE MODEL DIALOG */}
                    {(this.state.openCreateModelDialog)?
                    <CreateModelDialog title={
                        translate( `model.create_modal.${license.license}`, {},  (license.license === 'commercial') ? "Crea linea prodotto" : "Crea modello")
                    }
                                       loader={dialogLoader}
                                       open={this.state.openCreateModelDialog}
                                       onClose={() => this.setState({openCreateModelDialog:false})}
                                       onCreate={(newModel) => this.createModel(newModel)}
                    />
                    :null}

                    {/* UPDATE MODEL DIALOG */}
                    {(this.state.openUpdateModelDialog)?
                    <CreateModelDialog title={
                        translate( `model.edit_modal.${license.license}`, {},
                            (license.license === 'commercial') ?
                                "Modifica linea prodotto"
                                :
                                "Modifica modello")
                    }
                                       loader={dialogLoader}
                                       model={this.state.activeUpdateModel}
                                       open={this.state.openUpdateModelDialog}
                                       onClose={() => this.setState({openUpdateModelDialog:false})}
                                       onCreate={(updateModel) => this.updateModel(updateModel)}/>
                    :null}

                    {/* DELETE CONFIRM DIALOG */}
                    <AlertDialog open={this.state.openDeleteModelDialog}
                                 title={
                                     translate( `model.delete_modal.${license.license}`, {},  (license.license === 'commercial') ? "Eliminazione linea prodotto" : "Eliminazione modello")
                                 }
                                 loader={dialogLoader}
                                 message={
                                     translate( `model.delete_modal_message.${license.license}`, {},  (license.license === 'commercial') ? "Sei sicuro di voler eliminare la linea prodotto?" : "Sei sicuro di voler eliminare il modello?")
                                 }
                                 confirmBtnText={"Elimina"}
                                 onClose={() => this.setState({openDeleteModelDialog:false})}
                                 onConfirm={() => this.deleteModel(this.state.activeDeleteId)}/>

                    {/* LOADER */}
                    <SnackbarLoader open={loader} message={"Elaborazione in corso"}/>
                </BackofficeViewContainer>
            </div>
        )
    }
}


ModelsManager.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    multilanguage: state.multilanguage,
    license: state.license,
    product: state.product,
    utils: state.utils,
    api_control: state.api_control
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl,
    onUpdateMultilanguage: updateMultilanguage,
}

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(ModelsManager);
