import API from './API';

class ModelApi {

    static url = 'model';


    // ========================================= //
    // MODEL
    // ========================================= //

    static getModel(query){
        const api = new API(this.url);
        return api.get(query);
    }

    static getModelById(id, query) {
        const api = new API(this.url);
        return api.getById(id, query);
    }

    static createModel(model) {
        const api = new API(this.url);
        return api.post(model);
    }

    static addModelLabels(model, id, flag) {
        const api = new API(`core/${this.url}/${id}/label/${flag}`);
        return api.post(model);
    }

    static editModelLabels(model, id, flag) {
        // const api = new API(`core/${this.url}/${id}/update_language?lang=${model.flag.code}`);
        const api = new API(`core/${this.url}/${id}/label/${flag}`);

        return api.update(model);
    }

    static deleteModelLabels(model, id, flag) {
        const api = new API(`core/${this.url}/${id}/label/${flag}`);
        return api.delete();
    }

    static updateModel(id, model) {
        const api = new API(`${this.url}/${id}`);
        return api.update(model);
    }

    static deleteModel(id) {
        const api = new API(this.url);
        return api.deleteById(id);
    }

    // ========================================= //
    // MACHINE
    // ========================================= //

    static getMachine(id) {
        const api = new API(`${this.url}/${id}/machine`);
        return api.get();
    }

    // ========================================= //
    // FILE
    // ========================================= //

    static uploadFile(modelId, categoryId, data) {
        const api = new API(`${this.url}/${modelId}/category/${categoryId}/file`);
        return api.postFormData(data);
    }

    static updateLinkLanguage(modelId, categroyId, fileId, item, type) {

        const api =
            (type === 'link') ?
                new API(`${this.url}/${modelId}/category/${categroyId}/links/${fileId}`)
                :
                new API(`${this.url}/${modelId}/category/${categroyId}/file/${fileId}`)

        return api.update(item)
    }

    static createFile(modelId, categoryId, data) {
        const api = new API(`${this.url}/${modelId}/category/${categoryId}/file`);
        return api.post(data);
    }

    static getFile(modelId, categroyId, lang, query) {
        const api = new API(`${this.url}/${modelId}/category/${categroyId}/file${lang}`);
        return api.get(query);
    }

    static getFileById(modelId, fileId) {
        const api = new API(`${this.url}/${modelId}/file`);
        return api.getById(fileId)
    }

    static getFileUrlById(modelId, fileId) {
        const api = new API(`${this.url}/${modelId}/file-url/${fileId}`);
        return api.get();
    }

    static deleteLinkById(modelId, categroyId, fileId, type) {

        const api =
            (type === 'link') ?
                new API(`${this.url}/${modelId}/category/${categroyId}/links/${fileId}`)
                :
                new API(`${this.url}/${modelId}/file/${fileId}`)

        return api.delete()
    }

    // ========================================= //
    // CATEGORY
    // ========================================= //

    static addCategroy(modelId, categoryId) {
        const api = new API(`${this.url}/${modelId}/category/${categoryId}`);
        return api.post({});
    }

    static deleteCategory(modelId, categoryId) {
        const api = new API(`${this.url}/${modelId}/category/${categoryId}`);
        return api.delete();
    }

}

export default ModelApi
