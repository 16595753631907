import React from 'react';
// material ui import
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
// Redux
import { compose } from 'redux';
// i18n translation
import {translate} from "../../../../../../translations/i18n";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles  = theme => ({
    textField: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        width: '100%',
    },
    container: {
        display:'flex',
        flexDirection:'column',
        marginTop:8
    },
    dialogAction: {
        margin: '0px 20px',
        marginBottom:20,
    }
});


class AddComponentDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            component:{}
        }
    }

    handleChange = (e) => {
        e.persist();
        this.setState(prevState => ({
            component:{
                ...prevState.component,
                [e.target.name] : e.target.value
            }
        }));
    }

    render() {
        const { component } = this.state;
        const { classes, open, loader} = this.props;

        return (
            <div>
                <Dialog
                    open={open}
                    onClose={this.props.onClose}>
                    {/* Print edit machine on edit - Print the label on readOnly*/}
                    <DialogTitle>{this.props.title}</DialogTitle>
                    <form onSubmit={(e)=> {e.preventDefault(); this.props.onCreate(component)}}
                          className={classes.container}
                          onKeyPress={(e) => (e.which === 13)?e.preventDefault():null}>
                        <div>
                        <DialogContent style={{paddingTop:0}}>
                                <DialogContentText style={{paddingBottom:12}}>
                                    Compila i campi sottostanti
                                </DialogContentText>
                            {this.props.labelList.map(item =>
                                <TextField
                                    label={translate( `machine_components_import.${item.label}`, {},  `machine_components_import.${item.label}`)}
                                    name={item.label}
                                    InputLabelProps={{ shrink: true}}
                                    required={true}
                                    className={classes.textField}
                                    value={component[item]}
                                    onChange={(e) => this.handleChange(e)}
                                    disabled={loader}
                                />
                            )}
                        </DialogContent>
                        <DialogActions className={classes.dialogAction}>
                            <Button onClick={() => this.props.onClose()} color="primary">Chiudi</Button>
                            <Button disabled={loader} type="submit" variant="outlined" color="primary">
                                {(!loader)?
                                    "Aggiungi"
                                    :
                                    <CircularProgress className={classes.loader} size={16} style={{marginLeft:0}}/>
                                }
                            </Button>
                        </DialogActions>
                        </div>
                    </form>
                </Dialog>
            </div>
        );
    }
}

export default compose(
    withStyles(styles, {withTheme: true}),
)(AddComponentDialog);
