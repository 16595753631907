import React from 'react';
import Composer from 'react-composer';
import { Fetch } from 'react-request';

export default function GoogleSignedUpload(props){
    const {baseUrl, bearerKey, token} = props;
    const {onResultFileUpload, onResultGoogleSignedUpload, onFinish} = props;
    const {onErrorFileUpload} = props;
    const {files} = props;

    const headersFileUpload = {
        'Authorization': `${bearerKey} ${token}`,
        'Content-Type':'application/json'
    }

    function googleCloudServiceDoFetch(response,file,index) {


        if (response.status === 200){
            // Google Cloud Service Api Call
            onResultFileUpload(response.data, file);

            const fetchUploadGcsFile = new Request(response.data.url, {
                method: "put",
                headers: new Headers({'Content-Type':file.type}),
                body: file,
            });


            fetch(fetchUploadGcsFile).then(
                res => {
                    if (res.status === 200){
                        onResultGoogleSignedUpload(res, file)
                    }
                })
                .catch((err => {
                    console.log(err)
                }))
        }else{
            onErrorFileUpload(response, file, index)
        }

        if (index === files.length-1){
            onFinish()
        }
    }

    return(
        files.length > 0 &&
            files.map((file,index) => {
                const fileUpload={
                    request: 'file/upload-url',
                    params: {'file_name': file.name, 'type': props.type, 'content_type': file.type}
                }
                return (
                <React.Fragment>
                    <Composer
                        components={[
                            <Fetch url={`${baseUrl + fileUpload.request}?${getEncodeURI(fileUpload.params)}`}
                                   headers={headersFileUpload}
                                   method="GET"
                                   onResponse={(error, response) => {
                                       if (response) {
                                           googleCloudServiceDoFetch(response, file, index)
                                       }else{
                                           console.log('No response from Google Cloud Service...')
                                       }
                                   }}
                            />
                        ]}>
                        {([fetchFileUpload]) => {
                            return (
                                <React.Fragment>
                                    {/* File Upload Api Call */}
                                    {fetchFileUpload.fetching && console.log('Fetching file upload...')}
                                </React.Fragment>
                            );
                    }}
                    </Composer>
                </React.Fragment>)
            })
    )

    function getEncodeURI(params){
        let esc = encodeURIComponent;
        return Object.keys(params)
            .map(k => `${esc(k)}=${esc(params[k])}`)
            .join('&');
    }
}
