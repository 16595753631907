import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../../redux/actions/api-control-action';
//material-ui imports
import DialogDropzone from '../../../../../components/ui/dropzone/SimpleDropzone';
//import domtoimage from 'dom-to-image';
// import moment date

const styles  = () => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: -80,
        marginBottom: 80,
        width:'50%',
    },
    image:{
        width:'100%',
        height:'100%',
        backgroundSize:'contain',
        backgroundRepeat:'no-repeat',
        backgroundPosition:'center',
    }
});

class MachineImage extends Component {

    constructor(props){
        super(props);
        this.state = {}
    }

    render() {

        const { classes, product} = this.props;

        return (
            <div className={classes.root}>
                {(product.image)?
                    <div className={classes.image} style={{backgroundImage: `url(${product.image})`}}/>
                    :
                    <div style={{display:'flex', alignItems:'center'}}>
                        {/*<Button type="submit" variant="contained" color="primary">Carica immagine macchina</Button>*/}
                        <DialogDropzone style={{padding: 32}}/>
                    </div>
                }
            </div>
        )
    }
}


MachineImage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    api_control: state.api_control,
    router: state.router,
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl
};

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(MachineImage);
