import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// material ui import
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
// BlurDiv import
import BlurDiv from './BlurDiv';

const styles  = () => ({
    // loader
        loaderFullWidth:{
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            height:'100vh',
            width: '100%',
            position:'fixed',
            top:0,
            left:0,
            zIndex:9999
        },
        content:{
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'center',
        }
});

class BlurLoaderFullWidth extends Component {

    constructor(props){
        super(props);
        this.state = {}
    }

    componentDidMount(){}

    render() {
        const { classes,theme} = this.props;
        const { background = theme.palette.primary.main } = this.props;

        return (
            <BlurDiv
                className={classes.loaderFullWidth}
                background={background}
                overflow={false}>
                <div className={classes.content}>
                    <Typography color="secondary" style={{fontSize:20, marginBottom:20}}>{this.props.message}</Typography>
                    <CircularProgress className={classes.progress} color="secondary" />
                </div>
            </BlurDiv>
        )
    }
}


BlurLoaderFullWidth.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    background: PropTypes.object,
};

export default withStyles(styles, {withTheme: true})(BlurLoaderFullWidth);