import React, { Component } from 'react';
import PropTypes from 'prop-types';
// material ui import
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateLoaders } from '../../../../../redux/actions/loaders-action';
import { updateApiControl } from '../../../../../redux/actions/api-control-action';
// i18n translation

// api service import
import ProductApi from '../../../../../services/ProductApi';
// containers import
import BackofficeViewContainer from '../../../layout/container/AdminContainer';
// components import
import Search from '../../../../../components/ui/search/Search';
import SnackbarLoader from '../../../../../components/ui/loaders/snackbar/SnackbarLoader';
import MachinePermissionUserTable from './components/MachinePermissionUserTable';
import FunctionsToolbar from '../../../../../components/ui/toolbar/FunctionsToolbar';
import AlertDialog from '../../../../../components/ui/dialogs/alertDialog/AlertDialog';
import {translate} from "../../../../../translations/i18n";
//import SearchUserPermission from './components/SearchUserPermission';

const styles  = () => ({

});

class MachinePermissionUsers extends Component {

    constructor(props){
        super(props);
        this.state = {
            product: null,
            usersList:[],
            usersFound:[],
            selectedUsers: [],
            dialogLoader: false,
            openSearchUserDialog: false,
            prepare: {
                offset: 0,
                limit: 40,
                search: ""
            },
        }
    }

    // ==================================================
    // GET THE COMPONENTS LIST
    // ==================================================
    componentDidMount(){
        window.scrollTo(0, 0);
        this.setState({ loader: true });
        let product_id = this.props.match.params.machine_id;

        ProductApi.getPermissionUsers(product_id, this.state.prepare).then(
            res => {
                // ######################################
                let tmpUsers = this.state.usersList;
                if(tmpUsers.length > 0)
                    tmpUsers = [...tmpUsers, ...res.data.users];
                else
                    tmpUsers = res.data.users;
                // ######################################
                this.setState(prevState => ({
                    product: res.data.product,
                    usersList: tmpUsers,
                    usersFound: res.data.users,
                    prepare: {
                        ...prevState.prepare,
                        offset: this.state.prepare.offset + res.data.users.length
                    },
                    loader:false,
                }));
            },
            err => {
                this.setState({ loader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'loadPermissionUsers', );
            }
        )
    }

    // ==================================================
    // ON DELETE USERS
    // ==================================================
    deleteUsers(){
        this.setState({dialogLoader: true});
        const users_id = this.state.selectedUsers.map(function (item) {return item.id}).join('|');
        ProductApi.deletePermissionUsers(this.state.product.id, users_id).then(
            () => {
                for (let selectedUser of this.state.selectedUsers) {
                    let indexList = this.state.usersList.map(function (item) {return item.id;}).indexOf(selectedUser.id);
                    let indexFound = this.state.usersFound.map(function (item) {return item.id;}).indexOf(selectedUser.id);

                    this.setState(prevState => ({
                        selectedUsers: [],
                        usersList:  prevState.usersList.filter((_, i) => i !== indexList),
                        usersFound: prevState.usersFound.filter((_, i) => i !== indexFound),
                        dialogLoader:false,
                        openDeleteConfirmDialog: false
                    }));
                }

            },
            err => {
                this.setState({ dialogLoader: false, openDeleteConfirmDialog: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'deletePermissionUsers', );
            }
        )
    };

    handleAdmin(user, checked) {
        const { product } = this.state;
        if(checked) {
            ProductApi.addAdmin(product.id, user.id)
                .then(
                    () => {
                        this.updateAdmin(user, true)
                    },
                    err => {
                        // ====== API CONTROL MESSAGE ====== //
                        this.props.onUpdateApiControl(err, 'addAdmin', );
                    })
        } else {
            ProductApi.removeAdmin(product.id, user.id)
                .then(
                    () => {
                        this.updateAdmin(user, false)
                    },
                    err => {
                        // ====== API CONTROL MESSAGE ====== //
                        this.props.onUpdateApiControl(err, 'removeAdmin', );
                    })
        }
    }

    updateAdmin(user, isAdmin) {
        const { usersList, usersFound } = this.state;

        user['is_admin'] = isAdmin;
        let indexList = usersList.map(function (item) {return item.id;}).indexOf(user.id);
        let indexFound = usersFound.map(function (item) {return item.id;}).indexOf(user.id);

        let tmpUserList = [...usersList];
        let tmpUserFound = [...usersFound];

        tmpUserList[indexList] = user;
        tmpUserFound[indexFound] = user;

        this.setState(() => ({
            usersList: tmpUserList,
            usersFound: tmpUserFound,
        }));
    }

    handleSearchUser(query) {
        if(query === '') {
            this.setState({ searchQueryEmpty: true, usersFound: this.state.usersList })
        } else {
            this.setState({ searchQueryEmpty: false });
            ProductApi.getPermissionUsersSearch(this.state.product.id, query)
                .then(
                    res => {
                        (this.state.searchQueryEmpty) ?
                            this.setState({ usersFound: this.state.usersList }): this.setState({ usersFound: res.data })
                    },
                    err => {
                        // ====== API CONTROL MESSAGE ====== //
                        this.props.onUpdateApiControl(err, 'searchUser', );
                    })
        }
    }

    render() {
        const { classes, intl } = this.props;
        const { product, usersList, usersFound, loader } = this.state;

        return (
            <div className={classes.root}>
                <BackofficeViewContainer
                    title={` ${translate( `machine_collaborators.title`, {}, "Gestione collaboratori della macchina")} ${product && product.name}`}
                    subtitle={`
                    ${translate( `machine_collaborators.subtitle`, {}, "Dà e revoca lo status di admin o gestisci i permessi per di modifica, lettura ed eliminazione dei file caricati nella macchina")} 
                    ${product && product.name}`}
                    background={false}
                    hasMarginTop={false}
                    renderSearch={
                        <Search data={usersList} // data list where the component search
                                keySearch={"first_name"} // key that have to search

                                placeholder={translate( `machine_collaborators.search`, {}, "Cerca Utente in Ubilod")} // placeholder
                                onChange={(query) => this.handleSearchUser(query)}/> // action after search
                    }
                    renderToolbar={
                        <FunctionsToolbar items={[
                            {label: translate( `general.delete`, {}, 'Elimina'), icon: 'delete', disabled: (this.state.selectedUsers.length < 1), func: () => this.setState({openDeleteConfirmDialog: true})},
                        ]} />
                    }>

                    {(usersList.length > 0 || usersFound.length > 0) ?
                        <div style={{flex:1}}>
                            <MachinePermissionUserTable intl={intl}
                                                        product={product}
                                                        users={usersFound}
                                                        selectedUsers={this.state.selectedUsers}
                                                        onSelect={(selectedUsers) => this.setState({selectedUsers: selectedUsers})}
                                                        onSelectAdmin={(user, checked) => this.handleAdmin(user, checked)}/>
                        </div>
                        :
                        <Typography style={{marginTop:20}}>{translate( `machine_collaborators.no_result`, {},  'Non sono presenti utenti con permessi')}</Typography>
                    }

                   {/* LOADER */}
                    <SnackbarLoader open={loader} message={translate( `model_manage.loader`, {},  "Elaborazione in corso")}/>
                </BackofficeViewContainer>

                {/* DELETE CONFIRM DIALOG */}
                <AlertDialog title={translate( `machine_collaborators.remove.title`, {},  'Rimozione utenti')}
                             message={translate( `machine_collaborators.remove.message`, {},  'Sicuro di voler eliminare gli utenti selezionati?')}
                             confirmBtnText={translate( `model_manage.delete_modal_button`, {},  "Elimina")}
                             open={this.state.openDeleteConfirmDialog}
                             loader={this.state.dialogLoader}
                             onClose={() => this.setState({openDeleteConfirmDialog: false})}
                             onConfirm={() => this.deleteUsers()} />

            </div>
        )
    }
}


MachinePermissionUsers.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    utils: state.utils,
    api_control: state.api_control,
    router: state.router
});


const mapActionsToProps = {
    onUpdateLoaders : updateLoaders,
    onUpdateApiControl : updateApiControl
}

export default compose(

    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(MachinePermissionUsers);
