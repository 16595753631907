import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import FileDownload from 'js-file-download';

// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../redux/actions/api-control-action';
import { updateLoaders } from '../../../../redux/actions/loaders-action';
import { updateRouter } from "../../../../redux/actions/router-action";
// api service import
import ProductApi from '../../../../services/ProductApi';
// containers import
import BackofficeViewContainer from '../../layout/container/AdminContainer';
import CreateMachineDialog from './components/CreateMachineDialog';
import AssociateCategoryDialog from './components/AssociateCategoryDialog';
// components import
import Hidden from '@material-ui/core/Hidden';
import BoxCard from '../../../../components/ui/cards/BoxCard';
import AlertDialog from '../../../../components/ui/dialogs/alertDialog/AlertDialog';
import MachineLabel from './components/MachineLabel';
import MachineImage from './components/MachineImage';
import PermissionRequestDialog from './components/PermissionRequestDialog';
import Typography from '@material-ui/core/Typography'
import FunctionsToolbar from '../../../../components/ui/toolbar/FunctionsToolbar';
import SnackbarLoader from '../../../../components/ui/loaders/snackbar/SnackbarLoader';
import SignDialog from "../../../oauth/sign/SignDialog";
import ConfirmDialog from '../../../../components/ui/dialogs/confirmDialog/ConfirmDialog';
import CategoryApi from "../../../../services/CategoryApi";
import { updateUser } from '../../../../redux/actions/user-action';
import DownloadLabelDialog from "./components/DownloadLabelDialog";
import {updateMultilanguage} from "../../../../redux/actions/multilanguage-action";
import LanguageApi from "../../../../components/language-select/LanguageApi";
import {translate} from "../../../../translations/i18n";
import {Edit2, DownloadCloud, Trash, Users } from 'react-feather'

const styles  = theme => ({
    subtitle: {
        fontSize: 16,
        fontWeight: 800,
        marginBottom: 14,
        color: '#3c4252',
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
        },
    },
});

class MachineDetail extends Component {

    constructor(props){
        super(props);
        this.state = {
            loader: false,
            dialogLoader: false,
            product: null,
            company_categories: [],
            models:[],
            redirectToPermissions: false,
            openDeleteConfirmDialog: false,
            openAssociateCategoryDialog: false,
            openDeleteCategoryDialog: false,
            openBackupDialog: false,
            openDownloadLabel: false,
            finalLabels: {title : '', subtitle : ''},
            permissionRequest: {
                name: `${this.props.user.first_name} ${this.props.user.last_name}`,
                email: this.props.user.email,
                message: ``
            },
        }
    }


    componentDidMount(){
        this.setState({ loader: true });
        ProductApi.getProductById(this.props.match.params.machine_id, {'license': (this.props.license) ? this.props.license.license : ''}).then(
            res => {
                // ====== UPDATE BREADCRUMB ====== //
                this.props.onUpdateRouter({
                    ...this.props.router,
                    activeMachineName: res.data.product.name,
                    activeMachineLabels: res.data.product.labels,
                    activeModelName: res.data.product.model?.name,
                    activeModelLabels: res.data.product.model?.labels,
                });
                this.props.onUpdateUser({
                    ...this.props.user,
                    company_id : res.data.product.company
                });
                this.setState({
                    loader:false,
                    product: res.data.product,
                    company_categories: res.data.company_categories,
                    models: res.data.models,
                    openSignDialog: (res.data.product.public)?false:!this.props.user.is_logged,
                    productName: res.data.product.name,
                    product_id: res.data.product.id,
                });
                this.getLabels();
            },
            err => {
                this.setState({ loader: false });

                if(err.response.status === 403){
                    this.setState({
                        openPermissionRequest: true
                    })
                }


                if(true){
                    this.setState({
                        openPermissionRequest: true
                    })
                }else{
                    // ====== API CONTROL MESSAGE ====== //
                    this.props.onUpdateApiControl(err, 'getMachine', );
                }
            }
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.multilanguage !== this.props.multilanguage) {

            if (this.state.product)
                this.getLabels();
        }
    }


        // ==================================================
    // UPDATE MACHINE
    // ==================================================
    updateMachine(updateMachine, labels){
        this.setState({ dialogLoader: true });
        ProductApi.updateProduct(updateMachine.id, updateMachine).then(
            res => {

                let toBDeleted = [];

                if (labels.length > 0)
                    toBDeleted = res.data.labels.filter(item => labels.findIndex(v => v.flag.id === item.flag.id) === -1);

                labels.map(item =>
                    res.data.labels.findIndex(v => v.flag.id == item.flag.id) !== -1 ?
                        ProductApi.editProductLabels(item, res.data.id, item.flag.code).then(
                        r => {
                        },
                        err => {

                        }
                    )
                        :
                        ProductApi.addProductLabels(item, res.data.id, item.flag.code).then(
                            r => {
                            },
                            err => {

                            }
                        )
                );

                if(toBDeleted.length > 0)
                    toBDeleted.map(item =>
                        ProductApi.deleteProductLabels(item, res.data.id, item.flag.code).then(
                            r => {
                            },
                            err => {

                            }
                        )
                    );

                this.setState(() => ({
                    product:  {...res.data, labels},
                    dialogLoader:false,
                    openUpdateMachineDialog: false,
                }), () => this.getLabels())
            },
            err => {
                this.setState({ dialogLoader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'updateMachine', );
            }
        )
    }

    getBoxCardUrl = (category_id, type) => {
        switch(type){
            case 'F':
                    return `${this.props.match.url}/category/${category_id}`;
            case 'CT':
                    return `${this.props.match.url}/contacts`;
            case 'CP':
                    return `${this.props.match.url}/components`;
            case 'I':
                return null;
            default:
                break
        }
    };

    getBoxCardOnClick = (category_id, type) => {
        switch(type){
            case 'F':
            case 'CT':
            case 'CP':
                    return null;
            case 'I':
                return () => this.setState({openUpdateMachineDialog:true});
            default:
                break
        }
    };

    deleteMachine = () => {
        this.setState({ dialogLoader: true });
        ProductApi.deleteProduct(this.state.product.id).then(
            () => {
                this.updateFullWidthLoader(false);
                this.setState({openDeleteConfirmDialog: false, redirectToMachines: true});
            },
            err => {
                this.setState({ dialogLoader: false, openDeleteConfirmDialog: false,  });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'deleteMachine', );

            }
        )
    };

    associateCategory = (categoryId,newCategory=null, labels) => {
        this.setState({ dialogLoader: true});
        const {multilanguage} = this.props;

        if(newCategory!==null){
            CategoryApi.createCategory(newCategory).then(
                res => {
                    labels.map(item =>
                        CategoryApi.addCategoryLabels(item, res.data.id, multilanguage.languageValues.filter(v => v.id == item.flag )[0].code).then(
                            r => {
                            },
                            err => {

                            }
                        )
                    );
                    categoryId=res.data.id;
                    addCategory(categoryId, this)
                },
                err => {
                    this.setState({ dialogLoader: false });
                    // ====== API CONTROL MESSAGE ====== //
                    this.props.onUpdateApiControl(err, 'createCategory', );
                }
            )
        }else{
            addCategory(categoryId,this)
        }

        function addCategory(categoryId,context){
            ProductApi.addCategroy(context.state.product.id, categoryId).then(
                res => {
                    let deleteIndex = context.state.company_categories.map(function (item) {return item.id.toString()}).indexOf(categoryId);
                    context.setState(prevState => ({
                        openAssociateCategoryDialog: false,
                        product:  {
                            ...prevState.product,
                            categories: [...prevState.product.categories, res.data],
                        },
                        company_categories:  prevState.company_categories.filter((_, i) => i !== deleteIndex),
                        dialogLoader: false,
                    }));
                },
                err => {
                    context.setState({openAssociateCategoryDialog: false, dialogLoader: false });
                    // ====== API CONTROL MESSAGE ====== //
                    context.props.onUpdateApiControl(err, 'associateCategory');
                }
            )
        }
    };

    disassociateCategory(id){
        this.setState({ dialogLoader: true});
        ProductApi.deleteCategroy(this.state.product.id, id).then(
            () => {
                let deleteIndex = this.state.product.categories.map(function (item) {return item.id}).indexOf(id);
                this.setState(prevState => ({
                    company_categories: [...prevState.company_categories, prevState.product.categories[deleteIndex] ],
                    product:  {
                        ...prevState.product,
                        categories: prevState.product.categories.filter((_, i) => i !== deleteIndex),
                    },
                    openDeleteCategoryDialog: false,
                    dialogLoader: false
                }));
            },
            err => {
                this.setState({ openDeleteCategoryDialog: false, dialogLoader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'disassociateCategory', );
            }
        )
    }

    downloadBackup = () => {
        this.setState({dialogLoader: true});
        ProductApi.getBackup(this.state.product.id).then(
            res => {
                FileDownload(res.data, this.state.product.name+'.zip');
                this.setState({dialogLoader: false, openBackupDialog: false});
            },
            err => {
                this.setState({dialogLoader: false, openBackupDialog: false});
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'downloadBackup', );
            }
        )
    };

    updateFullWidthLoader = (open, message='') => {
        this.props.onUpdateLoaders({
            ...this.props.loaders,
            loaderFullWidth: {
                open: open,
                message: message,
            }
        });
    };

    addToolbarFunction = (product) => {

        const { user, license } = this.props;
        let toolBarFunctions = [];
        const admin = product && (product.is_admin || product.is_owner);
        if(!admin) { return toolBarFunctions; }

        if(admin){

            toolBarFunctions.push({
                label: translate( `machine_detail.download_tag`, {},  "Scarica Etichetta"),
                disabled: false,
                icon: (style) => <DownloadCloud style={style}/>,
                func: () => this.setState({openDownloadLabel: true})});


            /* NEWFUNCTION toolBarFunctions.push({label: 'Timeline', disabled: false, icon: 'timeline', func: () => this.setState({redirectToTimeline: true})}); */
            if(user.type === 'MF') {
                if (license && !license.demo && license.license === 'tech') {
                    toolBarFunctions.push({
                        label: 'Backup',
                        disabled: false,
                        icon: (style) => <DownloadCloud style={style}/>,
                        func: () => this.setState({openBackupDialog: true})});
                }
                toolBarFunctions.push({
                    label: translate( `machine_components.delete_confirm.${license.license}`, {},  (license.license === 'commercial') ? "Elimina prodotto" : "Elimina macchina"),
                    disabled: false,
                    icon: (style) => <Trash style={style}/>,
                    func:  () => this.setState({openDeleteConfirmDialog: true})});
            }

            toolBarFunctions.push({
                label: translate( `machines.edit_modal.${license.license}`, {},  (license.license === 'commercial') ? "Modifica prodotto" : "Modifica macchina"),
                disabled: false,
                icon: (style) => <Edit2 style={style}/>,
                func: () => this.setState({openUpdateMachineDialog: true})});


            toolBarFunctions.push({
                label: translate( `machine_detail.manage_collaborators`, {},  "Gestione collaboratori"),
                disabled: false,
                icon: (style) => <Users style={style}/>,
                func: () => this.setState({redirectToPermissions: true})
            });
        }
        return toolBarFunctions;
    };

    addToolbarFunctionNotLogged = (product) => {

        let toolBarFunctions = [];

            /*toolBarFunctions.push({
                label: translate( `machine_detail.download_tag`, {},  "Scarica Etichetta"),
                disabled: false,
                icon: (style) => <DownloadCloud style={style}/>,
                func: () => this.setState({openDownloadLabel: true})});*/

        return toolBarFunctions;
    };


    getLabels = () => {
        const {multilanguage} = this.props;
        const {labels = [], name , description = ''} = this.state.product;
        const {defaultlang} = this.state;

        let tmpLabels = [...labels];

        if (labels && labels.length > 0 && multilanguage.selectedLanguage && multilanguage.selectedLanguage.length > 0){
            let mainLanguage = multilanguage.selectedLanguage[0];

            tmpLabels = tmpLabels.filter(item =>
                item.flag.id == mainLanguage
            );

            if (tmpLabels.length === 0 && defaultlang)
                tmpLabels = labels.filter(item =>
                    item.flag.id == defaultlang
                );

            if (tmpLabels.length === 0 && !defaultlang)
                tmpLabels = {title : name , subtitle : description}

        }else if (!multilanguage.selectedLanguage ||  multilanguage.selectedLanguage.length === 0 && defaultlang){
            tmpLabels = tmpLabels.filter(item =>
                item.flag.id == defaultlang
            );
        }

        if (labels && labels.length === 1)
            tmpLabels = {title : labels[0].title, subtitle : labels[0].description};

        if (tmpLabels.length === 0 && (name || description))
            tmpLabels = {title : name , subtitle : description};


        if (tmpLabels && tmpLabels.length >= 1){
            tmpLabels = {title : tmpLabels[0].title, subtitle : tmpLabels[0].description }
        }

        this.setState({finalLabels : tmpLabels});
        return tmpLabels;
    };

    render() {
        const { product, models, loader, openDeleteConfirmDialog, openAssociateCategoryDialog,
                openDeleteCategoryDialog, openBackupDialog,  company_categories, dialogLoader, openSignDialog,openDownloadLabel,
                productName, product_id, permissionRequest,openPermissionRequest, finalLabels} = this.state;
        const { classes, license, multilanguage } = this.props;

        // REDIRECT ON MACHINE (AFTER PERMISSION REQUEST
        if(this.state.redirectOnMachines){
            return <Redirect to={'/machine'} />
        }

        if(this.state.redirectOnDashboatd){
            return <Redirect to={'/'} />
        }

        // const finalLabels = this.getLabels();

        return (
            <div className={classes.root}>

                {(this.state.redirectToMachines) ? <Redirect to={'/machine'}/> : null}
                {(this.state.redirectToPermissions) ? <Redirect to={`/machine/${product.id}/permission`}/> : null}
                {(this.state.redirectToTimeline) ? <Redirect to={`/machine/${product.id}/timeline`}/> : null}

                {(product && ((this.props.user.is_logged || product.public) || openPermissionRequest))?
                <BackofficeViewContainer title={`${(product)? finalLabels.title : translate( `machine_detail.title`, {},  "Gestione Macchina") }`}
                                         subtitle={
                                             this.props.user.isLogged ?
                                             `${product && product.description  ? finalLabels.subtitle : translate( `machine_detail.subtitle`, {}, "Gestisci la documentazione specifica abbinata ad ogni QR code e quindi ad ogni macchina.")}`
                                                 :
                                                 ''
                                         }
                                         renderToolbar={
                                             (this.props.user && this.props.user.is_logged)?
                                                 <>
                                                 {product && <Typography className={classes.subtitle}> Code : {product.code} </Typography>}
                                                 <FunctionsToolbar items={this.addToolbarFunction(product)} />
                                                 </>
                                                 :
                                                 <>
                                                     <FunctionsToolbar items={this.addToolbarFunctionNotLogged(product)} />
                                                 </>
                                         }
                                         languageSelect
                >
                    {(product && ((this.props.user.is_logged || product.public) || openPermissionRequest))?
                        (product)?
                        <div style={{display: 'flex', flexDirection: 'column', minWidth:'100%', marginTop:40}}>
                            {/* Container QR Label and Machine image*/}
                            {/*<div style={{display:'flex',justifyContent:'center',marginLeft:32,marginRight:32}}>
                                <MachineLabel product={product}/>
                                {(product.image)?
                                    <Hidden smDown>
                                       <MachineImage product={product}/>
                                    </Hidden>
                                    :null}
                            </div>*/}
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                                {/* CREATE CATEGORY BOX CARD */}
                                {(product.is_admin || product.is_owner) ?
                                    <BoxCard variant={"add"}
                                             title={"+"}
                                             disabled={this.state.loader}
                                             subtitle={
                                                 translate( `machine_detail.create`, {},  "Modifica Macchina")
                                             }
                                             onClick={() => this.setState({openAssociateCategoryDialog:true})}/>
                                    :null}

                                {(product.categories && product.categories.length > 0) ?
                                    product.categories.map((category, index) =>
                                        ((category.label === 'Componenti' && license && license.license !== 'commercial' || category.label !== 'Componenti') && category.label !== 'Informazioni') &&
                                        <BoxCard key={category + '-' + index}
                                                 title={category.label}
                                                 link={this.getBoxCardUrl(category.id, category.type)}
                                                 labels={category.labels}
                                                 onClick={this.getBoxCardOnClick(category.id, category.type)}
                                                 subtitle={category.description}
                                                 onDelete={(category.type === 'F' && !category.is_model_category && (product.is_admin || product.is_owner)) ? () => {this.setState({openDeleteCategoryDialog:true, activeDeleteId:category.id})} : null}/>
                                    )
                                    :
                                    <Typography variant="body" style={{fontWeight:500, marginTop:40}}>{/*Non sono state trovate categorie per questa macchina*/}</Typography>
                                }
                            </div>

                            {/* UPDATE MACHINE DIALOG */}
                            {(this.state.openUpdateMachineDialog) ?
                                <CreateMachineDialog title={translate( `machines.edit_modal.${license.license}`, {},  (license.license === 'commercial') ? "Modifica prodotto" : "Modifica macchina")}
                                                     product={product}
                                                     models={models}
                                                     loader={dialogLoader}
                                                     open={this.state.openUpdateMachineDialog}
                                                     readOnly={!(product.is_admin || product.is_owner)}
                                                     onClose={() => this.setState({openUpdateMachineDialog:false})}
                                                     onCreate={(updateMachine, labels) => this.updateMachine(updateMachine,labels)}/>
                                :null}


                            {/* DELETE MACHINE DIALOG */}
                            {(this.state.openDeleteConfirmDialog) ?
                                <AlertDialog title={translate( `machines.delete_modal.${license.license}`, {},  (license.license === 'commercial') ?"Eliminazione prodotto" : "Eliminazione macchina")}
                                             message={translate( `machines.delete_modal_message.${license.license}`, {}, (license.license === 'commercial') ? "Sei sicuro di voler eliminare il prodotto?" : "Sei sicuro di voler eliminare la Macchina?")}
                                             confirmBtnText={translate( `machine_detail.delete_modal_confirm`, {},  'Sì')}
                                             open={openDeleteConfirmDialog}
                                             loader={dialogLoader}
                                             onClose={() => this.setState({openDeleteConfirmDialog: false})}
                                             onConfirm={this.deleteMachine} />
                                :null}

                            {/* DOWNLOAD LABEL DIALOG */}
                            {(this.state.openDeleteConfirmDialog) ?
                                <AlertDialog title={translate( `machines.delete_modal.${license.license}`, {},  (license.license === 'commercial') ?"Eliminazione prodotto" : "Eliminazione macchina")}
                                             message={translate( `machines.delete_modal_message.${license.license}`, {}, (license.license === 'commercial') ? "Sei sicuro di voler eliminare il prodotto?" : "Sei sicuro di voler eliminare la Macchina?")}
                                             confirmBtnText={translate( `machine_detail.delete_modal_confirm`, {},  'Sì')}
                                             open={openDeleteConfirmDialog}
                                             loader={dialogLoader}
                                             onClose={() => this.setState({openDeleteConfirmDialog: false})}
                                             onConfirm={this.deleteMachine} />
                                :null}

                            {/* ASSOCIATE CATEGORY DIALOG */}
                            {(this.state.openAssociateCategoryDialog) ?
                                <AssociateCategoryDialog title={translate( `machine_detail.associate_modal`, {},  'Associa categoria')}
                                                         message={translate( `machine_detail.associate_modal_message`, {},  'Seleziona la categoria da associare alla macchina')}
                                                         confirmBtnText={translate( `machine_detail.associate_modal_confirm`, {},  'Associa')}
                                                         loader={dialogLoader}
                                                         open={openAssociateCategoryDialog}
                                                         categories={company_categories}
                                                         onClose={() => this.setState({openAssociateCategoryDialog: false})}
                                                         onSubmit={(categoryId, newCategory, labels) => this.associateCategory(categoryId,newCategory, labels)} />
                                :null
                            }


                            {/* DELETE CATEGORY CONFIRM DIALOG */}
                            <AlertDialog open={openDeleteCategoryDialog}
                                         title={translate( `machine_detail.associate_modal_delete`, {},  "Dissocia categoria")}
                                         message={translate( `machine_detail.associate_modal_message_delete`, {},  "Sei sicuro di voler dissociare la categoria?")}
                                         confirmBtnText={translate( `machine_detail.associate_modal_confirm_delete`, {},  "Dissocia")}
                                         loader={dialogLoader}
                                         onClose={() => this.setState({openDeleteCategoryDialog:false})}
                                         onConfirm={() => this.disassociateCategory(this.state.activeDeleteId)}/>


                            {/* DOWNLOAD BACKUP CONFIRM DIALOG */}
                            <DownloadLabelDialog open={openDownloadLabel}
                                         title={translate( `machine_detail.download_modal`, {},  "Download backup")}
                                         message={translate( `machine_detail.download_modal_message`, {},  "Sei sicuro di voler scaricare il backup della macchina?")}
                                         confirmBtnText={translate( `machine_detail.download_modal_confirm`,{}, "Scarica")}
                                         loader={dialogLoader}
                                         product={product}
                                         name={finalLabels.title}
                                         description={finalLabels.subtitle}
                                         license={license}
                                         onClose={() => this.setState({openDownloadLabel:false})}
                                         onConfirm={() => this.downloadBackup()}
                            />
                        </div>
                        :
                        <div>
                            {/* PERMISSION REQUEST*/}
                            {(openPermissionRequest)?
                                <PermissionRequestDialog title={translate( `machine_detail.permission_modal`, {},  "Richiesta permessi macchina")}
                                                         permissionRequest={permissionRequest}
                                                         loader={false}
                                                         open={openPermissionRequest}
                                                         onClose={() => this.setState({redirectOnMachines:true})}
                                                         onSubmitRequest={() => this.setState({openConfirmPermissionRequest:true})}/>
                                :null}
                            {/* Confirm dialog Permissione request*/}
                            {(this.state.openConfirmPermissionRequest)?
                                <ConfirmDialog title={translate( `machine_detail.permission_request_modal`, {},  "La tua richiesta è stata inoltrata")}
                                               subtitle={translate( `machine_detail.permission_request_modal_message`, {},  "Se il proprietario della macchina abilitaterà i permessi alla macchina ti verrà comunicato con una notifica")}
                                               closeBtn={translate( `machine_detail.permission_request_modal_confirm`, {},  "Torna alle mie macchine")}
                                               open={this.state.openConfirmPermissionRequest}
                                               onClose={() => this.setState({redirectOnMachines:true})}/>
                            :null}
                        </div>
                        :
                        (openSignDialog)?
                            <SignDialog title={translate( `login.dialog.title`, {},  "Effettua il login")}
                                        message={`
                                   ${translate( `login.dialog.subtitle.one`, {},  "La macchina")} 
                                   ${productName}
                                   ${translate( `login.dialog.subtitle.two`, {},  "risulta essere privata, effettua il login per visualizzare i dettagli")}
                                   `}
                                        redirectOnAuth={`/machine/${product_id}`}
                                        open={this.state.openSignDialog}
                                        onClose={() => {this.setState({openSignDialog:false})}}/>
                            :null
                    }


                    {/* LOADER */}
                    <SnackbarLoader open={loader} message={translate( `machine_detail.loader`, {},  "Elaborazione in corso")}/>
                </BackofficeViewContainer> : null}


                <div>

                    {(this.state.openPermissionRequest)?
                        <ConfirmDialog title={
                            translate( `private.title.${license.license}`, {},  (license.license === 'commercial') ?'Questo prodotto è privato' : 'Questa macchina è privata')
                            }
                                       subtitle={
                                           translate( `private.subtitle.${license.license}`, {},  `Finchè il proprietario della macchina abilitaterà i permessi ${license.license === 'tech' ? 'alla macchina' : 'al prodotto'} non potrai accedere alle sue informazioni`)
                                           }
                                       closeBtn={
                                           translate( `private.clase.${license.license}`, {},  (license.license === 'commercial') ?'Torna ai miei prodotti' : 'Torna alle mie macchine')
                                       }
                                       open={this.state.openPermissionRequest}
                                       onClose={() => this.setState({redirectOnMachines:true})}/>
                        :null}
                </div>
            </div>
        )
    }
}

MachineDetail.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    license: state.license,
    api_control: state.api_control,
    router: state.router,
    loaders: state.loaders,
    multilanguage: state.multilanguage,
});

const mapActionsToProps = {
    onUpdateApiControl : updateApiControl,
    onUpdateLoaders: updateLoaders,
    onUpdateRouter: updateRouter,
    onUpdateUser : updateUser,
    onUpdateMultilanguage: updateMultilanguage,
};

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(MachineDetail);
