import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import ApplicationRoutes from "./ApplicationRoutes";
import NoLicenseRoutes from "./NoLicenseRoutes";
import AdminTheme from './ApplicationTheme';
import ThemeDKC from '../../settings/ThemeDKC';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import HeaderRoot from './layout/header/HeaderRoot';
import FooterRoot from './layout/footer/FooterRoot';
import { compose } from 'redux';
import {Routing} from "cobrains-react";
import BreadCrumbs from '../../components/ui/breadcrumbs/BreadCrumbs'
import {updateUser} from "../../redux/actions/user-action";
import {connect} from "react-redux";
import BlurLoaderFullWidth from "../../components/ui-blur/BlurLoaderFullWidth";
import DemoLicenseBanner from "../../components/ui/banner/DemoLicenseBanner";
import AuthService from "../../services/AuthService";
import TagManager from 'react-gtm-module';
import moment from 'moment'
const styles = theme => ({
    root: {
        display: 'flex',
        position: 'relative'
    },
    // Content
    main: {
        position: 'relative',
        paddingTop:64,
        margin: 0,
        minHeight: '100vh',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
        //padding: '4px 20px'
    },
    content: {
        width: 'calc(100% - 169px)',
        padding: '38px 0px',
        /* padding-top: 32px; */
        margin: '0px 40px',
        [theme.breakpoints.down('xs')]:{
            width: 'calc(100% - 40px)',
            margin: '0px 20px',
        }
    },
});

/*const notificationBar = [
    {variant: 'warning', message: 'Spazio archiviazione in esaurimento richiedi più spazio al team di supporto UBILOD', link: 'https://ubilod.com/buy-space', linkMessage: 'Richiedi più spazio prova subito' },
]*/


class ApplicationLayout extends React.Component {


    componentDidMount(){
        TagManager.initialize({ gtmId: 'GTM-P48LJZ8' });
    }



    isLicenseExpired () {
        return this.props.user && this.props.user.company && this.props.user.company.licenses.length > 0 && moment(this.props.user.company.licenses[0].expire).isBefore(moment().format('YYYY-MM-DD'))
    }




    render() {
        const { classes, license } = this.props;

        // redirect if user is not logged
        if(!this.props.user.is_logged) {
            this.setState({ toLogin: false });
            return <Redirect to='/login' />
        }

        return (
            <MuiThemeProvider
                // theme={(this.props.user && (this.props.user.username === 'dkc' || (this.props.user.company && this.props.user.company.business_name === 'DKC Tech'))) ? ThemeDKC : AdminTheme}
                theme={ThemeDKC}
            >
                <div className={classes.root}>
                    {/* AppBar & Drawer */}
                    <HeaderRoot/>
                    {/* Main */}
                    <div className={classes.main}>

                        <main className={classes.content} style={(window.screen.width > 599)? {}:{width : '100%'} }>
                            <Routing routes={this.isLicenseExpired() ? NoLicenseRoutes : ApplicationRoutes}
                                     isLogged={AuthService.isLogged()}
                                     redirects={[
                                         {from: '/login', to: '/dashboard'},
                                         {from: '*', to: '/dashboard'},
                                     ]}/>
                        </main>

                        {/* Loader */}
                        {(this.props.loaders.loaderFullWidth.open) ?
                            <BlurLoaderFullWidth message={this.props.loaders.loaderFullWidth.message}
                                                 background={AdminTheme.palette.active.main}/>
                        : null}

                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

ApplicationLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    language: state.language,
    user: state.user,
    loaders: state.loaders,
    utils: state.utils,
    router: state.router,
    license: state.license,
});

const mapActionsToProps = {
    onUpdateUser : updateUser,
};

export default compose(withRouter, withStyles(styles, {withTheme: true}), connect(mapStateToProps,mapActionsToProps))(ApplicationLayout);
