import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const styles = () => ({
    root: {
        width: '100%',
    },
});

class ImportComponentsStepper extends React.Component {
    render() {
        const { classes, steps, activeStep } = this.props;

        return (
            <div className={classes.root}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map(label => {
                        return (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </div>
        );
    }
}

ImportComponentsStepper.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(ImportComponentsStepper);