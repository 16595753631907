import React from 'react';
// material ui import
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import {translate} from "../../../../../translations/i18n";
import LanguageSelect from "../../../../../components/language-select/LanguageSelect";
import {Globe, X} from "react-feather";
import Typography from "@material-ui/core/Typography";
import {updateApiControl} from "../../../../../redux/actions/api-control-action";
import {updateMultilanguage} from "../../../../../redux/actions/multilanguage-action";
import {compose} from "redux";
import {connect} from "react-redux";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles  = theme => ({
    textField: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        width: '47%',
        padding: '0px 0px 16px'
    },
    container: {
        display:'flex',
        flexDirection:'column',
        marginTop:8
    },
    dialogAction: {
        margin: '0px 20px',
        marginBottom:20,
    }
});


class CreateCategoryDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            category:{
                id: null,
                label: '',
                description: '',
            },
            labels : [
                {
                    description: '',
                    flag: null,
                    title: ''
                }
            ],
        }
    }

    componentDidMount(){
        const props = this.props;


        this.setState({
            labels : (props.category && props.category.labels && props.category.labels.length > 0) ? props.category.labels.sort((a,b) => a.flag.id == props.multilanguage.defaultlang ? -1 : 1).map(item => ({...item, flag : item.flag.id})) :
            [
                {
                    description: '',
                    flag: props.multilanguage.defaultlang,
                    title: ''
                }
            ],
            category:{
                id: (props.category)?props.category.id:'',
                label: (props.category)?props.category.label:'',
                description: (props.category)?props.category.description:'',
            }
        })
    }

    handleChangeLabel = (e , index) => {
        let tmpLabel = this.state.labels

        tmpLabel[index] = {
            ...tmpLabel[index],
            [e.target.name] : e.target.value
        }

        this.setState({labels : tmpLabel})
    };



    handleChangeMultiple = (event, index) => {

        let { value } = event.target;

         value = Number(value)

        let tmpValue =  this.state.labels[index].flag;
        let tmpLabels = this.state.labels;
        let  availableLang = this.props.multilanguage.languageValues.filter(item => this.state.labels.findIndex(v => v.flag == item.id) === -1 );


        if (value.id === tmpValue ){
            tmpLabels[index] = {
                ...tmpLabels[index],
                flag : null
            };
        }else if (availableLang.length > 0 && availableLang.findIndex(item => item.id == value) !== -1){
            tmpLabels[index] = {
                ...tmpLabels[index],
                flag : value
            };
        }

        this.setState({labels : tmpLabels})

    };

    addLabel = () => {
        let tmpLabels = this.state.labels;
        tmpLabels.push({
            description: '',
            flag: null,
            title: ''
        })

        this.setState({label : tmpLabels})

    };


    removeLabel = (index) => {
        let tmpLabels = this.state.labels;

        tmpLabels.splice(index , 1);

        this.setState({label : tmpLabels})
    };

    handleChange = (e) => {
        e = {...e};
        this.setState(prevState => ({
            category:{
                ...prevState.category,
                [e.target.name] : e.target.value
            }
        }));
    };

    formatLabels = () => {
        return this.state.labels.map((item, index) => {
            return ({
                description : item.description,
                title : item.title,
                flag : this.props.multilanguage.languageValues[this.props.multilanguage.languageValues.findIndex(v => v.id == item.flag)]
            })})
    };



    render() {
        const { classes, open, loader, multilanguage } = this.props;
        const { labels } = this.state;




        return (
            <div>
                <Dialog
                    open={open}
                    onClose={this.props.onClose}>
                    <DialogTitle>{this.props.title}</DialogTitle>
                    <form onSubmit={(e)=> {e.preventDefault(); this.props.onCreate(this.state.category, this.formatLabels())}}
                          className={classes.container}
                          onKeyPress={(e) => (e.which === 13)?e.preventDefault():null}>
                        <div>
                        <DialogContent style={{paddingTop:0}}>
                            <DialogContentText>
                                {translate('category.message', {}, "Inserisci nei campi sottostanti nome e descrizione")}
                            </DialogContentText>


                            <div style={{padding : '12px 0px'}}>
                                {multilanguage.languageValues && this.state.labels.map( (item, index) =>
                                    <div style={{marginTop : 20}}>
                                        <div style={{display : 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                                            {index > 0 ?
                                                <div style={{display : 'flex', alignItems : 'center'}}>
                                                    <Button
                                                        style={{minWidth : 16, padding : '4px', borderRadius : '16px'}}
                                                        onClick={() => this.removeLabel(index)}>
                                                        <X size={24}/>
                                                    </Button>
                                                    <Typography style={{fontSize : 14}}>
                                                        {translate('general.remove_language', {}, "Rimuovi traduzione")}
                                                    </Typography>
                                                </div>
                                                :
                                                <div style={{display : 'flex', alignItems : 'center', visibility : 'hidden'}}>
                                                    <Button
                                                        style={{minWidth : 16, padding : '4px', borderRadius : '16px'}}
                                                        onClick={() => this.removeLabel(index)}>
                                                        <X size={24}/>
                                                    </Button>
                                                    <Typography style={{fontSize : 14}}>
                                                        {translate('general.remove_language', {}, "Rimuovi traduzione")}
                                                    </Typography>
                                                </div>
                                            }
                                            <LanguageSelect
                                                onChange={(e) => this.handleChangeMultiple(e, index)}
                                                style={{padding : '0px 16px 16px 0px', justifyContent : 'flex-end'}}
                                                value={item.flag}
                                                multiple={false}
                                                label={''}
                                                languageOptions={null}
                                            />
                                        </div>
                                        <div style={{display : 'flex', flexWrap : 'wrap', justifyContent : 'space-between'}}>
                                            <TextField
                                                required
                                                variant={'outlined'}
                                                InputProps={{inputProps: {maxLength: 50}}}
                                                InputLabelProps={{shrink: true}}
                                                label={translate('machine_dialog.name', {}, 'Nome')}
                                                name="title"
                                                className={classes.textField}
                                                value={item.title}
                                                onChange={(e) => {
                                                    index === 0 &&
                                                    this.handleChange({...e, target : {...e.target, name : 'label'}});
                                                    this.handleChangeLabel(e, index);
                                                }}
                                                disabled={loader}
                                            />
                                            <TextField
                                                label={translate('associate_category.description', {}, "Descrizione")}
                                                name="description"
                                                multiline
                                                variant={'outlined'}
                                                rowsMax="4"
                                                InputLabelProps={{shrink: true}}
                                                className={classes.textField}
                                                value={item.description}
                                                onChange={(e) => {
                                                    index === 0 &&
                                                    this.handleChange(e);
                                                    this.handleChangeLabel(e, index)
                                                }}
                                                disabled={loader}
                                                margin="normal"
                                            />
                                        </div>
                                    </div>
                                )}

                                <Button
                                    style={{marginTop : 12}}
                                    disabled={this.state.labels.length >= multilanguage.languageValues.length}
                                    onClick={() => this.addLabel()}>
                                    <Globe size={16} style={{marginRight : 12}} />
                                    {translate( `general.add_language`, {},  'Aggiungi traduzione')}
                                </Button>
                            </div>

                        </DialogContent>
                            <DialogActions className={classes.dialogAction}>
                                <Button onClick={() => this.props.onClose()} color="primary">
                                    {translate( `general.cancel`, {},  "Annulla")}
                                </Button>
                                <Button type="submit" variant="outlined" color="primary"
                                disabled={this.state.labels.findIndex(item => item.flag === null) !== -1}
                                >
                                    {(!loader)?
                                        (!this.state.category.id)?
                                            translate( `general.create`, {},  "Crea")
                                            :
                                            translate( `general.edit`, {},  "Modifica")
                                        :
                                        <CircularProgress className={classes.loader} size={16} style={{marginLeft:0}}/>
                                    }
                                </Button>
                            </DialogActions>
                        </div>
                    </form>
                </Dialog>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    user: state.user,
    multilanguage: state.multilanguage,
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl,
};

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(CreateCategoryDialog);

