import React from 'react';
import PropTypes from 'prop-types';
// material-ui import
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux'
import { updateApiControl } from '../../../../../../redux/actions/api-control-action';
import {translate} from "../../../../../../translations/i18n";

const styles = theme => ({
    root: {
        width: '100%',
        boxShadow: 'none'
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    body: {
        fontSize: 14,
    },
});

class MachineComponentsTable extends React.Component {

    // ==================================================
    // ON SELECT CHECKBOX
    // ==================================================
    handleComponentCheckbox = (component) => {
        const index = this.props.selectedComponents.indexOf(component);
        let selectedComponents = [...this.props.selectedComponents];
        (index >= 0) ? selectedComponents.splice(index, 1) : selectedComponents.push(component);
        // call onResult function to set the searchResult
        this.props.onSelectComponents(selectedComponents);
    };

    render() {
        const {classes} = this.props;
        const { labelList, components, permissions } = this.props;



        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {(permissions && permissions.delete) ?
                                <TableCell>
                                    {/*<Checkbox color="primary" onChange={(e) => this.handleSelectAllCheckbox()}></Checkbox>*/}
                                </TableCell>
                                : null}
                            {labelList.map(item =>
                                <TableCell>
                                    {translate( 'machine_components_import.' + item.label, {},  'machine_components_import.' + item.label)}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(components.length > 0)?
                            components.map((component) =>
                                (component !== undefined)?
                                    <TableRow className={classes.row} key={component.id}>
                                        {(permissions && permissions.delete) ?
                                            <TableCell component="th" scope="row">
                                                    <Checkbox color="primary"
                                                              onChange={(e) => this.handleComponentCheckbox(component,e)}/>
                                            </TableCell>
                                            : null}
                                        {labelList.map(item =>
                                            <TableCell component="th" scope="row">
                                                {component[item.label]}
                                            </TableCell>
                                        )}
                                    </TableRow>
                                    :
                                    null
                            )
                            :
                            <TableRow className={classes.row}>
                                <TableCell component="th" scope="row" colSpan={6}>
                                    <Typography style={{padding: '20px 0'}}>Nessun risultato trovato</Typography>
                                </TableCell>
                            </TableRow>}
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}

MachineComponentsTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    api_control: state.api_control
});

const mapActionsToProps = {
    onUpdateApiControl : updateApiControl
}

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(MachineComponentsTable);


