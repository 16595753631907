import React, {Component} from 'react'
import PropTypes from 'prop-types'
import NoConnectionAlert from './components/NoConnectionAlert.jsx'
import SnackBarApiMessage from './components/SnackbarApiMessage.jsx'
// Redux
import { connect } from 'react-redux'
import { updateApiControl, resetApiControl } from '../../redux/actions/api-control-action'

import { updateUser } from '../../redux/actions/user-action'
import ReportProblemDialog from "../ui/dialogs/reportProblemDialog/ReportProblemDialog";

class ApiMessageControl extends Component {
    static propTypes = {
        auth: PropTypes.bool,
        api_control: PropTypes.object,
        onResetApiControl: PropTypes.func,
        retryApiCall: PropTypes.func,
        onUpdateUser: PropTypes.func,
    }

    constructor(props) {
        super(props)
        this.state = {
            auth: (this.props.auth) ? this.props.auth : false,
            openReportProblemDialog : false
        }
    }

    render() {
        const { api_control } = this.props

        return (
            <>
                <ReportProblemDialog open={this.state.openReportProblemDialog}
                                     onClose={() => this.setState({openReportProblemDialog:false})}/>

            {(api_control && api_control.response)
                ? <div style={{zIndex: 99999}}>
                    {/* [200] Success: Success message */}
                    {((api_control.response.status === 200 || api_control.response.status === 201) && api_control.message)
                        ? <SnackBarApiMessage open={true}
                                              autoHideDuration={4000}
                                              variant={'success'}
                                              message={api_control.message}
                                              onOpen={() => this.setState({openReportProblemDialog:true})}
                                              onClose={() => this.props.onResetApiControl()} />
                        : null}

                    {((api_control.response.status === 204) && api_control.message)
                        ? <SnackBarApiMessage open={true}
                                              autoHideDuration={4000}
                                              variant={'warning'}
                                              message={api_control.message}
                                              onOpen={() => this.setState({openReportProblemDialog:true})}
                                              onClose={() => this.props.onResetApiControl()} />
                        : null}

                    {/* [400] Bad Request: status message  */}
                    {((api_control.response.status === 400) && api_control.message)
                        ? <SnackBarApiMessage open={true}
                                              autoHideDuration={4000}
                                              variant={'warning'}
                                              message={api_control.message}
                                              onOpen={() => this.setState({openReportProblemDialog:true})}
                                              onClose={() => this.props.onResetApiControl()} />
                        : null}

                    {((api_control.response.status === 400) && !api_control.message)
                        ? <NoConnectionAlert open={true} action={(this.props.retryApiCall)} />
                        : null
                    }

                    {/* [401] Unauthorization (General): Message status 401 (Redirect to login) */}
                    {((api_control.context !== 'auth' && api_control.response.status === 401 && api_control.message === ''))
                        ? <SnackBarApiMessage open={true}
                                              autoHideDuration={1500}
                                              variant={'forbidden'}
                                              message={'Area riservata - effettuare nuovamente il login'}
                                              onOpen={() => this.setState({openReportProblemDialog:true})}
                                              onClose={() => {
                                                  this.props.onUpdateUser({})
                                                  this.props.onResetApiControl()
                                              }} />
                        : null}

                    {/* [401] Unauthorization (Login) : Message status 401 (Username and/or password wrong) */}
                    {(((api_control.context === 'auth' || api_control.context === 'change_password') && api_control.response.status === 401) && api_control.message !== '')
                        ? <SnackBarApiMessage open={api_control.response.status !== null}
                                              autoHideDuration={4000}
                                              variant={'warning'}
                                              message={api_control.message}
                                              onOpen={() => this.setState({openReportProblemDialog:true})}
                                              onClose={() => this.props.onResetApiControl()} />
                        : null}

                    {/* [403] Forbidden */}
                    {((api_control.response.status === 403) && api_control.message)
                        ? <SnackBarApiMessage open={true}
                                              autoHideDuration={4000}
                                              variant={'forbidden'}
                                              onOpen={() => this.setState({openReportProblemDialog:true})}
                                              message={api_control.message}
                                              onClose={() => this.props.onResetApiControl()} />
                        : null}

                    {/* [404] Redirect  */}
                    {(api_control.response.status === 404)
                        ? window.location.href = `${window.location.origin}/404`
                        : null}

                    {/* [405] Method not allowed */}

                    {/* [500] Server status */}
                    {(api_control.response.status === 500)
                        ?
                        <SnackBarApiMessage open={true}
                                            autoHideDuration={4000}
                                            variant={'error'}
                                            message={api_control.message}
                                            onOpen={() => this.setState({openReportProblemDialog:true})}
                                            onClose={() => this.props.onResetApiControl()} />
                        : null}
                </div>
                : <NoConnectionAlert open={true} retryApiCall={false} />}
                </>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user,
    api_control: state.api_control
})

const mapActionsToProps = {
    onUpdateApiControl: updateApiControl,
    onResetApiControl: resetApiControl,
    onUpdateUser: updateUser
}

export default connect(mapStateToProps, mapActionsToProps)(ApiMessageControl)
