import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import CircularProgress from '@material-ui/core/CircularProgress'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { resetApiControl } from '../../../redux/actions/api-control-action'

const styles = theme => ({
    close: {
        width: theme.spacing.unit * 4,
        height: theme.spacing.unit * 4
    }
})

const noConnectionTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#ffffff'
        }
    }
})

const timeWaiting = 120

class NoConnectionAlert extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentCount: timeWaiting,
            intervalId: null,
            loader: false
        }
    }

    componentDidMount() {
        let intervalId = setInterval(this.timer.bind(this), 1000)
        this.setState({intervalId: intervalId})
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            loader: false,
            currentCount: timeWaiting
        })
    }

    timer() {
        if (this.state.currentCount > 1) {
            // setState method is used to update the state
            this.setState({
                currentCount: this.state.currentCount - 1
            })
        } else {
            this.setState({
                loader: true
            })
        }
    }

    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId)
    }

    retryAction() {
        this.setState({
            loader: true,
            currentCount: timeWaiting
        })
        this.props.action()
    }

    render() {
        const { loader } = this.state

        return (
            <div>
                <MuiThemeProvider theme={noConnectionTheme}>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        open={this.props.open}
                        ContentProps={{'aria-describedby': 'message-id'}}
                        autoHideDuration={4000}
                        onClose={() => this.props.onResetApiControl()}
                        message={
                            <div>
                                <span id='message-id'>Si è verificato un errore, controllare la connessione ad internet e riprovare</span><br />
                                {(this.props.retryApiCall)
                                    ? (!loader) ? <span style={{fontSize: 11}}>Nuovo tentativo automatico tra {this.state.currentCount}</span> : null
                                    : null
                                }
                            </div>

                        }
                        action={(this.props.retryApiCall) ? [
                            <Button disabled={loader} key='undo' color='primary' size='small' onClick={() => this.retryAction}>
                                {(!loader) ? <span>Riprova</span> : null}
                                {(loader) ? <CircularProgress style={{color: '#fff'}} size={16} /> : null}
                            </Button>
                        ] : null}
                    />
                </MuiThemeProvider>
            </div>
        )
    }
}

NoConnectionAlert.propTypes = {
    classes: PropTypes.object.isRequired,
    action: PropTypes.func,
    open: PropTypes.bool,
    retryApiCall: PropTypes.bool
}

const mapStateToProps = state => ({
    api_control: state.api_control
})

const mapActionsToProps = {
    onResetApiControl: resetApiControl
}

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(NoConnectionAlert)
