import React from 'react';
// material ui import
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// components import
import ImportStepper from './components/ImportLinkStepper';
import ImportComponentsUploadFile from './components/importLinkUploadFile';
import ImportComponentsMatch from './components/ImportLinkMatch';
import ImportComponentsCompleted from './components/ImportLinkCompleted';
import AlertDialog from '../alertDialog/AlertDialog';
// api imports
import ProductApi from '../../../../services/ProductApi';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../redux/actions/api-control-action';
import API from "../../../../services/API";
import LanguageApi from "../../../language-select/LanguageApi";
import LanguageSelect from "../../link/components/LanguageSelect";
import {translate} from "../../../../translations/i18n";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles  = theme => ({
    backButton: {
        marginRight: theme.spacing.unit,
    },
    footer:{
        display: 'flex',
        justifyContent: 'flex-end',
    }
});

class ImportLinkDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            labelMatch: [],
            cvsLinks : [],
            languageValue: [],
            values : [],
            fileUploaded: null // file uploaded
        }
    }

    componentDidMount(){



        if (this.props.language && this.props.multilanguage.languageValues)
            this.setState({values : this.props.multilanguage.languageValues});

       if (this.props.multilanguage && this.props.multilanguage.defaultlang && this.props.language === true)
            this.setState({languageValue : [this.props.multilanguage.defaultlang]});


    }

    handleChangeMultiple = (event) => {

        let  { value } = event.target;

        if (value !== 'all')
        value = Number(value);

        let tmpValue =  this.state.languageValue

        let tmpI = tmpValue.findIndex(item => item === value)


        if (value === 'all') {
            if (this.state.values.filter(lng => tmpValue.includes(lng.id) === true).length === this.state.values.length)
                tmpValue = [];
            else
                this.state.values.map(item => {
                    let tmpIdx = tmpValue.findIndex(tmpV => tmpV === item.id);

                    if (tmpIdx === -1)
                        tmpValue.push(item.id);
                });
        }

        if (tmpI === -1 && value !== 'all')
            tmpValue.push(value);
        else if (value !== 'all')
            tmpValue.splice(tmpI , 1);


        this.setState({languageValue: tmpValue});
    };

    // ==================================================
    // RENDER STEP CONTENT
    // ==================================================
    getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:return <ImportComponentsUploadFile handleUpdateImportMatch={(file, labelMatch) => this.updateImportMatch(file, labelMatch)}/>;
            case 1:return <ImportComponentsMatch labelList={this.props.labelList}
                                                 multilanguage={this.props.multilanguage}
                                                 cvsLinks={this.state.cvsLinks}
                                                 labelMatch={this.state.labelMatch}
                                                 handleChangeLanguage={this.handleChangeMultiple}
                                                 languageValue={this.state.languageValue}
                                                 values={this.state.values}
            />;
            case 2:return <ImportComponentsCompleted/>;
            default:return 'Uknown stepIndex';
        }
    }

    // ==================================================
    // UPDATE LABEL AFTER DROPZONE IMPORT
    // ==================================================
    updateImportMatch(file, cvsLinks){
        this.setState({
            fileUploaded: file,
            cvsLinks: cvsLinks,
        }, this.props.handleNext())
    }

    // ==================================================
    // MATCH SUBMIT
    // ==================================================
    onMatchSubmit(){
        this.setState({loader:true})
        const {values} = this.state

        let product_id = this.props.idProduct;
        let model_id = this.props.idModel;
        let category_id = this.props.idCategory;

        let api;
        (model_id) ?
        api = new API(`model/${model_id}/category/${category_id}/links`)
            :
        api = new API(`product/${product_id}/category/${category_id}/links`);

        let tmpFlag = this.state.languageValue.map(item => Number(item));


        let csvLinksFormatted = this.state.cvsLinks.map(item => ({name : item.name, link : item.link , flags : tmpFlag }))
        let oldLinks = this.props.links;

        let result = oldLinks.filter(o1 => csvLinksFormatted.some(o2 => o1.name === o2.name));

        let updatedLinksList = [];

        api.post(csvLinksFormatted).then(
            res => {
                updatedLinksList = oldLinks.concat(res.data);

                if (res.data.length > 0) {
                    res.data.map(item =>
                        item.flags.map(lng =>
                        ProductApi.addLinkLabels({
                            title: item.name,
                            description: '',
                            flag: lng
                        }, item.id, lng.code).then(
                            r => {
                            },
                            err => {

                            }
                        ))
                    )
                };

                this.props.ImportSuccessful({
                    data : {
                        links : updatedLinksList
                    }
                })
                this.props.handleNext()
                this.setState({loader: false});
            },
            err => {
                this.setState({loader: false});
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'importComponent', );
            })
    }

    render() {
        const { classes, open, activeStep } = this.props;
        const { loader } = this.state;
        const steps = [
            translate('import_link.step.1', {}, 'Carica il tuo file'),
            translate('import_link.step.2', {}, 'Conferma'),
            translate('import_link.step.3', {}, 'Completato')];

        return (
            <div>
                <Dialog
                    open={open}
                    onClose={this.props.onClose}>
                    <DialogTitle>{translate('import_link.title', {}, 'Importazioni link macchina')}</DialogTitle>
                    <DialogContent style={{paddingTop:0}}>
                        {/* Controller Stepper */}
                        <ImportStepper steps={steps}
                                       activeStep={this.props.activeStep}
                                       handleReset={() => this.props.handleReset()}
                                       handleBack={() => this.props.handleBack()}
                                       handleNext={() => this.props.handleNext()}/>
                        {/* Container */}
                        <div>
                            {/* Step content */}
                            <div style={{marginBottom:20}}>
                                {this.getStepContent(activeStep)}
                            </div>
                            {/* footer container */}
                            <div className={classes.footer}>
                                {(activeStep < steps.length - 1)?
                                    <Button
                                        onClick={() => (activeStep!==0)?
                                            this.setState({openBackConfirmDialog:true})
                                            :
                                            this.props.onClose()}
                                        className={classes.backButton}>
                                        {(activeStep === 0)?
                                            translate('import_link.close', {}, 'Annulla')
                                            :
                                            translate('import_link.back', {}, 'Indietro')
                                            }
                                    </Button>
                                    :null}
                                {(activeStep > 0)?
                                    <Button variant="contained"
                                            disabled={(loader || this.props.multilanguage === true && this.state.languageValue.length === 0 && activeStep === 1)}
                                            color="primary"
                                        /* eslint-disable no-unused-expressions */
                                            onClick={() => {
                                                (activeStep !== steps.length - 1)?
                                                    (activeStep === 1)?
                                                        this.onMatchSubmit()
                                                        :null
                                                    :
                                                    this.props.onClose();
                                            }}>
                                        {(!loader) ?
                                            (activeStep === steps.length - 1) ?
                                                translate('import_link.complete', {}, 'Completato')
                                                :
                                                translate('import_link.next', {}, 'Avanti')
                                            :
                                            <CircularProgress color="secondary" size={16} style={{marginLeft:0}}/>
                                        }
                                    </Button>
                                    :null}
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>

                {/* BACK CONFIRM DIALOG */}
                <AlertDialog open={this.state.openBackConfirmDialog}
                             title={translate('import_link.dialog.title', {}, "Sei sicuro di voler tornare indietro")}
                             message={translate('import_link.dialog.message', {}, "Tornando indietro verrà annullato il file caricato")}
                             onClose={() => this.setState({openBackConfirmDialog:false})}
                             confirmBtnText={translate('general.confirm', {}, "Conferma")}
                             onConfirm={() => {this.setState({openBackConfirmDialog:false},this.props.handleBack())}}/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    api_control: state.api_control,
    router: state.router,
    multilanguage: state.multilanguage,
})
const mapActionsToProps = {
    onUpdateApiControl : updateApiControl
}

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(ImportLinkDialog);
