import { combineReducers } from "redux";

import language from "./language-reducer";
import user from "./user-reducer"
import license from "./license-reducer"
import utils from "./utils-reducer";
import api_control from "./api-control-reducer";
import router from "./router-reducer";
import loaders from "./loaders-reducer";
import multilanguage from "./multilanguage-reducer";

export default combineReducers({
    language,
    user,
    license,
    utils,
    api_control,
    router,
    loaders,
    multilanguage
})
