import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../redux/actions/api-control-action';
// i18n translation

import AdminTheme from "../../../views/application/ApplicationTheme";
import {Typography} from "@material-ui/core";

const styles  = theme => ({
    demoVersion:{
        borderTopLeftRadius:4,
        borderBottomLeftRadius:4,
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        padding:'20px 16px',
        boxShadow:'0px 1px 2px rgba(0,0,0,0.7)',
        position:'fixed',
        right:-2,
        bottom:40,
        width:300,
        height:90,
        '& p':{
            fontSize:16,
            color:'#fff',
        }
    }
});

class DemoLicenseBanner extends Component {

    constructor(props){
        super(props);
        this.state = {}
    }

    componentDidMount(){}

    render() {
        const { classes, license } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.demoVersion} style={{background:AdminTheme.palette.primary.main}}>
                    <Typography style={{
                        fontWeight:'700'
                    }}>Demo version</Typography>
                    <Typography style={{
                        fontWeight:'400'
                    }}>la tua licenza scadrà tra {Math.round(Math.abs(new Date(license.expire) - Date.now()) / (1000 * 3600 * 24))} giorni</Typography>
                    <a href="mailto:info@ubilod.com">
                        <Typography style={{
                            fontSize:14,
                            color:AdminTheme.palette.active.main
                        }}>Richiedi la tua licenza</Typography>
                    </a>
                </div>
            </div>
        )
    }
}


DemoLicenseBanner.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    license: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    api_control: state.api_control,
    router: state.router,
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl
}

export default compose(

    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(DemoLicenseBanner);
