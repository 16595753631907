import React, { Component } from 'react';
import PropTypes from 'prop-types';
// material ui import
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateLoaders } from '../../../../../redux/actions/loaders-action';
import { updateApiControl } from '../../../../../redux/actions/api-control-action';
import { updateRouter } from "../../../../../redux/actions/router-action";
// api service import
import ProductApi from '../../../../../services/ProductApi';
// containers import
import BackofficeViewContainer from '../../../layout/container/AdminContainer';
// components import
import Search from '../../../../../components/ui/search/Search';
import SnackbarLoader from '../../../../../components/ui/loaders/snackbar/SnackbarLoader';
import MachineComponentsTable from './components/MachineComponentsTable';
import FunctionsToolbar from '../../../../../components/ui/toolbar/FunctionsToolbar';
import ImportComponentsDialog from './components/ImportComponentsDialog';
import OrderComponentsDialog from './components/OrderComponentsDialog';
import AlertDialog from '../../../../../components/ui/dialogs/alertDialog/AlertDialog';
import AddComponentDialog from "./components/AddComponentDialog";
import ConfirmDialog from '../../../../../components/ui/dialogs/confirmDialog/ConfirmDialog';
import {translate} from "../../../../../translations/i18n";
import {Plus, UploadCloud, Trash } from 'react-feather'

const styles  = () => ({

});

class MachineComponents extends Component {

    constructor(props){
        super(props);
        this.state = {
            activeMachine: this.props.match.params.machine_id,
            product: null,
            permissions: null,
            idCategory: null,
            componentsList:[],
            componentsFound:[],
            selectedComponents: [],
            labelList: [
                {code: 'sigle_label', label: 'sigle'},
                {code: 'catalog_code_label', label: 'catalog_code'},
                {code: 'inner_code_label', label: 'inner_code'},
                {code: 'manufacturer_label', label: 'manufacturer'},
                {code: 'description_label', label: 'description'},
            ],
            activeStep: 0,
            dialogLoader:false,
        }
    }

    // ==================================================
    // GET THE COMPONENTS LIST
    // ==================================================
    componentDidMount(){
        window.scrollTo(0, 0);
        this.setState({ loader: true });
        ProductApi.getComponent(this.state.activeMachine).then(
            res => {
                // ====== UPDATE BREADCRUMB ====== //
                this.props.onUpdateRouter({
                    ...this.props.router,
                    activeMachineName: res.data.product.name,
                    activeMachineLabels: res.data.product.labels
                });

                this.setState({
                    componentsList: res.data.components,
                    componentsFound: res.data.components,
                    product: res.data.product,
                    permissions: res.data.permissions,
                    idCategory: res.data.category_id,
                    loader:false,
                })
            },
            err => {
                this.setState({ loader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'loadComponentMachine', );
            }
        )
    }

    // ==================================================
    // IMPORT COMPONENTS STEPPER
    // ==================================================
    handleStepperNext() {
        this.setState(() => ({
            activeStep: this.state.activeStep + 1,
        }));
    };

    handleStepperBack() {
        this.setState(() => ({
            activeStep: this.state.activeStep - 1,
        }));
    };

    // ==================================================
    // ON COMPLETE IMPORT
    // ==================================================
    handleOnCompleteImport(components) {
        let tmpComponentsList = this.state.componentsList;
        tmpComponentsList = tmpComponentsList.concat(components);
        this.setState({
            componentsList: tmpComponentsList,
            componentsFound: tmpComponentsList,
            loader:false,
        },
            this.handleStepperNext()
        )
    }

    // ==================================================
    // ON UPDATE COMPONENT
    // ==================================================
    addComponent(newComponent){
        this.setState({ dialogLoader: true });
        ProductApi.createComponent(this.state.activeMachine, newComponent).then(
            res => {
                this.setState(prevState => ({
                    componentsList: [...prevState.componentsList, res.data],
                    componentsFound: [...prevState.componentsFound, res.data],
                    dialogLoader:false,
                    openAddComponentDialog: false,
                }));
            },
            err => {
                this.setState({ dialogLoader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'createComponent', );
            }
        )
    }

    // ==================================================
    // ON DELETE COMPONENTS
    // ==================================================
    deleteComponents(){
        this.setState({ openDeleteConfirmDialog: false });
        this.state.selectedComponents.map((component,index) =>
            ProductApi.deleteComponentById(this.state.activeMachine, this.state.selectedComponents[index].id).then(
                () => {
                    let components = [...this.state.componentsList];
                    const indexRemove = components.indexOf(component);
                    if (indexRemove >= 0) components.splice(indexRemove, 1);

                    this.setState({
                        componentsList: components,
                        componentsFound: components,
                        selectedComponents: (index === this.state.selectedComponents.length-1)?[]:this.state.selectedComponents,
                    });
                },
                err => {
                    this.setState({ loader: false });
                    // ====== API CONTROL MESSAGE ====== //
                    this.props.onUpdateApiControl(err, 'deleteFile', );
                }
            )
        );
    };

    addToolbarFunction = (permissions) => {
        let toolBarFunctions = [];
        if(!permissions) { return toolBarFunctions; }

        if(permissions.write){
            toolBarFunctions.push({
                label: translate( `general.add`, {},  'Aggiungi'),
                icon: (style) => <Plus style={style}/>,
                func: () => this.setState({openAddComponentDialog:true})});
            toolBarFunctions.push({label: translate( `general.import`, {},  'Importa'),
                icon: (style) => <UploadCloud style={style}/>,
                func: () => this.setState({openImportComponentsDialog:true})});
            // NEWFUNCTION toolBarFunctions.push({label: 'Ordina', icon: 'shopping_cart', disabled: (this.state.selectedComponents.length < 1), func: () => this.setState({openOrderComponentsDialog:true})});
        }
        if(permissions.delete)
            toolBarFunctions.push({label: translate( `general.delete`, {},  "Elimina"),
                icon: (style) => <Trash style={style}/>,
                disabled: (this.state.selectedComponents.length < 1), func: () => this.setState({openDeleteConfirmDialog: true})});
        return toolBarFunctions;
    };

    render() {
        const { classes } = this.props;
        const { componentsList, componentsFound, loader, permissions } = this.state;
        const { labelList } = this.state;
        const { dialogLoader } = this.state;

        return (
            <div className={classes.root}>
                <BackofficeViewContainer
                    title={translate( `machine_components.title`, {},  "Componenti macchina")}
                    subtitle={translate( `machine_components.subtitle`, {},  "Crea e gestisci i componenti della macchina")}
                    hasMarginTop={false}
                    background={false}
                    renderSearch={
                        <Search data={componentsList} // data list where the component search
                                keySearch={"sigle"} // key that have to search
                                placeholder={translate( `machine_components.search`, {},  "Cerca componente")} // placeholder
                                onResult={(searchResult) => this.setState({componentsFound: searchResult})}/> // action after search
                    }
                    renderToolbar={
                        <FunctionsToolbar items={this.addToolbarFunction(permissions)} />
                    }>

                    {(componentsList.length > 0) ?
                        <div style={{flex:1}}>
                            <MachineComponentsTable labelList={labelList}
                                                    components={componentsFound}
                                                    selectedComponents={this.state.selectedComponents}
                                                    permissions={permissions}
                                                    onSelectComponents={(selectedComponents) => this.setState({selectedComponents: selectedComponents})}/>
                        </div>
                        :
                        <Typography style={{marginTop:20}}>{translate( `machine_components.no_data`, {},  "Non sono presenti componenti")}</Typography>
                    }

                    {/* ADD COMPONENT DIALOG */}
                    {(this.state.openAddComponentDialog) ?
                        <AddComponentDialog title={translate( `machine_components.create`, {},  "Aggiungi componente")}
                                            labelList={labelList}
                                            loader={dialogLoader}
                                            open={this.state.openAddComponentDialog}
                                            onClose={() => this.setState({openAddComponentDialog:false})}
                                            onCreate={(newComponent) => this.addComponent(newComponent)}/>
                        :null}

                    {/* IMPORT FILE */}
                    {(this.state.openImportComponentsDialog)?
                    <ImportComponentsDialog open={this.state.openImportComponentsDialog}
                                            idMachine={this.state.activeMachine}
                                            idCategory={this.state.idCategory}
                                            labelList={labelList}
                                            activeStep={this.state.activeStep}
                                            handleBack={() => this.handleStepperBack()}
                                            handleNext={() => this.handleStepperNext()}
                                            handleOnCompleteImport={(components) => this.handleOnCompleteImport(components)}
                                            onClose={() => this.setState({openImportComponentsDialog:false, activeStep:0})}/>
                    :null}

                    {/* ORDER COMPONENT */}
                    {(this.state.openOrderComponentsDialog)?
                        <OrderComponentsDialog open={this.state.openOrderComponentsDialog}
                                               loader={false}
                                               selectedComponents={this.state.selectedComponents}
                                               onClose={() => this.setState({openOrderComponentsDialog:false})}
                                               onConfirm={() => this.setState({openConfirmOrder:true})} />
                    :null}

                    {/* Confirm order component dialog */}
                    {(this.state.openConfirmOrder)?
                        <ConfirmDialog title={translate( `machine_components.order`, {}, "Il tuo ordine è stata inoltrato")}
                                       subtitle={translate( `machine_components.order_message`, {}, "Riceverai al tuo indirizzo email il riepilogo dell'ordine")}
                                       closeBtn={translate( `machine_components.order_confirm`, {}, "Continua")}
                                       open={this.state.openConfirmOrder}
                                       onClose={() => this.setState({openConfirmOrder:false, openOrderComponentsDialog:false})}/>
                        :null}

                    {/* LOADER */}
                    <SnackbarLoader open={loader} message={translate( `machine_components.loader`, {}, "Elaborazione in corso")}/>
                </BackofficeViewContainer>

                {/* DELETE CONFIRM DIALOG */}
                <AlertDialog title={translate( `machine_components.delete`, {}, 'Eliminazione componenti')}
                             message={translate( `machine_components.delete_message`, {}, 'Sicuro di voler eliminare i file selezionati?')}
                             confirmBtnText={translate( `machine_components.delete_confirm`, {}, 'Elimina')}
                             open={this.state.openDeleteConfirmDialog}
                             onClose={() => this.setState({openDeleteConfirmDialog: false})}
                             onConfirm={() => this.deleteComponents()} />
            </div>
        )
    }
}


MachineComponents.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    utils: state.utils,
    api_control: state.api_control,
    router: state.router
});


const mapActionsToProps = {
    onUpdateLoaders : updateLoaders,
    onUpdateApiControl : updateApiControl,
    onUpdateRouter: updateRouter,
}

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(MachineComponents);
