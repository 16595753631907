import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
// translation import

// redux
import { compose } from 'redux';
import {translate} from "../../../../../../translations/i18n";
const styles = theme => ({
    root: {
        width: '100%',
    },
    label: {
        marginTop:-4
    },
    icon:{
        fontSize:22,
        margin:'0 8px',
        color:theme.palette.primary.main
    },
    select: {
        minWidth: 140,
    }
});

class ImportComponentsMatch extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            selected: this.props.labelMatch
        }
    }

    handleOnChange(e){
        e.persist();
        let tmpLabelMatch = this.state.selected;
        let index = this.state.selected.map(function (item) {return item.code}).indexOf(e.target.name);
        tmpLabelMatch[index] = e.target.value;
        this.setState(() => ({
            selected:tmpLabelMatch
        }));
    }

    render() {
        const { classes} = this.props;
        const { labelList } = this.props;
        const { labelMatch } = this.props;

        return (
            <div className={classes.root}>
                <Typography component="h2">
                    Abbina la colonna del file importato con l'etichetta richiesta
                </Typography>
                <form id="import-match-form">
                {labelList.map(item =>
                    <div style={{display:'flex',alignItems:'center',marginTop:20}}>
                        <FormControl className={classes.formControl}>
                            <select
                                name={item.code}
                                className={classes.select}
                                onChange={(e) => this.handleOnChange(e)}>
                                <option value="">-</option>
                                {labelMatch.map(el =>
                                    <option value={el.code}>
                                        {el.label}
                                    </option>
                                )}
                            </select>
                        </FormControl>
                        <Typography className={classes.icon}>=</Typography>
                        <Typography className={classes.label}>
                            {translate( `machine_components_import.${item.label}`, {},  `machine_components_import.${item.label}`)}
                        </Typography>
                    </div>
                )}
                </form>
            </div>
        );
    }
}

ImportComponentsMatch.propTypes = {
    classes: PropTypes.object,
};

export default compose( withStyles(styles, {withTheme: true}))(ImportComponentsMatch);
