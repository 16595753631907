import React from 'react';
import PropTypes from 'prop-types';
//import material-ui
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// components import
import BlurDiv from '../../../../components/ui-blur/BlurDiv';
// assets import
import LogoLicense from '../../../../assets/logos/logo-license.svg';
import moment from 'moment';
import {compose} from "redux";
import connect from "react-redux/es/connect/connect";

const styles = theme => ({
    root: {
        background: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    licenseCode:{
        marginTop:20,
        fontSize:16,
        color: theme.palette.primary.main,
        fontWeight:700,
    },
    button:{
        marginTop:12,
        minWidth:200,
        padding: '6px 12px',
        minHeight:20,
        '& > span:first-child':{
            textTransform:'capitalize',
        },
        [theme.breakpoints.down('xs')]:{
            padding: '6px 18px',
        }
    },
});

class LicenseDialog extends React.Component {
    render() {
        const { classes, theme } = this.props;
        const { user, license } = this.props;

        return (
            <Dialog
                PaperProps={{
                    style: {backgroundColor:'transparent',background:'transparent'}
                }}
                onBackdropClick={this.props.onClose}
                open={this.props.open}
                onClose={this.props.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <BlurDiv background={'#ffffff'}
                         //backgroundImage={backgroundImage}
                         opacity={0.96}
                         style={{borderRadius:4, display: 'flex', justifyContent:'center', alignItems:'center',flexDirection: 'column'}}>
                    <img alt={'ubilod-logo'} src={LogoLicense} width={180} style={{marginTop:20}}/>
                    <DialogContent>
                        <Typography className={classes.licenseCode}>Azienda: {user.company.business_name}</Typography>
                        <Typography className={classes.licenseCode}>P.IVA: {user.company.vat}</Typography>
                        <Typography className={classes.licenseCode}>Codice licenza: {user.company.license_code}</Typography>
                        <Typography className={classes.licenseCode}>Tipo licenza: <span style={{textTransform: 'capitalize'}}>{license.license}</span></Typography>
                        <Typography className={classes.licenseCode}>Valida fino al {moment(new Date(license.expire)).format('DD/MM/YYYY')}</Typography>
                    </DialogContent>
                    <Button color="primary"
                            variant="contained"
                            className={classes.button}
                            onClick={this.props.onClose}>Chiudi</Button>
                </BlurDiv>
            </Dialog>
        );
    }
}

LicenseDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    open: PropTypes.bool,
    actionClose: PropTypes.func.isRequired,
    licenseCode: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    license: state.license,
});


export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps)
)(LicenseDialog);

