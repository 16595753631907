import AuthService from './AuthService';
import GeneralSettings from '../settings/GeneralSettings';
const axios = require('axios');

class API {
    static baseUrl = GeneralSettings.baseUrl;

    constructor(name, authControl = true) {
        this.url = API.baseUrl + name;
        this.authToken = (AuthService.getLocalToken()) ? GeneralSettings.bearerKey + ' ' + AuthService.getLocalToken() : '';
        /*if (authControl) {
            AuthService.isAuthenticated()
        };*/
    }

    getById = (id, query = {}) => axios.get(`${this.url}/${id}`, {
        params: query,
        headers: {
            'Authorization': this.authToken
        }
    });

    get = (query = {}, options) => axios.get(this.url, {
        params: query,
        headers: {
            'Authorization': this.authToken
        },
        ...options
    });

    getUnsafe = (query = {}) => axios.get(`${this.url}`);

    post = (toCreate = {}, query = {}) => axios.post(this.url, toCreate, {
        params: query,
        headers: {
            'Authorization': this.authToken
        }
    });

    postUnsafe = (toCreate = {}, query = {}) => axios.post(this.url, toCreate, {
        params: query
    });

    update = (toUpdate) => axios.put(`${this.url}`, toUpdate, {
        headers: {
            'Authorization': this.authToken
        }
    });

    deleteById = (id) => axios.delete(`${this.url}/${id}`, {
        headers: {
            'Authorization': this.authToken
        }
    });

    delete = (query = {}) => axios.delete(`${this.url}`, {
        params: query,
        headers: {
            'Authorization': this.authToken
        }
    });

    createToken = (toCreate) => axios.post(this.url, toCreate);

    postFormData = (data,  name) => {
        const formData = new FormData();

        for (var key in data) {
            formData.append(name || key, data[key])
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': this.authToken,
            }
        };

        return axios.post(this.url, formData, config);
    };


    getImportData = (data) => axios.get(this.url, {
        params: data,
        headers: {
            'content-type': 'multipart/form-data',
            'Authorization': this.authToken
        }
    });

    getFormData = (query = {}) => axios.get(this.url, {
        responseType: 'arraybuffer',
        params: query,
        headers: {
            'Authorization': this.authToken
        }
    });

    download = () => {
        const config = {
            responseType: 'arraybuffer',
            headers: {
                'content-type': 'application/x-zip-compressed',
                'Authorization': this.authToken,
            }
        };

        return axios.get(`${this.url}`, config)
    };
}

export default API
