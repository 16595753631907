import React from 'react';
import PropTypes from 'prop-types';

// material ui import
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import {translate} from "../../../../../translations/i18n";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles  = theme => ({
    dialogPaper: {
        [theme.breakpoints.down('xs')]:{
            margin: 10,
        },
    },
    textField: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        width: '100%',
    },
    container: {
        display:'flex',
        flexDirection:'column',
        marginTop:8
    },
    dialogAction: {
        margin: '0px 20px',
        marginBottom:20,
    },
    addContact:{
        cursor:'pointer',
        display:'flex',
        alignItems:'center',
        color:theme.palette.primary.main
    }
});


class AssociateContactDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            product: null,
            contacts: [],
            selectedContact: null,
            createContact: false,
            newContact:{label:"",description:""},
            contactAlreadyExist:false,
        }
    }

    componentDidMount(){
        const { product , contacts } = this.props;
        this.setState({
            product: product,
            contacts: contacts
        })
    }


    handleChange = (e) => {
        // control if the contact label name already exist
        if(e.target.name === 'label'){
            // get the array of contacts already present
            let contacts = this.state.contacts.map(item => item.label)
            this.setState({contactAlreadyExist:contacts.includes(e.target.value)})
        }
        e.persist();
        this.setState(prevState => ({
            newContact:{
                ...prevState.newContact,
                [e.target.name] : e.target.value
            }
        }));
    }


    handleSelectChange = (e) => {
        e.persist();
        this.setState(() => ({
            selectedContact: e.target.value
        }));
    };

    render() {
        const { contacts, createContact, newContact,contactAlreadyExist, selectedContact } = this.state;
        const { classes, title, message, confirmBtnText, open, loader } = this.props;

        return (
            <div>
                <Dialog
                    open={open}
                    PaperProps={{ classes: {root: classes.dialogPaper } }}
                    onClose={this.props.onClose}>
                    <DialogTitle>{title}</DialogTitle>
                    <form onSubmit={(e)=> {e.preventDefault(); (!createContact)?this.props.onSubmit(selectedContact):this.props.onSubmit(selectedContact,newContact)}}
                          className={classes.container}
                          onKeyPress={(e) => (e.which === 13)?e.preventDefault():null}>
                        <div>
                        <DialogContent style={{paddingTop:0}}>
                            {/* Select contact */}
                            {(!createContact)?
                                <div>
                                    <DialogContentText>
                                        {message}
                                    </DialogContentText>
                                    <FormControl className={classes.textField}>
                                        <InputLabel shrink={true} htmlFor="contact">Contatti</InputLabel>
                                        <Select
                                            native
                                            onChange={(e) => this.handleSelectChange(e)}
                                            inputProps={{
                                                name: 'contact',
                                                id: 'contact',
                                            }}
                                            disabled={loader}>
                                            <option value="">Seleziona una contatto</option>
                                            {contacts.map( contact =>
                                                <option selected={contact.id === selectedContact} value={contact.id}>{contact.label}</option>
                                            )}
                                        </Select>
                                    </FormControl>
                                    {/*<Typography className={classes.addContact} onClick={() => this.setState({createContact:true})}>
                                        <Icon style={{fontSize:13}}>add</Icon>
                                        Nuovo contatto
                                    </Typography>*/}
                                </div>
                            :null}
                            {/* Create new contact */}
                            {(createContact)?
                                {/*<div>
                                    <DialogContentText>
                                        Inserisci nei campi sottostanti nome e descrizione
                                    </DialogContentText>
                                    <TextField
                                        error={contactAlreadyExist}
                                        required
                                        label={"Etichetta"}
                                        name="label"
                                        helperText={(contactAlreadyExist)?"Etichetta già presente":null}
                                        InputLabelProps={{shrink:true}}
                                        className={classes.textField}
                                        value={newContact.label}
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                    <TextField
                                        label="Descrizione"
                                        name="description"
                                        multiline
                                        rowsMax="4"
                                        InputLabelProps={{shrink:true}}
                                        className={classes.textField}
                                        value={newContact.description}
                                        onChange={(e) => this.handleChange(e)}
                                        margin="normal"
                                    />
                                    <Typography className={classes.addContact}
                                                style={{marginTop:8}}
                                                onClick={() => this.setState({createContact:false})}>
                                        <Icon style={{fontSize:13}}>bookmarks</Icon>
                                        Torna alle mia categorie
                                    </Typography>
                                </div>*/}
                            :null}
                        </DialogContent>
                        <DialogActions className={classes.dialogAction}>
                            <Button onClick={() => this.props.onClose()} color="primary">
                                {translate( `general.cancel`, {},  "Annulla")}
                            </Button>
                            {(!createContact)?
                                <Button disabled={!selectedContact}
                                    type="submit"
                                    variant="outlined"
                                    color="primary">
                                    {(!loader)? confirmBtnText : <CircularProgress size={16} style={{marginLeft:0}}/>}

                                </Button>
                                :
                                <Button disabled={(!this.state.newContact.label || this.state.newContact.label==="") || contactAlreadyExist}
                                        type="submit"
                                        variant="outlined"
                                        color="primary">
                                        {(!loader)? "Crea e associa" : <CircularProgress size={16} style={{marginLeft:0}}/>}

                                </Button>
                            }
                        </DialogActions>
                        </div>
                    </form>
                </Dialog>
            </div>
        );
    }
}


AssociateContactDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    confirmBtnText: PropTypes.string.isRequired,
    loader: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired

};

export default withStyles(styles, {withTheme: true})(AssociateContactDialog);
