export const UPDATE_MULTILANGUAGE = 'multilanguage:updateMultilanguage';

export function updateMultilanguage(newMultilanguage) {
    return {
        type: UPDATE_MULTILANGUAGE,
        payload: {
            multilanguage: newMultilanguage
        }
    }
}
