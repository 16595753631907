import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Dialog, Button, DialogContent, DialogTitle,DialogActions, Typography, TextField} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    fieldsRoot : {
        marginTop: 12,
        display: 'flow-root',
    },
    root: {
        marginBottom: 20,
    },
    content: {
        padding: 12,
    },
    input: {
        marginTop: 20,
        width: '100%',
    },
    btn: {
        minWidth: 80,
    },
    header: {
        padding: 20,
    },
    draggableHeader: {
        cursor: 'move',
    },
    dialog: {
        '& .MuiDialog-paper': {
            minWidth: 375,
        },
    },
    title: {
        textTransform: 'uppercase',
        '& h2':{
            fontSize:28
        }
    },
    subtitle: {
        textTransform: 'uppercase',
        fontSize: 16,
        marginTop: 8,
    },
    label: {
        color: '#7a7e80',
    },
    valueRoot : {
        flexBasis: '33%',
        minWidth: 200,
        backgroundColor : '#E5E5E5',
        minHeight: '60px!important',
        margin: '4px 8px',
        marginBottom: 20,
        float: 'left',
        display: 'flex',
        '& div:first-of-type': {
            flex: 1,
        },
    },
    valueContainer : {
      display : 'flex',
      flexWrap : 'wrap',
      alignItems : 'center',
      justifyContent : 'space-between'
}
}));

export default function AddDialog(props) {
    const { onClose = () => null, onConfirm, open, subtitle, title, cancelText, confirmText, children, data, fields} = props;
    const classes = useStyles(props);
    const [formData, setFormData] = useState({});

    function onChange(name , value){
        let tmpFormData = formData;

        tmpFormData[name] = value;

        setFormData(tmpFormData)
    }

    return (
        <Dialog
            draggable={false}
            maxWidth="md"
            open={open}
            title={title}
            subtitle={subtitle}
            onClose={() => onClose()}
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby={title}
            className={classes.dialog}
        >
            <DialogTitle id="dialog-title">
                <Typography className={classes.title}>{title}</Typography>

                <Typography className={classes.subtitle}>{subtitle}</Typography>
            </DialogTitle>

            <DialogContent className={classes.valueContainer} dividers>

                {fields.map(item =>
                    item.key && (
                        <div className={classes.valueRoot}>
                    <TextField
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                    fullWidth
                    name={item.key}
                    label={item.label}
                    required={item.required}
                    value={formData[item.key]}
                    />
                        </div>
                    ))
                }
            </DialogContent>
            <DialogActions>

                <Button className={classes.btn} onClick={() => onClose()}>
                    {cancelText}
                </Button>

                {onConfirm
                 && <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={() => onConfirm()}
                    className={classes.btn}
                >
                    {confirmText}
                </Button>}

            </DialogActions>
        </Dialog>
    )
}
