import React, {Component} from 'react';
import PropTypes from 'prop-types';
// material-ui import
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
// i18n import
import { compose } from 'redux';
import {translate} from "../../../translations/i18n";

const styles = () => ({
    root: {},
    strength1:{'& div':{background: '#b5b5b5'}},
    strength2:{'& div':{background: '#ba0018'}},
    strength3:{'& div':{background: '#e9b200'}},
    strength4:{'& div':{background: '#0077a8'}},
    strength5:{'& div':{background: '#04aa00'}},
});

// Define the min strong security
const minSecurity = 60; // strong
class FirstAccessChangePassword extends Component {

    constructor(props){
        super(props);
        this.state = {
            strength_label: '',
            strength_value: 0,
        }
    }

    handleOnChange(event){
        let valueStrength = this.checkStrength(event.target.value);
        this.setState({
            strength_label: (event.target.value !== '')?
                translate( 'password_strength.'+ valueStrength, {},  'password_strength.'+ valueStrength):'',
            strength_value: (event.target.value !== '')?valueStrength:0,
        });
        let isPassStrong = valueStrength > minSecurity;
        this.props.onChange(event, isPassStrong)
    }

    getColorPasswordStrength(value, classes){
        switch(value){
            case 20: return classes.strength1;
            case 40: return classes.strength2;
            case 60: return classes.strength3;
            case 80: return classes.strength4;
            case 100: return classes.strength5;
            default: return null
        }
    }

    scorePassword(pass) {
        var score = 0;
        if (!pass) {
            return score;
        }

        var letters = {};
        for (var i = 0; i < pass.length; i++) {
            letters[pass[i]] = (letters[pass[i]] || 0) + 1;
            score += 5.0 / letters[pass[i]];
        }

        var variations = {
            digitals: /\d/.test(pass),
            lowerCaseLetters: /[a-z]/.test(pass),
            upperCaseLetters: /[A-Z]/.test(pass),
            nonSpaceWords: /\W/.test(pass),
        }

        let variationCount = 0;
        for (var check in variations) {
            variationCount += (variations[check] === true) ? 1 : 0;
        }
        score += (variationCount - 1) * 10;

        return parseInt(score,10);
    }

    checkStrength(pass) {
        var score = this.scorePassword(pass);

        if (score > 80) {
            return 100;
        } else if (score > 60) {
            return 80;
        } else if (score > 40) {
            return 60;
        } else if (score > 20) {
            return 40;
        } else if (score <= 10) {
            return 20;
        } else {
            return 0;
        }
    }

    render(){
        const { classes } = this.props;
        const { strength_label, strength_value } = this.state;

        return (
            <div className={classes.root}>
                <TextField
                    formhelpertext={'La password deve contenere almeno un carattere maiuscolo e un numero'}
                    {...this.props}
                    onChange={this.handleOnChange.bind(this)}
                />
                <LinearProgress variant="determinate"
                                className={this.getColorPasswordStrength(strength_value,classes)}
                                style={{background:'#f5f6f7', marginTop:12, marginBottom:4}}
                                value={(this.props.value !== "")?strength_value:0}/>
                {(strength_value>0 && this.props.value !== "")?<Typography>{strength_label}</Typography>:<Typography>&nbsp;</Typography>}
            </div>
        );
    }
}

FirstAccessChangePassword.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default compose(

    withStyles(styles, {withTheme: true}),
)(FirstAccessChangePassword);



