import React from 'react';
import PropTypes from 'prop-types';
// Material ui import
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// utils import
import Utils from '../../../../utils/Utils';
// api import
import SupportApi from '../../../../services/SupportApi';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux'
import { updateUser } from '../../../../redux/actions/user-action';
import { updateApiControl } from '../../../../redux/actions/api-control-action';


const styles  = () => ({
    root: {}
})
class ReportProblemDialog extends React.Component {
    state = {
        message: '',
        error: false,
    };

    handleMessageChange = (e) => {
        this.setState({
            message: e.target.value
        })
    };

    handleSendReport = () => {
        SupportApi.createSupportTicket({
            message: this.state.message,
            url: window.location.href,
            browser: Utils.getBrowserUserAgent(),
            device:Utils.getDeviceType(),
        })
        .then(
            res => {
                // Clean the message
                this.setState({message: ""});
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(res, 'supportTicket',  'Segnalazione inviata correttamente');
                this.props.onClose();
            },
            err => {
                console.log(err.response)
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'supportTicket', );
            }
        );
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.props.onClose()}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Segnala un problema</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Hai riscontrato un problema? Descrivicelo con un messaggio diretto.
                    </DialogContentText>
                    <TextField
                        style={{marginTop:20}}
                        autoFocus
                        margin="dense"
                        id="reportProblemMessage"
                        label="Descrivi il problema"
                        type="text"
                        multiline
                        maxRows={7}
                        fullWidth
                        value={this.state.message}
                        onChange={(e) => {this.handleMessageChange(e)}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.onClose()} color={this.props.color}>
                        Chiudi
                    </Button>
                    <Button onClick={this.handleSendReport} color={this.props.color}>
                        Invia segnalazione
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}


ReportProblemDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    api_control: state.api_control
});

const mapActionsToProps = {
    onUpdateUser : updateUser,
    onUpdateApiControl : updateApiControl
}


export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(ReportProblemDialog);
