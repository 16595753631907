import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// material-ui imports
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
// auth api imports
import ProductApi from '../../../services/ProductApi';
// assets import
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux'
import { updateUser } from '../../../redux/actions/user-action';
import { updateApiControl } from '../../../redux/actions/api-control-action';
// dialog import
import SignDialog from '../../oauth/sign/SignDialog';
import {translate} from "../../../translations/i18n";
import {updateMultilanguage} from "../../../redux/actions/multilanguage-action";

const styles  = theme => ({
    root:{
        marginTop:-64,
        display:'flex',
        background:theme.palette.primary.main,
        flex:1,
        height:'100vh',
        justifyContent:'center',
        alignItems:'center'
    }
});

class MachineQrCode extends Component {

    constructor(props){
        super(props);
        this.state = {
            codeMachine: (this.props.match.params.machine_qr_code)?this.props.match.params.machine_qr_code:'',
            machine_id: null,
        }
    }

    componentDidMount(){
        // if codeMachine is present called th api
        if(this.state.codeMachine){
            this.machineSign();
        }
    }

    // ========================================= //
    // MACHINE SIGN BY TOKEN
    // ========================================= //
    machineSign(){
        this.setState({loader: true});
        // Api call for auth user login
        ProductApi.getProductByToken(this.state.codeMachine)
            .then(
                res => {
                    if (res.status == 201) {
                        window.location.href = res.data.new_url;

                    } else {
                        // define if the user needs to sign in or can see the machine detail
                        let redirectOnDetail = (res.data.public)?true:(this.props.user.is_logged)?true:false;
                        this.setState({
                            loader: false,
                            machine_id: res.data.id,
                            machineName: res.data.name,
                            redirectOnDetail: redirectOnDetail,
                            openSignDialog: !redirectOnDetail,
                        });

                        let multilanguage = {
                            defaultlang : (res.data.flags.length > 0) ? res.data.flags[0].id :  1 ,
                            languageValues : res.data.flags,
                            selectedLanguage : [(res.data.flags.length > 0) ? res.data.flags[0].id :  1]
                        };

                        this.props.onUpdateMultilanguage(multilanguage)
                    }},
                err => {

                    this.setState({loader: false});
                    // ====== API CONTROL MESSAGE ====== //
                    this.props.onUpdateApiControl(err, 'getMachineByToken',  'Macchina non trovata');
                }
            );
    }

    render() {
        const { classes } = this.props;
        const { redirectOnDetail, machine_id, machineName, openSignDialog } = this.state;

        // Define the redirect on machine detail
        if (redirectOnDetail && machine_id) {
            return <Redirect to={`/machine/${machine_id}`}/>
        }

        return (
            <div className={classes.root}>
                <CircularProgress color={"secondary"} size={44}/>

                {/* DIALOG LOGIN TO ACCESS ON MACHINE */}
                {(!redirectOnDetail && openSignDialog)?
                    <SignDialog title={translate( `login.dialog.title`, {},  "Effettua il login")}
                                message={`
                                   ${translate( `login.dialog.subtitle.one`, {},  "La macchina")} 
                                   ${machineName}
                                   ${translate( `login.dialog.subtitle.two`, {},  "risulta essere privata, effettua il login per visualizzare i dettagli")}
                                   `}
                                redirectOnAuth={`/machine/${machine_id}`}
                                open={this.state.openSignDialog}
                                onClose={() => this.setState({openSignDialog:false})}/>
                    :null}
            </div>
        )
    }
}


MachineQrCode.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    router: state.router,
    api_control: state.api_control
});

const mapActionsToProps = {
    onUpdateUser : updateUser,
    onUpdateMultilanguage : updateMultilanguage,
    onUpdateApiControl : updateApiControl
}

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(MachineQrCode);
