import API from './API';

class FileApi {

    static url = 'file';


    // ========================================= //
    // FILE
    // ========================================= //

    static getFileUploadUrl(query){
        const api = new API(this.url + '/upload-url');
        return api.get(query);
    }

    static uploadGcsFile(url, file, type){
        const axios = require('axios');
        let config = {
            headers: {
                'Content-Type': type,
            }
        };
        return axios.put(url, file, config)
    }

}

export default FileApi