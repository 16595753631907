import React, {Component} from 'react';
import PropTypes from 'prop-types';
//import Components
//material-ui imports
import { withStyles } from '@material-ui/core/styles';
import ProductApi from "../../../../../services/ProductApi";
import BoxCard from "../../../../../components/ui/cards/BoxCard";
import CreateProductDialog from "./CreateMachineDialog";
import AlertDialog from "../../../../../components/ui/dialogs/alertDialog/AlertDialog";
import {compose} from "redux";
import connect from "react-redux/es/connect/connect";
import {updateApiControl} from "../../../../../redux/actions/api-control-action";
import {updateLoaders} from "../../../../../redux/actions/loaders-action";
import {updateRouter} from "../../../../../redux/actions/router-action";
import {updateUser} from "../../../../../redux/actions/user-action";
import {updateMultilanguage} from "../../../../../redux/actions/multilanguage-action";
import {translate} from "../../../../../translations/i18n";
import ModelApi from "../../../../../services/ModelApi";

const styles = () => ({
    root: {
        marginTop : 52,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
});

class ProductList extends Component {

    constructor(props){
        super(props);
        this.state = {
            loader: false,
            dialogLoader: false,
        }
    }

    componentWillReceiveProps(prevProps){
        if (prevProps.multilanguage !== this.props.multilanguage) {

            this.forceUpdate()
        }
    }

    // ==================================================
    // CREATE MACHINE
    // ==================================================
    createProduct(newProduct, labels = []){
        this.setState({ dialogLoader: true });
        newProduct.license = this.props.license.license;

        if (this.props.products.findIndex(item => item.name == newProduct.name) === -1 )
        ProductApi.createProduct(newProduct).then(
            res => {
                labels.map(item =>
                    ProductApi.addProductLabels(item, res.data.id, item.flag.code).then(
                        r => {
                        },
                        err => {
                        }
                    )
                );

                this.props.updateProductsCreate({...res.data, labels}, labels);
                this.setState(() => ({
                    dialogLoader:false,
                    openCreateProductDialog: false,
                }));
            },
            err => {
                this.setState({ dialogLoader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'createProduct', );
            }
        );
        else {
            this.setState({ dialogLoader: false });
            alert(`${newProduct.name} è già presente ${(this.props.license.license === 'commercial') ?
                translate( 'general.in_products', {},  'nei prodotti')
                :
                translate( 'general.in_machines', {},  'nelle macchine')
            }`)
        }
    }

    // ==================================================
    // DELETE MACHINE
    // ==================================================
    deleteProduct(id){
        this.setState({ dialogLoader: true });
        ProductApi.deleteProduct(id).then(
            () => {
                this.props.updateProductsDelete(id);
                this.setState(() => ({
                    dialogLoader:false,
                    openDeleteProductDialog: false,
                }));
            },
            err => {
                this.setState({ openDeleteProductDialog: false, dialogLoader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'deleteProduct', );
            }
        )
    }

    // ==================================================
    // UPDATE MACHINE
    // ==================================================
    updateProduct(updateProduct, labels = []){
        this.setState({ dialogLoader: true });
        ProductApi.updateProduct(updateProduct.id, updateProduct).then(
            res => {

                let toBDeleted = [];

                if (labels.length > 0)
                toBDeleted = res.data.labels.filter(item => labels.findIndex(v => v.flag.id === item.flag.id) === -1);

                if (labels.length > 0) {
                    labels.map(item =>
                        res.data.labels.length > 0 && res.data.labels.findIndex(v => v.flag.id == item.flag.id) !== -1 ?
                            ProductApi.editProductLabels(item, res.data.id, item.flag.code).then(
                                r => {
                                },
                                err => {
                                }
                            )
                            :
                            ProductApi.addProductLabels(item, res.data.id, item.flag.code).then(
                                r => {
                                },
                                err => {

                                }
                            )
                    );
                }

                if(toBDeleted.length > 0)
                    toBDeleted.map(item =>
                        ProductApi.deleteProductLabels(item, res.data.id, item.flag.code).then(
                            r => {
                            },
                            err => {

                            }
                        )
                    );

                this.props.updateProductUpdate({...res.data, labels});
                this.setState(() => ({
                    dialogLoader:false,
                    openUpdateProductDialog: false,
                }));
            },
            err => {
                this.setState({ dialogLoader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'updateProduct', );
            }
        )
    }

    render(){
        const { dialogLoader } = this.state;
        const { classes, user, products, models, baseLink, activeModel, license, languageValues, defaultlang, multilanguage } = this.props;

        return (
            <div className={classes.root}>
                {(user.type === 'MF')?
                    <BoxCard variant={"add"}
                             title={"+"}
                             disabled={this.state.loader}
                             subtitle={translate( `machines.add.${license.license}`, {},  (license.license === 'commercial') ? "Aggiungi Prodotto" : "Aggiungi Macchina")}
                             onClick={() => this.setState({openCreateProductDialog:true})}/>
                :null}

                {products.map((product, index) =>
                    <BoxCard key={product + '-' + index}
                             isShared={!product.is_owner}
                             title={product.name}
                             subtitle={product.description}
                             labels={product.labels}
                             link={`${baseLink}/${product.id}`}
                             image={product.image}
                             //onClick={() => this.setState({openUpdateProductDialog:true, activeUpdateProduct:product, productDialogReadOnly: true})}
                             onDelete={(user.type === 'MF') ? () => {this.setState({openDeleteProductDialog:true, activeDeleteId:product.id})} : null}
                             onUpdate={(user.type === 'MF') ? () => { this.setState({openUpdateProductDialog:true, activeUpdateProduct:product, productDialogReadOnly:false})}: null} />
                )}

                {/* CREATE MACHINE DIALOG */}
                {(this.state.openCreateProductDialog)?
                    <CreateProductDialog title={
                        translate( `model_manage.product_add_modal.${license.license}`, {},  (license.license === 'commercial') ? "Crea prodotto" : "Crea macchina")}
                                         languageValues={languageValues}
                                         defaultlang={defaultlang}
                                         loader={dialogLoader}
                                         model={activeModel}
                                         models={models}
                                         open={this.state.openCreateProductDialog}
                                         onClose={() => this.setState({openCreateProductDialog:false})}
                                         onCreate={(newProduct, labels) => this.createProduct(newProduct, labels)}/>
                    :null}

                {/* UPDATE MACHINE DIALOG */}
                {(this.state.openUpdateProductDialog && this.state.activeUpdateProduct)?
                    <CreateProductDialog title={
                        translate( `model_manage.product_edit_modal.${license.license}`, {},  (license.license === 'commercial') ? "Modifica prodotto":"Modifica macchina")
                    }
                                         languageValues={languageValues}
                                         defaultlang={defaultlang}
                                         loader={dialogLoader}
                                         product={this.state.activeUpdateProduct}
                                         models={models}
                                         open={this.state.openUpdateProductDialog}
                                         readOnly={this.state.productDialogReadOnly} // disable the edit
                                         onClose={() => this.setState({openUpdateProductDialog:false})}
                                         onCreate={(updateProduct, labels) => this.updateProduct(updateProduct, labels)}/>
                    :null}

                {/* DELETE CONFIRM DIALOG */}
                <AlertDialog open={this.state.openDeleteProductDialog}
                             title={
                                 translate( `machines.delete_modal.${license.license}`, {},  (license.license === 'commercial') ?"Eliminazione prodotto" : "Eliminazione macchina")
                                 }
                             message={
                                 translate( `machines.delete_modal_message.${license.license}`, {}, (license.license === 'commercial') ? "Sei sicuro di voler eliminare il prodotto?" : "Sei sicuro di voler eliminare la Macchina?")
                             }
                             confirmBtnText={
                                 translate( `model_manage.delete_modal_button`, {},  "Elimina")
                             }
                             loader={dialogLoader}
                             onClose={() => this.setState({openDeleteProductDialog:false})}
                             onConfirm={() => this.deleteProduct(this.state.activeDeleteId)}
                />
            </div>
        );
    }
}

ProductList.propTypes = {
    theme: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    products: PropTypes.array,
    baseLink: PropTypes.string,
    activeModel: PropTypes.object,
    models: PropTypes.array,
    updateProductsCreate: PropTypes.func,
    updateProductUpdate: PropTypes.func,
    updateProductsDelete: PropTypes.func,
};

const mapStateToProps = state => ({
    user: state.user,
    license: state.license,
    utils: state.utils,
    api_control: state.api_control,
    multilanguage: state.multilanguage,
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl,
};

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(ProductList);
