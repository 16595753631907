import React, {useState} from 'react';
//import material-ui
import {makeStyles, withStyles} from '@material-ui/core/styles';
import i18n from 'i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import LanguageSelect from "../../../language-select/LanguageSelect";
import {changeLanguage, translate} from "../../../../translations/i18n";
import Select from "@material-ui/core/Select/Select";
import {func} from "prop-types";

const useStyles = makeStyles(theme => ({
    deleteButton: {
        color: theme.palette.error.main,
    },
    title:{
        '& h2':{
            fontSize:18
        }
    }
}));

export default function LanguageDialog (props){

    const classes = useStyles(props);
    const { cancelBtnText = translate( `general.cancel`, {},  "Annulla"), confirmBtnText, loader } = props;
    const [value, setValue] = useState(i18n.language);


    function handleSelectChange(e){
        const {value} = e.target.value;

        setValue(e.target.value)
    }

    function handleConfirm() {
        changeLanguage(value)
    }

        return (
            <div>
                <Dialog
                    open={props.open}
                    onClose={props.onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" className={classes.title}>
                        {props.title}
                    </DialogTitle>
                    <DialogContent>
                        <Select
                            native
                            onChange={(e) => handleSelectChange(e)}
                            value={value}
                            disabled={loader}>
                            <option value="it">
                                italiano
                            </option>
                            <option value="en">
                                inglese
                            </option>
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.onClose} disabled={loader}>
                            {cancelBtnText}
                        </Button>
                        <Button onClick={() => handleConfirm()}
                                className={classes.deleteButton}
                                autoFocus>
                            {(!loader)?
                                confirmBtnText
                                :
                                <CircularProgress size={16} style={{marginLeft:0}}/>
                            }
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
}
