import React, {Component} from 'react';
import PropTypes from 'prop-types';
//material-ui imports
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../redux/actions/api-control-action';
import LanguageApi from "../../language-select/LanguageApi";
import LanguageSelect from "./components/LanguageSelect";
import FileAdd from "./components/FileAdd";
import {translate} from "../../../translations/i18n";

const styles = theme => ({
    dialogPaper: {
        [theme.breakpoints.down('xs')]:{
            margin: 10,
        },
    },
    dropzoneRoot: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    dropzoneText: {
        color: '#777',
        fontSize: 15,
        fontWeight: 200,
    },
    loaderFileStatusText: {
        marginTop:12,
        fontSize: 12,
    },
    dropzone: {
        width: '85%',
        height: 200,
        margin: 20,
        cursor: 'pointer',
        backgroundColor: '#e8e6e6',
        borderWidth: 1,
        borderColor: theme.palette.primary.main,
        borderStyle: 'dashed',
        borderRadius: 2,
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        fontSize: 68,
        marginTop: 20,
        color: theme.palette.primary.main,
    },
    dropText: {
        padding: 20,
        paddingTop:0,
        textAlign: 'center'
    },
    templateUrl: {
        color: theme.palette.active.main,
    }
});


class DialogDropzone extends Component {

    constructor(props){
        super(props);
        this.state={
            activeStep : 0,
            languageValue: [],
            values : []
        }
    }

    componentDidMount(){
        if (this.props.language === true){
            this.setState({values : this.props.multilanguage.languageValues})
            this.setState({languageValue : [this.props.multilanguage.defaultlang]})
        }
        else
            this.setState({activeStep : 1})
    }

    onDrop(acceptedFiles) {
        this.props.onDrop(acceptedFiles)
    }


     handleChangeMultiple = (event) => {

        let { value } = event.target;

        value = value === 'all' ? value : Number(value);

        let tmpValue =  this.state.languageValue;

        let tmpI = tmpValue.findIndex(item => item == value)


         console.log({value})


        if (value == 'all') {
            if (this.state.values.filter(lng => tmpValue.includes(lng.id) === true).length === this.state.values.length)
                tmpValue = [];
            else
                this.state.values.map(item => {
                    let tmpIdx = tmpValue.findIndex(tmpV => tmpV === item.id);

                    if (tmpIdx == -1)
                        tmpValue.push(item.id);
                });
        }

        if (tmpI == -1 && value !== 'all')
            tmpValue.push(value);
        else if (value != 'all')
            tmpValue.splice(tmpI , 1);

        this.setState({languageValue: tmpValue});

    };

    getStepContent(stepIndex, onDrop) {
        switch (stepIndex) {
            case 0:return <LanguageSelect
                contentText={translate('bulk_import.language', {}, 'Seleziona lingua')}
                                          handleChangeLanguage={this.handleChangeMultiple}
                                          languageValue={this.state.languageValue}
                                          values={this.state.values}
            />;
            case 1:return <FileAdd contentText={this.props.contentText}
                                   languageValue={this.state.languageValue}
                                   values={this.state.values}
                                   onDrop={(files) => onDrop(files, this.state.languageValue)}
                                   multiple={this.props.multiple}
                                   filesStep={this.props.filesStep}
                                   filesToBeSent={this.props.filesToBeSent}
            />;
            default:return 'Uknown stepIndex';
        }
    }

    handleStepperNext() {
        this.setState(() => ({
            activeStep: this.state.activeStep + 1,
        }));
    };

    handleStepperBack() {
        this.setState(() => ({
            activeStep: this.state.activeStep - 1,
        }));
    };

    render() {
        const { classes, multiple, loading, multilanguage = false } = this.props;
        const {  activeStep } = this.state;

        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.onClose}
                    PaperProps={{ classes: {root: classes.dialogPaper } }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" className={classes.title}>{this.props.title}</DialogTitle>

                    {this.getStepContent(activeStep, this.props.onDrop)}

                    <DialogActions>
                        <Button disabled={loading} onClick={(activeStep === 0 || multilanguage === false)? this.props.onClose : () => this.handleStepperBack()}>
                            {(activeStep === 0)?
                                translate( `general.cancel`, {},  "Annulla")
                                :
                                translate( `general.back`, {},  'Indietro')
                                }
                        </Button>
                        {(activeStep === 0) &&
                        <Button disabled={(loading || (this.state.languageValue.length === 0))} onClick={() => this.handleStepperNext()} variant="outlined"
                                color="primary">
                            {translate( `general.next`, {},  'Avanti')}
                        </Button>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

DialogDropzone.propTypes = {
    classes: PropTypes.object.isRequired,
    element: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    multiple: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    api_control: state.api_control,
    router: state.router,
    multilanguage : state.multilanguage
});

const mapActionsToProps = {
    onUpdateApiControl : updateApiControl
}

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(DialogDropzone);
