import TermsAndConditions from "../policy/termsAndConditions/TermsAndConditions";
import PrivacyAndCookiePolicy from "../policy/privacyAndCookiePolicy/PrivacyAndCookiePolicy";
import AcceptableUsage from "../policy/acceptableUsage/AcceptableUsage";
import RegulationOnIntellectualProperty
  from "../policy/regulationOnIntellectualProperty/RegulationOnIntellectualProperty";
import PageNotFound from "../error/404/PageNotFound";
import LandingPage from "./landingPage/LandingPage";
import MachineCode from "./machineCode/MachineCode";
import MachineDetail from "../application/views/machinesManager/MachineDetail";
import MachineCategoryFiles from "../application/views/machinesManager/MachineCategoryFiles";
import FileManager from "../application/views/machinesManager/fileManager/FileManager";
import SignIn from "../oauth/sign/SignIn";
import MachineQrCode from "./machineQrCode/MachineQrCode";
import MachineContacts from "../application/views/machinesManager/contactManager/MachineContacts";
import MachineComponents from "../application/views/machinesManager/componentsManager/MachineComponents";

const NoLoggedRoutes = [
  {
    // MAIN ROUTE linked on the AppBar
    path: "/",
    name: "SignIn",
    component: SignIn,
    exact: true,
  },
  {
    path: "/q/:machine_qr_code",
    name: "Machine qr code",
    icon: "",
    component: MachineQrCode,
    visible:false,
    collapse: null,
    exact: true,
    breadcrumbs:[
        {path: '/q'},
        {path: '/q/:machine_qr_code'},
    ],
  },
  {
    path: "/m",
    name: "Machine qr code",
    icon: "",
    component: MachineCode,
    visible:false,
    collapse: null,
    exact: true,
    breadcrumbs:[
      {path: '/m'},
    ],
  },
  {
    path: "/m/:machine_code",
    name: "Machine qr code",
    icon: "",
    component: MachineCode,
    visible:false,
    collapse: null,
    exact: true,
    breadcrumbs:[
      {path: '/m'},
      {path: '/m/:machine_code'},
    ],
  },
  {
    path: "/machine/:machine_id",
    name: "Machine detail",
    icon: "",
    component: MachineDetail,
    visible:false,
    collapse: null,
    exact: true,
    breadcrumbs:[
      {path: '/machine'},
      {path: '/machine/:machine_id'},
    ],
  },
  {
    path: "/machine/:machine_id/category/:category_id",
    name: "Machine category",
    icon: "",
    component: MachineCategoryFiles,
    visible:false,
    collapse: null,
    exact: true,
    breadcrumbs:[
      {path: '/machine'},
      {path: '/machine/:machine_id'},
      {path: '/machine/:machine_id/category/:category_id'},
    ],
  },
  {
    path: "/machine/:machine_id/category/:category_id/file/:file_id",
    name: "Machine file",
    icon: "",
    component: FileManager,
    visible:false,
    collapse: null,
    exact: true,
    breadcrumbs:[
      {path: '/machine'},
      {path: '/machine/:machine_id'},
      {path: '/machine/:machine_id/category/:category_id'},
      {path: '/machine/:machine_id/category/:category_id/file/:file_id'},
    ],
  },
  {
    path: "/machine/:machine_id/contacts",
    name: "Machine contacts",
    icon: "",
    component: MachineContacts,
    visible:false,
    collapse: null,
    exact: true,
    breadcrumbs:[
      {path: '/machine'},
      {path: '/machine/:machine_id'},
      {path: '/machine/:machine_id/contacts'},
    ],
  },
  {
    path: "/machine/:machine_id/components",
    name: "Machine components",
    icon: "",
    component: MachineComponents,
    visible:false,
    collapse: null,
    exact: true,
    breadcrumbs:[
      {path: '/machine'},
      {path: '/machine/:machine_id'},
      {path: '/machine/:machine_id/components'},
    ],
  },

  // Policy
  {
    path: "/terms-and-conditions",
    name: "Terms and conditions",
    icon: "",
    component: TermsAndConditions,
    visible:false,
    collapse: null,
    exact: true,
  },
  {
    path: "/privacy-and-cookie-policy",
    name: "Privacy and cookie",
    icon: "",
    component: PrivacyAndCookiePolicy,
    visible:false,
    collapse: null,
    exact: true,
  },
  {
    path: "/acceptable-usage",
    name: "Acceptable usage",
    icon: "",
    component: AcceptableUsage,
    visible:false,
    collapse: null
  },
  {
    path: "/regulation-on-intellectual-property",
    name: "Regulation on intellectual property",
    icon: "",
    component: RegulationOnIntellectualProperty,
    visible:false,
    collapse: null
  },
  {
    path: "/login",
    name: "SignIn",
    icon: "",
    component: SignIn,
    visible:false,
    collapse: null
  },
  {
    path: "/404",
    name: "Page not found",
    icon: "",
    component: PageNotFound,
    visible:false,
    collapse: null
  },
];

export default NoLoggedRoutes;
