import { UPDATE_APICONTROL } from '../actions/api-control-action';
import { RESET_APICONTROL } from '../actions/api-control-action';

export default function apiControlReducer(state = '', {type, payload}) {
    switch(type){
        case UPDATE_APICONTROL:
            return payload.api_control;
        case RESET_APICONTROL:
            return payload.api_control;
        default:
            return state;
    }
}