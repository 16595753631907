import { UPDATE_MULTILANGUAGE } from "../actions/multilanguage-action";

export default function multilanguageReducer(state = '', {type, payload}) {
    switch(type){
        case UPDATE_MULTILANGUAGE:
            return payload.multilanguage;
        default:
            return state;
    }
}
