import React, {useEffect, useState} from 'react';
// material ui import
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import {Typography} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    textField: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        width: '100%',
    },
    container: {
        display:'flex',
        flexDirection:'column',
        marginTop:8
    },
    dialogAction: {
        margin: '0px 20px',
        marginBottom:20,
    },
    counterContainer : {
    padding: '0.5rem 0.5rem 1.2rem 0.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
    fontWeight: 700,
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    border: '1px solid #ebebeb',
    borderRadius: '10px',
    textDecoration: 'none',
    color: '#000',
    backgroundColor : 'rgba(44,58,73,0.5)'
},

 countdownContainer : {
    lineHeight: '1.25rem',
    padding: '0 0.75rem 0 0.75rem',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
},

countdownDanger : {
    fontSize: 20,
    color: '#ff0000'
},
countdown:{
    fontSize: 20,
    lineHeight : 0
},
type : {
    textTransform: 'uppercase',
    fontSize: 12,
    lineHeight: '1rem'
},
    typeDanger : {
        textTransform: 'uppercase',
        fontSize: 12,
        lineHeight: '1rem'
    },
    btn: {
        color: '#fff',
        backgroundColor:theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.hover,
        },
    },
}));

const DateTimeDisplay = ({ value, type, isDanger }) => {
    const classes = useStyles();


    return (
        <div className={classes.countdownContainer}>
            <p className={isDanger ? classes.countdownDanger : classes.countdown}>{value}</p>
            <span className={isDanger ? classes.typeDanger : classes.type}>{type}</span>
        </div>
    );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    const classes = useStyles();

    return (
        <div className={classes.counterContainer} style={days < 3 ? {backgroundColor : '#ff6851'} : {}}>
                <DateTimeDisplay value={days} type={'Days'} isDanger={false} />
                <p>:</p>
                <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
                <p>:</p>
                <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
                <p>:</p>
                <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
        </div>
    );
};




const useCountdown = (targetDate) => {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
}


export default function NoLicenseDialog(props) {


    const classes = useStyles(props);
    const { open, loader, title, subtitle, type, user} = props;

    const [days, hours, minutes, seconds] = useCountdown(type === 'expired' ?  moment(user.company.licenses[0].expire).add(7,'d') :  user.company.licenses[0].expire);

    console.log({type})

        return (
            <div>
                <Dialog open={open}>
                    <DialogTitle sx={{fontWeight : 900}}>
                    <Typography style={{fontSize : 22, fontWeight : 'bold'}}>
                        {title}
                    </Typography>
                    </DialogTitle>
                        <div className={classes.container}>
                            <DialogContent style={{paddingTop:0}}>
                                <DialogContentText style={{paddingBottom:12}}>
                                    {subtitle}
                                </DialogContentText>

                                <ShowCounter
                                    days={days >= 0 ? days : 0}
                                    hours={hours >= 0 ? hours : 0}
                                    minutes={minutes >= 0 ? minutes : 0}
                                    seconds={seconds >= 0 ? seconds : 0}
                                />


                            </DialogContent>
                            <DialogActions className={classes.dialogAction}>

                                    <Button onClick={() => props.onClose()} color="primary">
                                        {type !== 'expired' ? 'Chiudi' : 'esci'}
                                    </Button>
                                <a href="mailto:info@ubilod.com">
                                <Button disabled={loader} className={classes.btn} variant="outlined" color="primary">
                                    RINNOVA
                                </Button>
                                </a>
                            </DialogActions>
                        </div>

                </Dialog>
            </div>
        );

}
