import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// components import

const styles  = () => ({
    root:{
        textAlign:'justify',
        padding:'40px 60px'
    }
});

class TermsAndConditions extends Component {

    componentDidMount(){

    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Typography variant="title" style={{marginBottom:40}}>TERMINI DI SERVIZIO</Typography>
                <Typography variant="body2">
                    <strong>Pubblicato:</strong> 15 Febbraio 2019<br/>
                    <strong>Data di validità:</strong> 15 Febbraio 2019<br/><br/>
                    Grazie per aver scelto ed utilizzato UBILOD. Il presente Contratto di Servizio ("Termini") disciplina riguarda l'utilizzo e l'accesso ai nostri servizi, ai nostri siti web ("Servizi") ed è in essere tra Te (“Utente”) e Co-Brains S.r.l., con sede in via Diaz 54, 22100 Como (CO), Italia. Le nostre Norme sulla privacy spiegano come raccogliamo e utilizziamo i tuoi dati, mentre le Norme sull'uso accettabile le tue responsabilità derivanti dall'utilizzo dei nostri Servizi. Utilizzando i nostri Servizi, l'utente accetta di essere vincolato al presente Contratto, dichiara di aver letto e compreso le Norme sulla privacy e le Norme sull'uso accettabile. Se l'utente sta utilizzando i nostri Servizi per un'organizzazione, stai accettando il presente Contratto per conto di tale organizzazione.
                    <br/><br/>Quando utilizzi i nostri Servizi, ci fornisci file, contenuti, messaggi e-mail, contatti e altro ancora ("I tuoi file"). Tali file sono di tua proprietà. il presente Contratto non ci conce nessun diritto sui tuoi file ad eccezione di quelli che ci consentono di offrire all’utente i nostri Servizi.
                    Ci concedi il permesso di eseguire alcune operazioni e tale permesso si estende ai nostri affiliati e a terze parti affidabili con le quali collaboriamo. In particolare, abbiamo bisogno del tuo permesso per eseguire operazioni, quali, a titolo esemplificativo ma non esaustivo, caricare i file, trasmetterli, memorizzarli, gestirne l'hosting, farne un backup, condividerli quando ci chiedi di farlo, inviare e ricevere contenuti. I nostri Servizi offrono inoltre funzioni quali aggiunta di commenti e modi semplici per ordinare, condividere e cercare i tuoi file. Queste e altre funzioni potrebbero richiedere l'accesso, l'archiviazione, la modifica, la creazione di opere derivate e la scansione dei tuoi file da parte del nostro sistema in modo che i contenuti funzionino meglio con i nostri Servizi.
                    É importante che ti assicuri di avere i diritti necessari per concederci questa licenza rispetto a qualsiasi contenuto inviato ai nostri Servizi.
                    <br/><br/><strong>Condivisione dei contenuti</strong><br/>
                    I nostri Servizi ti consentono di condividere i tuoi file con altre persone, per cui fai molta attenzione a cosa condividi.
                    <br/><br/><strong>Responsabilità dell'utente</strong><br/>
                    Sei ritenuto responsabile per la tua condotta, per i tuoi file e devi rispettare le nostre Norme sull'uso accettabile. I contenuti presenti nei Servizi potrebbero essere protetti da diritti di proprietà intellettuale di altri. Non copiare, caricare, scaricare o condividere contenuti se non hai il diritto di farlo.
                    Ci riserviamo il diritto di esaminare la tua condotta e i tuoi contenuti affinché siano conformi al presente Contratto e alle nostre Norme sull'uso accettabile. Detto ciò, non siamo obbligati a fare quanto appena detto. Non siamo responsabili per i contenuti pubblicati e condivisi dagli utenti tramite i nostri Servizi.
                    Aiutaci a tenere i tuoi file protetti. Proteggi la tua password per i Servizi e mantieni aggiornate le informazioni dell'account. Non condividere le credenziali dell'account o concedere ad altre persone l'accesso. Ti consigliamo di non riutilizzare la password dell’account UBILOD per altre applicazioni. In caso venissi a conoscenza di utilizzo non autorizzato della tua password o del tuo account UBILOD modifica le tue credenziali e contattaci via e-mail a privacy@ubilod.com.
                    Puoi utilizzare i nostri Servizi solo in base a quanto consentito dalla legislazione in vigore, comprese Leggi e i Regolamenti sul controllo delle esportazioni. Infine, per utilizzare i nostri Servizi devi avere almeno 13 anni o, in alcuni casi, un'età superiore. Se abiti in Francia, Germania o nei Paesi Bassi, devi avere almeno 16 anni. Controlla le Leggi Locali per sapere l'età del consenso in ambito digitale. Se non soddisfi questi requisiti di età, non puoi utilizzare i Servizi.

                    <br/><br/><strong>Richieste di terze parti</strong><br/>
                    Il Cliente è responsabile e deve rispondere delle richieste di terzi. Il Cliente contatterà UBILOD solo nel caso in cui non sia in grado di fornire riscontro, nonostante abbia compiuto sforzi ragionevoli.
                    Qualora il Cliente non risponda tempestivamente a una Richiesta di terze parti, UBILOD potrebbe farlo, benché non obbligato.

                    <br/><br/><strong>Restrizioni</strong><br/>
                    Al Cliente è fatto divieto di (a) vendere, rivendere o noleggiare i Servizi o il Software, (b) utilizzare i Servizi o il Software per attività nelle quali l'uso o il mancato funzionamento dei Servizi o del Software può comportare danni fisici, morte o lesioni personali, (c) decodificare i Servizi o il Software, nonché tentare o aiutare qualcuno a farlo, a meno che tale restrizione sia proibita dalla legge; (d) utilizzare i Servizi o il Software, compresa l'esportazione o la riesportazione dei Dati del Cliente, in violazione delle Leggi sul controllo delle esportazioni o (e) creare un account UBILOD Business come individuo per scopi personali, familiari o domestici.
                    <br/><br/><strong>Compliance</strong><br/>
                    I Clienti e i suoi Utenti finali devono utilizzare i Servizi in conformità con le Norme sull'uso accettabile. Il Cliente è responsabile per l'utilizzo dei Servizi da parte degli Utenti finali. Il Cliente è tenuto a rispettare le leggi e i regolamenti applicabili all'uso dei Servizi da parte del Cliente. Il Cliente non intraprenderà alcuna azione che possa causare la violazione da parte di UBILOD delle Leggi UE sulla protezione dei dati, della legge statunitense Foreign Corrupt Practices Act del 1977, nella versione modificata, della legge britannica Bribery Act del 2010 o di qualsiasi altra legge anticorruzione o antiriciclaggio applicabile. Il Cliente deve accertarsi che (i) i Servizi siano appropriati per gli scopi a cui sono destinati, tenendo conto della natura dei Dati del Cliente e che (ii) i requisiti tecnici e organizzativi applicabili a UBILOD ai fini delle Leggi UE sulla protezione dei dati siano soddisfatti dalle Misure di sicurezza e dal presente Contratto. Il cliente dovrà ottenere e mantenere dagli Utenti finali l'eventuale consenso necessario per permettere agli Amministratori di svolgere le attività descritte nel presente Contratto e per consentire a UBILOD di fornire i Servizi.
                    <br/><br/><strong>Servizi Beta</strong><br/>
                    A volte forniamo prodotti e funzioni ancora in fase di test e valutazione. Tali servizi sono conosciuti come beta, anteprima, accesso anticipato o valutazione (o con parole o frasi dal significato simile) e possono non essere altrettanto affidabili come gli altri servizi di UBILOD, pertanto tieni ben presenti questi aspetti quando utilizzi questi servizi.
                    <br/><br/><strong>I nostri Servizi</strong><br/>
                    I Servizi sono protetti da copyright e altre leggi della Repubblica Italiana e di altri Paesi. Il presente Contratto non ti concede nessun diritto, titolo, proprietà intellettuale o interesse sui Servizi, sui contenuti di altri nei Servizi, su marchi e loghi di UBILOD e altre caratteristiche del brand. Accogliamo con favore il feedback da parte degli utenti, ma tieni presente che potremmo utilizzare commenti o suggerimenti senza alcun obbligo nei loro confronti. A tal fine potremmo inviarti annunci di servizio, messaggi amministrativi ed altre informazioni.
                    <br/><br/><strong>Copyright</strong><br/>
                    Rispettiamo la proprietà intellettuale altrui e ti chiediamo di fare lo stesso. Rispondiamo ad avvisi relativi a presunte violazioni del copyright, se conformi alla legge e se ci vengono comunicati tramite le Norme sul copyright. Ci riserviamo il diritto di eliminare o disattivare contenuti che rappresentano presunte violazioni e di eliminare gli account che violano ripetutamente il copyright. Il nostro agente designato per la ricezione degli avvisi sulle presunte violazioni del copyright sui Servizi è:                    Responsabile del copyright
                    Co-Brains S.r.l.<br/>
                    Via Diaz 54<br/>
                    22100 Como (CO)<br/>
                    copyright@ubilod.com<br/><br/>

                    <br/><br/><strong>Privacy</strong><br/>
                    Le Norme sulla privacy di UBILOD  illustrano la nostra modalità di trattamento dei dati personali e di protezione della privacy dell’utente quando questi utilizza i nostri Servizi. Utilizzando i nostri Servizi, l’utente accetta che UBILOD possa utilizzare tali dati in conformità con le nostre norme sulla privacy.

                    <br/><br/><strong>Account a pagamento</strong>><br/>
                    Fatturazione. Puoi aumentare il tuo spazio di archiviazione e aggiungere funzioni a pagamento al tuo account (modificandolo in un "Account a pagamento"). Ti invieremo automaticamente una fattura dalla data in cui passi a un Account a pagamento e a ogni rinnovo periodico fino alla cancellazione. Se hai sottoscritto un piano annuale, ti invieremo un’e-mail di notifica (entro un periodo di tempo ragionevole prima della data di rinnovo) per ricordarti che il tuo piano verrà rinnovato. Sei ritenuto responsabile per tutte le imposte applicabili e queste ti verranno addebitate ove necessario. Alcuni Paesi prevedono leggi locali obbligatorie in merito ai diritti di cancellazione; tali leggi hanno la priorità su quanto specificato in questo paragrafo.                    Nessun rimborso. Puoi annullare il tuo Account a pagamento UBILOD in qualsiasi momento. I rimborsi vengono emessi solo se richiesto dalla legge. Ad esempio, gli utenti che vivono nell'Unione Europea hanno diritto a cancellare gli abbonamenti relativi ai loro Account a pagamento entro 14 giorni dalla registrazione, upgrade o rinnovo di un Account a pagamento.
                    Nessun rimborso. Puoi annullare il tuo Account a pagamento UBILOD in qualsiasi momento. I rimborsi vengono emessi solo se previsti dalla Legge. Ad esempio, gli utenti che vivono nell'Unione Europea hanno diritto a cancellare gli abbonamenti relativi ai loro Account a pagamento entro 14 giorni dalla registrazione, upgrade o rinnovo di un Account a pagamento.
                    Downgrade. Il tuo Account a pagamento rimarrà attivo fino al momento dell'eliminazione o cessazione secondo il presente Contratto. Se ritardi nel pagare per il tuo Account a pagamento, ci riserviamo il diritto di sospenderlo o di ridurre il tuo spazio di archiviazione a livelli di spazio gratuito.
                    Modifiche. Ci riserviamo il diritto di modificare le tariffe in vigore, ma verrai prima avvisato di tali modifiche tramite un messaggio all'indirizzo e-mail associato al tuo account.

                    <br/><br/><strong>Pagamento</strong><br/>
                    Tariffe. Il Cliente pagherà a UBILOD o al rivenditore del Cliente tutte le tariffe applicabili per i Servizi, nella valuta indicata nel Modulo d'ordine. Il Cliente autorizza UBILOD o il Rivenditore del Cliente ad addebitargli tutte le Tariffe applicabili utilizzando il metodo di pagamento selezionato dal Cliente. Le Tariffe non sono rimborsabili tranne nei casi previsti dalla legge o se altrimenti specificato nel presente Contratto.
                    Pagamento. Il Cliente pagherà le fatture di UBILOD secondo i termini di pagamento specificati nel Modulo d'ordine. UBILOD si riserva il diritto di sospendere o interrompere i Servizi in caso di mancato pagamento delle Tariffe. Il Cliente dovrà fornire a UBILOD o al rivenditore del Cliente dati per la fatturazione e di contatto completi e accurati.
                    Imposte. Le Tariffe sono al netto delle imposte e Il Cliente è responsabile del pagamento di qualsiasi Imposta. UBILOD o il rivenditore del Cliente addebiterà le Imposte dovute quando necessario. Se il Cliente fornisce a UBILOD o al suo rivenditore un certificato di esenzione valido, UBILOD o il rivenditore non riscuoteranno le imposte coperte da tale certificato.
                    Ritenute d'acconto. Il Cliente pagherà a UBILOD o al suo rivenditore il netto di eventuali Ritenute d'acconto applicabili. Il Cliente e UBILOD o, a seconda dei casi, il Rivenditore del Cliente collaboreranno per evitare qualsiasi Ritenuta d'acconto laddove siano previste esenzioni o un'aliquota di ritenuta agevolata. Se UBILOD o il rivenditore del Cliente risultano idonei a un'esenzione fiscale o a un'aliquota di ritenuta agevolata, UBILOD o il rivenditore del Cliente sono tenuti a fornire prove documentali ragionevoli al Cliente. Il Cliente dovrà fornire a UBILOD o al rivenditore del Cliente prove sufficienti a dimostrare di avere pagato la somma trattenuta o dedotta all'autorità fiscale pertinente.

                    <br/><br/><strong>Ordini di acquisto</strong><br/>
                    Se il Cliente richiede l'utilizzo di un ordine di acquisto o di un numero di ordine di acquisto, il Cliente (i) deve fornire lo stesso al momento dell'acquisto e (ii) accetta che i termini e le condizioni su un ordine di acquisto del Cliente non verranno applicati al presente Contratto e sono nulli e privi di effetto. Se il Cliente acquista tramite un rivenditore, eventuali termini e condizioni dettati dal rivenditore o presenti in un ordine di acquisto tra il Cliente e il rivenditore che siano in conflitto con il Contratto sono nulli e senza valore.

                    <br/><br/><strong>Termine</strong><br/>
                    Periodo di validità del Contratto. Il presente Contratto resterà in vigore per tutto il Periodo di validità.
                    Rinnovi automatici. Salvo indicazione contraria nel Modulo d'ordine, dopo il Periodo di validità iniziale dei Servizi o un Periodo di rinnovo, l'abbonamento ai Servizi si rinnoverà automaticamente per un Periodo di rinnovo (1 anno), a meno che una delle parti non invii all'altra un avviso scritto di rescissione almeno trenta giorni prima della scadenza del periodo di validità dei Servizi al momento corrente.

                    <br/><br/><strong>Risoluzione</strong><br/>
                    Anche se ci dispiacerà vederti andar via, hai facoltà di interrompere l'utilizzo dei Servizi in qualsiasi momento. Ci riserviamo il diritto di sospendere o terminare, dandotene comunicazione, il tuo accesso ai Servizi qualora:
                    (a) violi il presente Contratto.
                    (b) utilizzi i Servizi secondo modalità che comportano un rischio reale di danni o perdite per noi o altri utenti oppure
                    Ti contatteremo con ragionevole anticipo all'indirizzo e-mail associato al tuo account per consentirti di porre rimedio all'attività in questione e darti la possibilità di esportare i tuoi file dai nostri Servizi. Se in seguito a tale avviso non intraprendi i passaggi richiesti, termineremo o sospenderemo il tuo accesso ai Servizi.                    Non forniremo alcun avviso prima della risoluzione qualora:
                    Non forniremo alcun avviso prima della risoluzione qualora:
                    (a) hai commesso una violazione grave deil presente Contratto,
                    (b) l'invio di tale avviso potrebbe implicare una nostra responsabilità legale o compromettere la possibilità di fornire i Servizi ad altri nostri utenti oppure
                    (c) non sia consentito dalla legge.

                    <br/><br/><strong>Interruzione dei Servizi</strong><br/>
                    Potremmo decidere di interrompere i Servizi in risposta a circostanze impreviste fuori dal controllo di UBILOD o in ottemperanza a un obbligo giuridico. Al verificarsi di tale condizione, ti avviseremo con un preavviso ragionevole per consentirti di esportare i tuoi file dai nostri sistemi. Se interrompiamo i Servizi in questo modo, prima della fine di eventuali termini fissi o minimi per i quali hai già pagato, rimborseremo la quota già corrisposta ma per cui non hai usufruito dei Servizi.
                    Emergenze relative alla sicurezza. Indipendentemente da quanto disposto nel presente Contratto, in caso di Emergenze relative alla sicurezza, UBILOD si riserva il diritto di sospendere automaticamente l'uso dei Servizi. UBILOD metterà in atto qualsiasi iniziativa commercialmente ragionevole a sua disposizione per limitare il più possibile la sospensione nella misura necessaria a prevenire o risolvere l'Emergenza relativa alla sicurezza.

                    <br/><br/><strong>Servizi "A SCATOLA CHIUSA"</strong><br/>
                    UBILOD si impegna a fornire Servizi fantastici, ma alcune cose non possono essere garantite. NEI LIMITI PREVISTI DALLA LEGGE, UBILOD E I SUOI AFFILIATI, FORNITORI E DISTRIBUTORI NON RICONOSCONO ALCUNA GARANZIA, ESPLICITA O IMPLICITA, SUI SERVIZI. I SERVIZI VENGONO FORNITI "A SCATOLA CHIUSA". NON RICONOSCIAMO NEPPURE ALCUNA GARANZIA DI COMMERCIABILITÀ, DI IDONEITÀ AD UNO SCOPO PARTICOLARE E DI NON VIOLAZIONE. Alcuni Stati non consentono i tipi di limitazioni indicati in questa sezione, quindi tali limitazioni potrebbero non essere applicabili.

                    <br/><br/><strong>Limitazione di responsabilità</strong><br/>
                    Saremo diligenti nel fornirti i nostri Servizi, ma non garantiamo che questi possano funzionare sempre o subire interruzioni, ritardi o imperfezioni.
                    Usando diligenza e perizia nel fornire all’Utente i servizi UBILOD non potrà essere ritenuto responsabile di: (I) di danni per interruzioni, ritardi o imperfezioni del servizio; (II) danni non riconducibili a violazioni del presente contratto; (II) danni non ragionevolmente prevedibili; (III) contenuti illeciti, fraudolenti, offensivi, osceni, non appropriati; (III) danni prodotti da eventi fuori dal nostro controllo.
                    UBILOD È RESPONSABILE NEI CONFRONTI DELL'UTENTE SOLO PER PERDITE E DANNI CHE SIANO IL RISULTATO RAGIONEVOLMENTE PREVEDIBILE DELLA MANCATA ATTUAZIONE DA PARTE DI UBILOD DELLA NORMALE DILIGENZA E PERIZIA OPPURE IN CASO DI UNA VIOLAZIONE DEL CONTRATTO CON L'UTENTE COMMESSA DA UBILOD.
                    SE UTILIZZI I SERVIZI PER QUALSIASI SCOPO COMMERCIALE, AZIENDALE O DI RIVENDITA, UBILOD, I SUOI AFFILIATI, FORNITORI O DISTRIBUTORI NON AVRANNO ALCUNA RESPONSABILITÀ NEI TUOI CONFRONTI PER EVENTUALI PERDITE DI PROFITTI, PERDITE DI AFFARI, INTERRUZIONI DELL'ATTIVITÀ O PERDITE DI OPPORTUNITÀ DI BUSINESS. UBILOD E I SUOI AFFILIATI NON SONO RESPONSABILI PER LA CONDOTTA, SIA ONLINE CHE OFFLINE, DI QUALSIASI UTENTE DEI SERVIZI.
                    ESCLUDENDO I TIPI DI RESPONSABILITÀ CHE UBILOD NON PUÒ LIMITARE PER LEGGE, per i Paesi che consentono limitazioni di responsabilità, LIMITIAMO LA NOSTRA RESPONSABILITÀ NEI CONFRONTI DELL'UTENTE TRA € 20 O IL 100% DI QUALSIASI IMPORTO PAGATO PER IL PIANO DEL SERVIZIO CORRENTE CON UBILOD.

                    <br/><br/><strong>Controversie</strong><br/>
                    Qualora i nostri Servizi fossero utilizzati per conto di un’azienda, l’azienda dovrà accettare il presente Contratto. L’azienda deve tenere indenne e tutelare UBILOD e relativi affiliate, funzionari, agenti e dipendenti da qualsiasi reclamo, causa o azione derivanti da o relativi all’utilizzo dei Servizi o alla violazione del presente Contratto, comprese eventuali responsabilità o spese derivanti da reclami, perdite, danni, azioni legali, sentenze, spese processuali e legali.

                    <br/><br/><strong>Risoluzione di controversie</strong><br/>
                    Per prima cosa proviamo a risolvere le cose. È nostra intenzione affrontare i problemi senza che sia necessario ricorrere alle vie legali. Prima di presentare un reclamo nei confronti di UBILOD, l'utente accetta di provare a risolvere la controversia in maniera informale scrivendo all'indirizzo dispute-notice@UBILOD.com. Proveremo a risolvere la disputa in maniera informale contattandoti via e-mail. Qualora la controversia non venisse risolta entro 15 giorni dalla presentazione, l'utente o UBILOD può intentare una causa.
                    Interpretazione dei termini in conflitto. In caso di conflitto tra i documenti che costituiscono il presente Contratto, la priorità viene stabilita nel seguente ordine: la fattura, il Modulo d'ordine, il Contratto. I Termini e le condizioni del presente Contratto saranno considerati informazioni riservate di UBILOD che il Cliente non può divulgare a terzi. Il Cliente accetta che i Termini e le condizioni di un Ordine di acquisto del cliente non si applichino al presente Contratto e siano nulli. Se gli Utenti finali sono tenuti a fare clic sui termini di servizio per poter utilizzare i Servizi, tali clic sono subordinati al presente Contratto, che prevarrà in caso di conflitto.
                    Foro giudiziario per le controversie. L'utente e UBILOD accettano che nel caso in cui dovesse insorgere controversia fra le parti sarà competente, in via esclusiva, il Foro di Como.
                    L'utente accetta la giurisdizione italiana e la normativa italiana di riferimento. Se risiedi in un Paese diverso dall’Italia (ad esempio in uno stato membro dell'Unione Europea) le cui Leggi concedono ai consumatori il diritto di presentare le controversie nei propri Tribunali locali, questo paragrafo non ti riguarda.
                    NESSUNA AZIONE COLLETTIVA. L'utente può risolvere le controversie con UBILOD solo in modo individuale e non può presentare un reclamo come attore o membro di una classe di persone in un'azione collettiva, riunita o rappresentativa. Non sono consentiti arbitrati collettivi, azioni collettive, cause d'interesse generale portate avanti da un singolo avvocato e riunione con altri arbitrati.

                    <br/><br/><strong>Legislazione applicabile</strong><br/>
                    Il presente Contratto è regolato dalle Leggi della Repubblica Italiana, ad eccezione dei casi di conflitti con altri principi legislativi. Tuttavia, le legislazioni di alcuni Paesi (compresi quelli dell'Unione Europea) richiedono che gli accordi siano regolamentati dalle leggi locali del Paese del consumatore. Il presente paragrafo non prevale su tali leggi.

                    <br/><br/><strong>Rinuncia, clausole salvatorie e cessione</strong><br/>
                    La mancata applicazione da parte di UBILOD di una disposizione non rappresenta una rinuncia ad applicarla in seguito. Se una disposizione risulta inapplicabile, le restanti disposizioni del Contratto continueranno ad avere pieno effetto e si introdurrà un nuovo termine applicabile che rifletta il più fedelmente possibile i nostri intenti. L'utente non può assegnare ad altri nessuno dei propri diritti stabiliti dal presente Contratto e ogni tentativo in tale senso sarà nullo. UBILOD può assegnare i propri diritti ai suoi affiliati o consociati o a qualunque successore per l'interesse dell'attività associata ai Servizi.

                    <br/><br/><strong>Cessione</strong><br/>
                    Il Cliente non può cedere o trasferire il presente Contratto o qualsiasi diritto o obbligo stabilito dallo stesso senza il consenso scritto da parte di UBILOD. UBILOD non può cedere il presente Contratto senza informare il Cliente, ma può cedere il presente Contratto o qualsiasi diritto o obbligo da esso derivante a un Affiliato o in relazione a una fusione, un'acquisizione, una riorganizzazione aziendale o una vendita di tutte le sue risorse o di una parte significativa di esse senza informare il Cliente. Qualsiasi altro tentativo di trasferimento o cessione è da considerarsi nullo.

                    <br/><br/><strong>Assenza di legami</strong><br/>
                    UBILOD e il Cliente non sono agenti o partner legali, ma contraenti indipendenti.

                    <br/><br/><strong>Modifiche</strong><br/>
                    Ci riserviamo il diritto di modificare periodicamente il presente Contratto affinché rispecchino meglio:
                    (a) i cambiamenti legislativi,
                    (b) nuovi obblighi normativi o
                    (c) miglioramenti apportati ai nostri Servizi.
                    Se un aggiornamento riguarda il tuo utilizzo dei Servizi o i tuoi diritti legali in qualità di utente dei nostri Servizi, ti avviseremo prima della data effettiva dell'aggiornamento inviandoti un’e-mail all'indirizzo email associato al tuo account o tramite una notifica all'interno dei prodotti. I termini aggiornati saranno effettivi non prima di 14 giorni successivi alla data di notifica.
                    Le modifiche non avranno valore retroattivo ed entreranno in vigore non prima di quattordici giorni dopo la data di pubblicazione. Tuttavia, eventuali modifiche riferite alle nuove funzioni di un Servizio o eventuali modifiche apportate per motivi legali entreranno subito in vigore.
                    In caso di conflitto tra il presente Contratto e i Contratti aggiuntivi, prevarranno i Contratti aggiuntivi.
                    Il presente Contratto regola il rapporto tra UBILOD e l’utente, e non danno luogo ad alcun diritto di terze parti beneficiarie.
                    Qualora l’utente non dovesse rispettare il presente Contratto, la mancata pronta adozione di provvedimenti da parte nostra non potrà essere intesa quale rinuncia a nostri eventuali diritti (ad esempio al diritto di prendere provvedimenti in futuro).
                    Se non accetti gli aggiornamenti apportati, annulla il tuo account prima che diventino effettivi. Ove applicabile, ti offriremo un rimborso proporzionale sulla base degli importi corrisposti in anticipo per i Servizi e della data di cancellazione dell'account. Continuando ad accedere o utilizzare i Servizi dopo che gli aggiornamenti diventano effettivi, accetti di essere vincolato al Contratto modificato.
                </Typography>
            </div>
        )
    }
}


TermsAndConditions.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(TermsAndConditions);