import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import logo
import Logo from '../../../../../assets/logos/ubilod_logo-light_bg.svg'
// General settings import
import GeneralSettings from '../../../../../settings/GeneralSettings';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import FileSaver
import FileSaver from 'file-saver'
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../../redux/actions/api-control-action';
// i18n translation
import * as jsPDF from 'jspdf'
import classNames from "classnames";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";

const styles  = theme => ({
    downloadQrLinkContainer: {
        marginTop: 12,
        cursor: 'pointer',
        textAlign: 'center',
    },
    downloadQrLink: {
        fontWeight: 600,
        fontSize:16,
    },
    labelUrlFont: {
        marginTop:8,
        fontSize:15,
    },
    divQrToPrint: {
        background: '#fff',
        textAlign: 'center',
        padding: 20,
        fontWeight: '700',
    },
    toolbarBtn:{
        marginRight:16,
        padding: '6px 12px',
        minHeight:20,
        lineHeight: '2px',
        '& > span:first-child':{
            textTransform:'capitalize',
        },
        [theme.breakpoints.down('xs')]:{
            padding: '6px 18px',
        }
    },
});

class MachineLabel extends Component {

    handleDownloadQrCode = (name) => {
        let canvas = document.getElementById("labelQrDownload");
        let prop = 2
        let imgData = canvas.toDataURL("image/png", 1.0);
        const qrDimension = 500; // Grandezza qr
        let link = GeneralSettings.appBaseUrl + "m";
        let code = this.props.product.code;

        let pdf  = new jsPDF({
            unit: 'mm',
            format: [362.6 * prop, 362.6* prop]
        })



        pdf.addImage(imgData, 'PNG', 0, 0, 128* prop, 128* prop);

       // context.font = "bold 32px Courier";
        pdf.setFontSize(18);
        pdf.setFont("Courier");
        pdf.setFontType("bold");


        pdf.text(39* prop , 107* prop , 'Link: ',);
        pdf.text(50* prop , 117* prop , "Codice: ",);

        //context.font = "32px Courier";
        pdf.setTextColor(38,67,171);
        pdf.text(50* prop , 107* prop , link,);
        pdf.setTextColor(0,0,0);

        pdf.line(50* prop,108* prop,88.5* prop,108* prop);
        pdf.text(65* prop ,117* prop  , code,);

        pdf.save(name.replace(' ', '_') + '.pdf');
    }

    componentDidMount(){
        let canvas = document.createElement('canvas');
        let div = document.getElementById('canvasDownloadQrContainer');
        let context = canvas.getContext("2d");
        canvas.className = "canvas";
        const qrDimension = 500; // Grandezza qr
        const logoDimensionWidth = 400; // Width logo
        const logoDimensionHeight = 142; // Height logo
        canvas.height = 1000;
        canvas.width = 1000;
        canvas.id = 'labelQrDownload';
        canvas.style.display = "none";
        context.beginPath();
        context.rect(0, 0, canvas.width, canvas.height);
        context.fillStyle = "white"; // background
        context.fill();

        let image = new Image();
        let logo_image = new Image();
        logo_image.src = Logo;

        image.crossOrigin = "Anonymous";
        image.onload = function() {
            context.drawImage(logo_image, canvas.width/2 - (logoDimensionWidth/2), 60,logoDimensionWidth,logoDimensionHeight);
            context.drawImage(this, canvas.width/2 - (qrDimension/2), canvas.height/2 - (qrDimension/2), qrDimension, qrDimension );
            context.fillStyle = "black";

        }
        // set src last (recommend to use relative paths where possible)
        image.src = this.props.product.qrcode;
        div.appendChild(canvas);
    }

    render() {

        const { classes, product, button = false } = this.props;

        return (
            (!button) ?
            <div className={classes.downloadQrLinkContainer}>
                <a
                    role="link"
                    tabIndex="0"
                    id="downloadQr" download="qrCode.png">
                    <Typography className={classes.downloadQrLink}
                                onClick={this.handleDownloadQrCode.bind(this, product.name)}>Scarica etichetta</Typography>
                </a>
                <div id="canvasDownloadQrContainer"></div>
            </div>
                :

                <Button color="primary"
                        variant="outlined"
                        onClick={this.handleDownloadQrCode.bind(this, product.name)}
                        className={classNames(classes.toolbarBtn,classes.margin)}
                >
                    <Icon color="primary" style={{fontSize:18, marginRight:4}}>cloud_download</Icon>
                    Scarica etichetta
                    <div id="canvasDownloadQrContainer"></div>
                </Button>
        )
    }
}

MachineLabel.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    api_control: state.api_control,
    router: state.router,
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl
};

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(MachineLabel);
