import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// material-ui import
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';
// redux import
import { connect } from 'react-redux';
import { compose } from 'redux';
// i18n translation
import MenuList from '@material-ui/core/MenuList';
import MenuItem from "@material-ui/core/MenuItem";
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import ApplicationRoutes from  '../../../views/application/ApplicationRoutes'
import {translate} from "../../../translations/i18n";
import clsx from "clsx";
import {ChevronLeft, ArrowLeft} from 'react-feather'
const styles = theme => ({
    root: {
        width: '100%',
        minHeight: 40,
        zIndex:999,
        display: 'flex',
        alignItems:'center',
        [theme.breakpoints.down('xs')]:{
            background: theme.palette.secondary.main,
            width: 'calc(100% - 40px)',
            margin: '0px 20px',
            borderBottom : '1px solid rgba(0,0,0,0.3)',
            overflowX : 'overlay'
        },
    },
    rootNotLogged:{
        width: '100%',
        minHeight: 40,
        zIndex:999,
        display: 'flex',
        alignItems:'center',
        [theme.breakpoints.down('xs')]:{
            background: theme.palette.secondary.main,
            width: 'calc(100% - 40px)',
            margin: '0px 20px',
            borderBottom : '1px solid rgba(0,0,0,0.3)',
            overflowX : 'overlay'
        },
    },
    breadContainer:{
        display : 'flex',
        alignItems:'center',
        '& p:not(:first-child):not(:last-child)':{
            whiteSpace: 'nowrap',
            width : '100px',
            textOverflow: 'ellipsis',
            overflow : 'hidden'
        }
    },
    backButton: {
        fontWeight: 600,
        padding: '0px 8px',
        marginRight:20,
        minHeight:28,
        [theme.breakpoints.down('sm')]:{
            position : 'absolute',
            top : '12px',
            minWidth : 'unset',
            padding : '0px',
            display : 'none'
        },
    },
    breadcrumbItem: {
        fontSize:14,
        whiteSpace : 'nowrap',
            [theme.breakpoints.down('sm')]:{
            marginTop: 10,
            marginBottom: 5,
            fontSize:10,
            whiteSpace : 'nowrap'
        },
    },
    breadcrumbLink:{
        color : theme.palette.active.main,
        opacity : .5,
        fontWeight: 'bold',
        '&:hover':{
            opacity : 1
            // color: '#000'
        }
    },
    activeBreadcrumbLink:{
        fontWeight: 'bold',
        color : theme.palette.active.main,
        opacity : 1
    },
    separator : {
        color: 'rgba(0,0,0,0.5)',
        margin:'0 6px'
    }
});

class BreadCrumbs extends Component {

    constructor(props){
        super(props);
        this.state = {
            activePath : [],
            activeUrl: [],
            anchorBreadcrumbsEl: null,
            loader : false,
        }
    }

    componentDidMount(){
        this.setState({loader : true}, () => this.formatBreadcrumbs(this.props.breadcrumbs, this.props))
    }

    componentWillReceiveProps(props){
        this.setState({loader : true}, () => this.formatBreadcrumbs(props.breadcrumbs, props))
    }


    getLabels = (title , labels) => {
        const {multilanguage} = this.props;

        let tmpLabels = [];
        if (labels)
        tmpLabels = [...labels];

        if (labels && labels.length > 0 && multilanguage.selectedLanguage && multilanguage.selectedLanguage.length > 0){
            let mainLanguage = multilanguage.selectedLanguage[0];

            tmpLabels = tmpLabels.filter(item =>
                item.flag.id == mainLanguage
            );

            if (tmpLabels.length === 0)
                tmpLabels = {title}
            }

        if (labels && labels.length === 1)
            tmpLabels = {title : labels[0].title,};

        if (tmpLabels.length === 0 && (title))
            tmpLabels = {title};


        if (tmpLabels && tmpLabels.length >= 1){
            tmpLabels = {title : tmpLabels[0].title}
        }

        return tmpLabels;
    };

    formatBreadcrumbs = (breadcrumbs, newProps) => {

        let tmpBc = [...breadcrumbs];

        tmpBc = tmpBc.filter(item =>  {

            return  !!this.checkLastWord(item.key)
        });

        if (newProps !== null) {
            if (newProps.router.activeMachineName || newProps.router.activeMachineLabels) {
                let tmpBcIndx = tmpBc.findIndex(item => item.breadcrumbs === "machine_id")

                if (tmpBcIndx !== -1)
                tmpBc[tmpBcIndx].breadcrumbs = this.getLabels(newProps.router.activeMachineName,newProps.router.activeMachineLabels).title
            }

            // replace the model name if is present
            if (newProps.router.activeModelName || newProps.router.activeModelLabels) {

                let tmpBcIndx = tmpBc.findIndex(item => item.breadcrumbs === "model_id");

                if (tmpBcIndx !== -1)
                tmpBc[tmpBcIndx].breadcrumbs = this.getLabels(newProps.router.activeModelName,newProps.router.activeModelLabels).title

            }
            // replace the category name if is present
            if (newProps.router.activeCategoryName || newProps.router.activeCategoryLabels) {

                let tmpBcIndx = tmpBc.findIndex(item => item.breadcrumbs === "category_id");

                if (tmpBcIndx !== -1)
                tmpBc[tmpBcIndx].breadcrumbs = this.getLabels(newProps.router.activeCategoryName,newProps.router.activeCategoryLabels).title
            }
            // replace the permission name if is present
            if (newProps.router.activePermissionName) {
                let tmpBcIndx = tmpBc.findIndex(item => item.breadcrumbs === "user_id")

                if (tmpBcIndx !== -1)
                    tmpBc[tmpBcIndx].breadcrumbs = newProps.router.activePermissionName
            }

            // replace the active file name if is present
            if (newProps.router.activeFileName) {

                let tmpBcIndx = tmpBc.findIndex(item => item.breadcrumbs === "file_id")

                if (tmpBcIndx !== -1)
                    tmpBc[tmpBcIndx].breadcrumbs = this.getLabels(newProps.router.activeFileName, newProps.router.activeFileLabels).title
            }
        }

        this.setState({activePath : tmpBc, loader : false})
    };

    checkLastWord = (string) => {

        let w = string.split("/");

        let lastword = w[w.length - 1];

        return this.isNumeric(lastword) || ( (string === '/machine' && this.props.notLogged !== true) || string === '/dashboard' || string === '/model' || string === '/category' || string === '/contact' || string === '/contacts' || string === '/bulk_import' || string === '/restore')
    };

    isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    };

    render(){
        const { classes, license, breadcrumbs} = this.props;
        const { activePath, anchorBreadcrumbsEl, loader} = this.state;


        const DefaultBreadCrumbs = () => {
            return (
                <div className={classes.breadContainer}>
                    {activePath.map((item, index) =>
                        <div style={{display: 'flex', alignItems: 'baseline'}}>
                            <Typography key={item + '-' + index} color="secondary" className={classes.breadcrumbItem}>
                                <Link to={item.key}
                                      className={[classes.breadcrumbLink, (index === activePath.length - 1) ? classes.activeBreadcrumbLink : null].join(' ')}>
                                    {console.log(`breadcrumbs.${item.breadcrumbs}.${license.license}`)}

                                    {translate(`breadcrumbs.${item.breadcrumbs}.${license.license}`, {}, item.breadcrumbs)}
                                </Link>
                            </Typography>
                            {(index !== activePath.length - 1) ?
                                <strong className={classes.separator}>></strong> : null}
                        </div>
                    )}
                </div>
            )
        };

        const ReducedBreadCrumbs = () => {
            return (
                <div style={{width: '100%', display: 'flex'}}>
                    <Typography color="secondary" className={classes.breadcrumbItem}>
                        <Link to={'/' + activePath[2].key} className={[classes.breadcrumbLink,(activePath.length -1 === 1)?classes.activeBreadcrumbLink:null].join(' ')}>
                            {translate( 'breadcrumbs.' + activePath[2].breadcrumbs + `.${license.license}`, {},  activePath[2].breadcrumbs)}
                        </Link>
                        <strong style={{margin:'0 6px'}}>></strong>
                    </Typography>

                    <Typography color="secondary"
                                buttonRef={node => {
                                    this.anchorBreadcrumbsEl = node;
                                }}
                                aria-owns={anchorBreadcrumbsEl ? 'breadcrumbs-menu' : undefined}
                                aria-haspopup="true"
                                onClick={(e) => this.setState({ anchorBreadcrumbsEl: e.currentTarget })}
                                className={classes.breadcrumbItem}>
                        <span style={{fontSize: 30, lineHeight: '2px', cursor: 'pointer', color : '#000'}}>...</span>
                        <strong className={classes.separator}>></strong>
                    </Typography>

                    <Popper open={Boolean(anchorBreadcrumbsEl)} anchorEl={anchorBreadcrumbsEl} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                id="breadcrumbs-menu"
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={() => this.setState({ anchorBreadcrumbsEl: null })}>
                                        <MenuList>
                                            {activePath.map((item, index) =>
                                                (index >= 2 && index !== activePath.length-1)?
                                                    <MenuItem key={item + '-' + index} style={{minWidth:100, display:'flex', justifyContent:'center'}}>
                                                        <Link to={item.key} style={{color: 'black'}}>
                                                            {translate( 'breadcrumbs.' + item.breadcrumbs + `.${license.license}`, {},  item.breadcrumbs)}
                                                        </Link>
                                                    </MenuItem>
                                                    :null
                                            )}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>

                    <Typography color="secondary" className={classes.breadcrumbItem}>
                        <Link to={activePath[activePath.length - 1].key} className={[classes.breadcrumbLink,(activePath.length -1 === activePath.length - 1)?classes.activeBreadcrumbLink:null].join(' ')}>
                            {translate( 'breadcrumbs.' + activePath[activePath.length - 1].breadcrumbs + `.${license.license}`, {},  activePath[activePath.length - 1].breadcrumbs)}
                        </Link>
                    </Typography>
                </div>
            )};

        return (
            <div className={(this.props.notLogged) ? classes.rootNotLogged : classes.root}>
                {/*<Hidden smDown>*/}
                    {(activePath.length > 1 ) ?
                        <Link to={activePath[activePath.length - 2].key}>
                        <Button variant="contained"
                                color="secondary"
                                className={classes.backButton}
                        >
                            <Hidden smDown>
                            <ChevronLeft/>&nbsp;{translate( 'general.back', {},  'Indietro')}
                            </Hidden>
                            <Hidden smUp>
                                <ArrowLeft/>
                            </Hidden>
                        </Button>
                        </Link>
                        :
                        <>
                            <Hidden smUp>
                                <Button variant="contained"
                                        color="secondary"
                                        disabled
                                        style={{opacity : 0.5, background : 'transparent'}}
                                        className={classes.backButton}
                                >
                                    <ArrowLeft/>
                                </Button>
                            </Hidden>
                        </>
                    }
                {/*</Hidden>*/}
                {(activePath)?
                    <DefaultBreadCrumbs/>
                    // (activePath.length > 10 || (activePath.length > 2 && window.screen.width <= 600) ) ? <ReducedBreadCrumbs /> : <DefaultBreadCrumbs/>
                    :null
                }
            </div>
        );
    }
}

BreadCrumbs.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    router: state.router,
    license: state.license,
    multilanguage: state.multilanguage,
});

export default compose(
    withStyles(styles, {withTheme: true}),
    withBreadcrumbs(ApplicationRoutes),
    connect(mapStateToProps)
)(BreadCrumbs);





