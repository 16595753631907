import React from 'react';
import PropTypes from 'prop-types';

// material ui import
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import {translate} from "../../../../../translations/i18n";
import {Globe, X} from "react-feather";
import LanguageSelect from "../../../../../components/language-select/LanguageSelect";
import {updateApiControl} from "../../../../../redux/actions/api-control-action";
import {compose} from "redux";
import {connect} from "react-redux";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles  = theme => ({
    dialogPaper: {
        [theme.breakpoints.down('xs')]:{
            margin: 10,
        },
    },
    textField: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        width: '100%',
    },
    container: {
        display:'flex',
        flexDirection:'column',
        marginTop:8
    },
    dialogAction: {
        margin: '0px 20px',
        marginBottom:20,
    },
    addCategory:{
        cursor:'pointer',
        display:'flex',
        alignItems:'center',
        color:theme.palette.primary.main
    },

});

class AssociateCategoryDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            machine: null,
            categories: [],
            selectedCategory: null,
            createCategory: false,
            newCategory:{label:"",description:""},
            labels : [
                {
                    description: '',
                    flag: null,
                    title: ''
                }
            ],
            categoryAlreadyExist:false,
        }
    }

    componentDidMount(){
        const { machine , categories, multilanguage } = this.props;
        this.setState({
            machine: machine,
            categories: categories,
            labels : [
                {
                    description: '',
                    flag: multilanguage.defaultlang,
                    title: ''
                }
            ]
        })
    }

    handleChange = (e) => {
        e = {...e};
        this.setState(prevState => ({
            newCategory:{
                ...prevState.newCategory,
                [e.target.name] : e.target.value
            }
        }));
    };


    handleSelectChange = (e) => {
        e.persist();
        this.setState(() => ({
            selectedCategory: e.target.value
        }));
    };


    handleChangeLabel = (e , index) => {
        let tmpLabel = this.state.labels

        tmpLabel[index] = {
            ...tmpLabel[index],
            [e.target.name] : e.target.value
        }

        this.setState({labels : tmpLabel})
    };


    handleChangeMultiple = (event, index) => {

        let { value } = event.target;

        value = Number(value)

        let tmpValue =  this.state.labels[index].flag;
        let tmpLabels = this.state.labels;
        let  availableLang = this.props.multilanguage.languageValues.filter(item => this.state.labels.findIndex(v => v.flag == item.id) === -1 );


        if (value.id === tmpValue ){
            tmpLabels[index] = {
                ...tmpLabels[index],
                flag : null
            };
        }else if (availableLang.length > 0 && availableLang.findIndex(item => item.id == value) !== -1){
            tmpLabels[index] = {
                ...tmpLabels[index],
                flag : value
            };
        }

        this.setState({labels : tmpLabels})

    };

    addLabel = () => {
        let tmpLabels = this.state.labels;
        tmpLabels.push({
            description: '',
            flag: null,
            title: ''
        });

        this.setState({label : tmpLabels})
    };


    removeLabel = (index) => {
        let tmpLabels = this.state.labels;

        tmpLabels.splice(index , 1);

        this.setState({label : tmpLabels})
    };

    render() {
        const { categories, createCategory, newCategory,categoryAlreadyExist, selectedCategory, labels } = this.state;
        const { classes, title, message, confirmBtnText, open, loader, multilanguage } = this.props;

        return (
            <div>
                <Dialog
                    open={open}
                    PaperProps={{ classes: {root: classes.dialogPaper } }}
                    onClose={this.props.onClose}>
                    <DialogTitle>{title}</DialogTitle>
                    <form onSubmit={(e)=> {e.preventDefault(); (!createCategory)?this.props.onSubmit(selectedCategory):this.props.onSubmit(selectedCategory,newCategory, labels)}}
                          className={classes.container}
                          onKeyPress={(e) => (e.which === 13)?e.preventDefault():null}>
                        <div>
                        <DialogContent style={{paddingTop:0}}>
                            {/* Select category */}
                            {(!createCategory)?
                                <div>
                                    <DialogContentText>
                                        {message}
                                    </DialogContentText>
                                    <FormControl className={classes.textField}>
                                        <InputLabel shrink={true} htmlFor="category">
                                            {translate('associate_category.categories', {}, 'Categorie')}
                                        </InputLabel>
                                        <Select
                                            native
                                            onChange={(e) => this.handleSelectChange(e)}
                                            inputProps={{
                                                name: 'category',
                                                id: 'category',
                                            }}
                                            disabled={loader}>
                                            <option value="">
                                                {translate('associate_category.select', {}, 'Seleziona una categoria')}
                                            </option>
                                            {categories.map( category =>
                                                <option selected={category.id === selectedCategory} value={category.id}>{category.label}</option>
                                            )}
                                        </Select>
                                    </FormControl>
                                    <Typography className={classes.addCategory} onClick={() => this.setState({createCategory:true})}>
                                        <Icon style={{fontSize:13}}>add</Icon>
                                        {translate('associate_category.add', {}, 'Nuova categoria')}
                                    </Typography>
                                </div>
                            :null}
                            {/* Create new category */}
                            {(createCategory)?
                                <div>
                                    <DialogContentText>
                                        {translate('category.message', {}, "Inserisci nei campi sottostanti nome e descrizione")}
                                    </DialogContentText>

                                    <div style={{padding : '12px 0px'}}>
                                        {multilanguage.languageValues && this.state.labels.map( (item, index) =>
                                            <div style={{marginTop : 20}}>
                                                <div style={{display : 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                                                    {index > 0 ?
                                                        <div style={{display : 'flex', alignItems : 'center'}}>
                                                            <Button
                                                                style={{minWidth : 16, padding : '4px', borderRadius : '16px'}}
                                                                onClick={() => this.removeLabel(index)}>
                                                                <X size={24}/>
                                                            </Button>
                                                            <Typography style={{fontSize : 14}}>
                                                                {translate('general.remove_language', {}, "Rimuovi traduzione")}
                                                            </Typography>
                                                        </div>
                                                        :
                                                        <div style={{display : 'flex', alignItems : 'center', visibility : 'hidden'}}>
                                                            <Button
                                                                style={{minWidth : 16, padding : '4px', borderRadius : '16px'}}
                                                                onClick={() => this.removeLabel(index)}>
                                                                <X size={24}/>
                                                            </Button>
                                                            <Typography style={{fontSize : 14}}>
                                                                {translate('general.remove_language', {}, "Rimuovi traduzione")}
                                                            </Typography>
                                                        </div>
                                                    }
                                                    <LanguageSelect
                                                        onChange={(e) => this.handleChangeMultiple(e, index)}
                                                        style={{padding : '0px 16px 16px 0px', justifyContent : 'flex-end'}}
                                                        value={item.flag}
                                                        multiple={false}
                                                        label={''}
                                                        languageOptions={null}
                                                    />
                                                </div>
                                                <div style={{display : 'flex', flexWrap : 'wrap', justifyContent : 'space-between'}}>
                                                    <TextField
                                                        required
                                                        variant={'outlined'}
                                                        InputProps={{inputProps: {maxLength: 50}}}
                                                        InputLabelProps={{shrink: true}}
                                                        label={translate('machine_dialog.name', {}, 'Nome')}
                                                        name="title"
                                                        className={classes.textField}
                                                        value={item.title}
                                                        onChange={(e) => {
                                                            index === 0 &&
                                                            this.handleChange({...e, target : {...e.target, name : 'label'}});
                                                            this.handleChangeLabel(e, index);
                                                        }}
                                                        disabled={loader}
                                                    />
                                                    <TextField
                                                        label={translate('associate_category.description', {}, "Descrizione")}
                                                        name="description"
                                                        multiline
                                                        variant={'outlined'}
                                                        rowsMax="4"
                                                        InputLabelProps={{shrink: true}}
                                                        className={classes.textField}
                                                        value={item.description}
                                                        onChange={(e) => {
                                                            index === 0 &&
                                                            this.handleChange(e);
                                                            this.handleChangeLabel(e, index)
                                                        }}
                                                        disabled={loader}
                                                        margin="normal"
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        <Button
                                            style={{marginTop : 12}}
                                            disabled={this.state.labels.length >= multilanguage.languageValues.length}
                                            onClick={() => this.addLabel()}>
                                            <Globe size={16} style={{marginRight : 12}} />
                                            {translate( `general.add_language`, {},  'Aggiungi traduzione')}
                                        </Button>
                                    </div>
                                </div>
                            :null}
                        </DialogContent>
                        <DialogActions className={classes.dialogAction}>
                            <Button onClick={() => this.props.onClose()} color="primary">
                                {translate( `general.cancel`, {},  "Annulla")}
                            </Button>
                            {(!createCategory)?
                                <Button disabled={!selectedCategory}
                                    type="submit"
                                    variant="outlined"
                                    color="primary">
                                    {(!loader)? confirmBtnText : <CircularProgress size={16} style={{marginLeft:0}}/>}
                                </Button>
                                :
                                <Button disabled={this.state.labels.findIndex(item => item.flag === null) !== -1}
                                        type="submit"
                                        variant="outlined"
                                        color="primary">
                                        {(!loader)? translate('associate_category.create', {}, "Crea e associa")  : <CircularProgress size={16} style={{marginLeft:0}}/>}

                                </Button>
                            }
                        </DialogActions>
                        </div>
                    </form>
                </Dialog>
            </div>
        );
    }
}


AssociateCategoryDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    confirmBtnText: PropTypes.string.isRequired,
    loader: PropTypes.bool.isRequired,
    categories: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired

};



const mapStateToProps = state => ({
    user: state.user,
    multilanguage: state.multilanguage,
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl,
};


export default compose(withStyles(styles, {withTheme: true}),    connect(mapStateToProps, mapActionsToProps))(AssociateCategoryDialog)

