import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// material ui import
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateUtils } from '../../../../redux/actions/utils-action';
import { updateApiControl } from '../../../../redux/actions/api-control-action';
import { updateRouter } from "../../../../redux/actions/router-action";
// api service import
import ModelApi from '../../../../services/ModelApi';
// containers import
import BackofficeViewContainer from '../../layout/container/AdminContainer';
import AssociateCategoryDialog from '../machinesManager/components/AssociateCategoryDialog';

// components import
import ProductList from '../machinesManager/components/ProductList';
import BoxCard from '../../../../components/ui/cards/BoxCard';
import FunctionsToolbar from '../../../../components/ui/toolbar/FunctionsToolbar';
import AlertDialog from '../../../../components/ui/dialogs/alertDialog/AlertDialog';
import CreateModelDialog from './components/CreateModelDialog';
import CategoryApi from "../../../../services/CategoryApi";
import Icon from "@material-ui/core/Icon";
import LanguageApi from "../../../../components/language-select/LanguageApi";
import {updateMultilanguage} from "../../../../redux/actions/multilanguage-action";
import {translate} from "../../../../translations/i18n";
import {Folder, Cpu, Edit2, Trash} from 'react-feather'
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const styles  = theme => ({
    tabBar:{
        backgroundColor: '#fff',
        marginTop: -70,
        marginBottom: 100,

    },
    indicator: {
        backgroundColor: theme.palette.active.main,
    },
    tabItem: {
        color: theme.palette.primary.main
    },
    tabItemActive: {
        color: theme.palette.active.main
    },
});

class ModelsManager extends Component {

    constructor(props){
        super(props);
        this.state = {
            activeModel: this.props.match.params.model_id,
            model:null,
            company_categories: [],
            redirectToModels: false,
            dialogLoader: false,
            loader: true,
            tabValue: 0,
            languageValues : [],
            defaultlang : null,
            finalLabels : {title : '', subtitle : ''}
        }
    }



    apiCalls(){
        this.setState({ loader: true });


        ModelApi.getModelById(this.state.activeModel, {'license': this.props.license.license}).then(
            res => {
                this.props.onUpdateRouter({
                    ...this.props.router,
                    activeModelName: res.data.model.name,
                    activeModelLabels: res.data.model.labels
                });
                this.setState({
                    model: res.data.model,
                    company_categories: res.data.company_categories,
                    loader:false,
                }, () => this.getLabels() );
            },
            err => {
                this.setState({ loader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'LoadModelDetail', );
            }
        )

    }

    // ==================================================
    // GET MODEL DETAIL
    // ==================================================
    componentDidMount(){
        this.setState({ loader: true });

        this.apiCalls()

    }

    // ==================================================
    // DELETE MODEL
    // ==================================================
    deleteModel(id){
        this.setState({ dialogLoader: true });
        ModelApi.deleteModel(id).then(
            () => {
                this.setState({redirectToModels: true})
            },
            err => {
                this.setState({ openDeleteModelDialog: false, dialogLoader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'deleteModel', );
            }
        )
    }

    // ==================================================
    // UPDATE MODEL
    // ==================================================

    updateModel(model){
        this.setState({ dialogLoader: true });
        ModelApi.updateModel(model.id, model).then(
            res => {

                let toBDeleted = [];

                if (model.labels.length > 0)
                    toBDeleted = res.data.labels.filter(item => model.labels.findIndex(v => v.flag.id === item.flag.id) === -1);

                model.labels.map(item =>
                    res.data.labels.length > 0 && res.data.labels.findIndex(v => v.flag.id == item.flag.id) !== -1 ?
                    ModelApi.editModelLabels(item, res.data.id, item.flag.code).then(
                        r => {
                        },
                        err => {

                        }
                    )
            :
                ModelApi.addModelLabels(item, res.data.id, item.flag.code).then(
                    r => {
                    },
                    err => {

                    }
                )
                );

                if(toBDeleted.length > 0)
                    toBDeleted.map(item =>
                        ModelApi.deleteModelLabels(item, res.data.id, item.flag.code).then(
                            r => {
                            },
                            err => {
                            }
                        ));


                this.setState(prevState => ({
                    model: {
                        ...prevState.model,
                        name: res.data.name,
                        description: res.data.description,
                        labels : model.labels,
                    },
                    dialogLoader:false,
                    openUpdateModelDialog: false,
                }), () => this.getLabels());

            },
            err => {
                this.setState({ dialogLoader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'updateModel', );
            }
        )
    }

    associateCategory = (categoryId,newCategory=null, labels) => {
        this.setState({dialogLoader: true});
        const {multilanguage} = this.props;

        if(newCategory!==null){
            CategoryApi.createCategory(newCategory).then(
                res => {
                    labels.map(item =>
                        CategoryApi.addCategoryLabels(item, res.data.id, multilanguage.languageValues.filter(v => v.id == item.flag )[0].code).then(
                            r => {
                            },
                            err => {

                            }
                        )
                    );
                    categoryId=res.data.id;
                    addCategory(categoryId, this)
                },
                err => {
                    this.setState({ loader: false });
                    // ====== API CONTROL MESSAGE ====== //
                    this.props.onUpdateApiControl(err, 'createCategory', );
                }
            )
        }else{
            addCategory(categoryId,this)
        }

        function addCategory(categoryId,context) {
            ModelApi.addCategroy(context.state.model.id, categoryId).then(
                res => {
                    let deleteIndex = context.state.company_categories.map(function (item) {return item.id.toString()
                    }).indexOf(categoryId);
                    context.setState(prevState => ({
                        openAssociateCategoryDialog: false,
                        dialogLoader: false,
                        model: {
                            ...prevState.model,
                            categories: [...prevState.model.categories, res.data],
                        },
                        company_categories: prevState.company_categories.filter((_, i) => i !== deleteIndex),
                    }));
                },
                err => {
                    context.setState({openAssociateCategoryDialog: false, dialogLoader: false});
                    // ====== API CONTROL MESSAGE ====== //
                    context.props.onUpdateApiControl(err, 'associateCategory');
                }
            )
        }
    };


    disassociateCategory(id){
        this.setState({dialogLoader: true});
        ModelApi.deleteCategory(this.state.model.id, id).then(
            () => {
                let deleteIndex = this.state.model.categories.map(function (item) {return item.id}).indexOf(id);
                this.setState(prevState => ({
                    company_categories: [...prevState.company_categories, prevState.model.categories[deleteIndex] ],
                    model:  {
                        ...prevState.model,
                        categories: prevState.model.categories.filter((_, i) => i !== deleteIndex),
                    },
                    openDeleteCategoryDialog: false,
                    dialogLoader: false
                }));
            },
            err => {
                this.setState({ openDeleteCategoryDialog: false, dialogLoader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'disassociateCategory', );
            }
        )
    }

    // ==================================================
    // TAB BAR MANAGEMENT
    // ==================================================

    // ==================================================
    // CREATE MACHINE
    // ==================================================
    addMachine(data, labels) {
        this.setState(prevState => ({
            model:  {
                ...prevState.model,
                products: [...prevState.model.products, {...data}]
            },
        }));
    };

    // ==================================================
    // DELETE MACHINE
    // ==================================================
    removeMachine(id) {
        let deleteIndex = this.state.model.products.map(function (item) {return item.id}).indexOf(id);
        this.setState(prevState => ({
            model:  {
                ...prevState.model,
                products: prevState.model.products.filter((_, i) => i !== deleteIndex)
            },
        }));
    }

    // ==================================================
    // UPDATE MACHINE
    // ==================================================
    updateMachine(data, labels) {
        let products = this.state.model.products;
        let updateIndex =  this.state.model.products.map(function (item) {return item.id}).indexOf(data.id);
        products[updateIndex] = {...data};
        this.setState(prevState => ({
            model:  {
                ...prevState.model,
                products: products
            },
        }));
    }

    handleChange = (event, tabValue) => {
        this.setState({ tabValue });
    };

    isActiveTab (tab) {
        const { classes } = this.props;
        return this.state.tabValue === tab ? classes.tabItemActive : classes.tabItem
    }


    getLabels = () => {
        const {multilanguage} = this.props;
        const {labels = [], name , description = ''} = this.state.model;
        const {defaultlang} = this.state;



        let tmpLabels = [...labels];

        if (labels && labels.length > 0 && multilanguage && multilanguage.length > 0){
            let mainLanguage = multilanguage[0];

            tmpLabels = tmpLabels.filter(item =>
                item.flag.id == mainLanguage
            );

            if (tmpLabels.length === 0 && defaultlang)
                tmpLabels = labels.filter(item =>
                    item.flag.id == defaultlang
                );

            if (tmpLabels.length === 0 && !defaultlang)
                tmpLabels = {title : name , subtitle : description}

        }else if (!multilanguage ||  multilanguage.length === 0 && defaultlang){
            tmpLabels = tmpLabels.filter(item =>
                item.flag.id == defaultlang
            );
        }

        if (labels && labels.length === 1)
            tmpLabels = {title : labels[0].title, subtitle : labels[0].description};

        if (tmpLabels.length === 0 && (name || description))
            tmpLabels = {title : name , subtitle : description};


        if (tmpLabels && tmpLabels.length >= 1){
            tmpLabels = {title : tmpLabels[0].title, subtitle : tmpLabels[0].description }
        }

        this.setState({finalLabels : tmpLabels});
        return tmpLabels;
    };

    render() {
        const { classes, license, multilanguage } = this.props;
        const { model, dialogLoader, tabValue, languageValues, defaultlang, loader, finalLabels } = this.state;

        // Redirect on Models (Delete)
        if(this.state.redirectToModels){
            return <Redirect to={"/model"}/>
        }

        return (
            <div className={classes.root}>
                <BackofficeViewContainer title={`${translate( `model_detail.title.${license.license}`, {},  
                    (license.license === 'commercial') ?
                        "Gestione linea prodotto" 
                        :
                        "Gestione modello")} ${(model)?"- "+finalLabels.title:""}`}

                                         subtitle={
                                             translate( `model_detail.subtitle.${license.license}`, {},  (license.license === 'commercial') ? "Visualizza le cartelle ed i prodotti relativi alla linea selezionata" : "Visualizza le cartelle ed le macchine relative alla linea selezionata")}
                                         renderToolbar={
                                             <FunctionsToolbar items={[
                                                 {label: translate( `model_detail.edit_button`, {},  'Modifica'),
                                                     icon: (style) => <Edit2 style={style}/>,
                                                     func: () => this.setState({openUpdateModelDialog: true})},
                                                 {label: translate( `model_detail.delete_button`, {},  'Elimina'),
                                                     icon: (style) => <Trash style={style}/>,
                                                     func: () => this.setState({openDeleteModelDialog: true})},
                                             ]} />
                                         }
                                         languageSelect>

                    {(model)?
                        <div style={{width: '100%'}}>
                            <Tabs
                                value={tabValue}
                                onChange={this.handleChange}
                                className={classes.tabBar}
                                classes={{indicator: classes.indicator}}
                                variant="fullWidth">
                                <Tab className={this.isActiveTab(0)}
                                     icon={<Folder className={classes.icon}/>}
                                     label={translate( `model_detail.tab_folder`, {},  "Struttura cartelle")} />
                                <Tab className={this.isActiveTab(1)}
                                     icon={<Cpu className={classes.icon}/>}
                                     label={translate( `model_detail.tab_products`, {}, "Prodotti associati")} />
                            </Tabs>
                            {tabValue === 1 &&
                            <ProductList products={model.products}
                                         activeModel={model}
                                         languageValues={languageValues}
                                         defaultlang={defaultlang}
                                         multilanguage={multilanguage.selectedLanguage}
                                         baseLink={`${model.id}`}
                                         updateProductsCreate={(data, labels) => this.addMachine(data, labels)}
                                         updateProductUpdate={(data, labels) => this.updateMachine(data, labels)}
                                         updateProductsDelete={(id) => this.removeMachine(id)}/>
                            }
                            {tabValue === 0 &&
                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                                    {/* CREATE CATEGORY BOX CARD */}
                                    <BoxCard variant={"add"}
                                             title={"+"}
                                             disabled={this.state.loader}
                                             subtitle={translate( `model_detail.add_folder`, {}, "Aggiungi Cartella")}
                                             onClick={() => this.setState({openAssociateCategoryDialog:true})}/>
                                    {(model.categories.length > 0) ?
                                        model.categories.map((category, index) =>
                                            <BoxCard key={category + '-' + index}
                                                     title={category.label}
                                                     loader={loader}
                                                     labels={category.labels}
                                                     defaultlang={defaultlang}
                                                     link={`${this.props.match.url}/category/${category.id}`}
                                                     onDelete={(category.type === 'F') ? () => {this.setState({openDeleteCategoryDialog:true, activeDeleteId:category.id})} : null}
                                                     subtitle={category.description}/>
                                        )
                                        :
                                        <Typography variant="body" style={{fontWeight:500, marginTop:40}}></Typography>
                                    }
                                </div>}
                        </div>
                    :
                        <div style={{width: '100%', display : 'flex'}}>
                            <CircularProgress size={40} style={{margin : '0px auto'}}/>
                        </div>
                    }
                </BackofficeViewContainer>

                {/* UPDATE MODEL DIALOG */}
                {(this.state.openUpdateModelDialog)?
                <CreateModelDialog title={translate( `model_detail.edit_model`, {}, "Modifica modello")}
                                   loader={dialogLoader}
                                   model={this.state.model}
                                   languageValues={languageValues}
                                   defaultlang={defaultlang}
                                   open={this.state.openUpdateModelDialog}
                                   onClose={() => this.setState({openUpdateModelDialog:false})}
                                   onCreate={(updateModel) => this.updateModel(updateModel)}/>
                :null}

                {/* DELETE CONFIRM DIALOG */}
                <AlertDialog open={this.state.openDeleteModelDialog}
                             title={translate( `model_detail.delete_modal.${license.license}`, {}, (license.license === 'commercial') ?
                                 "Eliminazione linea prodotto"
                                 :
                                 "Eliminazione modello")}
                             loader={dialogLoader}
                             message={translate( `model_detail.delete_modal_message.${license.license}`, {}, (license.license === 'commercial') ?
                                 "Sei sicuro di voler eliminare la linea prodotto?"
                                 :
                                 "Sei sicuro di voler eliminare il modello?")}
                             confirmBtnText={"Elimina"}
                             onClose={() => this.setState({openDeleteModelDialog:false})}
                             onConfirm={() => this.deleteModel(this.state.activeModel)}/>

                {/* ASSOCIATE CATEGORY DIALOG */}
                {(this.state.openAssociateCategoryDialog) ?
                    <AssociateCategoryDialog title={translate( `model_detail.associate_modal`, {}, 'Associa categoria')}
                                             message={translate( `model_detail.associate_modal_message.${license.license}`, {},
                                                 (license.license === 'commercial') ?
                                                     'Seleziona la categoria da associare a tutte le macchine della linea prodotto'
                                                     :
                                                     'Seleziona la categoria da associare a tutte le macchine del modello')}
                                             confirmBtnText={translate( `machine_detail.associate_modal_confirm`, {}, 'Associa')}
                                             loader={this.state.dialogLoader}
                                             open={this.state.openAssociateCategoryDialog}
                                             categories={this.state.company_categories}
                                             onClose={() => this.setState({openAssociateCategoryDialog: false})}
                                             onSubmit={(categoryId, newCategory, labels) => this.associateCategory(categoryId,newCategory,labels)} />
                    :null}

                {/* DELETE CATEGORY CONFIRM DIALOG */}
                <AlertDialog open={this.state.openDeleteCategoryDialog}
                             title={translate( `model_detail.associate_modal_delete`, {}, "Dissocia categoria")}
                             message={translate( `model_detail.associate_modal_delete_message`, {}, "Sei sicuro di voler dissociare la categoria?")}
                             loader={this.state.dialogLoader}
                             confirmBtnText={translate( `machine_detail.associate_modal_confirm_delete`, {}, 'Dissocia')}
                             cancelBtnText={translate( `general.cancel`, {}, 'Annulla')}
                             onClose={() => this.setState({openDeleteCategoryDialog:false})}
                             onConfirm={() => this.disassociateCategory(this.state.activeDeleteId)}/>

            </div>
        )
    }
}


ModelsManager.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    license: state.license,
    utils: state.utils,
    api_control: state.api_control,
    router: state.router,
    multilanguage: state.multilanguage,
});


const mapActionsToProps = {
    onUpdateUtils : updateUtils,
    onUpdateApiControl : updateApiControl,
    onUpdateRouter: updateRouter,
    onUpdateMultilanguage: updateMultilanguage,
};

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(ModelsManager);
