import React, { Component } from 'react';
import PropTypes from 'prop-types';
// material ui import
import { withStyles } from '@material-ui/core/styles';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../redux/actions/api-control-action';
// i18n translation
import {translate} from "../../../../translations/i18n";
import BackofficeViewContainer from '../../layout/container/AdminContainer';
import AutoTable from '../../../../components/autotable/AutoTable'
import fields from './config/fields'
import FunctionsToolbar from "../../../../components/ui/toolbar/FunctionsToolbar";
import DetailDialog from "./components/DetailDialog";
import {Dialog} from "@material-ui/core";
import BulkAPI from "../../../../services/BulkAPI";

const styles  = (theme) => ({
    container : {
        width : '100%',
        display : 'flex',
        flexDirection : 'column',
        alignItems : 'flex-start',
    },
});

class BulkImport extends Component {

    constructor(props){
        super(props);
        this.state = {
            open  : false,
            selected : {},
            data : [],
            loader : false,
            next : '',
            prev : '',
            page : 0,
            count : 0
        }
    }


    getFilter(string = '? '){
        return string ? string.split('?')[1] : '';
    }

    componentDidMount(){
        this.setState({ loader: true });

        BulkAPI.getLogs().then(
            res => {
                this.setState({
                    data : res.data.results,
                    count : res.data.count,
                    next : this.getFilter(res.data.next),
                    prev : this.getFilter(res.data.prev)});
                this.setState({ loader: false });

            },
            err => {

            }
        )
    }

    handleChangePage = (e, newPage) => {
        const {page, next, prev} = this.state;

        if (newPage > this.state.page  && this.state.next !== ''){
            BulkAPI.getLogs(this.state.next).then(
                res => {
                    this.setState({
                        data : res.data.results,
                        count : res.data.count,
                        next : this.getFilter(res.data.next),
                        prev : this.getFilter(res.data.prev),
                        page : newPage,
                        loader: false
                    }, () => window.scrollTo(0, 0));
                },
                err => {

                }
            )
        }else if (this.state.prev){
            BulkAPI.getLogs(this.state.prev !== '').then(
                res => {
                    this.setState({
                        data : res.data.results,
                        count : res.data.count,
                        next : this.getFilter(res.data.next),
                        prev : this.getFilter(res.data.prev),
                        page : newPage
                    });
                    this.setState({ loader: false });
                },
                err => {

                }
            )
        }
    }

    addToolbarFunction = () => {
        let toolBarFunctions = [];

            toolBarFunctions.push({
                label: 'Crea punto di ripristino',
                func: () => alert('click')
            });

        return toolBarFunctions;
    };

    render() {
        const { classes } = this.props;
        const { open, selected, data, loader, page, count } = this.state;
        return (
            <div className={classes.root}>
                <BackofficeViewContainer
                    hasMarginTop={false}
                    /*renderToolbar={
                        <FunctionsToolbar items={this.addToolbarFunction()}/>
                    }*/
                    title={translate( `restore.title`, {},  "Punti di ripristino" )}>
                    <div className={classes.container}>

                        <AutoTable
                            loader={loader}
                            fields={fields({
                                openInfo : (value) => this.setState({open : value}),
                                setSelected : (value) => this.setState({selected : value}),
                                selected
                            })}
                            handleChangePage={this.handleChangePage}
                            page={page}
                            count={count}
                            data={data}
                        />
                    </div>
                </BackofficeViewContainer>

                {open && <DetailDialog
                    title={'Dettaglio'}
                    subtitle={'dettaglio punto di ripristino'}
                    cancelText={'chiudi'}
                    open={open}
                    onClose={() => {
                        this.setState({open : false , selected : {}})
                    }}
                    fields={fields()}
                    data={selected}/>}
            </div>
        )
    }
}


BulkImport.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    utils: state.utils,
    api_control: state.api_control
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl
};

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(BulkImport);
