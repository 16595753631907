import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import {translate} from "../../../../translations/i18n";
import DialogLanguageSelector from "../components/DialogLanguageSelector";
const styles = theme => ({
    root: {
        width: '100%',
    },
    label: {
        marginTop:-4
    },
    icon:{
        fontSize:22,
        margin:'0 8px',
        color:theme.palette.primary.main
    },
    select: {
        minWidth: 140,
    }
});

class ImportLinkMatch extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            selected: this.props.labelMatch
        }
    }

    render() {
        const { classes, contentText, handleChangeLanguage, languageValue, values} = this.props;

        return (
            <DialogContent>

                <DialogContentText id="alert-dialog-description">
                    <Typography>
                        {contentText}
                    </Typography>
                </DialogContentText>

                <DialogLanguageSelector
                    multiple
                    label={translate('bulk_import.language', {}, 'Seleziona lingua')}
                    onChange={handleChangeLanguage}
                    languageValue={languageValue}
                    values={values}
                />

            </DialogContent>
        );
    }
}

ImportLinkMatch.propTypes = {
    classes: PropTypes.object,
};

export default compose( withStyles(styles, {withTheme: true}))(ImportLinkMatch);
