import React, { Component } from 'react';
import PropTypes from 'prop-types';
// material ui import
import { withStyles } from '@material-ui/core/styles';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../../redux/actions/api-control-action';
// api service import
import ProductApi from '../../../../../services/ProductApi';
// containers import
import BackofficeViewContainer from '../../../layout/container/AdminContainer';
// components import
import BoxCard from '../../../../../components/ui/cards/BoxCard';
import Search from '../../../../../components/ui/search/Search';
import SnackbarLoader from '../../../../../components/ui/loaders/snackbar/SnackbarLoader';
import AlertDialog from '../../../../../components/ui/dialogs/alertDialog/AlertDialog';
import CreateContactDialog from "../../contactsManager/components/CreateContactDialog";
import AssociateContactDialog from '../components/AssociateContactDialog';
import {translate} from "../../../../../translations/i18n";


const styles  = () => ({

});

class MachineContacts extends Component {

    constructor(props){
        super(props);
        this.state = {
            contactsList:[],
            contactsFound:[],
            companyContacts:[],
            product: null,
            dialogLoader: false,
            openDeleteContactDialog: false,
        }
    }

    // ==================================================
    // GET THE CONTACT LIST
    // ==================================================
    componentDidMount(){

        this.setState({ loader: true });
        ProductApi.getContact(this.props.match.params.machine_id).then(
            res => {
                this.setState({
                    contactsList: res.data.contacts,
                    contactsFound: res.data.contacts,
                    product: res.data.product,
                    companyContacts: res.data.company_contacts,
                    loader:false,
                })
            },
            err => {
                this.setState({ loader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'loadContactManager', );
            }
        )
    }

    handleAssociateContact(contactId) {
        this.setState({ dialogLoader: true });

        ProductApi.addContact(this.state.product.id, contactId).then(
            res => {
                let deleteIndex = this.state.companyContacts.map(function (item) {return item.id.toString()}).indexOf(contactId);
                this.setState(prevState => ({
                    openAssociateContactDialog: false,
                    contactsList: [...prevState.contactsList, res.data],
                    contactsFound: [...prevState.contactsList, res.data],
                    companyContacts:  prevState.companyContacts.filter((_, i) => i !== deleteIndex),
                    dialogLoader: false,
                }));
            },
            err => {
                this.setState({dialogLoader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'associateContact', );
            }
        )
    }

    handleRemoveContact(contactId) {
        this.setState({ dialogLoader: true });

        ProductApi.removeContact(this.state.product.id, contactId).then(
            () => {
                let deleteIndex = this.state.contactsList.map(function (item) {return item.id}).indexOf(contactId);
                this.setState(prevState => ({
                    companyContacts: [...prevState.companyContacts, prevState.contactsList[deleteIndex] ],
                    contactsList: prevState.contactsList.filter((_, i) => i !== deleteIndex),
                    contactsFound: prevState.contactsList.filter((_, i) => i !== deleteIndex),
                    openDeleteContactDialog: false,
                    dialogLoader: false
                }));
            },
            err => {
                this.setState({dialogLoader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'associateContact', );
            }
        )
    }

    render() {
        const { classes } = this.props;
        const { contactsList, contactsFound, loader, product, dialogLoader } = this.state;

        return (
            <div className={classes.root}>
                <BackofficeViewContainer
                    title={translate( `machine_contacts.title`, {},  "Contatti Macchina")}
                    subtitle={translate( `machine_contacts.subtitle`, {},  "Contatti per la gestione della macchina")}
                    renderSearch={
                        <Search data={contactsList} // data list where the component search
                                keySearch={"label"} // key that have to search
                                placeholder={translate( `machine_contacts.search`, {},  "Cerca per contatto")} // placeholder
                                onResult={(searchResult) => this.setState({contactsFound: searchResult})}/> // action after search
                    }>

                    {product && (product.is_admin || product.is_owner) ?
                        <BoxCard variant={"add"}
                                 title={"+"}
                                 disabled={this.state.loader}
                                 subtitle={translate( `machine_contacts.create`, {},  "Aggiungi Contatto")}
                                 onClick={() => this.setState({openAssociateContactDialog:true})}/>
                        :null}

                    {/* CONTACT LIST BOX CARDS */}
                    {contactsFound.map((contact, index) =>
                            <BoxCard key={contact + '-' + index}
                                     title={contact.label}
                                     subtitle={contact.email}
                                     //link={`contact/${contact.id}`}
                                     onClick={() => this.setState({openUpdateContactDialog:true, activeUpdateContact:contact, contactDialogReadOnly: true})}
                                     onDelete={(product.is_admin || product.is_owner) ? () => {this.setState({openDeleteContactDialog:true, activeDeleteId:contact.id})} : null}/>
                    )}

                    {/* UPDATE CONTACT DIALOG */}
                    {(this.state.openUpdateContactDialog)?
                    <CreateContactDialog title={translate( `machine_contacts.edit_dialog`, {},  "Modifica contatto")}
                                         loader={loader}
                                         contact={this.state.activeUpdateContact}
                                         open={this.state.openUpdateContactDialog}
                                         readOnly={this.state.contactDialogReadOnly} // disable the edit
                                         onClose={() => this.setState({openUpdateContactDialog:false, contactDialogReadOnly:false})}
                                         onCreate={(updateContact) => this.updateContact(updateContact)}/>
                    :null}

                    {/* ASSOCIATE CATEGORY DIALOG */}
                    {(this.state.openAssociateContactDialog) ?
                        <AssociateContactDialog title={translate( `machine_contacts.associate_modal`, {},  'Associa Contatto')}
                                                 message={translate( `machine_contacts.associate_modal_message`, {},  'Seleziona il contatto da associare alla macchina')}
                                                 confirmBtnText={translate( `machine_contacts.associate_modal_confirm`, {},  'Associa')}
                                                 loader={dialogLoader}
                                                 open={this.state.openAssociateContactDialog}
                                                 contacts={this.state.companyContacts}
                                                 onClose={() => this.setState({openAssociateContactDialog: false})}
                                                 onSubmit={(contactId) => this.handleAssociateContact(contactId)} />
                        :null}

                    {/* DELETE CATEGORY CONFIRM DIALOG */}
                    <AlertDialog open={this.state.openDeleteContactDialog}
                                 title={translate( `machine_contacts.associate_modal_delete`, {},  "Dissocia contatto")}
                                 message={translate( `machine_contacts.associate_modal_delete_message`, {},  "Sei sicuro di voler dissociare il contatto?")}
                                 confirmBtnText={translate( `machine_contacts.associate_modal_delete_confirm`, {},  "Dissocia")}
                                 loader={dialogLoader}
                                 onClose={() => this.setState({openDeleteContactDialog:false})}
                                 onConfirm={() => this.handleRemoveContact(this.state.activeDeleteId)}/>

                    {/* LOADER */}
                    <SnackbarLoader open={loader} message={translate( `machine_contacts.loader`, {},  "Elaborazione in corso")}/>
                </BackofficeViewContainer>
            </div>
        )
    }
}


MachineContacts.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    utils: state.utils,
    router: state.router,
    api_control: state.api_control
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl
}

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(MachineContacts);
