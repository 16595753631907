import React, { Component } from 'react';
import PropTypes from 'prop-types';
//material ui
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import {Search as SearchIcon} from 'react-feather';
// Redux
import { compose } from 'redux';
// i18n translation
import Utils from '../../../utils/Utils';

const styles  = (theme) => ({
    container: {
        flex:1,
        border : `1px solid #2C3A49`,
        borderRadius : 10
    },
    inputSearch: {
        width:'100%',
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,

        '& input':{
            padding: 14,
            border: 'unset',
            borderTopLeftRadius: 4,
        }
    },
    button : {
        backgroundColor : '#fff',
        boxShadow: 'unset',
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        height: '100%',
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        '&:hover':{
            backgroundColor : '#9f9f9f !important',
        }
    }
    });

class Search extends Component {

    constructor(props){
        super(props);
        this.state = {}
    }

    componentDidMount(){}

    selectLangLabel = (x) =>{
        let { multilingual }= this.props;

        return x.flag.id == multilingual[0]
    };

    // On change of input search
    handleOnSearch(e){
        let { data, keySearch }= this.props;
        // search function

        let split = keySearch.split('.');
        const search = stringToSearch =>
            data.filter(element =>
            split.some(key => (key.includes(':')) ?
                element[key.split(':')[0]].filter(this.selectLangLabel)[0] && element[key.split(':')[0]].filter(this.selectLangLabel)[0][key.split(':')[1]] && element[key.split(':')[0]] && element[key.split(':')[0]].filter(this.selectLangLabel)[0] && element[key.split(':')[0]].filter(this.selectLangLabel)[0][key.split(':')[1]].toLowerCase().includes(stringToSearch.toLowerCase())
                :
                element[key] && element[key].toLowerCase().includes(stringToSearch.toLowerCase()))
        );
        let searchResult = search(e.target.value);
        // call onResult function to set the searchResult
        this.props.onResult(searchResult, e.target.value);
    }

    render() {
        const { classes, disabled = false } = this.props;


        return (
            <div className={classes.container}>
                <form onSubmit={(e) => e.preventDefault()} style={{display:'flex'}}>
                    {/* disableUnderline={true} */}

                    <Input className={classes.inputSearch}
                           disableUnderline={true}
                           disabled={disabled}
                           onChange={(this.props.onChange) ? (e) => this.props.onChange(e.target.value) : (e) => this.handleOnSearch(e)}
                           placeholder={(this.props.placeholder)?this.props.placeholder:'Ricerca'}
                    endAdornment={
                        <Button type="submit"
                                color="primary"
                                disabled
                                className={classes.button}>
                            <SearchIcon style={{fontSize : 24, color : '#2C3A49'}}/>
                        </Button>
                    }
                    />
                </form>
            </div>
        )
    }
}


Search.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired, // where the component search
    placeholder: PropTypes.string,
    searchParams: PropTypes.string,
    onChange: PropTypes.func
};

export default compose( withStyles(styles, {withTheme: true}))(Search);
