import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../redux/actions/api-control-action';
import { updateLoaders } from '../../../../redux/actions/loaders-action';
import { updateRouter } from "../../../../redux/actions/router-action";
// Material-ui
import Typography from '@material-ui/core/Typography';
import ProductApi from '../../../../services/ProductApi';
import FileApi from '../../../../services/FileApi';
import BackofficeViewContainer from '../../layout/container/AdminContainer';
import FileList from './components/FileList';
import ChangeLanguageDialog from './components/dialogs/ChangeLanguageDialog';
import Search from '../../../../components/ui/search/Search';
import DialogDropzone from '../../../../components/ui/dropzone/DialogDropzone';
import AlertDialog from '../../../../components/ui/dialogs/alertDialog/AlertDialog';
import FunctionsToolbar from '../../../../components/ui/toolbar/FunctionsToolbar';
import SnackbarLoader from '../../../../components/ui/loaders/snackbar/SnackbarLoader';
import GoogleSignedUpload from "../../../../components/gcp/GoogleSignedUpload";
import GeneralSettings from "../../../../settings/GeneralSettings";
import AuthService from "../../../../services/AuthService";
import LinkDialog from "../../../../components/ui/link/LinkDialog";
import API from "../../../../services/API";
import ImportComponentsDialog from "./componentsManager/components/ImportComponentsDialog";
import ImportLinkDialog from "../../../../components/ui/dialogs/importLinkDialog/importLinkDialog";
import LanguageApi from "../../../../components/language-select/LanguageApi";
import multilanguage from "../../../../redux/reducers/multilanguage-reducer";
import {updateMultilanguage} from "../../../../redux/actions/multilanguage-action";
import {translate} from "../../../../translations/i18n";
import {Plus, UploadCloud, Trash, Globe } from 'react-feather'
import { debounce } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import './MachineCategoryFiles.css'

const styles  = () => ({
    root:{},
    optionLabel:{
        textTransform : 'uppercase',
        marginTop : 6,
        margin : '0px 0px 0px 8px',
        color :'#000',
        fontSize: 16,
        fontWeight: 600,
    },
    outerDiv:{
        '.infinite-scroll-component__outerdiv':{
            width : '100%'
        }
    }



});

class MachineCategoryFiles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            product: null,
            category: null,
            permissions: null,
            files: [],
            links: [],
            filteredFiles: [],
            filteredLinks: [],
            selectedFiles: [],
            labelList: [
                {code: 'name', label: 'nome'},
                {code: 'link', label: 'link'},
            ],
            languageValue : [],
            activeStep: 0,
            idCategory : null,
            dialogLoader: false,
            openDeleteConfirmDialog: false,
            openDropzoneDialog: false,
            openLinkDialog: false,
            openLanguageDialog: false,
            openImportComponentsDialog:false,
            addFileData: {count: 1, total: 0},
            uploadFiles:[],
            fileLanguages : [],
            languageValues : [],
            availableLang : [],
            search : '',
            finalLabels : {title : '', subtitle : ''},
            pagination : {
                offset : 0,
                limit : 30,
                hasMore : true
            },
            searchQuery : ''
        };
        this.searchLinks = debounce((string) => {
            let tmpLanguageValue = this.props.multilanguage.selectedLanguage;

            if (this.props.multilanguage.languageValues && this.props.multilanguage.languageValues.length > 0)
                tmpLanguageValue = tmpLanguageValue.map(item => this.props.multilanguage.languageValues[this.props.multilanguage.languageValues.findIndex(lng => lng.id == item)].code)

            let tmpFilterRaw = tmpLanguageValue ? tmpLanguageValue.map((item , index) => (index === 0) ? `?lang=${item}` : `&lang=${item}`)
                :
                ['&lang=IT'];


            let tmpFilter = '';

            if (tmpFilterRaw.length > 0)
                tmpFilterRaw.forEach(item => tmpFilter = tmpFilter + item);

            let product_id = this.props.match.params.machine_id;
            let category_id = this.props.match.params.category_id;

            this.setState({searchQuery : string, pagination : {
                    offset : 0,
                    limit : 30,
                    hasMore : true
                },
                loader : true,
            }, () => {
                ProductApi.getFile(product_id, category_id, tmpFilter, {limit : this.state.pagination.limit, offset : this.state.pagination.offset, search : this.state.searchQuery}).then(
                    res => {
                        const links = res.data.links;
                        if (links)
                            links.forEach((link, idx) => {
                                link.is_model_file = link.is_model_file;
                                link.type = 'link';
                            });
                        this.setState({
                            pagination : {
                                ...this.state.pagination,
                                offset: this.state.pagination.offset + this.state.pagination.limit,
                                hasMore : res.data.files.length === this.state.pagination.limit || links.length === this.state.pagination.limit
                            },
                            loader:false,
                            files: res.data.files,
                            filteredFiles: res.data.files,
                            links: links,
                            filteredLinks: links
                        }, () => this.getLabels('category'));
                    },
                    err => {
                        this.setState({ loader: false });
                        // ====== API CONTROL MESSAGE ====== //
                        this.props.onUpdateApiControl(err, 'getFiles', );
                    }
                );
            })
        }, 700);
    }

    getLinks(){

        if (this.state.loader === false) {
            this.setState({loader: true});

            let tmpLanguageValue = this.props.multilanguage.selectedLanguage;

            if (this.props.multilanguage.languageValues && this.props.multilanguage.languageValues.length > 0)
                tmpLanguageValue = tmpLanguageValue.map(item => this.props.multilanguage.languageValues[this.props.multilanguage.languageValues.findIndex(lng => lng.id == item)].code)

            let tmpFilterRaw = tmpLanguageValue ?
                tmpLanguageValue.map((item, index) => (index === 0) ? `?lang=${item}` : `&lang=${item}`)
                :
                ['&lang=IT']

            let tmpFilter = '';

            if (tmpFilterRaw.length > 0)
                tmpFilterRaw.forEach(item => tmpFilter = tmpFilter + item);

            let product_id = this.props.match.params.machine_id;
            let category_id = this.props.match.params.category_id;

            ProductApi.getFile(product_id, category_id, tmpFilter,
                {
                    limit: this.state.pagination.limit,
                    offset: this.state.pagination.offset,
                    search: this.state.searchQuery
                }
            ).then(
                res => {

                    const links = res.data.links;
                    if (links)
                        links.forEach((link, idx) => {
                            link.is_model_file = link.is_model_file;
                            link.type = 'link';
                        });

                    this.setState({
                        pagination: {
                            ...this.state.pagination,
                            offset: this.state.pagination.offset + this.state.pagination.limit,
                            hasMore: res.data.files.length === this.state.pagination.limit || links.length === this.state.pagination.limit
                        },
                        loader: false,
                        files: [...this.state.files, ...res.data.files],
                        filteredFiles: [...this.state.filteredFiles, ...res.data.files],
                        links: [...this.state.links, ...links],
                        filteredLinks: [...this.state.filteredLinks, ...links]
                    }, () => this.getLabels('category'));
                },
                err => {
                    this.setState({loader: false});
                    // ====== API CONTROL MESSAGE ====== //
                    this.props.onUpdateApiControl(err, 'getFiles',);
                }
            );
        }
    }

    apiCalls(reset){
        if (this.props.match && !this.state.loader) {
            this.setState({loader: true, selectedFiles: []});
            let product_id = this.props.match.params.machine_id;
            let category_id = this.props.match.params.category_id;


            let tmpLanguageValue = this.props.multilanguage.selectedLanguage;

            if (this.props.multilanguage.languageValues && this.props.multilanguage.languageValues.length > 0)
                tmpLanguageValue = tmpLanguageValue.map(item => this.props.multilanguage.languageValues[this.props.multilanguage.languageValues.findIndex(lng => lng.id == item)].code)

            let tmpFilterRaw = tmpLanguageValue ? tmpLanguageValue.map((item , index) => (index === 0) ? `?lang=${item}` : `&lang=${item}`)
                :
                ['&lang=IT']

            let tmpFilter = '';

            if (tmpFilterRaw.length > 0)
                tmpFilterRaw.forEach(item => tmpFilter = tmpFilter + item);

            ProductApi.getComponent(product_id).then(
                res => {
                    // ====== UPDATE BREADCRUMB ====== //
                    res.data.product &&
                    this.props.onUpdateRouter({
                        ...this.props.router,
                        activeMachineName: res.data.product.name,
                        activeMachineLabels: res.data.product.labels
                    });

                    res.data.category_id &&
                    this.setState({
                        idCategory: res.data.category_id,
                    })
                },
                err => {
                    // ====== API CONTROL MESSAGE ====== //
                    this.props.onUpdateApiControl(err, 'loadComponentMachine', );
                }
            );

            ProductApi.getFile(product_id, category_id, tmpFilter,
                {limit : this.state.pagination.limit, offset : this.state.pagination.offset, search : this.state.searchQuery}
            ).then(
                res => {
                    const links = res.data.links;
                    if (links)
                        links.forEach((link, idx) => {
                            link.is_model_file = link.is_model_file;
                            link.type = 'link';
                        });

                    this.props.onUpdateRouter({
                        ...this.props.router,
                        activeCategoryName: res.data.category.label,
                        activeMachineName: res.data.product.name,
                        activeCategoryLabels: res.data.category.labels
                    });
                    this.setState({
                        loader:false,
                        product: res.data.product,
                        category: res.data.category,
                        permissions: res.data.permissions,
                        files: (reset) ? res.data.files : [...this.state.files, ...res.data.files],
                        filteredFiles: (reset) ? res.data.files : [...this.state.filteredFiles ,...res.data.files],
                        availableLang: res.data.available_languages,
                        links: (reset) ? links : [...this.state.links ,...links],
                        filteredLinks: (reset) ? links : [...this.state.filteredLinks, ...links],
                        pagination: {
                            ...this.state.pagination,
                            offset: this.state.pagination.offset + this.state.pagination.limit,
                            hasMore: res.data.files.length === this.state.pagination.limit || links.length === this.state.pagination.limit,
                        },
                    }, () => this.getLabels('category'));
                },
                err => {
                    this.setState({ loader: false });
                    // ====== API CONTROL MESSAGE ====== //
                    this.props.onUpdateApiControl(err, 'getFiles', );
                }
            )

            // const api = new API(`${this.url}/${productId}/category/${categroyId}/file/434024${lang}`);

            // api.get().then(res => {console.log('res.data', res.data)})
            // api.get().then(res => {console.log('res.data', res.data)})
        }
    }


    componentDidUpdate(prevProps) {

        if (prevProps.multilanguage !== this.props.multilanguage) {

            this.getLabels();
            this.setState({pagination : {
                    offset : 0,
                    limit : 30,
                    hasMore : true
                }}, () => this.apiCalls(true))
        }
    }

    componentDidMount(){

        this.apiCalls();
    }

    handleStepperNext() {
        this.setState(() => ({
            activeStep: this.state.activeStep + 1,
        }));
    };

    handleStepperBack() {
        this.setState(() => ({
            activeStep: this.state.activeStep - 1,
        }));
    };

    handleOnCompleteImport(components) {
        let tmpComponentsList = this.state.componentsList;
        tmpComponentsList = tmpComponentsList.concat(components);
        this.setState({
                componentsList: tmpComponentsList,
                componentsFound: tmpComponentsList,
                loader:false,
            },
            this.handleStepperNext()
        )
    }

    addToolbarFunction = (permissions) => {
        let toolBarFunctions = [];
        if(!permissions) { return toolBarFunctions; }

        if (permissions.write)
            toolBarFunctions.push({
                label: translate('documents.add', {}, "Aggiungi documenti"),
                disabled: false,
                icon: (style) => <Plus style={style}/>,
                func: () => this.setState({openDropzoneDialog: true, fileLanguages : []})
            });
        if (permissions.write)
            toolBarFunctions.push({
                label: translate('documents.add_link', {}, "Aggiungi link"),
                disabled: false,
                icon: (style) => <Plus style={style}/>,
                func: () => this.setState({openLinkDialog: true, fileLanguages : []})
            });
        if (permissions.write)
            toolBarFunctions.push({
                label: translate( `bulk_import.title`, {},  "Importa link" ),
                disabled: false,
                icon: (style) => <UploadCloud style={style}/>,
                func: () => this.setState({openImportComponentsDialog: true})
            });
        if (permissions.delete)
            toolBarFunctions.push({
                label: translate('general.delete', {}, 'Elimina'),
                disabled: (this.state.selectedFiles.length < 1),
                icon: (style) => <Trash style={style}/>,
                func: () => this.setState({openDeleteConfirmDialog: true})
            });
        /*if (permissions.write)
            toolBarFunctions.push({
                label: translate('language.title', {}, "Modifica lingua"),
                disabled: (this.state.selectedFiles.length < 1),
                icon: (style) => <Globe style={style}/>,
                func: () => this.setState({openLanguageDialog: true})
            });*/
        return toolBarFunctions;
    };

    deleteFile = () => {
        let product_id = this.props.match.params.machine_id;
        let category_id = this.props.match.params.category_id;
        this.setState({ dialogLoader: true});
        /*eslint no-unused-vars: 0*/

        let tmpFiles = [...this.state.selectedFiles];
        tmpFiles.map((file, i) => {
                ProductApi.deleteLinkById(product_id, category_id, file.id, file.type).then(
                    () => {
                        let links = [...this.state.links];
                        const index = links.findIndex(item => file.id == item.id);

                        if (index >= 0)
                            links.splice(index, 1);

                        let selectedFiles = [...this.state.selectedFiles];
                        const indexS = selectedFiles.indexOf(file);
                        if (indexS >= 0)
                            selectedFiles.splice(indexS, 1);

                        this.setState({
                            links: links,
                            filteredLinks: links,
                            selectedFiles: selectedFiles,
                            openDeleteConfirmDialog: false,
                            dialogLoader: false,
                            pagination : {
                                offset : 0,
                                limit : 30,
                                hasMore : true
                            }
                        }, () => this.apiCalls(true));
                    },
                    err => {
                        this.setState({loader: false, openDeleteConfirmDialog: false, dialogLoader: false});
                        // ====== API CONTROL MESSAGE ====== //
                        this.props.onUpdateApiControl(err, 'deleteFile', );
                    }
                )
            }
        );
    };


    editLanguage = (fileEdit, linkEdit) => {
        let product_id = this.props.match.params.machine_id;
        let category_id = this.props.match.params.category_id;
        this.setState({ dialogLoader: true });
        /*eslint no-unused-vars: 0*/

        let tmpFiles = [...this.state.selectedFiles];
        tmpFiles.map((item, i) => {
            let tmpItem = item;
            let tmpFlags = (tmpItem.flags) ? tmpItem.flags : [];

            linkEdit.forEach(linkValue => {
                if (linkValue.value === 'multiple' && tmpFlags.findIndex(flagV => flagV.id === linkValue.id) === -1) {
                    let tmpLng = {...linkValue}
                    delete tmpLng.value
                    tmpFlags = [...tmpFlags, tmpLng];
                }
                else if (linkValue.value === 'removed' && tmpFlags.findIndex(flagV => flagV.id === linkValue.id) !== -1) {

                    const tmpFlagsI = tmpFlags.findIndex(flagV => flagV.id === linkValue.id);
                    tmpFlags.splice(tmpFlagsI , 1)
                }
            });

            ProductApi.updateLinkLanguage(product_id, category_id, item.id, {...tmpItem, flags :tmpFlags}, item.type)
                .then(
                    res => {
                        if (tmpFiles.length === i + 1) {
                            this.setState({
                                selectedFiles: [],
                                openLanguageDialog: false,
                                dialogLoader: false,
                            });
                            this.apiCalls()
                        }

                    },
                    err => {
                        this.setState({dialogLoader: false});
                        // ====== API CONTROL MESSAGE ====== //
                        this.props.onUpdateApiControl({
                            context: "",
                            message: "",
                            response: err.response,
                            action: null,
                        });
                    }
                )

        })
    };

    addLink = (link, labels) => {
        this.setState({dialogLoader: true});
        const api = new API(`product/${this.state.product.id}/category/${this.state.category.id}/links`);

        if (this.state.links.findIndex(item => item.name === link.name) === -1 )
            api.post(link).then(
                res => {
                    if (res.data.length > 0, res.data[0].flags.length > 0)
                        res.data[0].flags.map(item =>
                            ProductApi.addLinkLabels({title : labels[labels.findIndex(d => (d.flag[0] == item.id))].title, description : '', flag : item}, res.data[0].id, item.code).then(
                                r => {
                                },
                                err => {
                                }
                            )
                        );


                    let updatedLinksList = this.state.links.concat({
                        ...link[0],
                        flags : this.props.multilanguage.languageValues.filter(lng => link[0].flags.includes(lng.id)),
                        labels,
                        type : 'link',
                        is_model_file : false,
                        id : res.data[0].id
                    })

                    this.setState(prevState => ({
                        dialogLoader: false,
                        links: updatedLinksList,
                        filteredLinks: updatedLinksList,
                        openLinkDialog: false
                    }));
                },
                err => {
                    this.setState({dialogLoader: false});
                    // ====== API CONTROL MESSAGE ====== //
                    this.props.onUpdateApiControl({
                        context: "",
                        message: "",
                        response: err.response,
                        action: null,
                    });
                }
            );
        else {
            this.setState({dialogLoader: false,})
            this.props.onUpdateApiControl({status: 400}, 'link_add',  `il link ${link.name} esiste già` );
        }
    };

    ImportSuccessful = (res) => {

        const links = res.data.links;
        links.forEach((l, idx) => {
            l.is_model_file = l.is_model_file
            l.type = 'link'
        });
        this.setState(prevState => ({
            dialogLoader: false,
            links: res.data.links,
            filteredLinks: res.data.links,
            openLinkDialog: false
        }))
    };

    addFiles = (post_files, languages) =>{

            this.setState({
            dialogLoader: true,
                loader : true,
            addFileData: {
                count: 0,
                total: post_files.length
            }});
        let tmpUploadFiles = [];
        for (let i=0;i<post_files.length;i++){
            tmpUploadFiles.push(post_files[i]);
        }
        this.setState({uploadFiles:tmpUploadFiles , fileLanguages : languages});

        this.setState({pagination : {
                offset : 0,
                limit : 30,
                hasMore : true
            }}, () => this.apiCalls(true))
    };

    handleSearch(searchResult, value){
        let split = "name".split('.');
        const search = stringToSearch => this.state.links.filter(element =>
            split.some(key => element[key] && element[key].toLowerCase().includes(stringToSearch.toLowerCase()))
        );
        let linkSearchResult = search(value);
        this.setState({filteredFiles: searchResult, filteredLinks : linkSearchResult, search : value})
    }

    onResultGoogleSignedUpload(result,file){

        this.setState({loader : true});

        ProductApi.createFile(this.state.product.id, this.state.category.id, {'file_name': file.name}).then(
            res => {
                let files = [...this.state.files];
                files.push(res.data);


                this.setState(state => ({
                    files: files,
                    filteredFiles: files,
                    loader : false
                }));

                if(this.state.fileLanguages && this.state.fileLanguages.length > 0) this.state.fileLanguages.map( (item, index) =>
                    ProductApi.associateFile(item , res.data.id).then(
                        res => {
                            if (index + 1 == this.state.fileLanguages.length){
                                this.setState({
                                    pagination : {
                                        offset : 0,
                                        limit : 30,
                                        hasMore : true,
                                        loader : false,
                                    }}, () => this.apiCalls(true))
                            }
                        },
                        err => {

                        }
                    ))
            },
            err => {
                this.setState({dialogLoader: false, loader : false});
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'create_file', );
            }
        )
    }

    getLabels = (type = 'category') => {

        if (this.state[type]) {
            const {multilanguage} = this.props;
            const {labels = [], name, description = '', label} = this.state[type];
            const {defaultlang, finalLabels} = this.state;

            let tmpLabels = [...labels];

            if (labels && labels.length > 0 && multilanguage.selectedLanguage && multilanguage.selectedLanguage.length > 0) {
                let mainLanguage = multilanguage.selectedLanguage[0];

                tmpLabels = tmpLabels.filter(item =>
                    item.flag.id == mainLanguage
                );

                if (tmpLabels.length === 0 && defaultlang)
                    tmpLabels = labels.filter(item =>
                        item.flag.id == defaultlang
                    );


                if (type === 'category' && tmpLabels.length === 0 && !defaultlang)
                    tmpLabels = {title: label, subtitle: description};
                else if (tmpLabels.length === 0 && !defaultlang)
                    tmpLabels = {title: name, subtitle: description}

            } else if (!multilanguage || multilanguage.length === 0 && defaultlang) {
                tmpLabels = tmpLabels.filter(item =>
                    item.flag.id == defaultlang
                );
            }

            if (labels && labels.length === 1)
                tmpLabels = {title: labels[0].title, subtitle: labels[0].description};

            if (type === 'category' && tmpLabels.length === 0 && (label || description))
                tmpLabels = {title: label, subtitle: description};
            else if (tmpLabels.length === 0 && (name || description))
                tmpLabels = {title: name, subtitle: description};

            if (tmpLabels && tmpLabels.length >= 1) {
                tmpLabels = {title: tmpLabels[0].title, subtitle: tmpLabels[0].description}
            }

            this.setState({finalLabels: tmpLabels});
            return tmpLabels;
        }
    };

    render() {
        const { product, category, permissions, files, filteredFiles,filteredLinks,
            openDeleteConfirmDialog, openDropzoneDialog, loader, dialogLoader, openLinkDialog,openLanguageDialog, idCategory, languageValue, languageValues} = this.state;
        const { uploadFiles, finalLabels, pagination } = this.state;
        const { classes, multilanguage } = this.props;


        return (
            <div className={classes.root}>
                <GoogleSignedUpload baseUrl={GeneralSettings.baseUrl}
                                    bearerKey={GeneralSettings.bearerKey}
                                    token={AuthService.getLocalToken()}
                                    files={uploadFiles}
                                    type={(product && category) && `p/${product.id}/${category.id}`}
                                    onResultFileUpload={(res, file) => {console.log('onResultFileUpload', res, file)}}
                                    onErrorFileUpload={(res, file, index) => {
                                        if (res.data.error === 'already_exists')
                                            this.props.onUpdateApiControl({status: 400}, 'create_file', `Il file ${file.name} esiste già`);
                                        else
                                            this.props.onUpdateApiControl({status : 400}, 'create_file', "Errore nell'upload del file!" );

                                        this.setState(state => ({
                                            addFileData: {
                                                count: state.addFileData.count + 1,
                                                total: state.uploadFiles.length
                                            },
                                            loader : false,
                                        }));

                                        // Clean if all files are uploaded
                                        if (this.state.addFileData.count === index){
                                            this.setState({
                                                uploadFiles:[],
                                                dialogLoader: false,
                                                openDropzoneDialog: false,
                                            })
                                        }
                                    }}
                                    onResultGoogleSignedUpload={(result, file) => {
                                        this.setState(state => ({
                                            addFileData: {
                                                count: state.addFileData.count + 1,
                                                total: state.uploadFiles.length
                                            }
                                        }));
                                        this.onResultGoogleSignedUpload(result, file)
                                    }}
                                    onFinish={() => {
                                        this.setState(state => ({
                                            uploadFiles:[],
                                            dialogLoader: false,
                                            openDropzoneDialog: false,
                                        }));
                                    }}
                />

                <BackofficeViewContainer
                    title={`${translate('documents.manage.title', {}, "Gestione Documenti")} ${(category) ? "- " + finalLabels.title : ""}`}
                    subtitle={
                        this.props.user.isLogged ?
                            `${translate('documents.manage.subtitle', {}, "Gestisci i documenti relativi alla categoria")}`
                            : ''
                    }
                    background={false}
                    hasMarginTop={false}
                    languageSelect
                    handleChangeLanguage={this.handleChangeMultiple}
                    renderSearch={
                        <Search data={files} // data list where the component search
                                keySearch={"name.labels:title"} // key that have to search
                                multilingual={multilanguage.selectedLanguage}
                                disabled={loader}
                                placeholder={translate('documents.search', {}, "Cerca per nome")} // placeholder
                                onResult={(searchResult, value) =>
                                    this.searchLinks(value)
                                    // this.handleSearch(searchResult,value)
                                }/> // action after search
                    }
                    renderToolbar={<FunctionsToolbar items={this.addToolbarFunction(permissions)}/>}
                >

                    {(product && category && permissions) ?
                        ([...filteredFiles,...filteredLinks].length > 0) ?
                            <InfiniteScroll
                                dataLength={[...filteredFiles,...filteredLinks].length}
                                next={() => this.getLinks()}
                                hasMore={pagination.hasMore}
                                loader={loader}
                                style={{width : '100%'}}>
                                <FileList readonly={!permissions.delete}
                                          product={product}
                                          category={category}
                                          updateFiles={null}
                                          files={[...filteredFiles,...filteredLinks]}
                                          languageValues={this.props.multilanguage.languageValues}
                                          selectedFiles={this.state.selectedFiles}
                                          onSelectFile={(selectedFiles) => this.setState({selectedFiles: selectedFiles})}
                                />
                            </InfiniteScroll>
                            :
                            (this.state.availableLang && this.state.availableLang.length > 0 && this.state.search.length === 0) ?
                                <div style={{display :'flex', flexDirection :'column'}}>
                                    <Typography variant="body" style={{fontWeight: 500, marginTop: 40}}>
                                        {translate('documents.not_found_language', {}, "Non sono stati trovati documenti nella lingua selezionata")}
                                    </Typography>

                                    <Typography variant="body" style={{fontWeight: 500, margin : '20px auto'}}>
                                        {translate('documents.available_in', {}, "Questa documentazione è disponibile in:")}
                                    </Typography>

                                    <div style={{display :'flex', justifyContent : 'center'}}>
                                        {this.state.availableLang.map((item, index) =>
                                            <div style={{display: 'flex', justifyContent : 'flex-start', alignItems : 'center'}}>
                                                <img src={  item.src} style={{height : 18,margin: '0px 0px 0px 8px'}} width={28} alt={`lang-${index}`}/>
                                                <Typography className={classes.optionLabel}>{item.code}</Typography>
                                                {index + 1 !== this.state.availableLang.length &&
                                                <Typography style={{fontSize : 15, color : '#000', margin : '0px 4px'}}>
                                                    -
                                                </Typography>}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                :
                                <Typography variant="body" style={{fontWeight: 500, marginTop: 40}}>
                                    {translate('documents.not_found', {}, "Non sono stati trovati documenti per questa categoria")}
                                </Typography>
                        :
                        null
                    }

                    {/* LOADER */}
                    <SnackbarLoader open={loader} message={translate(`contacts.loader`,{}, "Elaborazione in corso")}/>
                </BackofficeViewContainer>

                {openDropzoneDialog &&
                <DialogDropzone title={translate('documents.add', {}, "Aggiungi documenti")}
                                contentText={translate('documents.upload', {}, "Carica da qui i tuoi file per aggiungerli ai documenti della macchina")}
                                multiple={true}
                                open={openDropzoneDialog}
                                language
                                loading={dialogLoader}
                                filesStep={this.state.addFileData.count}
                                filesToBeSent={this.state.addFileData.total}
                                onClose={() => this.setState({openDropzoneDialog: false})}
                                onDrop={(files, languages) => this.addFiles(files, languages)}/>
                }

                {openLinkDialog &&
                <LinkDialog title={translate('documents.add_link', {}, "Aggiungi link")}
                            contentText={translate('documents.upload_link', {}, "Carica da qui il tuo link per aggiungerli ai documenti della macchina")}
                            multiple={true}
                            open={openLinkDialog}
                            loading={dialogLoader}
                            language
                            onClose={() => this.setState({openLinkDialog: false})}
                            onSubmit={(link, labels) => this.addLink(link, labels)}/>
                }

                {openLanguageDialog &&
                <ChangeLanguageDialog
                    title={translate('language.title', {}, "Modifica lingua")}
                    multiple={true}
                    selected={this.state.selectedFiles}
                    open={openLanguageDialog}
                    loading={dialogLoader}
                    onClose={() => this.setState({openLanguageDialog: false})}
                    onSubmit={(linkLng, fileLng) => this.editLanguage(fileLng , linkLng)}/>
                }

                {(this.state.openImportComponentsDialog)?
                    <ImportLinkDialog open={this.state.openImportComponentsDialog}
                                      links={this.state.links}
                                      idProduct={product.id}
                                      language
                                      idCategory={category.id}
                                      labelList={this.state.labelList}
                                      activeStep={this.state.activeStep}
                                      ImportSuccessful={res => this.ImportSuccessful(res)}
                                      handleBack={() => this.handleStepperBack()}
                                      handleNext={() => this.handleStepperNext()}
                                      handleOnCompleteImport={(components) => this.handleOnCompleteImport(components)}
                                      onClose={() => this.setState({openImportComponentsDialog:false, activeStep:0})}/>
                    :null}

                {/* DELETE CONFIRM DIALOG */}
                <AlertDialog title={translate('documents.delete.title', {}, "Eliminazione file")}
                             message={translate('documents.delete.subtitle', {}, "Sicuro di voler eliminare i file selezionati?")}
                             confirmBtnText={translate('documents.delete.confirm', {}, "Elimina")}
                             open={openDeleteConfirmDialog}
                             loader={dialogLoader}
                             onClose={() => this.setState({openDeleteConfirmDialog: false})}
                             onConfirm={this.deleteFile}/>
            </div>
        )
    }
}


MachineCategoryFiles.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    api_control: state.api_control,
    router: state.router,
    utils: state.utils,
    multilanguage: state.multilanguage,
});


const mapActionsToProps = {
    onUpdateApiControl: updateApiControl,
    onUpdateLoaders: updateLoaders,
    onUpdateRouter: updateRouter,
    onUpdateMultilanguage: updateMultilanguage,
};

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(MachineCategoryFiles);
