import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// components import

const styles  = () => ({
    root:{
        textAlign:'justify',
        padding:'40px 60px'
    }
});

class TermsAndConditions extends Component {

    componentDidMount(){

    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Typography variant="title" style={{marginBottom:40}}>USO ACCETTABILE</Typography>
                <Typography variant="body2">
                    <br/><br/><strong>Norme sull'uso accettabile di Ubilod</strong><br/>
                    Siamo orgogliosi della fiducia che è stata riposta in noi da tutti i nostri utenti. In cambio, chiediamo agli utenti stessi di utilizzare i nostri servizi in modo responsabile.

                    <br/><br/><strong>Condivisione dei contenuti</strong><br/>
                    I nostri Servizi ti consentono di condividere i tuoi file con altre persone, per cui fai molta attenzione a cosa condividi.
                    <br/><br/>
                    L'utente accetta di non utilizzare i servizi di Ubilod (i "Servizi") in modo inappropriato e di non aiutare nessun'altra persona a farlo. Ad esempio, non deve nemmeno tentare di svolgere una delle seguenti azioni in connessione con i Servizi:

                    <ul>
                        <li>sondare, esaminare o testare la vulnerabilità di qualsiasi sistema o rete;</li>
                        <li>violare o aggirare in qualsiasi modo qualunque misura di sicurezza o autenticazione;</li>
                        <li>accedere a, manomettere o utilizzare aree o parti non pubbliche del Servizio oppure aree condivise dei Servizi a cui non è stato invitato;</li>
                        <li>interferire in o manomettere qualsiasi utente, host o rete, ad esempio inviando virus o spam, sovraccaricando o utilizzando tecniche di flooding e mail-bombing in qualsiasi parte dei Servizi;</li>
                        <li>accedere, effettuare ricerche o creare account per i Servizi attraverso mezzi diversi dalle interfacce supportate pubblicamente (ad esempio mediante "scraping" o creazione di account in blocco);</li>
                        <li>inviare comunicazioni, promozioni o annunci pubblicitari non richiesti oppure spam;</li>
                        <li>inviare informazioni alterate, ingannevoli o con falsa identificazione della provenienza, inclusi "spoofing" o "phishing";</li>
                        <li>promuovere o pubblicizzare prodotti o servizi diversi da quelli che possiede senza le autorizzazioni pertinenti;</li>
                        <li> abusare delle raccomandazioni o delle promozioni per ottenere più spazio di archiviazione di quanto meritato;</li>
                        <li>aggirare i limiti di spazio di archiviazione;</li>
                        <li>vendere i Servizi, a meno che non si sia specificamente autorizzati a farlo;</li>
                        <li>pubblicare o condividere materiali che siano illegalmente pornografici o indecenti oppure che contengano atti di estrema violenza;</li>
                        <li>promuovere l'intolleranza o l'odio nei confronti di persone o gruppi di persone sulla base di razza, religione, etnia, sesso, identità di genere, preferenze sessuali, disabilità o handicap.</li>
                        <li> molestare o tenere comportamenti illeciti nei confronti di dipendenti, rappresentanti o agenti di Ubilod in servizio per conto di Ubilod;</li>
                        <li> violare la legge in qualsiasi modo, incluse l'archiviazione, la pubblicazione o la condivisione di materiali fraudolenti, diffamatori o fuorvianti; oppure</li>
                        <li>violare la privacy o i diritti di altre persone.</li>
                    </ul>
                </Typography>
            </div>
        )
    }
}


TermsAndConditions.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(TermsAndConditions);