import React from 'react';
// material ui import
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
// Redux
import { compose } from 'redux';
// components import
import TextFieldControlCharacters from '../../../../../../components/ui/inputs/TextFieldControlCharacters'

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles  = theme => ({
    textField: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        width: '100%',
    },
    container: {
        display:'flex',
        flexDirection:'column',
        marginTop:8
    },
    dialogAction: {
        margin: '0px 20px',
        marginBottom:20,
    },
    row:{
        display:'flex',
        alignItems:'center',
        borderTop:'1px solid #e0e0e0',
        padding:'12px 8px',
    },
    grayRow:{
        background:'#fafafa'
    }
});


class OrderComponentsImport extends React.Component {

    constructor(props){
        super(props);
        this.state = {
        }
    }

    render() {
        const { classes, open, loader, selectedComponents } = this.props;

        return (
            <div>
                <Dialog
                    open={open}
                    onClose={this.props.onClose}>
                    {/* Print edit machine on edit - Print the label on readOnly*/}
                    <DialogTitle>{this.props.title}</DialogTitle>
                    <form onSubmit={(e)=> {e.preventDefault(); this.props.onConfirm()}}
                          className={classes.container}
                          onKeyPress={(e) => (e.which === 13)?e.preventDefault():null}>
                        <div>
                        <DialogContent style={{paddingTop:0}}>
                                <DialogContentText style={{paddingBottom:40}}>
                                    Compila le quantità e il messaggio sottostanti per richiedere il tuo ordine
                                </DialogContentText>
                            {selectedComponents.map((item, index) =>
                                <div className={(index%2===0)?[classes.row, classes.grayRow].join(' '):classes.row} style={(index === (selectedComponents.length-1))?{borderBottom:'1px solid #e0e0e0'}:null}>
                                    <Typography style={{flex:2,}}>{item.catalog_code}</Typography>
                                    <TextField
                                        inputProps={{
                                            style: {width: 60}
                                        }}
                                        value={1}
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                            )}
                            <TextField
                                style={{marginTop:20}}
                                label="Messaggio (facoltativo)"
                                name="message"
                                multiline
                                rowsMax="4"
                                InputLabelProps={{ shrink: true}}
                                required={false}
                                className={classes.textField}
                                value={""}
                                onChange={(e) => this.handleChange(e)}
                                disabled={loader}
                                inputProps={{
                                    maxLength: 1000
                                }}
                            />
                            <TextFieldControlCharacters element={""} length={1000}/>
                        </DialogContent>
                        <DialogActions className={classes.dialogAction}>
                            <Button onClick={() => this.props.onClose()} color="primary">Chiudi</Button>
                            <Button disabled={loader} type="submit" variant="outlined" color="primary">
                                {(!loader)?
                                    "Invia ordine"
                                    :
                                    <CircularProgress className={classes.loader} size={16} style={{marginLeft:0}}/>
                                }
                            </Button>
                        </DialogActions>
                        </div>
                    </form>
                </Dialog>
            </div>
        );
    }
}

export default compose(
    withStyles(styles, {withTheme: true}),
)(OrderComponentsImport);
