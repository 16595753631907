import React, { Component } from 'react';
import {BrowserRouter as Router, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import './App.css';
import RouteManager from './settings/RouteManager';
import { connect } from 'react-redux';
import { compose } from 'redux'
import { updateUser } from './redux/actions/user-action';
import GeneralSettings from './settings/GeneralSettings';
import {LocalStorageKey} from "./utils/LocalStorage";
import Theme from "./settings/Theme";
import AuthService from "./services/AuthService";
import {updateMultilanguage} from "./redux/actions/multilanguage-action";
import LanguageApi from "./components/language-select/LanguageApi";
import TagManager from 'react-gtm-module'
import ApiMessageControl from "./components/api-message-control/ApiMessageControl";
import 'react-image-lightbox/style.css';

const tagManagerArgs = {
    gtmId: 'G-Y71V69MREW'
};

const tagManagerDKCArgs = {
    gtmId: 'G-E2Z63QN7TD'
};

const styles = () => ({
    root: {
        background: '#fff',
        overflowX: 'hidden',
    },
});

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
        };
        AuthService.isAuthenticated();
        this.onUnload = this.onUnload.bind(this);
    }



    componentDidMount(){

        const {user} = this.props

        if (this.props.user && ((this.props.user.company_id && this.props.user.company_id === 103) || (this.props.user.company && this.props.user.company.business_name === 'DKC Tech'))){
            TagManager.initialize(tagManagerDKCArgs);
        }
        else if (this.props.user) {
            TagManager.initialize(tagManagerArgs);
        }


        if ((user.company_id && user.company_id === 103) || (user.company && (user.company.business_name === 'DKC Tech' || user.company.business_name === 'DKC'))){

            const favicon = document.getElementById("favicon");


            favicon.href = 'https://www.dkceurope.com/favicon-32x32.png'

        }

        // refresh prevent
        window.process = { ...window.process };
        window.addEventListener("beforeunload", this.onUnload);

        LanguageApi.getFlags().then(
            res => {
                let multilanguage = {
                    defaultlang : res.data.default_lang,
                    languageValues : res.data.content.items,
                    selectedLanguage : [res.data.default_lang]
                };

                this.props.onUpdateMultilanguage(multilanguage)

            },
            err => {
            }
        );
    }

    componentDidUpdate(prevProps, prevState){

        const {user} = this.props


        if (prevProps.user !== this.props.user) {
            if (this.props.user && ((this.props.user.company_id && this.props.user.company_id === 103) || (this.props.user.company && this.props.user.company.business_name === 'DKC Tech'))){
                TagManager.initialize(tagManagerDKCArgs);
            }
                else if (this.props.user) {
                TagManager.initialize(tagManagerArgs);
            }

        }

        if ((user.company_id && user.company_id === 103) || (user.company && (user.company.business_name === 'DKC Tech' || user.company.business_name === 'DKC'))){

            const favicon = document.getElementById("favicon");


            favicon.href = 'https://www.dkceurope.com/favicon-32x32.png'
        }
    }

    componentWillUnmount(){
        // call the control on exit [STAY_CONNECTED user control]
        window.removeEventListener("beforeunload", this.onUnload)
    }

    onUnload() {
        // the method that will be used for both add and remove event
        // clean the state of redux/localstorage if the user set the var stay_connected to false
        if(!this.props.user.stay_connected){
            const serializedState = localStorage.getItem(LocalStorageKey);
            // clean the user
            delete serializedState.user;
            localStorage.setItem(GeneralSettings.stateKeyLocalStorage,serializedState);
            return '';
        }else{

        }
    }

    render() {
        const { classes } = this.props;

        return (
                <MuiThemeProvider theme={Theme}>
                    <div className="App">
                        <div className={classes.root} >
                            <Router>
                                <main className={classes.content}>
                                    <RouteManager user={this.props.user}/>
                                </main>
                            </Router>
                        </div>
                        <ApiMessageControl/>
                    </div>
                </MuiThemeProvider>
        );
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    language: state.language,
    user: state.user,
    utils: state.utils,
});

const mapActionsToProps = {
    onUpdateUser : updateUser,
    onUpdateMultilanguage : updateMultilanguage,
};

export default compose(
    withRouter,
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps,mapActionsToProps)
)(App);
