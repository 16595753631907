import React, {Component} from 'react';
import PropTypes from 'prop-types';
// material-ui import
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
// assets import
import Logo from '../../../../assets/logos/ubilod_logo-light_bg.svg'
const styles = theme => ({
    headerContainer: {
        marginBottom: 20,
        textAlign: 'center'
    },
    coverImage: {
        marginTop:20,
        marginBottom:20,
        backgroundSize: 'cover',
        width: '100%',
        height: 80,
        flex: 1,
        borderRadius:4
    },
    containerTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        flex: 1
    },
    title: {
        marginTop:18,
        fontSize:20,
        color: theme.palette.primary.main,
        marginBottom:20
    },
    subtitle:{
        fontSize:14,
        color: '#777'
    },
    message: {
        fontSize:14,
        color: theme.palette.secondary.main,
    }
});

class ConfirmDialog extends Component {

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div className={classes.headerContainer} >
                                <img src={Logo} width={180} alt="Ubilod logo"/>
                                <Typography className={classes.title} style={{marginBottom:20}}>{this.props.title}</Typography>
                                <Typography className={classes.subtitle} style={{marginBottom:20}}>{this.props.subtitle}</Typography>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{display: 'flex', justifyContent:'center'}}>
                        <Button onClick={this.props.onClose}
                                variant="contained"
                                color="primary"
                                autoFocus style={{minWidth:200, marginBottom:20}}>
                            {this.props.closeBtn}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ConfirmDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    closeBtn: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(ConfirmDialog);




