import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
// redux
import { compose } from 'redux';
import TableBody from "@material-ui/core/TableBody";
import File from "../../../../../views/application/views/machinesManager/components/File";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FileUtils from "../../../../../utils/FileUtils";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import TableRow from "@material-ui/core/TableRow";
import LanguageSelect from "../../../../language-select/LanguageSelect";
import {translate} from "../../../../../translations/i18n";
import DialogLanguageSelector from "../../../dropzone/components/DialogLanguageSelector";
const styles = theme => ({
    root: {
        width: '100%',
    },
    label: {
        marginTop:-4
    },
    icon:{
        fontSize:22,
        margin:'0 8px',
        color:theme.palette.primary.main
    },
    select: {
        minWidth: 140,
    }
});

class ImportLinkMatch extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            selected: this.props.labelMatch
        }
    }

    handleOnChange(e){
        e.persist();
        let tmpLabelMatch = this.state.selected;
        let index = this.state.selected.map(function (item) {return item.code}).indexOf(e.target.name);
        tmpLabelMatch[index] = e.target.value;
        this.setState(() => ({
            selected:tmpLabelMatch
        }));
    }

    render() {
        const { classes} = this.props;
        const { labelList, handleChangeLanguage, languageValue, values, multilanguage } = this.props;
        const { cvsLinks } = this.props;

        return (
            <div className={classes.root}>
                <Typography component="h1" style={{fontSize : 16 , padding : 14}}>
                    {translate('import_link.step_2.title',{}, "Conferma l'aggiunta dei link importati dal documento caricato")}
                </Typography>

                {multilanguage &&
                    <div style={{padding : '16px 0px', display : 'flex', justifyContent : 'flex-start'}}>
                <DialogLanguageSelector
                    label={
                        translate('import_link.step_2.language_select',{}, 'Seleziona lingua')
                    }
                    onChange={handleChangeLanguage}
                    languageValue={languageValue}
                    values={values}
                />
                </div>}

                <Table className={classes.table}>
                    <TableBody>
                        <TableRow>
                            <TableCell padding="default" style={{width:'50%'}}>
                                <Typography className={classes.fileNameCell}>
                                    {translate('import_link.step_2.table.name',{}, 'Nome')}
                                </Typography>
                            </TableCell>
                            <TableCell padding="default" style={{width:'20%'}}>
                                <Typography className={classes.fileNameCell}>
                                    {translate('import_link.step_2.table.url',{}, 'Url')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        {this.props.cvsLinks && this.props.cvsLinks.map((file, i) =>
                            <TableRow>
                                <TableCell padding="default" style={{width:'50%'}}>
                                    <Typography className={classes.fileNameCell}>{ file.name }</Typography>
                                </TableCell>
                                <TableCell padding="default" style={{width:'20%'}} onClick={() => window.open(file.link)}>
                                        <Typography className={classes.fileNameCell}>{ file.link }</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
        );
    }
}

ImportLinkMatch.propTypes = {
    classes: PropTypes.object,
};

export default compose(withStyles(styles, {withTheme: true}))(ImportLinkMatch);
