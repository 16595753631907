import React from 'react';
// material ui import
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import {translate} from "../../../../../translations/i18n";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles  = theme => ({
    dialogPaper: {
        [theme.breakpoints.down('xs')]:{
            margin: 10,
        },
    },
    textField: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        width: '47%',
        padding: '0px 0px 16px'
    },
    textFieldLink:{
        '& input':{
            cursor:'pointer',
            //color: theme.palette.active.main,
        },
        [theme.breakpoints.down('xs')]:{
            width : '100% !important'
        }
    },
    container: {
        display:'flex',
        flexDirection:'column',
        marginTop:8
    },
    dialogAction: {
        margin: '0px 20px',
        marginBottom:20,
    }
});


class CreateContactDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            contact:{
                id: null,
                label:null,
                email:null,
                phone_number:null,
                skype:null,
            }
        }
    }

    componentDidMount(){
        const props = this.props;
        this.setState({
            contact:{
                id: (props.contact)?props.contact.id:null,
                label: (props.contact)?props.contact.label:null,
                email: (props.contact)?props.contact.email:null,
                phone_number: (props.contact)?props.contact.phone_number:null,
                skype: (props.contact)?props.contact.skype:null,
            }
        })
    }

    handleChange = (e) => {
        e.persist();
        this.setState(prevState => ({
            contact:{
                ...prevState.contact,
                [e.target.name] : e.target.value
            }
        }));
    }

    render() {
        const { contact } = this.state;
        const { classes, open, loader } = this.props;

        return (
            <div>
                <Dialog
                    open={open}
                    PaperProps={{ classes: {root: classes.dialogPaper } }}
                    onClose={this.props.onClose}>
                    {/* Print edit contact on edit - Print the label on readOnly*/}
                    {(this.props.readOnly)?
                        <DialogTitle>{contact.label}</DialogTitle>
                    :
                        <DialogTitle>{this.props.title}</DialogTitle>
                    }
                    <form onSubmit={(e)=> {e.preventDefault(); this.props.onCreate(contact)}}
                          className={classes.container}
                          onKeyPress={(e) => (e.which === 13)?e.preventDefault():null}>
                        <div>
                            <DialogContent style={{paddingTop:0}}>
                                {(!this.props.readOnly)?
                                    <DialogContentText>
                                    Compila i campi sottostanti
                                    </DialogContentText>
                                :null}
                                <div style={{display : 'flex', flexWrap : 'wrap', justifyContent : 'space-between'}}>
                                {(!this.props.readOnly)?
                                    <TextField
                                        required={!this.props.readOnly}
                                        label={translate( `general.label`, {},  "Etichetta")}
                                        name="label"
                                        InputLabelProps={{shrink:true}}
                                        variant={'outlined'}
                                        className={classes.textField}
                                        value={contact.label}
                                        onChange={(e) => this.handleChange(e)}
                                        disabled={loader}
                                        InputProps={{
                                            readOnly: this.props.readOnly,
                                            disableUnderline: this.props.readOnly,
                                        }}
                                    />
                                :null}
                                {(!this.props.readOnly || contact.email)?
                                    <a
                                        style={{width : (window.innerWidth <= 480) ?  '100%': '47%'}}
                                        className={classes.textField} href={(this.props.readOnly)?"mailto:" + contact.email:'#'}>
                                    <TextField
                                        label="Email"
                                        name="email"
                                        type="email"
                                        variant={'outlined'}
                                        style={{width : '100%'}}
                                        InputLabelProps={{shrink:true}}
                                        className={[(this.props.readOnly)?classes.textFieldLink:''].join(' ')}
                                        value={contact.email}
                                        onChange={(e) => this.handleChange(e)}
                                        disabled={loader}
                                        InputProps={{
                                            readOnly: this.props.readOnly,
                                            disableUnderline: this.props.readOnly,
                                        }}
                                    />
                                    </a>
                                :null}
                                {(!this.props.readOnly || contact.phone_number)?
                                    <a style={{alignItems: 'center', display: 'flex'}} className={classes.textField} href={(this.props.readOnly)?"tel:" + contact.phone_number:'#'}>
                                    <TextField
                                        label={translate( `general.phone_number`, {},  "Numero di telefono")}
                                        name="phone_number"
                                        type="tel"
                                        InputLabelProps={{shrink:true}}
                                        variant={'outlined'}
                                        style={{width : '100%'}}
                                        className={[(this.props.readOnly)?classes.textFieldLink:''].join(' ')}
                                        value={contact.phone_number}
                                        onChange={(e) => this.handleChange(e)}
                                        disabled={loader}
                                        InputProps={{
                                            readOnly: this.props.readOnly,
                                            disableUnderline: this.props.readOnly,
                                        }}
                                    />
                                    </a>
                                :null}
                                {(!this.props.readOnly || contact.skype)?
                                    <a className={classes.textField}  href={(this.props.readOnly)?"tel:" + contact.skype:'#'}>
                                    <TextField
                                        label="Skype"
                                        name="skype"
                                        InputLabelProps={{shrink:true}}
                                        className={[(this.props.readOnly)?classes.textFieldLink:''].join(' ')}
                                        value={contact.skype}
                                        style={{width : '100%'}}
                                        variant={'outlined'}
                                        onChange={(e) => this.handleChange(e)}
                                        disabled={loader}
                                        InputProps={{
                                            readOnly: this.props.readOnly,
                                            disableUnderline: this.props.readOnly,
                                        }}
                                        margin="normal"
                                    />
                                    </a>
                                :null}
                                {(this.props.readOnly && !contact.email && !contact.phone_number && !contact.skype)?
                                    <Typography>Non sono presenti dati relativi al contatto</Typography>
                                :null}
                                </div>
                            </DialogContent>
                            <DialogActions className={classes.dialogAction}>
                            <Button onClick={() => this.props.onClose()} color="primary">
                                {(!this.props.readOnly)?
                                    translate( `general.cancel`, {},  "Annulla")
                                    :
                                    translate( `general.close`, {},  "Chiudi")
                                }
                            </Button>
                            {(!this.props.readOnly)?
                                <Button type="submit" variant="outlined" color="primary">
                                    {(!loader)?
                                        (!contact.id)?
                                            translate( `general.create`, {},  "Crea")
                                            :
                                            translate( `general.edit`, {},  "Modifica")
                                        :
                                        <CircularProgress size={16} style={{marginLeft:0}}/>
                                    }
                                </Button>
                            :null}
                        </DialogActions>
                        </div>
                    </form>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles, {withTheme: true})(CreateContactDialog);
