import API from './API';

class ProductApi {

    static url = 'product';


    // ========================================= //
    // MACHINE
    // ========================================= //

    static getProducts(query = {'q': ''}){
        const api = new API(this.url);
        return api.get(query);
    }

    static downloadFile(product_id, file_id){
        const api = new API(`${this.url}/${product_id}/file-download/${file_id}`);
        return api.get();
    }

    static getProductById(id, query) {
        const api = new API(this.url);
        return api.getById(id, query);
    }

    static createProduct(model) {
        const api = new API(this.url);
        return api.post(model);
    }

    static addProductLabels(model, id, flag) {
        const api = new API(`core/${this.url}/${id}/label/${flag}`);
        return api.post(model);
    }


    static editProductLabels(model, id, flag) {
        const api = new API(`core/${this.url}/${id}/label/${flag}`);
        return api.update(model);
    }

    static deleteProductLabels(model, id, flag) {
        const api = new API(`core/${this.url}/${id}/label/${flag}`);
        return api.delete();
    }

    static updateProduct(id, model) {
        const api = new API(`${this.url}/${id}`);
        return api.update(model);
    }

    static deleteProduct(id) {
        const api = new API(this.url);
        return api.deleteById(id);
    }

    static filterProduct(query) {
        const api = new API(`${this.url}/filter`);
        return api.get(query)
    }

    static getProductByToken(token) {
        const api = new API(`${this.url}/scan/${token}` );
        return api.getUnsafe()
    }

    // ========================================= //
    // CONTACTS
    // ========================================= //

    static getContact(id) {
        const api = new API(`${this.url}/${id}/contact`);
        return api.get();
    }

    static addContact(productId, contactId) {
        const api = new API(`${this.url}/${productId}/contact/${contactId}`);
        return api.post({});
    }

    static removeContact(productId, contactId) {
        const api = new API(`${this.url}/${productId}/contact/${contactId}`);
        return api.delete();
    }

    // ========================================= //
    // CATEGORY
    // ========================================= //

    static addCategroy(productId, categoryId) {
        const api = new API(`${this.url}/${productId}/category/${categoryId}`);
        return api.post({});
    }

    static addCategoryLabels(model, id, lang) {
        const api = new API(`core/${this.url}/${id}/label/${lang}`);
        return api.post(model);
    }

    static deleteCategroy(productId, categoryId) {
        const api = new API(`${this.url}/${productId}/category/${categoryId}`);
        return api.delete();
    }

    // ========================================= //
    // FILE
    // ========================================= //

    static getFile(productId, categroyId, lang, query) {
        const api = new API(`${this.url}/${productId}/category/${categroyId}/file${lang}`);
        return api.get(query);

    }

    static getFileById(productId, fileId) {
        const api = new API(`${this.url}/${productId}/file/${fileId}`);
        return api.getFormData();
    }

    static getFileUrlById(productId, fileId) {
        const api = new API(`${this.url}/${productId}/file-url/${fileId}`);
        return api.get();
    }

    static createFile(productId, categoryId, data) {
        const api = new API(`${this.url}/${productId}/category/${categoryId}/file`);
        return api.post(data);
    }

    static associateFile(codeId , fileId){
        const api = new API(`flag/${codeId}/file/${fileId}`);
        return api.post();
    }

    static dissociateFile(codeId , fileId){

        const api = new API(`flag/${codeId}/file/${fileId}`);
        return api.delete();

    }


    static uploadFile(productId, categoryId, data) {
        const api = new API(`${this.url}/${productId}/category/${categoryId}/file`);
        return api.postFormData(data);
    }

    static deleteFileById(productId, fileId) {
        const api = new API(`${this.url}/${productId}/file`);
        return api.deleteById(fileId)
    }

    static deleteLinkById(productId, categroyId, fileId, type) {

        const api =
            (type === 'link') ?
            new API(`${this.url}/${productId}/category/${categroyId}/links/${fileId}`)
                :
                new API(`${this.url}/${productId}/category/${categroyId}/file/${fileId}`)

        return api.delete()
    }

    static updateLinkLanguage(productId, categroyId, fileId, item, type) {

        const api =
            (type === 'link') ?
                new API(`${this.url}/${productId}/category/${categroyId}/links/${fileId}`)
                :
                new API(`${this.url}/${productId}/category/${categroyId}/file/${fileId}`)

        return api.update(item)
    }





    static getBackup(productId) {
        const api = new API(`${this.url}/${productId}/backup`);
        return api.download()
    }

    static addFileLabels(model, id,lang) {
        const api = new API(`core/file/${id}/label/${lang}`);
        return api.post(model);
    }

    static editFileLabels(model, id,lang) {
        const api = new API(`core/file/${id}/label/${lang}`);
        return api.update(model);
    }

    static deleteFileLabels(model, id,lang) {
        const api = new API(`core/file/${id}/label/${lang}`);
        return api.delete(model);
    }

    static addLinkLabels(model, id, lang, test){

        const api = new API(`core/link/${id}/label/${lang}`);
        return api.post(model);
    }

    static editLinkLabels(model, id,lang){
        const api = new API(`core/link/${id}/label/${lang}`);
        return api.update(model);
    }

    static deleteLinkLabels(model, id,lang) {
        const api = new API(`core/link/${id}/label/${lang}`);
        return api.delete(model);
    }

    // ========================================= //
    // COMPONENTS
    // ========================================= //

    static getComponent(productId){
        const api = new API(`${this.url}/${productId}/component`);
        return api.get();
    }

    static getComponentById(productId, componentId) {
        const api = new API(`${this.url}/${productId}/component`);
        return api.getById({id: componentId});
    }

    static createComponent(productId, data) {
        const api = new API(`${this.url}/${productId}/component`);
        return api.post(data);
    }

    static updateComponent(productId, data) {
        const api = new API(`${this.url}/${productId}/component`);
        return api.update(data);
    }

    static deleteComponentById(productId, componentId) {
        const api = new API(`${this.url}/${productId}/component`);
        return api.deleteById(componentId);
    }

     static importComponent(productId, categoryId, data) {

         const api = new API(`${this.url}/${productId}/category/${categoryId}/import`);
         return api.postFormData(data)
     }

     static importComponentMatch(data) {
         const api = new API(`${this.url}/component/import/match`);
         return api.postFormData(data)
     }

    // ========================================= //
    // PERMISSIONS
    // ========================================= //

    static getPermission(productId, userId) {
        const api = new API(`${this.url}/${productId}/permission/user/${userId}`);
        return api.get()
    }

    static addPermission(productId, categoryId, userId, mod) {
        const api = new API(`${this.url}/${productId}/category/${categoryId}/permission/user/${userId}`);
        return api.post({}, {mod: mod})
    }

    static removePermission(productId, categoryId, userId, mod) {
        const api = new API(`${this.url}/${productId}/category/${categoryId}/permission/user/${userId}`);
        return api.delete({mod: mod})
    }

    static getPermissionUsers(productId, params) {
        const api = new API(`${this.url}/${productId}/permission/user`);
        return api.get(params)
    }

    static deletePermissionUsers(productId, users_id) {
        const api = new API(`${this.url}/${productId}/permission/user`);
        return api.delete({users: users_id})
    }

    static getPermissionUsersSearch(productId, query) {
        const api = new API(`${this.url}/${productId}/permission/user/s`);
        return api.get({q: query})
    }

    static addAdmin(productId, userId) {
        const api = new API(`${this.url}/${productId}/admin/${userId}`);
        return api.post()
    }

    static removeAdmin(productId, userId) {
        const api = new API(`${this.url}/${productId}/admin/${userId}`);
        return api.delete()
    }
}

export default ProductApi
