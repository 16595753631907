import React, { Component } from 'react';
import PropTypes from 'prop-types';
// material ui import
import { withStyles } from '@material-ui/core/styles';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateApiControl } from '../../../../redux/actions/api-control-action';
// api service import
import ContactApi from '../../../../services/ContactApi';
// containers import
import BackofficeViewContainer from '../../layout/container/AdminContainer';
// components import
import BoxCard from '../../../../components/ui/cards/BoxCard';
import Search from '../../../../components/ui/search/Search';
import SnackbarLoader from '../../../../components/ui/loaders/snackbar/SnackbarLoader';
import CreateContactDialog from './components/CreateContactDialog';
import AlertDialog from '../../../../components/ui/dialogs/alertDialog/AlertDialog';
import {translate} from "../../../../translations/i18n";

const styles  = () => ({

});

class ContactsManager extends Component {

    constructor(props){
        super(props);
        this.state = {
            contactsList:[],
            contactsFound:[],
            loader:true,
        }
    }

    // ==================================================
    // GET THE CONTACT LIST
    // ==================================================
    componentDidMount(){
        this.setState({ loader: true });
        ContactApi.getContact().then(
            res => {
                this.setState({
                    contactsList: res.data,
                    contactsFound: res.data,
                    loader:false,
                })
            },
            err => {
                this.setState({ loader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'loadContactManager', );
            }
        )
    }

    // ==================================================
    // CREATE CONTACT
    // ==================================================
    createContact(newContact){
        this.setState({ loader: true });
        ContactApi.createContact(newContact).then(
            res => {
                this.setState(prevState => ({
                    contactsList:  [...prevState.contactsList, res.data],
                    contactsFound: [...prevState.contactsFound, res.data],
                    loader:false,
                    openCreateContactDialog: false,
                }));
            },
            err => {
                this.setState({ loader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'createContact', );
            }
        )
    }

    // ==================================================
    // DELETE CONTACT
    // ==================================================
    deleteContact(id){
        this.setState({ loader: true });
        ContactApi.deleteContact(id).then(
            () => {
                let deleteIndex = this.state.contactsList.map(function (item) {return item.id}).indexOf(id);
                this.setState(prevState => ({
                    contactsList:  prevState.contactsList.filter((_, i) => i !== deleteIndex),
                    contactsFound: prevState.contactsFound.filter((_, i) => i !== deleteIndex),
                    loader:false,
                    openDeleteContactDialog: false,
                }));
            },
            err => {
                this.setState({ loader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'deleteContact', );
            }
        )
    }

    // ==================================================
    // UPDATE CONTACT
    // ==================================================
    updateContact(updateContact){
        this.setState({ loader: true });
        ContactApi.updateContact(updateContact.id, updateContact).then(
            res => {
                let contactsList = this.state.contactsList;
                let updateIndex = contactsList.map(function (item) {return item.id}).indexOf(updateContact.id)
                contactsList[updateIndex] = res.data;
                this.setState(() => ({
                    contactsList:  contactsList,
                    contactsFound: contactsList,
                    loader:false,
                    openUpdateContactDialog: false,
                }));
            },
            err => {
                this.setState({ loader: false });
                // ====== API CONTROL MESSAGE ====== //
                this.props.onUpdateApiControl(err, 'updateContact', );
            }
        )
    }


    render() {
        const { classes, license} = this.props;
        const { contactsList, contactsFound } = this.state;
        const { loader } = this.state;

        return (
            <div className={classes.root}>
                <BackofficeViewContainer
                    title={translate(`contacts.title`,{},"Contatti")}
                    subtitle={translate( `contacts.subtitle.${license.license}`, {}, "Crea, modifica ed elimina i contatti abbinabili ad ogni commessa.")}
                    renderSearch={
                        <Search data={contactsList} // data list where the component search
                                keySearch={"label"} // key that have to search
                                placeholder={translate(`contacts.search`,{}, "Cerca per contatto")} // placeholder
                                onResult={(searchResult) => this.setState({contactsFound: searchResult})}/> // action after search
                    }>

                    {/* CREATE CONTACT BOX CARD */}
                    <BoxCard variant={"add"}
                             title={"+"}
                             disabled={this.state.loader}
                             subtitle={translate(`contacts.add`,{},"Aggiungi contatto")}
                             onClick={() => this.setState({openCreateContactDialog:true})}/>

                    {/* CONTACT LIST BOX CARDS */}
                    {contactsFound.map((contact, index) =>
                            <BoxCard key={contact + '-' + index}
                                     title={contact.label}
                                     subtitle={contact.email}
                                     //link={`contact/${contact.id}`}
                                     onClick={() => this.setState({openUpdateContactDialog:true, activeUpdateContact:contact, contactDialogReadOnly: true})}
                                     onDelete={() => {this.setState({openDeleteContactDialog:true, activeDeleteId:contact.id})}}
                                     onUpdate={() => this.setState({openUpdateContactDialog:true, activeUpdateContact:contact, contactDialogReadOnly:false})}/>
                    )}

                    {/* CREATE CONTACT DIALOG */}
                    {(this.state.openCreateContactDialog)?
                    <CreateContactDialog title={translate(`contacts.create_modal`,{}, "Crea contatto")}
                                         loader={loader}
                                         open={this.state.openCreateContactDialog}
                                         onClose={() => this.setState({openCreateContactDialog:false})}
                                         onCreate={(newContact) => this.createContact(newContact)}/>
                    :null}

                    {/* UPDATE CONTACT DIALOG */}
                    {(this.state.openUpdateContactDialog)?
                    <CreateContactDialog title={translate(`contacts.edit_modal`,{}, "Modifica contatto")}
                                         loader={loader}
                                         contact={this.state.activeUpdateContact}
                                         open={this.state.openUpdateContactDialog}
                                         readOnly={this.state.contactDialogReadOnly} // disable the edit
                                         onClose={() => this.setState({openUpdateContactDialog:false, contactDialogReadOnly:false})}
                                         onCreate={(updateContact) => this.updateContact(updateContact)}/>
                    :null}

                    {/* DELETE CONFIRM DIALOG */}
                    <AlertDialog open={this.state.openDeleteContactDialog}
                                 title={translate(`contacts.delete_modal`, "Eliminazione contatto")}
                                 message={translate(`contacts.delete_modal_message`, {}, "Sei sicuro di voler eliminare il contatto?")}
                                 confirmBtnText={translate(`contacts.delete_modal_button`, {}, "Elimina")}
                                 onClose={() => this.setState({openDeleteContactDialog:false})}
                                 onConfirm={() => this.deleteContact(this.state.activeDeleteId)}/>

                    {/* LOADER */}
                    <SnackbarLoader open={loader} message={translate(`contacts.loader`,{}, "Elaborazione in corso")}/>
                </BackofficeViewContainer>
            </div>
        )
    }
}


ContactsManager.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user,
    utils: state.utils,
    license: state.license,
    api_control: state.api_control
});


const mapActionsToProps = {
    onUpdateApiControl : updateApiControl
}

export default compose(
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapActionsToProps)
)(ContactsManager);
