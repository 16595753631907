import React, {useEffect, useState} from "react";
import {Table, TableHead, TableCell, TableRow,TableContainer, TableBody, Typography, Box, TableSortLabel, TablePagination} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {func} from "prop-types";
import {translate} from "../../translations/i18n";



const useStyles = makeStyles(theme => ({
    headText : {
        fontSize : 14,
        fontStyle : 'italic',
        color : '#878787'
    },
    dataText : {
        fontSize : 14,
        color : '#000'
    },
    headCell : {
        flex : 1,
        borderBottom : 0
    },
    dataCell : {
        flex : 1,
        borderColor : '#000',
        padding : '24px 16px !important',
    }


}));

export default function AutoTable(props) {

    const classes = useStyles(props);
    const {fields = [], data = [], rowStyle = () => {}, cellStyle = {}, textStyle = {}, noDataMessage = 'Non ci sono dati in questa tabella', loader,
        page, count, handleChangePage = () => null, pagination} = props;
    const [order, setOrder] = React.useState('asc');
    const [pageLocal, setPageLocal] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [orderBy, setOrderBy] = React.useState('');
    const [localData, setLocalData] = React.useState(data);


    useEffect(() => {


        const tmpData = [...data];

        if (pagination === 'local'){
            setPageLocal(0);
            setLocalData(tmpData.slice(pageLocal * rowsPerPage, pageLocal * rowsPerPage + rowsPerPage))
        }else{
            setLocalData(tmpData)
        }
    }, [data]);


    function handleChangePageLocal(event, newPage){
        setPageLocal(newPage);
        setLocalData(data.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage))
    };

    const handleChangeRowsPerPage = (event) => {

        setRowsPerPage(parseInt(event.target.value, 10));
        setPageLocal(0);
        setLocalData(data.slice(pageLocal * parseInt(event.target.value, 10), pageLocal * parseInt(event.target.value, 10) + parseInt(event.target.value, 10)))
    };

    function descendingComparator(a, b, orderBy){
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        const isDesc = orderBy === property && order === 'desc';

        if (isAsc) {
            setOrder('desc');
            setOrderBy(property)
        }
        else if (isDesc){
            setOrder('asc');
            setOrderBy('')
        }else{
            setOrder('asc');
            setOrderBy(property)
        }
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    return (
        <div style={
            {width : '100%',
            position : 'relative',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'flex-start',
            overflow: 'auto'
        }}>
            <Table>
                <TableHead>
                    <TableRow>
                        {fields.map(item =>
                            <TableCell className={classes.headCell}>
                                {item.sort === true ?
                                <TableSortLabel
                                    active={orderBy === item.key}
                                    direction={orderBy === item.key ? order : 'asc'}
                                    onClick={createSortHandler(item.key)}>
                                    <Typography className={classes.headText}>
                                        {item.label}
                                    </Typography>
                                </TableSortLabel>
                                :
                                    <Typography className={classes.headText}>
                                        {item.label}
                                    </Typography>
                                }
                            </TableCell>
                        )
                        }
                    </TableRow>
                </TableHead>
                {loader ?
                    <div style={{display : 'flex', flexDirection : 'column', justifyContent : 'center', alignItems : 'center', minHeight : 500, minWidth : '100%', position: 'absolute', }}>
                        <CircularProgress size={32}/>
                        <Typography className={classes.text}>
                            {translate( `bulk_import.fileLoader`, {},  "Caricamento dati..." )}
                        </Typography>
                    </div>
                    :
                    localData && localData.length > 0 ?
                        <TableBody>
                        {localData.map((row) => (
                        <TableRow style={rowStyle(row)}>
                        {fields.map(item =>
                                <TableCell component="th" scope="row" className={classes.dataCell} style={{...cellStyle}}>
                                    {
                                        item.render ?
                                            item.render(row)
                                            :
                                            <Typography className={classes.dataText} style={{...textStyle}}>
                                                {row[item.key]}
                                            </Typography>
                                    }
                                </TableCell>
                        )}
                            </TableRow>
                    ))}
                        </TableBody>
                    :
                        <Typography
                        style={{
                            fontSize: 16,
                            width: '100%',
                            textAlign: 'end',
                            position: 'absolute',
                            right: '43%',
                            padding: '50px 0px',
                            fontWeight: 700
                        }}
                        >
                            {noDataMessage}
                        </Typography>
                    }
            </Table>
                {pagination &&
                pagination === 'local' ?
                    loader === false &&
                    <TablePagination
                        component={'div'}
                        rowsPerPageOptions={[50, 100, 250]}
                        count={count}
                        showFirstButton
                        showLastButton
                        rowsPerPage={rowsPerPage}
                        page={pageLocal}
                        onPageChange={handleChangePageLocal}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    :
                    loader === false &&
                <TablePagination
                    count={count}
                    rowsPerPage={50}
                    page={page}
                    onPageChange={handleChangePage}
                />}
        </div>
    );
}
